import type {StartDetailState} from "./startDetailsReducer";

// RACE
export const getRaceDetails = (state: StartDetailState) =>
    state.startDetails.all.details || [];

export const getRaceDetailsError = (state: StartDetailState) =>
    state.startDetails.all.error;

export const getIsRaceLoading = (state: StartDetailState) =>
    state.startDetails.all.isLoading;

// START
export const getDetailsForStart = (startId: string | null) => (state: StartDetailState) =>
    startId && state.startDetails?.data[startId]
        ? state.startDetails.data[startId]
        : null;
export const getCurrentlyShownStart = (state: StartDetailState) =>
    state.startDetails.current || {startId: "", gameId: ""};

export const getIsLoadingStartDetails = (state: StartDetailState) =>
    // @ts-expect-error
    state.startDetails.isPending[getCurrentlyShownStart(state).startId];

export const getStartDetailsError = (state: StartDetailState) =>
    // @ts-expect-error
    state.startDetails.error[getCurrentlyShownStart(state).startId];

// VIDEO
export const getStartDetailsVideoRace = (state: StartDetailState) =>
    state.startDetails.video?.race;

export const getStartDetailsVideoSource = (state: StartDetailState) =>
    state.startDetails?.video?.videoSource;
