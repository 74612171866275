import type {Reducer} from "redux";
import {applyPatch} from "fast-json-patch";
import {cloneDeep, omit} from "lodash";
import type {CalendarAPITypes} from "@atg-horse-shared/racing-info-api";
import {createFetchReducer} from "@atg-shared/fetch-redux";
import {type FetchReducer, type FetchState} from "@atg-shared/fetch-types";
import * as CalendarActions from "./calendarActions";
import {addGameTypeToCalendarGames} from "./calendar";
import type {CalendarDay} from "./calendar";
import type {Action} from ".";

export type CalendarDayState = {
    day: CalendarDay | null;
};

/**
 * for the `calendar` each day is loaded separately, thus `FetchState<CalendarDayState>`
 */
export type CalendarState = {
    [key in string]: FetchState<CalendarDayState>;
};

/**
 * for the `sportsCalendar` all data is loaded at the same time
 */
export type SportCalendarState =
    FetchState<CalendarAPITypes.GaloppCalendarResponse | null>;

// @ts-expect-error
const calendarDay: FetchReducer<CalendarDayState, Action> = createFetchReducer(
    CalendarActions.REQUEST_CALENDAR,
    CalendarActions.RECEIVE_CALENDAR,
    CalendarActions.RESET_STATUS,
    (state: any, action: Action) => {
        if (action.type === CalendarActions.RECEIVE_CALENDAR) {
            if (action.error) {
                return {
                    day: null,
                };
            }

            return {
                day: addGameTypeToCalendarGames({
                    ...action.payload,
                    version: action.payload.currentVersion || action.payload.version || 0,
                }),
            };
        }

        if (action.type === CalendarActions.RECEIVE_CALENDAR_PUSH) {
            const {patch} = action.payload;
            // @ts-expect-error
            const newDay = applyPatch(cloneDeep(state.day), patch).newDocument;
            return {
                day: addGameTypeToCalendarGames(newDay),
            };
        }

        return state;
    },
    {day: null},
);

export const calendar = (state: CalendarState = {}, action: Action) => {
    switch (action.type) {
        case CalendarActions.REQUEST_CALENDAR:
        case CalendarActions.RECEIVE_CALENDAR:
        case CalendarActions.RECEIVE_CALENDAR_PUSH: {
            if (!action.context) return state;

            const {date} = action.context;
            return {
                ...state,
                [date]: calendarDay(state[date], action),
            };
        }
        case CalendarActions.STOP_LISTENING_TO_CALENDAR_PUSH: {
            const {date, removeData} = action.payload;

            if (!removeData) return state;
            if (!state[date]) return state;

            return omit(state, date);
        }
        default:
            return state;
    }
};

// @ts-expect-error
export const sportCalendar: FetchReducer<
    CalendarAPITypes.GaloppCalendarResponse,
    Action
    // buggy flow, should be fixeable once `FetchState` in `atg-fetch-redux` has been updated to use
    // type spread (`type A = {...typeB, ...typeC}`)
    // @ts-expect-error
> = createFetchReducer(
    CalendarActions.REQUEST_SPORT_CALENDAR,
    CalendarActions.RECEIVE_SPORT_CALENDAR,
    "",
    (state: FetchState<CalendarAPITypes.GaloppCalendarResponse>, action) => {
        const {type, payload, error} = action;
        switch (type) {
            case CalendarActions.REQUEST_SPORT_CALENDAR:
                return null;
            case CalendarActions.RECEIVE_SPORT_CALENDAR:
                if (error) return state;
                return payload;
            default:
                return state;
        }
    },
);
