import type {ExtendedStartState, ExtendedStarts} from "./extendedStart.flow";

export type GlobalState = {
    extendedStart: ExtendedStartState;
};

export const getExtendedStartsForRace = (
    state: GlobalState,
    raceId: string,
): ExtendedStarts => state.extendedStart.data[raceId];

export const getExtendedStartsForRaces = (
    state: GlobalState,
    raceIds: Array<string>,
): Array<ExtendedStarts> => raceIds?.map((raceId) => state.extendedStart.data[raceId]);

export const getIsLoadingExtendedStart = (state: GlobalState, raceId: string): boolean =>
    state.extendedStart.isPending[raceId];

export const getExtendedStartError = (state: GlobalState, raceId: string): boolean =>
    state.extendedStart.error[raceId];
