import {find} from "lodash";
import dayjs from "dayjs";
import {assertNever} from "@atg/utils";
import * as Time from "@atg-shared/server-time";
import type {
    GameType,
    GameStatus,
    GameTypeWithoutLegacy,
} from "@atg-horse-shared/game-types";
import type {HarryFlavor} from "@atg-horse-shared/bet-types";
import {t} from "@lingui/macro";

interface TrackIdentity {
    id: number;
    name: string;
}
/**
 * @property name is the short format e.g. "favorit". **name is also used for test ids**.
 * @property label is the full format e.g. "Harry Boy favorit".
 * @property analyticsName flavor in the format that analytics expect to get.
 * @property description e.g. "Fler favoriter, oftare vinster".
 * @property accentColor hexColor
 * @property gradient tuple to and from hexColor
 */
export type HarryFlavorInfo = {
    name: string;
    label: string;
    analyticsName?: "" | "chans" | "favorit";
    description: string;
    appDescription?: string;
    accentColor: string;
    gradient: [string, string];
};

/**
 * This represents the simplified (fewer attributes) game object that is returned from the
 * RacingInfo `/harry` endpoint.
 *
 * Redux state: `state.simpleHarry`
 * API endpoint: `/services/racinginfo/v1/api/harry`
 */
export interface SimpleHarryGame {
    addOns?: Array<string>;
    date: string;
    id: string;
    startTime: string;
    status: GameStatus;
    tracks: Array<TrackIdentity>;
    type: GameType;
    harryFlavour?: HarryFlavor;
}

/**
 * A **Harry bag bet** represents the specific bet that Harry Boy will place, when the user has
 * purchased a Harry bag. Usually a bag contains several bets.
 *
 * Also see `HarryBag` and `HarryBagGroup`.
 *
 * API endpoint: `/services/v1/products/harry/bags`
 */
export interface HarryBagBet {
    betType: GameTypeWithoutLegacy;
    cost: number;
    raceNumber: number;
    trackId: number;
    addOns?: Array<string>;
}

/**
 * A **Harry bag** contains one or more bets, that Harry Boy places on behalf of the user. Unlike
 * a normal bet, the user only needs to select and purchases a bag and all bets inside will be
 * placed at once.
 *
 * Also see `HarryBagBet` and `HarryBagGroup`.
 *
 * API endpoint: `/services/v1/products/harry/bags`
 */
export interface HarryBag {
    id?: number;
    cost: number;
    bets: Array<HarryBagBet>;
}

/**
 * A **Harry bag group** is a collection of one or more Harry bags. Each bag contains one or more
 * bets, that Harry Boy will place on behalf of the user. Usually each bag inside a group is priced
 * differently, to give the user some flexibility. After selecting a Harry bag group, the user will
 * also need to select a specific Harry bag before they can continue with their purchase.
 *
 * Also see `HarryBag` and `HarryBagBet`.
 *
 * API endpoint: `/services/v1/products/harry/bags`
 */
export interface HarryBagGroup {
    id: number;
    firstGameDate: string;
    name: string;
    version: number;
    bags: Array<HarryBag>;
}

/**
 * Can be used to get a complete HarryFlavor object with all meta/brand data.
 * ```ts
 *  const { name, label } = getHarryFlavorInfo("ORIGINAL")
 * ```
 */
const lineBreak = "\n";
export const getHarryFlavorInfo = (harryFlavor: HarryFlavor): HarryFlavorInfo => {
    switch (harryFlavor) {
        case "CHANCE":
            return {
                name: "chans",
                label: t`Harry Boy Chans`,
                analyticsName: "chans",
                description: t`Fler skrällar, större vinster`,
                appDescription: t`Med fler skrällar får du ${lineBreak}större vinster`,
                accentColor: "#d31f47",
                gradient: ["#CD364A", "#942F54"],
            };
        case "FAVORITE":
            return {
                name: "favorit",
                label: t`Harry Boy Favorit`,
                analyticsName: "favorit",
                description: t`Fler favoriter, oftare vinster`,
                appDescription: t`Med fler favoriter får du ${lineBreak}oftare vinster`,
                accentColor: "#bad12e",
                gradient: ["#96B85D", "#6A9134"],
            };
        case "ORIGINAL":
        default:
            return {
                name: "original",
                label: t`Harry Boy`,
                analyticsName: undefined,
                description: t`Både skrällar och favoriter`,
                appDescription: t`En blandning av både skrällar ${lineBreak}och favoriter`,
                accentColor: "#006db6",
                gradient: ["#006db6", "#1c3885"],
            };
    }
};

/**
 * Can be used to map legacy value to HarryFlavor.
 * @param legacyHarryBoyFlavor e.g. chans/favorit.
 * ```ts
 *  const harryFlavor = mapHarryFlavorByLegacyName("chans") // "CHANCE"
 * ```
 */
export const mapHarryFlavorByLegacyName = (
    legacyHarryFlavor: "chans" | "favorit" | "original" | string | undefined | null,
): HarryFlavor => {
    if (!legacyHarryFlavor) {
        return "ORIGINAL";
    }

    switch (legacyHarryFlavor.toLowerCase()) {
        case "chans":
            return "CHANCE";
        case "favorit":
            return "FAVORITE";
        default:
            return "ORIGINAL";
    }
};

/**
 * Can be used to map HarryFlavor to legacy compatible bet endpoint value.
 * ```ts
 *  const legacyHarryFlavor = mapHarryFlavorToLegacyBetValue("CHANCE") // "chans"
 * ```
 */
export const mapHarryFlavorToLegacyBetValue = (
    harryFlavor: HarryFlavor,
): "chans" | "favorit" | undefined => {
    switch (harryFlavor) {
        case "CHANCE":
            return "chans";
        case "FAVORITE":
            return "favorit";
        default:
            return undefined;
    }
};

const getDaysUntilGame = (game: SimpleHarryGame | undefined, today: dayjs.Dayjs) =>
    game ? dayjs(game.startTime).diff(today, "day") : Number.MAX_SAFE_INTEGER;

/**
 * Find and display the next playable big game (V75, V86 or GS75)
 */
export function getHarryGameInFocus(games?: Array<SimpleHarryGame>) {
    if (!games) return undefined;

    const V75 = find(games, {type: "V75"});
    const V86 = find(games, {type: "V86"});
    const GS75 = find(games, {type: "GS75"});

    const today = dayjs(Time.serverTime(false));

    const daysUntilV75 = getDaysUntilGame(V75, today);
    const daysUntilV86 = getDaysUntilGame(V86, today);
    const daysUntilGS75 = getDaysUntilGame(GS75, today);

    const v75IsNext =
        V75 && daysUntilV75 <= daysUntilV86 && daysUntilV75 <= daysUntilGS75;
    const v86IsNext = V86 && daysUntilV86 <= daysUntilGS75;

    if (v75IsNext) return V75;
    if (v86IsNext) return V86;
    return GS75;
}
