import * as React from "react";
import type {Props as SvgIconPropType} from "../components/SvgIcon";
import SvgIcon from "../components/SvgIcon";

export function Clock(props: SvgIconPropType) {
    return (
        <SvgIcon {...props}>
            <path d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0zm0 28.3C9.2 28.3 3.7 22.8 3.7 16 3.7 9.2 9.2 3.7 16 3.7c6.8 0 12.3 5.5 12.3 12.3 0 6.8-5.5 12.3-12.3 12.3z" />
            <path d="M22.8 17.1l-4.4-2.5V7.8c0-1.3-1.1-2.4-2.4-2.4-1.3 0-2.4 1.1-2.4 2.4V16c0 1 .7 1.9 1.6 2.2l5.2 3c1.1.7 2.6.3 3.3-.8.6-1.2.2-2.6-.9-3.3z" />
        </SvgIcon>
    );
}

export default Clock;
