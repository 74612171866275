import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import calendar from "dayjs/plugin/calendar";
import {getMarketConfig} from "@atg-shared/market-config";
import isYesterday from "dayjs/plugin/isYesterday";

dayjs.extend(calendar);
dayjs.extend(LocalizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isYesterday);

export const parseDateTimestamp = (date: string) => {
    const marketConfig = getMarketConfig();
    // If the incoming datetime is in UTC we can parse it directly
    // and return it with the market timezone
    if (date.includes("Z") || date.includes("GMT")) {
        return dayjs.utc(date).tz(marketConfig.timezone);
    }

    // If the incoming datetime is not in UTC we assume it is +2 hours ahead of UTC
    // tz should take the market timezone
    return dayjs.utc(dayjs(date).tz("Europe/Stockholm")).tz(marketConfig.timezone);
};
