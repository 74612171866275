import * as GuideSelectors from "./guideReducer";

export const getActiveGuideId = (state: any) => state.guide.active.id;

export const isShowingGuide = (state: any) =>
    state.guide && state.guide.active && Boolean(state.guide.active.id);

export const getCurrentStepIndex = (state: any) =>
    GuideSelectors.getCurrentStepIndex(state.guide.active);

export const getCurrentStepId = (state: any) =>
    isShowingGuide(state) ? state.guide.active.currentStepId : null;

export const getGuideSteps = (state: any) => state.guide.active.steps;

export const getNumberOfGuideSteps = (state: any) => getGuideSteps(state).length;

export const hasShownGuide = (guideId: any) => (state: any) => state.guide.shown[guideId];

export const isOnLastStep = (state: any) =>
    getCurrentStepIndex(state) + 1 === getNumberOfGuideSteps(state);

export const isCurrentStep = (state: any, stepId: string) =>
    getCurrentStepId(state) === stepId;
