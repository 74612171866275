import {type Saga} from "redux-saga";
import {select, takeLatest} from "redux-saga/effects";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {trackStartlistCustomization} from "@atg-horse-shared/startlist-table";
import type * as StartlistActions from "./startlistActions";
import * as StartlistSelectors from "./startlistSelectors";
import {PERSIST_KEY} from "./startlistReducer";

type Action = {type: "persist/REHYDRATE"; key: string; payload: Record<string, unknown>};

/**
 * After the tableSettings state has rehydrated (from localStorage), or after it's been updated,
 * update the GTM variables to match.
 */
// @ts-expect-error
function* logStartlistCustomizationAnalytics(action: Action): Saga<void> {
    if (action.type === "persist/REHYDRATE" && action.key !== PERSIST_KEY) return;

    const currentStartlistId = yield select(StartlistSelectors.getCurrentStartlistId);

    const currentStartlist = yield select(
        StartlistSelectors.getCurrentStartlist(currentStartlistId),
    );

    const staticStartlists = yield select(StartlistSelectors.getStaticStartlists);
    const isVerticalStartlistsActive = yield select(
        StartlistSelectors.getIsVerticalStartlistsActive,
    );
    const showRaceComments = yield select(StartlistSelectors.getShowRaceComments);
    const showCompactStartlist = yield select(StartlistSelectors.getShowCompactStartlist);
    const showBetDistributionDecimals = yield select(
        StartlistSelectors.getShowBetDistributionDecimals,
    );

    const defaultPopularStartlist = staticStartlists.find(
        (staticStartlist: StartlistActions.StaticStartlist) =>
            staticStartlist.id === "popular",
    );

    const trackableStartlist = currentStartlist ?? defaultPopularStartlist;

    trackStartlistCustomization({
        currentStartlist: trackableStartlist,
        isVerticalStartlistsActive,
        showRaceComments,
        showCompactStartlist,
        showBetDistributionDecimals,
    });
}
export default function* startlistAnalyticsSaga() {
    yield takeLatest(["persist/REHYDRATE"], logStartlistCustomizationAnalytics);
}
