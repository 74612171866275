import {css} from "@emotion/react";

const fontSizeMap = {
    xs: "12px",
    sm: "16px",
    md: "24px",
    lg: "32px",
    xl: "48px",
    inherit: "inherit",
};

export const rootStyle = css`
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentColor;
    flex-shrink: 0;
`;

export type SizeType = "xs" | "sm" | "md" | "lg" | "xl" | "inherit";

export const fontSize = (size: SizeType) => css`
    font-size: ${fontSizeMap[size]};
`;
