import {reduce} from "lodash";
import {map, filter, flow} from "lodash/fp";
import {isVXYGameType, type GameType} from "@atg-horse-shared/game-types";
import type {HarryBoyFeeBracket} from "./harryBoyFeeReducer";

/**
 * Get the fee for a Harry Boy bet
 *
 * If the fee is `undefined` (it hasn't been fetched from the backend yet) we return `false` to
 * indicate that the fee is still unknown.
 *
 * API endpoint: `/services/v1/products/harry/fee-brackets`
 *
 * @param betLimit cost of the bet in öre (before fee has been added)
 * @param feeBrackets contains information about the fee for any given bet size
 */
export const getFeeFromBrackets = (
    betLimit: number | boolean,
    {brackets}: HarryBoyFeeBracket,
) =>
    reduce(
        brackets,
        (result, bracket) =>
            betLimit > bracket.from && betLimit <= bracket.to ? bracket.fee : result,
        false as number | false,
    );

/**
 * Add the correct fee for a Harry Boy bet
 *
 * If the fee is unknown (it hasn't been fetched from the backend yet) we return `false`.
 */
export const addFeeToBetLimit = (
    betLimit: number | boolean,
    feeBrackets: HarryBoyFeeBracket,
    gameType?: GameType | null,
) => {
    if (gameType && isVXYGameType(gameType)) return betLimit;

    const fee = getFeeFromBrackets(betLimit, feeBrackets);

    if (typeof fee === "boolean" || typeof betLimit === "boolean") {
        return fee;
    }

    return betLimit + fee;
};

export const getStakeWithoutFee = (
    betLimit: number,
    harryBoyFeeBracket: HarryBoyFeeBracket,
): number => {
    const {brackets} = harryBoyFeeBracket;

    const newBrackets = {
        brackets:
            brackets &&
            map(
                (bracket) => ({
                    from: bracket.from + bracket.fee,
                    to: bracket.to + bracket.fee,
                    fee: bracket.fee,
                }),
                brackets,
            ),
    };

    let originalFee = getFeeFromBrackets(betLimit, newBrackets);

    let newBetLimit = betLimit;

    while (originalFee === false && newBetLimit > 0) {
        newBetLimit -= 100;

        originalFee = getFeeFromBrackets(newBetLimit, newBrackets);
    }

    if (newBetLimit === 0) return betLimit;

    // @ts-expect-error should be always number
    return newBetLimit - originalFee;
};

/**
 * HarryBoyFeeBracket may contain boolean or other types, this is a converter to number, to add correct types of the brackets
 * and filter out unwanted types.
 *
 * @param betLimits costs of the bets in öre (before fee has been added)
 * @param feeBrackets contains information about the fee for any given bet size
 * @param gameType information about which gameType
 */
export const convertHarryBoyFeeToNumber = (
    betLimits: Array<number | boolean>,
    feeBrackets: HarryBoyFeeBracket,
    gameType: GameType,
): Array<number> =>
    flow(
        map((betLimit: number) => addFeeToBetLimit(betLimit, feeBrackets, gameType)),
        (betLimitsWithFee: Array<number>) =>
            filter<number>(Number.isInteger, betLimitsWithFee),
    )(betLimits);
