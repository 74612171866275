/* eslint-disable atg/no-direct-store-access */
import type {AnyAction, Middleware} from "redux";
import {omit} from "lodash";
import type {LazyStore} from "@atg-shared/lazy-store";

type MicroFEStore = LazyStore<any, any>;
declare global {
    interface Window {
        _frameStore: MicroFEStore;
        _globalStore: MicroFEStore;
        _horseStore: MicroFEStore;
        _casinoStore: MicroFEStore;
        _sportsBookStore: MicroFEStore;
        _shopStore: MicroFEStore;
        _playStore: MicroFEStore;
        _retailPurchaseStore: MicroFEStore;
        _myAtgStore: MicroFEStore;
        _tillsammansStore: MicroFEStore;
        _amlStore: MicroFEStore;
    }
}
/**
 * This flag indicates that an action should be forwarded to the frame store (via the
 * `frameActionMiddleware` middleware)
 */
export const GLOBAL_ACTION = "ATG_FRAME_ACTION";

/**
 * This flag indicates that an action should be forwarded to all other product stores (via
 * the `broadcastActionMiddleware` middleware)
 */
export const BROADCAST_ACTION = "ATG_BROADCAST_ACTION";

/**
 * Make an action become visible in the frame store
 *
 * This function takes an action object and returns the same object, but with an extra flag which
 * serves to indicate that it will also be forwarded to the frame store.
 */
export const frameAction = <A extends AnyAction>(action: A) => ({
    ...action,
    meta: {...action.meta, [GLOBAL_ACTION]: true},
});

/**
 * Make an action become visible in all product stores
 *
 * This function takes an action object and returns the same object, but with an extra flag which
 * serves to indicate that it will also be forwarded to the product stores.
 */
export const broadcastAction = <A extends AnyAction>(action: A) => ({
    ...action,
    meta: {...action.meta, [BROADCAST_ACTION]: true},
});

/**
 * Forward specific actions from a product store to the frame store, so that we can update the state
 * there as well
 *
 * This is needed in situations where we want to have a synced state across frame/product frontends,
 * for example whether the user is logged in or not.
 *
 * [microFE docs](../../atg-micro-frontend/README.md)
 */
export const frameActionMiddleware: Middleware = () => (next) => (action) => {
    if (!action.meta?.[GLOBAL_ACTION]) return next(action);

    // _first_ dispatch to the frame store, so the state there can update...
    window._frameStore?.dispatch(action);

    // ...before dispatching to the local (product) store (where this middleware is running)
    return next(action);
};

/**
 * Forward specific actions from the frame store to the product store(s), so that we can update the
 * state there as well
 *
 * This is needed in situations where we want to have a synced state across frame/product frontends,
 * for example whether the user is logged in or not.
 *
 * [microFE docs](../../atg-micro-frontend/README.md)
 */
export const broadcastActionMiddleware: Middleware =
    () => (next) => (action: AnyAction) => {
        if (!action.meta?.[BROADCAST_ACTION]) return next(action);

        // let the local (frame) reducers run first...
        const result = next(action);

        // ...before dispatching to the product store(s)
        // we need to omit `GLOBAL_ACTION` from the meta object here to prevent an infinite loop in case action is both `GLOBAL_ACTION` and `BROADCAST_ACTION`
        const resultWithoutFrameActionMeta = omit(
            action,
            `meta.${GLOBAL_ACTION}`,
        ) as AnyAction;

        window._globalStore?.dispatch(resultWithoutFrameActionMeta);
        window._horseStore?.dispatch(resultWithoutFrameActionMeta);
        window._casinoStore?.dispatch(resultWithoutFrameActionMeta);
        window._sportsBookStore?.dispatch(resultWithoutFrameActionMeta);
        window._shopStore?.dispatch(resultWithoutFrameActionMeta);
        window._playStore?.dispatch(resultWithoutFrameActionMeta);
        window._retailPurchaseStore?.dispatch(resultWithoutFrameActionMeta);
        window._myAtgStore?.dispatch(resultWithoutFrameActionMeta);
        window._tillsammansStore?.dispatch(resultWithoutFrameActionMeta);
        /**
         * VS code don't see this as an error, but `yarn atg typescript:ci` does
         * _paymentStore is defined in apps/atgse/payment/src/store.ts
         * @ts-ignore */
        window._paymentStore?.dispatch(resultWithoutFrameActionMeta);
        window._amlStore?.dispatch(resultWithoutFrameActionMeta);

        return result;
    };
