export const MAX_MAP_SIZE = 120;

class FixedSizeMap<K, V> {
    _maxSize: number;

    _keys: Array<K>;

    _map: Map<K, V>;

    constructor(maxSize: number = MAX_MAP_SIZE) {
        this._map = new Map();
        this._keys = [];
        this._maxSize = maxSize;
    }

    clear() {
        this._map.clear();
        this._keys = [];
    }

    delete(key: K) {
        const result = this._map.delete(key);
        if (result) {
            const keyIndex = this._keys.indexOf(key);
            if (keyIndex === 0) this._keys.shift();
            // shift is faster than splice
            else this._keys.splice(keyIndex, 1);
        }

        return result;
    }

    forEach(
        callback: (value: V, kep: K, map: FixedSizeMap<K, V>) => void,
        context?: any,
    ) {
        this._map.forEach((value: V, key: K) => {
            callback.call(context || this, value, key, this);
        });
    }

    get(key: K) {
        return this._map.get(key);
    }

    has(key: K) {
        return this._map.has(key);
    }

    keys() {
        return this._map.keys();
    }

    set(key: K, value: V) {
        const currentSize = this._keys.length;
        if (currentSize === this._maxSize) {
            this.delete(this._keys[0]);
        }

        this._keys.push(key);
        this._map.set(key, value);
        return this;
    }

    values() {
        return this._map.values();
    }

    get size() {
        return this._keys.length;
    }

    get maxSize() {
        return this._maxSize;
    }
}

export default FixedSizeMap;
