import {combineReducers} from "redux";
import {createFetchReducer} from "@atg-shared/fetch-redux";
import {type FetchState} from "@atg-shared/fetch-types";
import {
    SHARED_BET_CONDITIONS,
    SHARED_BET_CONDITIONS_RESPONSE,
    SHARED_BET_CONDITIONS_CLEAR,
    type SharedBetMaxSharesEditModalAction,
    SHARED_BET_OPEN_MAX_SHARES_EDIT_MODAL,
} from "./sharedBetActions";
import {
    EDIT_TOTAL_NR_SHARES_RESPONSE,
    EDIT_TOTAL_NR_SHARES_REQUEST,
    EDIT_TOTAL_NR_SHARES_RESET,
    type SharedBetEditTotalNrSharesAction,
} from "./sharedBetApi";
import type {TotalNrSharesOpenModalConditions} from "./sharedBet";

type EditTotalNrSharesState = {
    couponId?: string;
    totalNrShares?: number;
};

export type SharedBetConditionsReducer = {
    data: Array<{
        [key: string]: any;
    }>;
    error: {
        [key: string]: any;
    };
    loading: boolean;
    editTotalNrShares: FetchState<EditTotalNrSharesState>;
    totalNrSharesOpenModalConditions: TotalNrSharesOpenModalConditions;
};

const editTotalNrShares = createFetchReducer(
    EDIT_TOTAL_NR_SHARES_REQUEST,
    EDIT_TOTAL_NR_SHARES_RESPONSE,
    EDIT_TOTAL_NR_SHARES_RESET,
    (state: EditTotalNrSharesState, action: SharedBetEditTotalNrSharesAction) => {
        switch (action.type) {
            case EDIT_TOTAL_NR_SHARES_RESPONSE:
                if (action.error) return state;
                return {
                    couponId: action.context.couponId,
                    totalNrShares: action.context.totalNrShares,
                };
            default:
                return state;
        }
    },
    {},
);

const totalNrSharesOpenModalConditions = (
    state: any = null,
    action: SharedBetMaxSharesEditModalAction,
) => {
    const {type, payload} = action;

    switch (type) {
        case SHARED_BET_OPEN_MAX_SHARES_EDIT_MODAL:
            return {
                ...state,
                couponId: payload?.couponId,
                totalNrSoldShares: payload?.totalNrSoldShares,
                totalNrShares: payload?.totalNrShares,
            };
        default:
            return state;
    }
};

const data = (state: any = null, action: any) => {
    const {type, payload, error, context} = action;

    switch (type) {
        case SHARED_BET_CONDITIONS_RESPONSE:
            return error
                ? state
                : {
                      ...state,
                      [payload.couponId]: payload,
                  };
        case EDIT_TOTAL_NR_SHARES_RESPONSE: {
            const currentConditions = state[context.couponId];

            return error
                ? state
                : {
                      ...state,
                      [context.couponId]: {
                          ...currentConditions,
                          totalNrShares: context.totalNrShares,
                      },
                  };
        }
        case SHARED_BET_CONDITIONS_CLEAR:
            return null;
        default:
            return state;
    }
};

const error = (state = {}, action: any) => {
    const {type, payload, error: actionError} = action;
    switch (type) {
        case SHARED_BET_CONDITIONS_RESPONSE:
            return actionError ? payload : state;
        case SHARED_BET_CONDITIONS_CLEAR:
            return {};
        default:
            return state;
    }
};

const loading = (state = false, action: any) => {
    switch (action.type) {
        case SHARED_BET_CONDITIONS:
            return true;
        case SHARED_BET_CONDITIONS_RESPONSE:
        case SHARED_BET_CONDITIONS_CLEAR:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    data,
    error,
    loading,
    editTotalNrShares,
    totalNrSharesOpenModalConditions,
});
