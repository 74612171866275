import {frameAction} from "atg-store-addons";

export const LOCK_CONTEXT = "vertical/LOCK_CONTEXT";
export const UNLOCK_CONTEXT = "vertical/UNLOCK_CONTEXT";

type LockContextAction = {
    type: typeof LOCK_CONTEXT;
};

type UnlockContextAction = {
    type: typeof UNLOCK_CONTEXT;
};

export type VerticalAction = LockContextAction | UnlockContextAction;

export const lockContextAction = (): LockContextAction =>
    frameAction({
        type: LOCK_CONTEXT,
    });

export const unlockContextAction = (): UnlockContextAction =>
    frameAction({
        type: UNLOCK_CONTEXT,
    });
