import {includes} from "lodash";
import {pureFetch as atgFetch} from "@atg-shared/fetch";
import * as ThePlatform from "./ThePlatform";

// Add these to resolvePlatformUrl if we want to hardcode the streams for main-backup
/*
} else if (includes(channel, "mainbackup")) {
    return "https://www.atgvision.com/hls/atglive2/index.m3u8";
} */

export function callBackendEndpoint(source: any) {
    const url = source.stream || source;
    const codec = source.codec || null;

    if (
        url.indexOf("theplatform") > -1 ||
        url.indexOf("/v1/api/media") > -1 ||
        url.indexOf("/test/data/") > -1
    ) {
        return atgFetch(url).then((thePlatformData) =>
            ThePlatform.parse(thePlatformData.data),
        );
    }
    return {
        streams: [
            {
                type: "application/x-mpegURL",
                url,
                codec,
            },
        ],
    };
}

export const fetchGraphicsVideoUrlWithPublicUrl = (publicUrl: any) =>
    fetch(publicUrl).then((response) => response.url);

export const resolvePlatformURL = async (platformURL: string, channel: any) => {
    if (platformURL && !includes(platformURL, "link.theplatform.eu")) {
        return platformURL;
    }

    // Hard-code main streams for now - saves us a click on mobiles,
    //  and money by avoiding a theplatform request.

    if (includes(channel, "main-")) {
        if (includes(channel, "-premium")) {
            return "https://httpcache0-00688-cachelive1.dna.qbrick.com/00688-cachelive1/smil:00688_2500/playlist.m3u8";
        }
        if (includes(channel, "-free") || includes(channel, "tillsammans")) {
            return "https://httpcache0-00688-cachelive1.dna.qbrick.com/00688-cachelive1/smil:00688_1200/playlist.m3u8";
        }
        if (includes(channel, "-capped")) {
            return "https://httpcache0-00688-cachelive1.dna.qbrick.com/00688-cachelive1/smil:00688_430/playlist.m3u8";
        }
        if (includes(channel, "-audio")) {
            return "https://httpcache0-00688-cachelive1.dna.qbrick.com/00688-cachelive1/00688_aac/playlist.m3u8";
        }
    }

    if (platformURL.indexOf("https") < 0)
        platformURL.replace("//link.theplatform.eu", "https://link.theplatform.eu");
    const response = await fetch(platformURL);
    return response.url;
};
