import type {LazyStore} from "@atg-shared/lazy-store";
import {runLazySaga} from "@atg-shared/lazy-store";
import {accountSettingsSaga} from "@atg-payment-shared/account-settings-domain";
// eslint-disable-next-line @nx/enforce-module-boundaries
import appBridgeSaga from "@mobileapp-shared/atgse-bridge/domain/appBridgeSaga";
// eslint-disable-next-line @nx/enforce-module-boundaries
import calendarSaga from "@atg-horse-shared/calendar/domain/calendarSaga";
import {fetchSaga} from "@atg-shared/fetch-redux";
import guideAnalyticsSaga from "@atg-horse/onboarding-guide/domain/guideAnalyticsSaga";
import horseFavoritesSaga from "@atg-horse/favorites/domain/horseFavoritesSaga";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {harryBoyFeeSaga} from "@atg-horse-shared/coupon";
import pushSaga from "@atg-frame-shared/push-saga/domain/pushSaga";
// eslint-disable-next-line @nx/enforce-module-boundaries
import startInfoSaga from "@atg-horse/more-info-modal/domain/startDetailsSaga";
// eslint-disable-next-line @nx/enforce-module-boundaries
import startlistAnalyticsSaga from "@atg-horse/startlists/src/domain/startlistAnalyticsSaga";
// eslint-disable-next-line @nx/enforce-module-boundaries
import fileBetSaga from "@atg-horse/bet-history-receipts/src/domain/fileBetSaga";
// eslint-disable-next-line @nx/enforce-module-boundaries
import betSpeedPayoutSaga from "@atg-horse/bet-history-receipts/src/domain/speedPayoutSaga";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {sharedBetSaga} from "@atg-horse/shared-bet";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {hotGamesPushListenerSaga} from "@atg-horse-shared/hotgames";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {authHelperSaga} from "@atg-horse/auth-helpers";
import {
    DepositAnalyticsSaga,
    DepositFlowSaga,
    DepositSaga,
} from "@atg-payment-shared/deposit-domain";
// @ts-expect-error
// eslint-disable-next-line @nx/enforce-module-boundaries
import cmsSaga from "@atg-global-shared/legacy-cms/domain/cmsSaga";
// eslint-disable-next-line @nx/enforce-module-boundaries
import liveSaga from "@atg-horse-shared/video-domain/src/live/redux/liveSaga";
import {userSettingsSaga} from "@atg-global-shared/user-settings";
// eslint-disable-next-line @nx/enforce-module-boundaries
import videoPlaySaga from "@atg-horse-shared/video-domain/src/video/videoPlaySaga";
// eslint-disable-next-line @nx/enforce-module-boundaries
import videoSaga from "@atg-horse-shared/video-domain/src/video/videoSaga";
// eslint-disable-next-line @nx/enforce-module-boundaries
import quickplaySaga from "@atg-horse-shared/video-domain/src/quickplay/quickplaySaga";
import {kycQuestionnaireBeforeBetSaga} from "@atg-aml-shared/kyc-domain";
// @ts-expect-error
// eslint-disable-next-line @nx/enforce-module-boundaries
import playerSaga from "@atg-horse-shared/video-domain/src/player/playerSaga";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    externalPurchaseFlowSaga,
    persistPurchaseSaga,
    purchaseBalanceSaga,
    purchaseDepositSaga,
    purchaseSaga,
    restorePurchaseSaga,
} from "@atg-horse-shared/purchase";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {highlightsPushListenerSaga, highlightsSaga} from "@atg-horse-shared/highlights";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {gameRouteSaga} from "@atg-horse/game-controller";
// eslint-disable-next-line @nx/enforce-module-boundaries
import extendedStartSaga from "atg-horse-game/domain/extendedStart/extendedStartSaga";
// eslint-disable-next-line @nx/enforce-module-boundaries
import gamePushListenerSaga from "atg-horse-game/domain/gamePushListenerSaga";
// eslint-disable-next-line @nx/enforce-module-boundaries
import gameSaga from "atg-horse-game/domain/gameSaga";
// eslint-disable-next-line @nx/enforce-module-boundaries
import selectedRaceSaga from "atg-horse-game/redux/selectedRaceSaga";
import toastSaga from "atg-ui-toast/domain/toastSaga";
import cleanupHorseStoreApiSaga from "./domain/cleanupHorseStoreApiSaga";

export default function rootSaga(store: LazyStore<any, any>) {
    runLazySaga(store, fetchSaga);
    runLazySaga(store, persistPurchaseSaga);
    runLazySaga(store, restorePurchaseSaga, store);
    // @ts-expect-error
    runLazySaga(store, guideAnalyticsSaga);
    runLazySaga(store, cmsSaga);
    runLazySaga(store, pushSaga, store.dispatch);
    // Send dispatch to pushlisteners in order to bse able to dispatch action when push is received don't do this otherwise
    runLazySaga(store, hotGamesPushListenerSaga, store.dispatch);
    // Send dispatch to pushlisteners in order to be able to dispatch action when push is received don't do this otherwise
    runLazySaga(store, highlightsPushListenerSaga, store.dispatch);
    runLazySaga(store, highlightsSaga);
    runLazySaga(store, startInfoSaga);
    runLazySaga(store, extendedStartSaga);
    runLazySaga(store, purchaseSaga);
    runLazySaga(store, authHelperSaga);
    runLazySaga(store, startlistAnalyticsSaga);
    runLazySaga(store, videoSaga, store.dispatch);
    runLazySaga(store, videoPlaySaga);
    runLazySaga(store, playerSaga);
    runLazySaga(store, purchaseBalanceSaga);
    runLazySaga(store, userSettingsSaga);
    runLazySaga(store, DepositSaga);
    runLazySaga(store, DepositFlowSaga);
    runLazySaga(store, selectedRaceSaga);
    runLazySaga(store, kycQuestionnaireBeforeBetSaga);
    runLazySaga(store, harryBoyFeeSaga);
    runLazySaga(store, purchaseDepositSaga);
    // this saga has to be triggered AFTER fetchSaga since it is triggered (using fetch action) directly when initialized
    runLazySaga(store, externalPurchaseFlowSaga, store);
    runLazySaga(store, DepositAnalyticsSaga);
    runLazySaga(store, toastSaga);
    runLazySaga(store, sharedBetSaga);
    // Send dispatch to pushlisteners in order to be able to dispatch action when push is received don't do this otherwise
    runLazySaga(store, calendarSaga, store.dispatch, store.getState);
    runLazySaga(store, gamePushListenerSaga, store.dispatch, store.getState);
    runLazySaga(store, gameSaga);
    runLazySaga(store, accountSettingsSaga);
    // @ts-expect-error
    runLazySaga(store, gameRouteSaga);
    runLazySaga(store, appBridgeSaga);
    // @ts-expect-error
    runLazySaga(store, quickplaySaga);
    runLazySaga(store, liveSaga);
    runLazySaga(store, horseFavoritesSaga);
    runLazySaga(store, fileBetSaga);
    runLazySaga(store, betSpeedPayoutSaga);
    runLazySaga(store, cleanupHorseStoreApiSaga, store.dispatch);
}
