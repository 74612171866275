import _ from "lodash";
import {compose, filter, map, compact} from "lodash/fp";
import * as React from "react";
import produce from "immer";
// eslint-disable-next-line @nx/enforce-module-boundaries
import BetCodeHorses from "@atg-horse/bet-codereceipt/components/BetCodeHorses";
import Horses from "../common/Horses";
import receiptColumnViews from "./receipt/receiptcolumnviews";
import ConfirmLegView from "./confirm/confirmlegview";
import harryStatusColumnView from "./confirm/harrystatuscolumnview";
import ReserveColumnLabel from "./ReserveColumnLabel";

// @ts-expect-error convert does not exist in map
const mapWithIndex = map.convert({cap: false});

const horseColumn = {
    id: "horses",
    order: 3,
    label: "Hästar",
    value: "horses",
    item: {
        view: Horses,
        props(props: any) {
            props.horses = produce(props.data.columns.horses.data, (draft: any) => {
                // show selected reserve as well if sneak(smyg) is used before the result is shown to make possible to watch the video clip, knowing which horse to watch in the race.
                const reserves = props.data.columns.sneak?.data?.reserves;
                if (reserves) {
                    // if the reserves are also scratched we get new reserves instead
                    const reservesNonScratched = filter(
                        (reserve) => !reserve.scratched,
                        reserves,
                    );

                    // if 1 horse is scratched then push only the first reserve, if 3 horses, then the first 3 reserves
                    const reservesToPush = compose(
                        compact, // just in case reserves[index] is undefined
                        mapWithIndex(
                            (horse: any, index: number) => reservesNonScratched[index],
                        ),
                        filter((horse: any) => horse.scratched),
                    )(draft?.picks ?? []);

                    draft.picks.push(...reservesToPush);
                }
            });
            props.separateWith = "";
            props.showEmptyMessage = true;
            if (_.isUndefined(props.showResults)) {
                props.showResults = !props.horses.hideResult;
            }
            props.isCancelled =
                props.tableProps?.receipt?.bet?.races[props.index]?.cancelled;
            props.showScratched = _.isEmpty(props.showScratched);
            return props;
        },
    },
};

const horseCodeColumn = {
    id: "code-horses",
    order: 3,
    label: "Rätt rad",
    value: "horses",
    item: {
        view: BetCodeHorses,
    },
};

const reservesColumn = {
    id: "reserves",
    order: 4,
    label(props: any) {
        const {receipt} = props;
        const isConfirm = receipt.receiptType === "confirm";
        // @ts-expect-errors isConfirm does not exist in ReserveColumnLabel
        return <ReserveColumnLabel isConfirm={isConfirm} />;
    },
    item: {
        view: Horses,
        props(props: any) {
            props.horses = props.data.columns.reserves.data;
            return props;
        },
    },
};

const winnerColumn = {
    id: "winner",
    order: 5,
    label(props: any) {
        return props.receipt.bet.races.length === 1 ? "Resultat" : "Vinnare";
    },
    item: {
        view: receiptColumnViews.WinnersView,
        props(props: any) {
            props.phase = props.data.phase;
            props.data = props.data.columns.winner.data;
            return props;
        },
    },
    width: 55,
};

const resultColumn = {
    id: "result",
    order: 5,
    label: "Resultat",
    item: {
        view: receiptColumnViews.WinnersView,
        props(props: any) {
            props.phase = "win";
            props.data = props.data.columns.result.data;
            return props;
        },
    },
    width: 55,
};

const sneakColumn = {
    id: "sneak",
    order: 5,
    label: "Smyg",
    item: {
        view: receiptColumnViews.SneakButton,
        props(props: any) {
            props.boost = false;
            props.race = props.data.columns.sneak.data;
            props.onClickAction = props.tableProps.actions.markAsSneaked;
            props.receiptId = props.tableProps.receipt.id;
            return props;
        },
    },
    width: 35,
};

const videoColumn = {
    id: "video",
    order: 6,
    label: "Video",
    item: {
        view: receiptColumnViews.VideoButton,
        props(props: any) {
            props.race = props.data.columns.video.data;
            return props;
        },
    },
    width: 35,
};

const confirmRaceColumn = {
    id: "race",
    order: 1,
    label: "Lopp",
    item: {
        view: ConfirmLegView,
        props(props: any) {
            props.legNo = props.data.columns.race.data;
            return props;
        },
    },
};

const raceColumn = {
    id: "race",
    order: 1,
    label: "Lopp",
    value: "columns.race.data",
    width: 35,
};

const betTypeColumn = {
    id: "betType",
    order: 2,
    label: "Spelform",
    value: "columns.betType.data",
};

const confirmLegColumn = {
    id: "leg",
    order: 1,
    label: "Avd",
    item: {
        view: ConfirmLegView,
        props(props: any) {
            props.legNo = props.data.columns.leg.data;
            return props;
        },
    },
};

const receiptLegColumn = {
    id: "leg-no",
    order: 1,
    label: "Avd",
    value: "columns.leg-no.data",
    data(receipt: any, race: any) {
        return race.legNo.toString();
    },
    width: 35,
};

const receiptPlaceColumn = {
    id: "place",
    order: 2,
    label: "Plac.",
    value: "columns.place.data",
    width: 35,
};

const confirmPlaceColumn = {
    id: "place",
    order: 2,
    label: "Plac.",
    value: "columns.place.data",
};

const harryOpenColumn = {
    id: "harry-open",
    order: 10,
    item: {
        view: harryStatusColumnView,
    },
    label: "Status",
    value: "columns.harry-open.data",
};

const boostLogoColumn = {
    id: "boost-logo",
    order: 1,
    width: 96,
    item: {
        view: receiptColumnViews.BoostLogoColumn,
    },
};

const boostCodeColumn = {
    id: "boost-code",
    order: 2,
    label: "Ditt nr.",
    item: {
        view: receiptColumnViews.BoostCodeColumn,
        props(props: any) {
            const {code, result, showWin} = props.data.columns["boost-code"].data;

            props.code = code;
            props.correctCode = showWin ? result : null;

            return props;
        },
    },
};

const boostSneakColumn = {
    id: "boost-sneak",
    order: 3,
    label: "Rätt nr.",
    item: {
        view: receiptColumnViews.SneakButton,
        props(props: any) {
            props.boost = true;
            props.onClickAction = props.tableProps.actions.boostMarkAsSneaked;
            props.receiptId = props.tableProps.receipt.id;

            return props;
        },
    },
    width: 78,
};

const boostResultColumn = {
    id: "boost-result",
    order: 3,
    label: "Rätt nr.",
    item: {
        view: receiptColumnViews.BoostCodeColumn,
        props(props: any) {
            props.code = props.data.columns["boost-result"].data.code;
            return props;
        },
    },
    width: 78,
};

export default {
    horseColumn,
    horseCodeColumn,
    reservesColumn,
    winnerColumn,
    confirmRaceColumn,
    raceColumn,
    betTypeColumn,
    confirmLegColumn,
    receiptLegColumn,
    confirmPlaceColumn,
    receiptPlaceColumn,
    harryOpenColumn,
    videoColumn,
    sneakColumn,
    boostLogoColumn,
    boostCodeColumn,
    boostSneakColumn,
    boostResultColumn,
    resultColumn,
};
