import {put, call, select, take, takeLatest} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import {DepositActionConstants} from "@atg-payment-shared/deposit-types";
import {MemberActions} from "@atg-global-shared/member-data-access";
import {DepositActions} from "@atg-payment-shared/deposit-domain";
import * as AuthActions from "@atg-shared/auth/domain/authActions";
import * as ModalSelectors from "atg-modals/modalSelectors";
import * as ModalActions from "atg-modals/modalActions";
import * as PurchaseActions from "./purchaseActions";
import * as PurchaseSelectors from "./purchaseSelectors";
import {takeSuccessful} from "./purchaseBalanceSaga";

type DepositFlowActionType =
    | typeof DepositActionConstants.START_DEPOSIT_FLOW
    | typeof PurchaseActions.RESTORE_PURCHASE_FLOW;
type DepositFlowAction = {
    type: DepositFlowActionType;
    payload: any;
};

export const selectShowUserGamblingSummaryModal = () =>
    ModalSelectors.showUserGamblingSummaryModal(window?._frameStore?.getState());

export function* handleDepositFlow({
    type,
    payload,
}: DepositFlowAction): SagaIterator<void> {
    const showUserGamblingSummaryModal = yield select(selectShowUserGamblingSummaryModal);
    if (showUserGamblingSummaryModal) {
        // Wait for user to close "welcome back modal" if started a purchase before login.
        yield take(ModalActions.closeUserGamblingSummaryModal);
    }
    const isPurchasing = yield select(PurchaseSelectors.isPurchasing);
    if (!isPurchasing) return;

    yield put(AuthActions.checkAuth());
    const action = yield call(takeSuccessful, [
        AuthActions.AUTH_CHECK_RESPONSE,
        MemberActions.CANCELLED_LOGIN_FLOW,
    ]);
    if (action.type === AuthActions.AUTH_CHECK_RESPONSE) {
        if (type === PurchaseActions.RESTORE_PURCHASE_FLOW) {
            yield put(DepositActions.fetchOptions());
            return;
        }
        yield put(DepositActions.depositFlowStarted(payload));
        return;
    }

    yield put(PurchaseActions.finishPurchase());
}

export default function* purchaseDepositFlowSaga(): SagaIterator<void> {
    yield takeLatest(
        [
            DepositActionConstants.START_DEPOSIT_FLOW,
            PurchaseActions.RESTORE_PURCHASE_FLOW,
        ],
        handleDepositFlow,
    );
}
