import {AUTH_URL, PUBLIC_URL} from "./constants";

type Params = Record<string, string | number | undefined>;

export class UrlBuilder {
    private url: string;

    private params: Params;

    constructor(private readonly baseUrl = AUTH_URL) {
        this.url = baseUrl;
        this.params = {};
    }

    unAuthorized(): UrlBuilder {
        this.url = PUBLIC_URL;
        return this;
    }

    append(subUrl: string): UrlBuilder {
        this.url += `/${subUrl}`;
        return this;
    }

    withParams(params: Params): UrlBuilder {
        this.params = {...this.params, ...params};
        return this;
    }

    build(): string {
        const queryParams = this.createQueryParams(this.params);
        return [this.url, queryParams].join("?");
    }

    private createQueryParams(params: Params): string {
        const qs = Object.entries(params).reduce((acc, [k, v]) => {
            if (v !== undefined) {
                const param = [k, encodeURIComponent(v)].join("=");
                return [...acc, param];
            }

            return acc;
        }, [] as string[]);

        return qs.join("&");
    }
}
