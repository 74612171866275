import {createSelector} from "reselect";
import {filter, some, get, flow, find} from "lodash/fp";
import type {CalendarAPITypes} from "@atg-horse-shared/racing-info-api";
import type {GameType} from "@atg-horse-shared/game-types";
import {serverDate} from "@atg-shared/server-time";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {type CalendarDay} from "@atg-horse-shared/calendar/domain/calendar";
import {type FetchState} from "@atg-shared/fetch-types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {State as CalendarState} from "@atg-horse-shared/calendar/domain";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {CalendarDayState} from "@atg-horse-shared/calendar/domain/calendarReducer";
import * as Race from "@atg-horse-shared/utils/race";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as Calendar from "@atg-horse-shared/calendar/domain/calendar";
import type {GlobalQuickplayState} from "./quickplayActions";

export const quickplay = (state: GlobalQuickplayState) => state.horse.quickplay;

export const getSelectedTrack = (state: GlobalQuickplayState) =>
    quickplay(state).selectedTrack;

export const getSelectedTrackMemo: (
    state: GlobalQuickplayState,
) => number | null | undefined = createSelector(
    quickplay,
    (currentQuickplay) => currentQuickplay.selectedTrack,
);

export const getSelectedRaceId = (state: GlobalQuickplayState) =>
    quickplay(state).selectedRaceId;

export const getSelectedGameType = (state: GlobalQuickplayState) =>
    quickplay(state).selectedGameType;

export const isAutomatic = (state: GlobalQuickplayState) => quickplay(state).automatic;

export const isLiveBetsToggled = (state: GlobalQuickplayState) =>
    quickplay(state).liveBetsToggled;

export const isLiveBetsVisited = (state: GlobalQuickplayState) =>
    Boolean(quickplay(state).liveBetsVisited);

export const getTodaysCalendar = (
    state: CalendarState,
): FetchState<CalendarDayState> | null | undefined => {
    const date = serverDate();
    if (!date) return null;

    return state.calendar[date];
};

/**
 * Selected a raceId based on the selected race id status
 * For -> raceid = `null` the next upcoming race id is selected
 */
export const getSelectedRaces: (
    state: GlobalQuickplayState & CalendarState,
) => Array<CalendarAPITypes.CalendarRace> | null | undefined = createSelector(
    getSelectedTrack,
    // @ts-expect-error
    getTodaysCalendar,
    (selectedTrack, calendar) => {
        if (!selectedTrack || !calendar?.day) return null;
        const {day} = calendar;
        const track = Calendar.findTrackById(day, selectedTrack);
        if (!day || !track) return null;
        const {races} = track;
        return races;
    },
);

export const getSelectedRaceNumber: (
    state: GlobalQuickplayState & CalendarState,
) => number | null | undefined = createSelector(
    getSelectedRaceId,
    getSelectedRaces,
    (selectedRaceId, races) => {
        if (!races || !selectedRaceId) return null;
        return flow(
            // @ts-expect-error
            find((race) => race.id === selectedRaceId),
            get("number"),
        )(races);
    },
);

/**
 * Get live race id from next upcoming races or the selected one by the user
 */
export const getLiveRaceId: (state: GlobalQuickplayState & CalendarState) => string =
    createSelector(
        getSelectedRaceId,
        getSelectedRaces,
        (selectedRaceId, races) =>
            // @ts-expect-error
            selectedRaceId || Race.getNextUpcomingRaceId(races) || "",
    );

/**
 * Get today`s division game of the seleceted race
 */
export const getTodayDivisionGamesByRaceId: (
    state: GlobalQuickplayState & CalendarState,
) => Array<Calendar.CalendarGame> | null | undefined = createSelector(
    getLiveRaceId,
    getTodaysCalendar,
    (selectedRaceId, calendar) => {
        if (!calendar?.day) return null;
        const {day} = calendar;
        return Calendar.getDivisionGamesContainingRaceId(day, selectedRaceId, true);
    },
);

// Whitelisted singleRace GameTypes
const wantedSingleRaceGameTypes = [
    "vinnare",
    "plats",
    "vp",
    "tvilling",
    "komb",
    "trio",
    "top7",
];

/**
 * If track and day exist all the white listed single race gametypes is returned.
 * White listed single race games are as follow `[vinnare, plats, vp, tvilling, komb, trio, top7]`
 */
// @ts-expect-error
export const selectAvailableSingleRaceGameTypes: (
    state: GlobalQuickplayState & CalendarState,
) => Array<GameType> = createSelector(
    getLiveRaceId,
    getTodaysCalendar,
    (raceId, calendar) => {
        if (!calendar?.day || !raceId) return [];
        const {day} = calendar;
        return filter((gameType) => {
            // @ts-expect-error
            const singleRaceGames = day.games[gameType];
            if (!singleRaceGames) return false;
            return some((game) => game.id === `${gameType}_${raceId}`, singleRaceGames);
        }, wantedSingleRaceGameTypes);
    },
);

/**
 * If track and day exist all the white listed single race gametypes is returned.
 * White listed single race games are as follow `[vinnare, plats, vp, tvilling, komb, trio, top7]`
 */
export const selectAvailableSingleRaceGameTypesForGivenRaceId = (
    day: CalendarDay | null,
    raceId?: string,
) => {
    if (!day || !raceId) return [];
    return filter((gameType) => {
        // @ts-expect-error
        const singleRaceGames = day.games[gameType];
        if (!singleRaceGames) return false;
        return some((game) => game.id === `${gameType}_${raceId}`, singleRaceGames);
    }, wantedSingleRaceGameTypes);
};
