import {find, head, isEmpty, uniqBy, every} from "lodash";
import {find as fpFind, flow, get, isEqual} from "lodash/fp";
import * as Dayjs from "dayjs";
import type {CalendarAPITypes, GameAPITypes} from "@atg-horse-shared/racing-info-api";
import type {GameType} from "@atg-horse-shared/game-types";
import * as Race from "../race";

// preferrably used with lodash: get(trackId, Tracks.getTrackNameById)
export const getTrackNameById = {
    5: "Solvalla",
    6: "Åby",
    7: "Jägersro",
    8: "Axevalla",
    9: "Bergsåker",
    11: "Boden",
    12: "Bollnäs",
    14: "Eskilstuna",
    15: "Färjestad",
    16: "Gävle",
    18: "Halmstad",
    19: "Kalmar",
    22: "Mantorp",
    23: "Romme",
    27: "Umåker",
    32: "Örebro",
    33: "Östersund",
};

export const getIdByTrackName = {
    Solvalla: 5,
    Åby: 6,
    Jägersro: 7,
    Axevalla: 8,
    Bergsåker: 9,
    Boden: 11,
    Bollnäs: 12,
    Eskilstuna: 14,
    Färjestad: 15,
    Gävle: 16,
    Halmstad: 18,
    Kalmar: 19,
    Mantorp: 22,
    Romme: 23,
    Umåker: 27,
    Örebro: 32,
    Östersund: 33,
};
export interface TrackIdentity {
    id: number;
    name: string;
}

export const isScheduled = (track: GameAPITypes.CalendarTrack): boolean =>
    !track.races || track.races.length === 0;

export function findRaceById(track: GameAPITypes.CalendarTrack | null, raceId?: string) {
    if (!track || !track.races) return null;
    return track.races.find((race) => race.id === raceId);
}
export const findRaceNumberById = (
    track: GameAPITypes.CalendarTrack | null,
    raceId: string,
) => {
    const race = findRaceById(track, raceId);
    if (!race) return null;
    return race.number;
};

export function getTimeToRace(
    race: CalendarAPITypes.CalendarRace | null,
    currentTime: Dayjs.Dayjs,
) {
    if (!race) return "";

    const timeTillStart = Dayjs.default(race.startTime).diff(currentTime, "minutes");

    if (timeTillStart < 0) return "-- min";
    if (timeTillStart <= 30) return `om ${timeTillStart} min`;

    return `kl ${Dayjs.default(race.startTime).format("HH:mm")}`;
}

export function allRacesFinished(track: GameAPITypes.CalendarTrack) {
    if (!track.races || track.races.length === 0) return false;

    return every(
        track.races,
        (race) => race.status === "results" || race.status === "cancelled",
    );
}

export function getFirstUpcomingRace(track: GameAPITypes.CalendarTrack) {
    return find(track.races, (race) => race.status === "upcoming");
}

export function getBettableOrFirstRace(track: GameAPITypes.CalendarTrack) {
    const firstUpcomingRace = getFirstUpcomingRace(track);
    if (firstUpcomingRace) return firstUpcomingRace;
    return head(track.races);
}

export function getGameTrackName(
    tracks: Array<GameAPITypes.GameRaceTrack>,
    separator = "-",
) {
    if (isEmpty(tracks)) return null;

    return uniqBy(tracks, "name")
        .map((track) => track.name)
        .join(separator);
}

/**
 *
 * @returns `TrackName` from the given `raceId`
 */
export const getTrackFromRaceId = (
    raceId: Race.RaceId | null,
    tracks: Array<GameAPITypes.CalendarTrack>,
) => {
    if (!raceId) return null;
    const trackNumber = Race.selectValueFromRaceIdString(
        raceId,
        Race.RaceIdValues.TrackNumber,
    );
    return flow(fpFind(flow(get("id"), isEqual(trackNumber))))(tracks);
};

/**
 * @todo see if merging with `CalendarTrack` makes sense, questions about countryCode field
 */
export interface GameInfoTrack extends TrackIdentity {
    races: Array<CalendarAPITypes.CalendarRace>;
    startTime: Dayjs.Dayjs;
    nextRace: string;
    slug: string;
    biggestGameType: GameType;
}
