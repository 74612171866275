import _ from "lodash";

export const getObjectDifferences = (
    oldOptions: Record<string, any>,
    newOptions: Record<string, any>,
    addProp: string,
) =>
    _.reduce(
        newOptions,
        (result: Record<string, any>, value, key) => {
            if (oldOptions[key]) {
                if (!_.isEqual(oldOptions[key], value)) {
                    if (
                        typeof oldOptions[key] === "object" &&
                        !oldOptions[key][addProp]
                    ) {
                        result[key] = getObjectDifferences(
                            oldOptions[key],
                            value,
                            addProp,
                        );
                    } else {
                        result[key] = value;
                    }
                }
            } else {
                result[key] = newOptions[key];
            }
            return result;
        },
        {},
    );

/**
 * Performs equality by iterating through keys on an object and returning false
 * when any key has values which are not strictly equal between the arguments.
 * Returns true when the values of all keys are strictly equal.
 *
 * https://github.com/facebook/fbjs/blob/master/packages/fbjs/src/core/shallowEqual.js
 */
export const shallowEqual = (
    objA: Record<string, unknown> | null,
    objB: Record<string, unknown> | null,
) => {
    if (Object.is(objA, objB)) {
        return true;
    }

    if (
        typeof objA !== "object" ||
        objA === null ||
        typeof objB !== "object" ||
        objB === null
    ) {
        return false;
    }

    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) {
        return false;
    }

    // Test for A's keys different from B.
    for (let i = 0; i < keysA.length; i += 1) {
        if (
            !Object.prototype.hasOwnProperty.call(objB, keysA[i]) ||
            !Object.is(objA[keysA[i]], objB[keysA[i]])
        ) {
            return false;
        }
    }

    return true;
};
