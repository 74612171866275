import type {GameStatus, GameType} from "@atg-horse-shared/game-types";
import type {HarryFlavor} from "./harry";
import type {StartData} from "./code-result-mark";
import type {VxyDetailedBet} from "./vxy-bet";

export type Channel = "Internet";
export type CheckBetCode = string;

type HorseId = number;
type TrackId = number;
type GameId = string;

export enum BetMethod {
    Harry = "harry",
    Flex = "flex",
    OnlyVx = "onlyVx",
}

export type Bet = {
    horse: {
        id?: number;
        name: string;
        nationality?: string;
    };
    number: number;
    win?: boolean;
    scratched?: boolean;
};

export type Timestamp = string;

export type BetTrack = {
    countryCode?: string;
    id: TrackId;
    name: string;
};

export type BetGame<TGameType> = {
    date: string;
    id: GameId;
    type: TGameType;
    startTime: string;
    status: GameStatus;
    tracks: Array<BetTrack>;
    win?: boolean;
    cancelled?: boolean;
};

export type BetHorse = {
    id: HorseId;
    name: string;
};

export type BetRace = {
    raceNumber: number;
    trackId: number;
    reserves: Array<{
        number: number;
        win?: boolean;
        horse: BetHorse;
        scratched?: boolean;
    }>;
    positionResults?: Array<Array<{number: number; horse?: {name: string}}>>;
    result?: boolean;
};

export type ReceiptRace = {
    trackId: TrackId;
    result?: boolean;
    bets?: Array<Bet>;
    reserves?: Array<Bet>;
    firstPlaceBets?: Array<Bet>;
    secondPlaceBets?: Array<Bet>;
    thirdPlaceBets?: Array<Bet>;
    baseBets?: Array<Bet>;
    raceNumber: number;
};

export type BetDetailedStart = {
    number: number;
    horse: BetHorse;
    scratched?: boolean;
    win?: boolean;
};

export type Payout = {
    amount: number;
    fee?: number;
    jackpot?: boolean;
};

export type Payment = {
    dividend: number;
    winning: string;
    correct: number;
    amount: number;
    jackpot?: true;
    boostMultiplier?: number;
    boostAmount?: number;
};

export type BaseBet = {
    id: string;
    channel: Channel;
    serialNumber: string;
    checkable: boolean;
    cost: number;
    costWithAddons: number;
    fee: number;
    refundable: boolean;
    timestamp: Timestamp;
    checkBetCode?: CheckBetCode;
    betMethod?: BetMethod;
    // Result / Corrected props
    betFlavour?: HarryFlavor;
    harryFlavour?: HarryFlavor;
    payout?: Payout;
    refund?: number;
    flexValue?: number;
    payments?: Array<Payment>;
    boxedBets?: Array<Array<StartData>>;
    cancelled?: {
        id: number;
        timestamp: string;
    };
};

export type DetailedBet = VxyDetailedBet;

export type SingleRaceBet = BaseBet & {
    game: BetGame<
        "vinnare" | "plats" | "vp" | "trio" | "tvilling" | "komb" | "top7" | "raket"
    >;
    races: Array<BetRace>;
};

export type BetReceipt = SingleRaceBet | VxyDetailedBet;

export type Top7HorseGroup = Array<{
    horseNumber: number;
    selectedHorseStatus: "CORRECT" | "RESERVE" | "SCRATCHED";
    moved: boolean;
    win: boolean;
}>;

export type HorseSelection = {
    baseInvestment?: number;
    betType?: string;
    horseNumber: number;
    raceNumber?: number;
    poolId?: string; // used for raket bets
    selectedHorseStatus: "CORRECT" | "RESERVE" | "SCRATCHED";
};

export type HorseReserve = {
    horseNumber: number;
    reserveHorseStatus: "CORRECT" | "SCRATCHED";
};

export type Selections = Array<Array<HorseSelection>>;

export type ShareInfo = {
    commission?: number;
    initiator?: string;
    ownedShares: number;
    teamId?: number;
    totalShares: number;
    totalSharesSold: number;
};

export type PayoutStatus = "PRELIMINARY" | "PAID" | "PENDING";

export const PayoutStatuses = {
    PRELIMINARY: "PRELIMINARY",
    PAID: "PAID",
    PENDING: "PENDING",
};

type PayoutV2 = {
    boostPayoutAmount?: number;
    payFee?: number;
    payoutAmount?: number;
    payoutStatus?: PayoutStatus;
    refundAmount?: number;
    refundFee?: number;
    timestamp?: string;
};

export type BoostSelections = Array<{
    boostNumber: number;
}>;

export type BoostWinningPlan = Array<{
    correct: number;
    amount: number;
}>;

export type BoostPayouts = Array<{
    correct: number;
    amount: number;
    multiplier: number;
}>;

export type RaketSystem =
    | "TWO_OF_THREE"
    | "TWO_OF_FOUR"
    | "THREE_OF_FOUR"
    | "THREE_OF_FIVE"
    | "FOUR_OF_FIVE";

// See swagger documentation: https://atg-horse-reduced-bet-grading-qa-horse-reduced-bet-grading.ocptest.hh.atg.se/swagger-ui/index.html?configUrl=/v3/api-docs/swagger-config
export type BetGradingResponse = {
    baseSelection?: Array<HorseSelection>;
    betMethod?: "NONE" | "CAMPAIGN" | "HARRY" | "ONLY_VX" | "FLEX";
    betTime: string;
    betType: GameType;
    boost: boolean;
    boostCost?: number;
    boostSelections?: BoostSelections;
    boostWinningPlan?: BoostWinningPlan;
    cancelled?: {
        id?: number;
        name?: string;
        timestamp?: string;
    };
    channel: string;
    checkBetCode: string; // known as Rättningskod,
    fee: number;
    flexPercent?: number;
    gameId: string;
    gradingAvailable: boolean;
    groupBetId?: number;
    groupSelections: Array<Top7HorseGroup>;
    harryBoyType?: string;
    highWin?: boolean;
    numberOfCombinations?: number;
    numberOfSystems?: number;
    ownerPayout?: PayoutV2;
    raceCancelled?: boolean;
    raketSystem?: RaketSystem;
    rankingGroups?: Array<number>;
    reserves?: Array<Array<HorseReserve>>;
    selections: Selections;
    serialNumber?: string;
    shareInfo?: ShareInfo;
    stake: number;
    systemPayout?: PayoutV2;
    systemCost: number;
    v3LegNumber?: number;
    winSummary?: {
        winningElements: {
            [key: string]: {rows: number; boostMultiplier: number};
        };
    };
};

export enum ReceiptType {
    Confirm = "confirm",
    BetReceipt = "bet-receipt",
    HarrySubscription = "harry-subscription",
}

export enum BetType {
    Dd = "DD",
    Gs75 = "GS75",
    Komb = "KOMB",
    Ld = "LD",
    Plats = "PLATS",
    Raket = "RAKET",
    Top7 = "TOP7",
    Trio = "TRIO",
    Tvilling = "TVILLING",
    V3 = "V3",
    V4 = "V4",
    V5 = "V5",
    V64 = "V64",
    V65 = "V65",
    V75 = "V75",
    V86 = "V86",
    Vinnare = "VINNARE",
    Vp = "VP",
}

export enum TechnicalChannel {
    WebAtgse = "WEB_ATGSE",
    AppAndroid = "APP_ANDROID",
    AppIos = "APP_IOS",
    RetailTerminal = "RETAIL_TERMINAL",
    WebTillsammans = "WEB_TILLSAMMANS",
}

export enum BetStatus {
    Active = "ACTIVE",
    Canceled = "CANCELED",
    Graded = "GRADED",
    GradingRevoked = "GRADING_REVOKED",
    Refunded = "REFUNDED",
}

export enum PoolParticipation {
    Pool64 = "64",
    Pool6 = "6",
    Pool75 = "75",
    Pool7 = "7",
    Pool86 = "86",
    Pool65 = "65",
    Pool5 = "5",
    Pool4 = "4",
    Pool3 = "3",
}

export enum ContractType {
    Bet = "BET",
    FileBet = "FILE_BET",
    GeneratedBet = "GENERATED_BET",
    ReducedBet = "REDUCED_BET",
    HarrySubscription = "HARRY_SUBSCRIPTION",
    Unknown = "UNKNOWN",
}
