import type {GameType, DivisionGameType} from "./gameTypes";

export type GameTypeWithoutLegacy = Exclude<GameType, "V3Legacy">;
export enum GameTypesWithoutLegacy {
    V75 = "V75",
    V86 = "V86",
    GS75 = "GS75",
    V64 = "V64",
    V65 = "V65",
    V5 = "V5",
    V4 = "V4",
    V3 = "V3",
    dd = "dd",
    ld = "ld",
    trio = "trio",
    komb = "komb",
    tvilling = "tvilling",
    vinnare = "vinnare",
    plats = "plats",
    raket = "raket",
    vp = "vp",
    top7 = "top7",
}

export type DivisionGameTypeWithoutLegacy = Exclude<DivisionGameType, "V3Legacy">;
