import {combineReducers} from "redux";
import {SHARED_BET_RECEIPT_CLEAR, SHARED_BET_INVITE_STEP} from "./sharedBetActions";
import {
    SHARED_BET_RECEIPT_INVITE_STEP,
    SHARED_BET_RECEIPT_SUCCESS_STEP,
    type SharedBetReceiptStep,
} from "./sharedBetReceiptSteps";

export type SharedBetReceiptReducer = {
    currentStep: SharedBetReceiptStep;
};

const currentStep = (
    state: SharedBetReceiptStep = SHARED_BET_RECEIPT_SUCCESS_STEP,
    action: any,
): SharedBetReceiptStep => {
    const {type} = action;
    switch (type) {
        case SHARED_BET_INVITE_STEP:
            return SHARED_BET_RECEIPT_INVITE_STEP;
        case SHARED_BET_RECEIPT_CLEAR:
            return SHARED_BET_RECEIPT_SUCCESS_STEP;
        default:
            return state;
    }
};

export default combineReducers({
    currentStep,
});
