import * as React from "react";
import type {EmotionStyle} from "../../utils/emotion";
import * as styles from "./SvgIcon.styles";

export interface Props extends Omit<React.HTMLAttributes<HTMLElement>, "style"> {
    /**
     * The component or element used for the root node.
     */
    component?: React.ElementType;

    /**
     * [Emotion 10 style object used in place of the traditional className.](https://developer.atg.se/frontend/styling.html#emotion)
     */
    style?: EmotionStyle;

    /**
     * The fontsize applied to the icon.
     *
     * Default is `"md"` (24px), but can inherit font size.
     */
    size?: styles.SizeType;

    /**
     * [Provides an accessible, short-text description <br> of any SVG container element or graphics element.](https://developer.mozilla.org/en-US/docs/Web/SVG/Element/title)
     */
    title?: string;

    /**
     * Unitless coordinates inside an SVG element
     */
    viewBox?: string;

    /**
     * ID used for unit tests and e2e tests.
     *
     * Shows up in the DOM as `data-test-id`.
     */
    testId?: string;
}

interface PropsWithChildren extends Props {
    /**
     * The content of `SvgIcon`.
     *
     * Expected to be the content of an svg element.
     */
    children: React.ReactNode;
}

/**
 *  An `svg` element with conventient props and default styling. Expects to be
 *  wrapped around svg content (like <path>, etc)
 */
// todo
const SvgIcon = React.forwardRef<HTMLElement, PropsWithChildren>(
    (
        {
            children,
            style,
            component: Component = "svg",
            size = "md",
            title,
            viewBox = "0 0 32 32",
            testId,
            ...other
        }: PropsWithChildren,
        ref,
    ) => (
        <Component
            {...other}
            ref={ref}
            viewBox={viewBox}
            focusable="false"
            aria-hidden={title ? undefined : true}
            css={[styles.rootStyle, styles.fontSize(size), style]}
            data-test-id={testId}
        >
            {children}
            {title ? <title>{title}</title> : null}
        </Component>
    ),
);

SvgIcon.displayName = "SvgIcon";

export default SvgIcon;
