import {select, call, put, takeLatest} from "redux-saga/effects";
import * as dayjs from "dayjs";
import * as Time from "@atg-shared/server-time";
import {MEDIA_SERVICE_URL} from "@atg-shared/service-url";
import * as AuthSelectors from "@atg-shared/auth/domain/authSelectors";
import * as AuthActions from "@atg-shared/auth/domain/authActions";
import {OldStreamingApi} from "@atg-horse-shared/old-archiveraces-theplatform-api";
import * as API from "@atg-horse-shared/racing-info-api";
import {OPEN_START_DETAILS} from "atg-modals/modalActionTypes";
import * as StartDetailsActions from "./startDetailsActions";
import {getDetailsForStart, getRaceDetails} from "./startDetailsSelectors";
import {type StartDetailsResponse, type HistoricStart} from "./startDetailsInterfaces";

function* fetchStartDetails(action: StartDetailsActions.DisplayStartDetails) {
    const {startId} = action.payload;
    // @ts-expect-error
    const previousStartDetails = yield select(getDetailsForStart(startId));
    // @ts-expect-error
    const currentRaceDetails = yield select(getRaceDetails);
    // check that we have not already loaded the horse for head 2 head (where we fetch the whole race)
    const alreadyLoadedItem = currentRaceDetails.find(
        (details: StartDetailsResponse) =>
            `${details.raceId}_${details.startNumber}` === startId,
    );

    if (previousStartDetails) return;

    if (!alreadyLoadedItem) {
        yield put(StartDetailsActions.requestStartDetails(startId));
        let startDetails;
        try {
            // @ts-expect-error
            startDetails = yield call(API.fetchStartDetails, startId);
        } catch (error: unknown) {
            yield put(StartDetailsActions.receiveStartDetailsError(startId, error));
            return;
        }
        yield put(StartDetailsActions.receiveStartDetails(startDetails.data));
        return;
    }
    yield put(StartDetailsActions.receiveStartDetails(alreadyLoadedItem));
}

function* fetchRaceDetails(action: StartDetailsActions.RequestRaceDetails) {
    const raceId = action.payload && action.payload.raceId;
    if (!raceId) return;

    // @ts-expect-error
    const currentRaceDetails = yield select(getRaceDetails);
    if (currentRaceDetails.length > 0 && `${currentRaceDetails[0].raceId}` === raceId)
        return;

    let raceDetails;
    try {
        yield put(StartDetailsActions.requestedRaceDetails());
        // @ts-expect-error
        raceDetails = yield call(API.fetchRaceDetails, raceId);
    } catch (error: unknown) {
        yield put(StartDetailsActions.receiveRaceDetailsError(raceId, error));
        return;
    }

    yield put(StartDetailsActions.receiveRaceDetails(raceDetails.data));
}

export function* resolveSourceFromBackend(source: string) {
    let sourceResponse;
    let resolvedSource;
    try {
        // @ts-expect-error
        sourceResponse = yield call(OldStreamingApi.callBackendEndpoint, source);
    } catch (err: unknown) {
        return null;
    }

    if (!sourceResponse || !sourceResponse.streams) return null;

    const stream: {type: string; url: string} = sourceResponse.streams.find(
        (item: {type: string; url: string}) =>
            item.type === "application/x-mpegURL" || item.type === "video/mp4",
    );

    try {
        // @ts-expect-error
        resolvedSource = yield call(
            OldStreamingApi.resolvePlatformURL,
            stream.url,
            source,
        );
    } catch (err: unknown) {
        return null;
    }

    return {
        resolvedSource,
        stream,
        metaData: sourceResponse,
    };
}
// copied from @atg-horse-shared/video-domain but with typed action here
export function* canWatchContent(race: HistoricStart) {
    const isLoggedIn: boolean = yield select(AuthSelectors.isLoggedIn);
    const nowDate = dayjs.default(Time.serverTime(false));
    const raceDate = dayjs.default(race.date);
    const limitDate = raceDate.add(10, "days");
    const withinFreePeriod = !nowDate.isAfter(limitDate);
    // `options.countryCode` will be undefined when triggered from the receipt page.
    // In that case `isFrenchRace` will evaluate to `false` and the user will be allowed to watch
    // the video which is fine since they're always logged in on receipt page.
    const isFrenchRace = race.countryCode === "FR";

    return isLoggedIn || (!isFrenchRace && withinFreePeriod);
}

export function* resolveArchiveSource(action: StartDetailsActions.StartDetailsVideo) {
    const {race} = action.payload;
    if (!race.mediaId) return;

    const canWatch: boolean = yield call(canWatchContent, race);

    if (!canWatch) {
        yield put(AuthActions.checkAuth());
    } else {
        // @ts-expect-error 'yield' expression implicitly results in an 'any' type because its containing generator lacks a return-type annotation.
        const sourceData = yield call(
            resolveSourceFromBackend,
            `${MEDIA_SERVICE_URL}/${race.mediaId}`,
        );
        if (!sourceData) return;

        const {resolvedSource} = sourceData;

        yield put(StartDetailsActions.storeStartDetailsVideo(race, resolvedSource));
    }
}

export default function* startInfoSaga() {
    yield takeLatest(OPEN_START_DETAILS, fetchStartDetails);
    yield takeLatest(StartDetailsActions.SHOW_START_DETAILS_VIDEO, resolveArchiveSource);
    yield takeLatest(StartDetailsActions.OPEN_HEAD_2_HEAD, fetchRaceDetails);
}
