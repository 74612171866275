import * as React from "react";

type Props = {
    legNo: string;
    tableProps: {
        [key: string]: any;
    };
    column: {
        [key: string]: any;
    };
};

function LegView({legNo, tableProps, column}: Props) {
    const gameType = tableProps.bet.game.type;

    return (
        <td
            className={column.id + "-col game-type-" + gameType.toLowerCase()}
            data-test-id={`confirm-${column.id}-${legNo}`}
        >
            {legNo}
        </td>
    );
}

export default LegView;
