import {broadcastAction} from "atg-store-addons";
import * as PlayerActions from "../player/playerActions";
import {PlayerIds} from "../player/playerReducer";
import type {
    Channel,
    Channels,
    SelectedChannel,
    VideoObject,
    VideoArchive,
} from "./video";
import {
    EXPAND_VIDEO_FRAME,
    TOGGLE_VIDEO_FRAME_EXPANDED,
    TOGGLE_VIDEO_ONLY,
    DEPRECATED_SELECT_CHANNEL,
    LOADED_CHANNEL_CONFIG,
    SET_VIDEO_ONLY,
    SET_POPOUT_FRAME,
    LOAD_VIDEO_CHANNELS_START,
    START_LISTENING_TO_CHANNELS_PUSH,
    STOP_LISTENING_TO_CHANNELS_PUSH,
    RECEIVE_CHANNELS_PUSH,
    SELECT_CHANNEL,
    MEDIA_WARMUP,
    MEDIA_WARMUP_WINDOW,
    MEDIA_ARCHIVE,
    MEDIA_ARCHIVE_START,
    MEDIA_ARCHIVE_WINDOW,
    OPEN_VIDEO_POPUP,
    CLOSE_VIDEO_POPUP_START,
    CLOSE_VIDEO_POPUP,
    RESET_BANNER_RIGHT_VIDEO,
    LOAD_CHANNELS_EXTERNAL_WINDOW,
    RECEIVE_SELECT_CHANNEL,
    V2_SET_LIVE_VIDEO_QUALITY,
    ACTIVATE_LOW_LATENCY_VIDEO,
    DEACTIVATE_LOW_LATENCY_VIDEO,
} from "./videoActionConstants";
import {deprecated_saveQualityToStorage} from "./videoutil";

type SelectChannelPayload = {
    channel: Channel;
    quality: string;
};

type LoadedChannelConfigPayload = {
    channels: Channels;
    selectedChannel: SelectedChannel;
    selectedQuality: string;
};

export const receiveChannelsPush = (data: Channel) => ({
    type: RECEIVE_CHANNELS_PUSH,
    payload: {
        channels: data,
    },
});

export const expandVideoFrame = (expanded = true) =>
    broadcastAction({
        type: EXPAND_VIDEO_FRAME,
        payload: {
            expanded,
        },
    });

export const toggleExpanded = () => ({
    type: TOGGLE_VIDEO_FRAME_EXPANDED,
});

export const toggleVideoOnly = () => ({
    type: TOGGLE_VIDEO_ONLY,
});

export const setVideoOnly = (isVideoOnly: boolean) => ({
    type: SET_VIDEO_ONLY,
    payload: {
        isVideoOnly,
    },
});

export const setPopoutFrame = (isPopoutFrame: boolean) => ({
    type: SET_POPOUT_FRAME,
    payload: {
        isPopoutFrame,
    },
});

export const startLoadVideoChannels = () => ({
    type: LOAD_VIDEO_CHANNELS_START,
});

export const startListeningToChannelsPush = () => ({
    type: START_LISTENING_TO_CHANNELS_PUSH,
});

export const stopListeningToChannelsPush = () => ({
    type: STOP_LISTENING_TO_CHANNELS_PUSH,
});

export const selectChannel = (payload: SelectChannelPayload) => {
    // Using redux-persist instead, this will be removed soon. See `https://github.com/atgse/atgse/pull/4873` for details.
    deprecated_saveQualityToStorage(payload.quality);

    return {
        type: SELECT_CHANNEL,
        payload,
    };
};

export const loadChannelsOnExternalWindow = (streamId: string) => ({
    type: LOAD_CHANNELS_EXTERNAL_WINDOW,
    payload: streamId,
});

export const loadedChannelConfig = (payload: LoadedChannelConfigPayload) => ({
    type: LOADED_CHANNEL_CONFIG,
    payload,
});

export const setMediaWarmupWindow = (payload: VideoObject) => ({
    type: MEDIA_WARMUP_WINDOW,
    payload,
});

export const setMediaWarmup = (payload: VideoObject) => ({
    type: MEDIA_WARMUP,
    payload,
});

export const setMediaArchiveWindow = (payload: VideoArchive) => ({
    type: MEDIA_ARCHIVE_WINDOW,
    payload,
});

export const setMediaArchiveStart = (payload: VideoArchive) => ({
    type: MEDIA_ARCHIVE_START,
    payload,
});

export const setMediaArchive = (payload: VideoArchive) =>
    broadcastAction({
        type: MEDIA_ARCHIVE,
        payload,
    });

export const openVideoPopup = (playerName: string) => ({
    type: OPEN_VIDEO_POPUP,
    payload: playerName,
});

export const closeVideoPopupStart = (playerName: string) => ({
    type: CLOSE_VIDEO_POPUP_START,
    payload: playerName,
});

export const closeVideoPopup = (playerName: string) => ({
    type: CLOSE_VIDEO_POPUP,
    payload: playerName,
});

export const resetBannerRightVideo = () => ({
    type: RESET_BANNER_RIGHT_VIDEO,
});

export const receiveSelectChannel = (channel: Channel) => ({
    type: RECEIVE_SELECT_CHANNEL,
    channel,
});

export const playMediaVideo = () =>
    PlayerActions.play(PlayerIds.WARMUP_ARCHIVE_PLAYER_ID);
export const stopVideo = () => PlayerActions.pause(PlayerIds.WARMUP_ARCHIVE_PLAYER_ID);
export const playLiveVideo = () => PlayerActions.play(PlayerIds.LIVE_PLAYER_ID);
export const stopLiveVideo = () => PlayerActions.pause(PlayerIds.LIVE_PLAYER_ID);

export const v2SetLiveVideoQuality = (selectedQuality: string) => ({
    type: V2_SET_LIVE_VIDEO_QUALITY,
    payload: selectedQuality,
});

export const activateLowLatencyVideo = () => ({
    type: ACTIVATE_LOW_LATENCY_VIDEO,
});

export const deactivateLowLatencyVideo = () => ({
    type: DEACTIVATE_LOW_LATENCY_VIDEO,
});

// This is deprecated action use selectChannel instead `https://github.com/atgse/atgse/pull/4873` will be remove in future release
// @ts-expect-error
function _deprecatedSelectChannel(payload) {
    return {
        type: DEPRECATED_SELECT_CHANNEL,
        payload,
    };
}

/**
 * @deprecated - This action is deprecated, use `selectChannel` instead. See `https://github.com/atgse/atgse/pull/4873` for details.
 */
export function deprecated_selectChannel(channel: {[key: string]: any}) {
    return (
        dispatch: (...args: Array<any>) => any,
        getState: (...args: Array<any>) => any,
    ) => {
        if (!channel) return;
        const state = getState();
        const oldChannel = state.video.liveVideo.selectedChannel;
        const nextChannel = channel;
        const oldChannelId = oldChannel && oldChannel.id;
        const nextChannelId = nextChannel && nextChannel.id;
        if (oldChannelId === nextChannelId) return;
        dispatch(_deprecatedSelectChannel(channel));
    };
}
