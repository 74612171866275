export enum PlayerActionTypes {
    PLAYER_INIT = "PLAYER_INIT",
    PLAYER_PLAY = "PLAYER_PLAY",
    PLAYER_PAUSE = "PLAYER_PAUSE",
    CONTROLBAR_MOUSEOVER = "CONTROLBAR_MOUSEOVER",
    CONTROLBAR_MOUSEOUT = "CONTROLBAR_MOUSEOUT",
    CONTROLBAR_HIDE = "CONTROLBAR_HIDE",
    PLAYER_MOUSEOVER = "PLAYER_MOUSEOVER",
    PLAYER_MOUSEOUT = "PLAYER_MOUSEOUT",
    PLAYER_STREAMTYPE_CHANGE = "RESET_PLAYER_SEEK_POSITION",
    LOW_LATENCY_CHROME_CAST_AVAILABLE = "LOW_LATENCY_CHROME_CAST_AVAILABLE",
    PLAYER_CHANGE_VOLUME = "PLAYER_CHANGE_VOLUME",
    PLAYER_MUTE = "PLAYER_MUTE",
    PLAYER_UNMUTE = "PLAYER_UNMUTE",
    START_PLAYER_SOURCE = "START_PLAYER_SOURCE",
    PLAYER_SOURCE = "PLAYER_SOURCE",
    PLAYER_NEXT_TRACK = "PLAYER_NEXT_TRACK",
    PLAYER_PREVIOUS_TRACK = "PLAYER_PREVIOUS_TRACK",
    PLAYER_DESTROY = "PLAYER_DESTROY",
    PLAYER_TOGGLE_FULLSCREEN = "PLAYER_TOGGLE_FULLSCREEN",
    PLAYER_TRACK_COMPLETED = "PLAYER_TRACK_COMPLETED",
    SET_PLAYER_SOURCE_URL = "SET_PLAYER_SOURCE_URL",
    CHECK_AVAILABLE_PLACE = "CHECK_AVAILABLE_PLACE",
    SET_IS_AIRPLAY_AVAILABLE = "SET_IS_AIRPLAY_AVAILABLE",
    SET_IS_AIRPLAY_CALLED = "SET_IS_AIRPLAY_CALLED",
    RESET_AUTOPLAY = "RESET_AUTOPLAY",
    START_PLAYER_LIVE_SOURCE = "video/startPlayerLiveSource",
    START_PLAYER_MEDIA_SOURCE = "video/startPlayerMediaSource",
    VIDEO_ENTER_FULLSCREEN = "video/enterFullscreen",
    REQUEST_FULLSCREEN = "video/requestFullscreen",
    SHOW_VIDEO_SETTINGS = "video/showVideoSettings",
    OPEN_RADAR_MENU = "video/openRadarMenu",
    CLOSE_RADAR_MENU = "video/closeRadarMenu",
    RESET_PLAYLIST_POS = "video/resetPlaylistPos",
    VIDEO_EXIT_FULLSCREEN = "video/exitFullscreen",
    DEACTIVATE_AUDIO_ONLY = "video/deactivateAudioOnly",
    SET_QUALITY_LEVEL = "SET_QUALITY_LEVEL",
    SET_QUALITY_LEVELS = "SET_QUALITY_LEVELS",
    SET_IS_BUFFERING = "SET_IS_BUFFERING",
}

export enum PlayerPlayTargets {
    ANDROID_PLAYER_TOUCH_FULLSCREEN = "ANDROID_PLAYER_TOUCH_FULLSCREEN",
    ANDROID_PLAYER_PLAY_BUTTON = "ANDROID_PLAYER_PLAY_BUTTON",
}
