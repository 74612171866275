import {memoize} from "lodash/fp";
import root from "window-or-global";
import {pureFetch} from "@atg-shared/fetch";
import TTLCache from "@atg/utils/TTLCache";
import type {AtgResponse} from "@atg-shared/fetch-types";

interface CountryResponse {
    value: string | null;
}

// @ts-ignore Need to extend window-or-global type
const COUNTRY_API = root.clientConfig?.countryEndpoint;
const COUNTRY_CACHE_TIME = 30 * 60 * 1000;

const fetchCountry = memoize(
    (): Promise<AtgResponse<CountryResponse>> => pureFetch(COUNTRY_API),
);

const ttlCache: TTLCache<string, CountryResponse> = new TTLCache(COUNTRY_CACHE_TIME);

fetchCountry.cache = ttlCache;

export default fetchCountry;
