import {capitalize} from "lodash";
import {
    horses,
    createReserves,
    activateReserves,
    getResultsByRace,
    getResultForPlacement,
    isSneaked,
} from "../domain/legacyBet";

const receiptColumnData = (column: {id: string}, receipt: any, data: any) => {
    const winner = getResultsByRace(receipt.bet, data);

    const boostSneakOrResult = () => {
        const {boostWinningPlan} = receipt.bet.boostInfo;
        return {
            code: boostWinningPlan ? boostWinningPlan.code : null,
        };
    };

    switch (column.id) {
        case "horses":
            return horses(receipt, data, activateReserves(receipt, data));
        case "code-horses":
        case "video":
            return data;
        case "reserves":
            return createReserves(receipt, data, data.reserves);
        case "winner":
            return winner;
        case "result":
            return getResultForPlacement(receipt.bet.races[0].positionResults, data);
        case "sneak":
            return isSneaked(receipt, data) ? winner : data;
        case "race":
            return data.raceNumber.toString();
        case "betType":
            return capitalize(data.betType);
        case "leg":
            return data.legNo ? data.legNo.toString() : "";
        case "leg-no":
            if (receipt.bet.verbalV3Info) {
                return receipt.bet.verbalV3Info.leg;
            }

            if (receipt.bet.v3LegNumber) {
                return receipt.bet.v3LegNumber;
            }

            return data.legNo ? data.legNo.toString() : "";
        case "place":
            return data.place;
        case "harry-open":
            return data.harryOpen ? "Öppen" : "Stängd";
        case "boost-code": {
            const {boostInfo} = receipt.bet;
            const {boostWinningPlan} = boostInfo;

            return {
                code: boostInfo.code,
                result: boostWinningPlan ? boostWinningPlan.code : null,
                showWin: receipt.selectedTab === "result" || receipt.boostSneaked,
            };
        }

        case "boost-sneak":
        case "boost-result":
            return boostSneakOrResult();

        default:
            return receipt;
    }
};

export default receiptColumnData;
