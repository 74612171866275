export const SIMPLE_LIST = "Enkel lista";
export const POPULAR_LIST = "Populär lista";
export const DETAILED_LIST = "Detaljerad lista";
export const MANAGE_LIST = "Hantera lista";
export const NEW_LIST = "Ny lista";
export const EDIT_LIST = "Ändra lista";
export const MY_LIST = "Min lista";
export const VERTICAL_STARTLISTS_ON = "Visa alla avdelningar on";
export const VERTICAL_STARTLISTS_OFF = "Visa alla avdelningar off";
export const RACE_COMMENTS_ON = "Loppkommentar on";
export const RACE_COMMENTS_OFF = "Loppkommentar off";
export const COMPACT_VIEW_ON = "Kompakt vy on";
export const COMPACT_VIEW_OFF = "Kompakt vy off";
export const BET_DISTRIBUTION_DECIMALS_ON = "Visa decimaler on";
export const BET_DISTRIBUTION_DECIMALS_OFF = "Visa decimaler off";
