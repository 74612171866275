import {combineReducers, type Reducer} from "redux";
import {
    REQUESTED_EXTENDED_RACE,
    RECEIVED_EXTENDED_RACE,
} from "./extendedStartActionTypes";
import type {ExtendedStartAction} from "./extendedStartActions.flow";
import type {
    ExtendedRace,
    ExtendedStartRaces,
    ExtendedStartData,
    ExtendedStartState,
    ExtendedStartPending,
    ExtendedStartError,
} from "./extendedStart.flow";

export type IsPendingReducer = Reducer<ExtendedStartPending, ExtendedStartAction>;
export type ExtendedStartErrorReducer = Reducer<ExtendedStartError, ExtendedStartAction>;
export type ExtendedStartDataReducer = Reducer<ExtendedStartRaces, ExtendedStartAction>;

const getResults = (extendedRace: ExtendedRace) => {
    const raceId = extendedRace.id;
    if (!extendedRace.starts) return {};

    return extendedRace.starts.reduce((results, start: ExtendedStartData) => {
        const startId = `${raceId}_${start.number}`;
        // @ts-expect-error
        results[startId] = {
            previousResults: start.horse.results,
            comments: start.comments,
        };
        return results;
    }, {});
};

const isPending: IsPendingReducer = (state = {}, action) => {
    switch (action.type) {
        case REQUESTED_EXTENDED_RACE:
            return {
                ...state,
                [action.payload.raceId]: true,
            };
        case RECEIVED_EXTENDED_RACE:
            return {
                ...state,
                [action.payload.raceId]: false,
            };
        default:
            return state;
    }
};

const error: ExtendedStartErrorReducer = (state = {}, action) => {
    // @ts-expect-error
    if (action.type !== RECEIVED_EXTENDED_RACE || !action.error) return state;

    return {
        ...state,
        // @ts-expect-error
        [action.payload.raceId]: action.error,
    };
};

const data: ExtendedStartDataReducer = (state = {}, action) => {
    switch (action.type) {
        case RECEIVED_EXTENDED_RACE:
            // @ts-expect-error
            if (action.error) return {};
            return {
                ...state,
                // @ts-expect-error
                [action.payload.raceId]: getResults(action.payload.data),
            };
        default:
            return state;
    }
};

const extendedStartReducer: Reducer<ExtendedStartState, ExtendedStartAction> =
    combineReducers({
        data,
        error,
        isPending,
    });

export default extendedStartReducer;
