import type {AtgResponse} from "@atg-shared/fetch-types";
import atgRequest, {pureFetch} from "@atg-shared/fetch";
import {CALENDAR_SERVICE_URL} from "@atg-shared/service-url";
import * as Storage from "@atg-shared/storage";
import type {CalendarDayResponse, GaloppCalendarResponse} from "./types";

// day

/**
 * Build URL to racinginfo/v1/api/calendar/day/ with date and minVersion params.
 * When using this url racingInfo will return an object with @type CalendarAPITypes.CalendarDayResponse
 */

const createDayUrl = (date?: string, minVersion?: number) => {
    const minVersionSuffix = minVersion ? `?minVersion=${minVersion}` : "";
    const dayParam = date ? `/${date}` : ``;
    return `${CALENDAR_SERVICE_URL}/day${dayParam}${minVersionSuffix}`;
};

/**
 * Fetch all games for a calendar day
 * API endpoint: `/services/racinginfo/v1/api/calendar/day/<day>`
 */

const fetchDay = (
    date?: string,
    minVersion?: number,
): Promise<AtgResponse<CalendarDayResponse>> => {
    if (Storage.getItem("radarDebugger", Storage.SESSION) === "true") {
        return pureFetch<CalendarDayResponse>(
            `https://www.atg.se${CALENDAR_SERVICE_URL}/day/${date}?minVersion=${minVersion}`,
        );
    }
    return atgRequest<CalendarDayResponse>(createDayUrl(date, minVersion));
};

/**
 * Create URL to /services/racinginfo/v1/api/calendar/gallop with fromDate and toDate params.
 * When using this url racingInfo will return an object with @type CalendarAPITypes.GaloppCalendarResponse
 */

const createGaloppCalendarUrl = (fromDate: string, toDate: string) => {
    const sportUrl = `${CALENDAR_SERVICE_URL}/gallop`;
    return `${sportUrl}?from=${fromDate}&to=${toDate}`;
};

/**
 * Fetch from API endpoint: `/services/racinginfo/v1/api/calendar/gallop`
 */

const fetchGaloppCalendar = (fromDate: string, toDate: string) =>
    atgRequest<GaloppCalendarResponse>(createGaloppCalendarUrl(fromDate, toDate));

export {createDayUrl, fetchDay, createGaloppCalendarUrl, fetchGaloppCalendar};
