import {css} from "@emotion/react";

import {
    // @ts-expect-error
    receiptResultBg,
    // @ts-expect-error
    receiptCorrectColor,
    // @ts-expect-error
    receiptPlacementCorrectColor,
} from "atg-ui/css/variables.styles";

export const check = css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const dashedCheck = css`
    border: 1px dashed;
`;

export const icon = css`
    width: 14px;
    height: 14px;
`;

export const correct = css`
    ${check};
    color: ${receiptResultBg};

    background-color: ${receiptCorrectColor};
`;

export const placementCorrect = css`
    ${check};
    ${dashedCheck};
    color: ${receiptPlacementCorrectColor};

    border-color: ${receiptPlacementCorrectColor};
`;

export const tempCorrect = css`
    ${check};
    ${dashedCheck};
    color: ${receiptCorrectColor};

    border-color: ${receiptCorrectColor};
`;
