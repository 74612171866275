import type {Top7BoxedSelection} from "@atg-tillsammans-shared/shared-bet-types";

export const createBoxesByBoxSize = (
    boxSizes: number[],
    selections: number[],
): number[][] =>
    boxSizes.reduce<number[][]>((acc, size) => {
        const startIndex = acc.flat().length;
        const box = selections.slice(startIndex, startIndex + size);
        return [...acc, box];
    }, []);

export const getFinalSelections = (
    selections: number[],
    scratchedSelections: number[],
    reserves: number[],
): number[] => {
    // No scratched selections to remove so just return.
    if (scratchedSelections.length === 0) return selections;

    // Remove scratched selections
    const selectionsWithoutScratched = selections.reduce<number[]>(
        (acc, selection) =>
            scratchedSelections.includes(selection) ? acc : [...acc, selection],
        [],
    );

    const numberOfReservesToBeAdded =
        selections.length - selectionsWithoutScratched.length;

    // Add the correct number of reserves at the end
    return selectionsWithoutScratched.concat(
        reserves.slice(0, numberOfReservesToBeAdded),
    );
};

export const getFinalBoxes = (boxes: number[][], selections: number[]): number[][] => {
    const boxSizes = boxes.map((box) => box.length);
    return createBoxesByBoxSize(boxSizes, selections);
};

export const isSelectionInBox = (
    boxes: number[][],
    boxIndex: number,
    selection: number,
) => boxes[boxIndex].includes(selection);

export const getScratchedSelectionFromBox = (
    boxes: number[][],
    boxIndex: number,
    scratchedSelections: number[],
): Top7BoxedSelection[] => {
    const scratchedSet = new Set(scratchedSelections);

    return boxes[boxIndex].reduce<Top7BoxedSelection[]>((acc, selection) => {
        if (scratchedSet.has(selection)) {
            acc.push({selection, scratched: true});
        }
        return acc;
    }, []);
};

export const getSelectionBoxIndex = (boxes: number[][], selection: number) =>
    boxes.findIndex((box) => box.includes(selection));

export const getBoxedBets = (
    boxSizes: number[],
    originalSelections: number[],
    scratchedSelections: number[],
    reserves: number[],
): Top7BoxedSelection[][] => {
    const originalBoxes = createBoxesByBoxSize(boxSizes, originalSelections);

    // Get selections excluding scratched, including reserves if needed
    const finalSelections = getFinalSelections(
        originalSelections,
        scratchedSelections,
        reserves,
    );

    // Get updated boxes based on final selections calculated above. Box sizes will be the same
    const finalBoxes = getFinalBoxes(originalBoxes, finalSelections);

    // Decorate selections in each box with the data we need.
    return finalBoxes.map((box, boxIndex) => {
        const mappedSelections = box.map((selection) => {
            // Check if selection is in its original box, if true there's nothing more to do
            if (isSelectionInBox(originalBoxes, boxIndex, selection)) return {selection};

            // Selection is somewhere else, from which box did it move? -1 hear means moved from reserves. Perhaps a better indication needed?
            const movedFrom = getSelectionBoxIndex(originalBoxes, selection);

            return movedFrom > -1
                ? {selection, moved: movedFrom}
                : {selection, given: true};
        });

        // At this point we don't have any information about scratched selections. Lets add them from the original boxes
        const scratchedSelectionsFromOriginalBoxes = getScratchedSelectionFromBox(
            originalBoxes,
            boxIndex,
            scratchedSelections,
        );

        return [...mappedSelections, ...scratchedSelectionsFromOriginalBoxes];
    });
};

export const getBoxedBetsByRanking = (
    boxSizes: number[],
    originalSelections: number[],
    scratchedSelections: number[],
    reserves: number[],
): Top7BoxedSelection[][] => {
    const boxedBets = getBoxedBets(
        boxSizes,
        originalSelections,
        scratchedSelections,
        reserves,
    );

    return boxSizes.reduce<Top7BoxedSelection[][]>((acc, boxSize, index) => {
        const boxedBet = boxedBets[index];

        if (!boxedBet) return acc;

        if (boxSize > 1) {
            for (let i = 0; i < boxSize; i += 1) {
                acc.push(boxedBet);
            }
        } else {
            acc.push(boxedBet);
        }

        return acc;
    }, []);
};
