import * as Analytics from "@atg-shared/analytics";
import {call, take, select, takeLatest} from "redux-saga/effects";
import {type Saga} from "redux-saga";
import reportCloseGuide from "./guideAnalytics";
import {
    START_GUIDE,
    CLOSE_GUIDE,
    GO_TO_GUIDE_STEP,
    NEXT_GUIDE_STEP,
} from "./guideActions";

import {getCurrentStepIndex, getNumberOfGuideSteps} from "./guideSelectors";

export function* reportGuideCompletion({
    currentStepIndex,
    numberOfSteps,
    isAutoStart,
    isClosedBeforeStart,
}: {
    currentStepIndex: number;
    numberOfSteps: number;
    isAutoStart: boolean;
    isClosedBeforeStart?: boolean;
    // @ts-expect-error
}): Saga<void> {
    yield call(
        Analytics.deprecated_logEvent,
        reportCloseGuide(
            currentStepIndex,
            numberOfSteps,
            isAutoStart,
            isClosedBeforeStart,
        ),
    );
}

export function* calculateGuideCompletion({
    payload,
}: {
    payload: {isAutoStart: boolean; isClosedBeforeStart: boolean};
    // @ts-expect-error
}): Saga<void> {
    const {isAutoStart, isClosedBeforeStart} = payload;
    const guide = {
        numberOfSteps: yield select(getNumberOfGuideSteps),
        currentStepIndex: yield select(getCurrentStepIndex),
        isAutoStart,
        isClosedBeforeStart,
    };

    yield takeLatest(CLOSE_GUIDE, reportGuideCompletion, guide);

    while (yield take([NEXT_GUIDE_STEP, GO_TO_GUIDE_STEP])) {
        guide.currentStepIndex = yield select(getCurrentStepIndex);
    }
}

// @ts-expect-error
export default function* guideAnalyticsSaga(): Saga<void> {
    // @ts-expect-error
    yield takeLatest(START_GUIDE, calculateGuideCompletion);
}
