/** loading state, we don't know what screen to show yet */
export const INIT = "INIT";

/** ready to place bet, show confirm screen */
export const CONFIRM = "CONFIRM";

/** need to add money to account, show deposit screen */
export const DEPOSIT = "DEPOSIT";

/** final step, show receipt after successful bet */
export const RECEIPT = "RECEIPT";

/** new step for making the purchase with Varenne BE */
export const VARENNE_PURCHASE = "VARENNE_PURCHASE";

export const KYC = "KYC";

export type PurchaseStep =
    | typeof INIT
    | typeof CONFIRM
    | typeof DEPOSIT
    | typeof RECEIPT
    | typeof KYC
    | typeof VARENNE_PURCHASE;
