import * as React from "react";
import {Clock, Eye} from "atg-ui-components/icons";
import type {TableProps, ColumnData} from "./CodeResultMark";
import CodeHorsesColumn from "./CodeHorsesColumn";
import * as styles from "./BetCodeHorses.styles";

export type RowData = {
    resultExists?: boolean;
    sneaked: boolean;
    columns: {
        [id: string]: {
            data: ColumnData;
        };
    };
};

export type Column = {
    id: string;
};

export type Props = {
    tableProps: TableProps;
    data: RowData;
    column: Column;
};

function BetCodeHorses({tableProps, data, column}: Props) {
    const markAsSneaked = () => tableProps.actions.markAsSneaked(data);

    if (!data.resultExists) {
        return (
            <td data-test-id="mark-eye" className={`${column.id}-col`}>
                <Clock size="sm" style={styles.icon} />
            </td>
        );
    }

    if (data.sneaked) {
        const columnData = data.columns["code-horses"].data;
        return (
            <td data-test-id="mark-sneak" className={`${column.id}-col`}>
                <CodeHorsesColumn columnData={columnData} tableProps={tableProps} />
            </td>
        );
    }

    return (
        <td
            data-test-id="mark-eye"
            className={`${column.id}-col pointer`}
            onClick={markAsSneaked}
        >
            <Eye size="sm" style={styles.icon} />
            <span className="space-left">Visa vinnare</span>
        </td>
    );
}

export default BetCodeHorses;
