import type {AnyAction, Store} from "redux";
import log, {serializeError} from "@atg-shared/log";
import {serverTime} from "@atg-shared/server-time";
import type {
    ExperienceReturnValue,
    ExperienceConfig,
} from "@atg-global-shared/personalization-types";
import type {State, Action} from "@atg-global-shared/personalization-types";
import {env, getVariationIndex, getExperience} from "./personalizationHooks";
import {nativeExperienceTriggered} from "./nativePersonalizationActions";

/**
 * A function that corresponds to the useNativeABTest hook. This is useful
 * outside of React, for instance in sagas or similar.
 *
 * @param cache
 * By default this function is memoized and will never make more than one call to the Qubit API for
 * any given experience. `cache` can be set to `false` to disable this behavior.
 */
export async function runNativeABTest(
    config: ExperienceConfig,
    store: Store<State, Action | AnyAction>,
    cache = true,
): Promise<ExperienceReturnValue> {
    async function fetchExperience() {
        const {experienceId, variations} = config[env];

        try {
            // In some situations we want to throw away any cached/memoized result (for example when
            // using the AB test overlay to change the current variation). The lodash memoized
            // function exposes the cache like this, so we can delete any potentially existing cache
            // before calling the function.
            if (!cache) getExperience.cache.delete(experienceId);

            const {data} = await getExperience(experienceId);
            const [experience] = data.experiencePayloads;

            if (!experience) {
                store.dispatch(
                    nativeExperienceTriggered({
                        // default to the first variation (typically the control)
                        variation: 0,
                        experienceId,
                    }),
                );
                return {
                    variation: 0,
                };
            }

            const mappedExperience = {
                fields: experience.payload,
                variation: getVariationIndex({experience, variations}),
            };

            store.dispatch(
                nativeExperienceTriggered({...mappedExperience, experienceId}),
            );

            return mappedExperience;
        } catch (error: unknown) {
            // turn this error logging off during winter burst
            // https://jira-atg.riada.cloud/browse/LIVE-1152
            if (serverTime().format("YYYY-MM-DD") !== "2022-12-31") {
                log.error(
                    `personalizationHooks:useNativeABTest: failed to fetch experience from qubit`,
                    {
                        error: serializeError(error),
                        experienceId,
                    },
                );
            }

            return {
                // default to the first variation (typically the control)
                variation: 0,
            };
        }
    }

    let experienceData = {
        variation: 0,
    };

    if (!store) {
        return experienceData;
    }

    experienceData = await fetchExperience();

    return experienceData;
}
