import {collectWinStats} from "./libs/collect-win-stats";
import {getBoxedBetsWinners} from "./libs/get-boxed-bets-winners";
import {INITIAL_WIN_STATS} from "./libs/initial-win-stats";
import {isPositionSneaked} from "./libs/is-position-sneaked";

function appendSneakState(result: any, sneakState: any) {
    return {
        ...result,
        sneakState: [...result.sneakState, sneakState],
    };
}

// This should be the real getTop7SneakState when receipt data structure is removed (git log 97c5de2)
export function privateGetTop7SneakState(bet: any, sneakedPositions?: Array<any>) {
    const {sneakState} = getBoxedBetsWinners(bet.boxedBets).reduce(
        (prev, groupWinners, index) => {
            const result = collectWinStats(sneakedPositions)(prev, groupWinners, index);
            if (!isPositionSneaked(sneakedPositions, index))
                return appendSneakState(result, {});

            if (result.win === false) {
                return appendSneakState(result, {
                    win: false,
                    placementCorrect: true,
                });
            }

            if (!result.isCertain) {
                return appendSneakState(result, {
                    win: false,
                    tempWin: true,
                });
            }

            return appendSneakState(result, {
                win: true,
            });
        },
        {...INITIAL_WIN_STATS, sneakState: []},
    );

    return sneakState;
}
