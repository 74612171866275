import type {AnyAction, Reducer} from "redux";
import {combineReducers} from "redux";
import {createFetchReducer, createInitialState} from "@atg-shared/fetch-redux";
import {RECEIVE_USER} from "@atg-global-shared/user/userActionTypes";
import type {ReceiveUserAction} from "@atg-global-shared/user/userActions";
import type {
    ContactInfoState,
    ContactOptionsState,
    AccountSettingsState,
} from "@atg-payment-shared/account-settings-types";
import {getErrorMessage} from "../saga/accountSettingsSaga";

import type {Action} from "../accountSettingsActions";
import {
    ACCOUNT_SETTINGS_CLEAR,
    RESET_STATUS,
    SET_CONTACT_INFO_REQUEST,
    SET_CONTACT_INFO_RECEIVE,
    USER_CONTACT_OPTIONS,
    USER_CONTACT_OPTIONS_RESPONSE,
    COMMUNICATION_OPTIONS_EDIT_SETTINGS_SUCCESS,
    OPEN_CHANGE_AVATAR_MODAL,
} from "../accountSettingsActions";

const error = (state = "", action: {type: string; payload: any; error: any}): string => {
    const {type, payload} = action;

    switch (type) {
        case SET_CONTACT_INFO_RECEIVE:
            if (!action.error) return "";
            return getErrorMessage(payload);
        case ACCOUNT_SETTINGS_CLEAR:
            return "";
        default:
            return state;
    }
};

const triggerChangeAvatarModal = (state = false, action: Action) => {
    switch (action.type) {
        case OPEN_CHANGE_AVATAR_MODAL: {
            return true;
        }
        default:
            return state;
    }
};

export const initialContactInfoState = createInitialState({
    data: {},
});

const contactInfo = createFetchReducer(
    SET_CONTACT_INFO_REQUEST,
    SET_CONTACT_INFO_RECEIVE,
    RESET_STATUS,
    (state: ContactInfoState, action: Action | ReceiveUserAction) => {
        switch (action.type) {
            case RECEIVE_USER: {
                return {data: action.payload.user.contactInfo};
            }
            default:
                return state;
        }
    },
    initialContactInfoState,
);

export const initialContactOptionsState = createInitialState({
    data: {
        HORSE_BETTING: {
            EMAIL: {
                optIn: false,
            },
            SMS: {
                optIn: false,
            },
            POSTAL: {
                optIn: false,
            },
        },
        SPORTSBOOK: {
            EMAIL: {
                optIn: false,
            },
            SMS: {
                optIn: false,
            },
            POSTAL: {
                optIn: false,
            },
        },
        CASINO: {
            EMAIL: {
                optIn: false,
            },
            SMS: {
                optIn: false,
            },
            POSTAL: {
                optIn: false,
            },
        },
        SHOP_SHARES: {
            EMAIL: {
                optIn: false,
            },
            SMS: {
                optIn: null,
            },
            POSTAL: {
                optIn: null,
            },
        },
    },
});

const contactOptions = createFetchReducer(
    USER_CONTACT_OPTIONS,
    USER_CONTACT_OPTIONS_RESPONSE,
    RESET_STATUS,
    (state: ContactOptionsState, action: Action) => {
        switch (action.type) {
            case USER_CONTACT_OPTIONS_RESPONSE: {
                if (action.error) return state;
                // When the newly registered user has not set the marketing options,
                // The response will be an object with all values set to false
                const options = {
                    ...action.payload,
                    SHOP_SHARES: {
                        EMAIL: {optIn: action.payload.shopShare},
                        SMS: {optIn: null},
                        POSTAL: {optIn: null},
                    },
                };

                return {data: options};
            }
            case COMMUNICATION_OPTIONS_EDIT_SETTINGS_SUCCESS:
                return {data: action.payload};
            default:
                return state;
        }
    },
    initialContactOptionsState,
);

const settingsReducer: Reducer<AccountSettingsState, AnyAction> = combineReducers({
    triggerChangeAvatarModal,
    error,
    contactInfo,
    contactOptions,
});

export default settingsReducer;
