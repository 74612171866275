import type {SagaIterator} from "redux-saga";
import {put, call, takeLatest, takeEvery, select} from "redux-saga/effects";
import log from "@atg-shared/log";
import {GameTypes} from "@atg-horse-shared/game-types";
import type {SelectBetMethodAction} from "../couponActions";
import {SELECT_BET_METHOD, updateTop7CouponAttributes} from "../couponActions";
import {getSystemByCost} from "../top7SystemUtils";
import {getHarryBoyFeeBrackets} from "./harryBoyFeeSelectors";
import {
    receiveHarryBoyFeeBrackets,
    FETCH_HARRY_BOY_FEE_BRACKETS,
} from "./harryBoyFeeActions";
import type {Brackets} from "./harryBoyFeeReducer";
import {fetchFeeBrackets} from "./harryBoyFeeApi";

export function* harryBoyFeeBracketsFetchFlow(): SagaIterator<Brackets> {
    let response;

    try {
        response = yield call(fetchFeeBrackets);
    } catch (err: unknown) {
        yield call(log.warn, "Error loading fee brackets", err);
        // Adds error to fee brackets
        yield put(receiveHarryBoyFeeBrackets({brackets: null}, true));
        return {brackets: null};
    }

    yield put(receiveHarryBoyFeeBrackets(response.data));
    return response.data;
}

function* addFeeToHarryBetLimit({
    payload: {coupon, couponAttributes, stake},
}: SelectBetMethodAction): SagaIterator<void> {
    if (
        couponAttributes.betMethod === "harry" &&
        coupon.game.type === GameTypes.top7 &&
        stake
    ) {
        const systemAsString = getSystemByCost(stake, coupon);
        const {brackets} = yield select(getHarryBoyFeeBrackets);
        yield put(
            updateTop7CouponAttributes(
                coupon,
                {
                    systemId: systemAsString,
                    stake,
                },
                true,
                brackets,
            ),
        );
    }
}
export default function* harryBoyFeeSaga(): SagaIterator<void> {
    yield takeLatest(FETCH_HARRY_BOY_FEE_BRACKETS, harryBoyFeeBracketsFetchFlow);
    yield takeEvery(SELECT_BET_METHOD, addFeeToHarryBetLimit);
}
