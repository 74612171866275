import type {BetType} from "@atg-horse-shared/bet-types";
import type {BetReceipt} from "@atg-horse-shared/bet-history-types";

export function assertIsDefined<T>(val: T): asserts val is NonNullable<T> {
    if (val === undefined || val === null) {
        throw new Error(`Expected val to be defined, but recieved ${val}`);
    }
}

export function assertExists<T>(value: T): value is NonNullable<T> {
    return value !== undefined && value !== null;
}

export function assertIsOfBetType<T extends BetReceipt>(
    val: BetReceipt,
    ...betTypes: BetType[]
): asserts val is T {
    if (!betTypes.includes(val.bet.type)) {
        throw new Error("Betreceipt with wrong type");
    }
}

type Assert = (condition: boolean) => asserts condition;
export const assertTrue: Assert = (condition: boolean): asserts condition => {
    if (!condition) {
        throw new Error();
    }
};
