import {clone, compact, map, filter, find, includes} from "lodash";
import dayjs from "dayjs";
import {serverTime} from "@atg-shared/server-time";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as Calendar from "@atg-horse-shared/calendar/domain/calendar";
import * as storage from "@atg-shared/storage";

const SELECTED_MAIN_QUALITY_ID_KEY = "defaultMainLiveStreamId";
const STANDARD_QUALITY = {
    id: "",
    ids: ["", "free", "nochannel"],
    name: "Standard",
};

const QUALITIES = [
    STANDARD_QUALITY,
    {
        id: "capped",
        ids: ["capped", "nochannel"],
        name: "Mobilvänlig",
    },
    {
        id: "audio",
        ids: ["audio", "nochannel"],
        name: "Bara Ljud",
        icon: "sound-loud",
    },
];

export function getQualityFromStreamId(streamId: string) {
    const qualityId = streamId.split("-")[1] || streamId;
    const wantedQuality =
        find(QUALITIES, (quality) => includes(quality.ids, qualityId)) ||
        STANDARD_QUALITY;

    return wantedQuality.id;
}

// Note: this is not the stream bitrate, it's mobilvänlig, bara ljud and so forth.
// Note: this function is deprecated; use redux-pesist instead. See `https://github.com/atgse/atgse/pull/4873` for details.
export function deprecated_saveQualityToStorage(quality: string) {
    storage.setItem(SELECTED_MAIN_QUALITY_ID_KEY, quality);
}

// Note: this function is deprecated; use redux-pesist instead. See `https://github.com/atgse/atgse/pull/4873` for details.
export function deprecated_loadQualityFromStorage() {
    return storage.getItem(SELECTED_MAIN_QUALITY_ID_KEY);
}

// Note: this function is deprecated; use redux-pesist instead. See `https://github.com/atgse/atgse/pull/4873` for details.
export function deprecated_getLoadedOrDefaultQuality(
    savedQuality: string,
    isDevice: boolean,
) {
    if (savedQuality === null) {
        if (isDevice) return "capped";
        return "";
    }
    return savedQuality;
}

export function getNextRaceForTrack(track: any, calendar: any) {
    if (!track) return null;

    let calenderTrack = track;
    let nextRaceId = calenderTrack.nextRace;
    if (!nextRaceId) {
        calenderTrack = Calendar.findTrackById(calendar, track.id);
        if (!calenderTrack) return null;

        nextRaceId = calenderTrack.nextRace;
    }
    if (nextRaceId)
        return (
            calenderTrack.races &&
            // @ts-expect-error
            calenderTrack.races.find((race) => race.id === nextRaceId)
        );

    const {races} = calenderTrack;
    // @ts-expect-error
    return races && races.find((race) => race && race.status === "upcoming");
}

export function getRaceInfoFromTrack(calendar: any) {
    return (channelTrack: any) => {
        const nextRace = getNextRaceForTrack(channelTrack, calendar);
        if (!nextRace) return null;

        return {
            name: channelTrack.name,
            number: nextRace.number,
            startTime: nextRace.startTime,
            trackId: channelTrack.id,
            countryCode: channelTrack.countryCode,
        };
    };
}

// A main track has DD & a startTime within at least an hour of now.
export function getMainTracks(calendarDay: any, tracks: any): any {
    const {dd, ld} = calendarDay.games;
    const dailyDouble = dd && dd[0];
    const lunchDouble = ld && ld[0];

    if (!dailyDouble && !lunchDouble) return [];

    return filter(tracks, (track) => {
        const trackHasDouble =
            (dailyDouble && dailyDouble.tracks.indexOf(track.id) > -1) ||
            (lunchDouble && lunchDouble.tracks.indexOf(track.id) > -1);
        if (!trackHasDouble) return false;

        const nextRace = getNextRaceForTrack(track, calendarDay);
        if (!nextRace) return false;
        if (nextRace.number > 1) return true;

        const hoursTillStart = dayjs(nextRace.startTime).diff(serverTime(false), "hours");
        return hoursTillStart < 1;
    });
}

export function getLiveTracks(day: any, selectedChannelTracks: any, numberOfTracks = 4) {
    const mainTracks = getMainTracks(day, selectedChannelTracks);
    const raceInfoFromTrack = getRaceInfoFromTrack(day);

    const tracksWithNextRace = clone(
        compact(map(selectedChannelTracks, raceInfoFromTrack)),
    );
    tracksWithNextRace.sort((a, b) => {
        // @ts-expect-error
        const aMainTrack = mainTracks.find((track) => track.id === a.trackId);
        // @ts-expect-error
        const bMainTrack = mainTracks.find((track) => track.id === b.trackId);

        if (aMainTrack && bMainTrack) return dayjs(a.startTime).diff(dayjs(b.startTime));
        if (aMainTrack) return Number.MIN_SAFE_INTEGER;
        if (bMainTrack) return Number.MAX_SAFE_INTEGER;

        return dayjs(a.startTime).diff(dayjs(b.startTime));
    });

    const wantedLiveTracks: any = tracksWithNextRace.slice(0, numberOfTracks);
    return wantedLiveTracks;
}
