import isEmpty from "lodash/isEmpty";
import keys from "lodash/keys";
import {getHarryFlavorInfo} from "@atg-horse-shared/utils/harry";
import * as Cost from "../components/common/costCalculations";
import {
    calculateCorrect,
    getSneakState,
    getTop7SneakState,
} from "../domain/legacyBetState";
import {getFlexLabel, getOnlyV6Label, getOnlyGS7Label} from "../domain/legacyBetLabel";
import columns from "../components/views/columns";
import {divisionGameRows, combinationGameRows, top7GameRows} from "./betGameRows";

const sneakLabelVxy = "Smyg fram vinnare";

function getSneakableRowsAmount(bet: any) {
    return bet.races.length;
}

export function getTop7SneakableRowsAmount(bet: any) {
    const race = bet.races[0];
    if (isEmpty(race.bets)) return keys(race.positionResults).length;

    return race.bets.length;
}

// allowed labels for Harry Boy
const harry = {
    default: getHarryFlavorInfo("ORIGINAL").label,
    ORIGINAL: getHarryFlavorInfo("ORIGINAL").label,
    CHANCE: getHarryFlavorInfo("CHANCE").label,
    FAVORITE: getHarryFlavorInfo("FAVORITE").label,
};

const betDefs: any = {
    // division game types
    V75: {
        getRows: divisionGameRows,
        costSpec: Cost.divisionGame,
        showNoOfCorrect: true,
        hideSneak: false,
        getSneakState,
        getSneakableRowsAmount,
        columns: [
            {
                selectByReceiptType: true,
                confirm: columns.confirmLegColumn,
                "bet-receipt": columns.receiptLegColumn,
            },
            columns.horseColumn,
            columns.horseCodeColumn,
            columns.sneakColumn,
            columns.winnerColumn,
            columns.videoColumn,
            columns.harryOpenColumn,
            columns.reservesColumn,
        ],
        sneakLabel: sneakLabelVxy,
        calculateNoOfCorrect: calculateCorrect,
        allowedBetMethodsLabels: {harry},
        // only V75 have boost
        boostColumns: [
            columns.boostLogoColumn,
            columns.boostCodeColumn,
            columns.boostSneakColumn,
            columns.boostResultColumn,
        ],
    },
    V86: {
        getRows: divisionGameRows,
        costSpec: Cost.divisionGame,
        showNoOfCorrect: true,
        hideSneak: false,
        getSneakState,
        getSneakableRowsAmount,
        columns: [
            {
                selectByReceiptType: true,
                confirm: columns.confirmLegColumn,
                "bet-receipt": columns.receiptLegColumn,
            },
            columns.horseColumn,
            columns.horseCodeColumn,
            columns.sneakColumn,
            columns.winnerColumn,
            columns.videoColumn,
            columns.harryOpenColumn,
            columns.reservesColumn,
        ],
        sneakLabel: sneakLabelVxy,
        calculateNoOfCorrect: calculateCorrect,
        allowedBetMethodsLabels: {harry},
    },
    GS75: {
        getRows: divisionGameRows,
        costSpec: Cost.divisionGame,
        showNoOfCorrect: true,
        hideSneak: false,
        getSneakState,
        getSneakableRowsAmount,
        columns: [
            {
                selectByReceiptType: true,
                confirm: columns.confirmLegColumn,
                "bet-receipt": columns.receiptLegColumn,
            },
            columns.horseColumn,
            columns.horseCodeColumn,
            columns.sneakColumn,
            columns.winnerColumn,
            columns.videoColumn,
            columns.harryOpenColumn,
            columns.reservesColumn,
        ],
        sneakLabel: sneakLabelVxy,
        calculateNoOfCorrect: calculateCorrect,
        allowedBetMethodsLabels: {
            harry,
            onlyVx: getOnlyGS7Label(),
        },
    },
    V65: {
        getRows: divisionGameRows,
        costSpec: Cost.divisionGame,
        showNoOfCorrect: true,
        hideSneak: false,
        getSneakState,
        getSneakableRowsAmount,
        columns: [
            {
                selectByReceiptType: true,
                confirm: columns.confirmLegColumn,
                "bet-receipt": columns.receiptLegColumn,
            },
            columns.horseColumn,
            columns.horseCodeColumn,
            columns.sneakColumn,
            columns.winnerColumn,
            columns.videoColumn,
            columns.harryOpenColumn,
            columns.reservesColumn,
        ],
        sneakLabel: sneakLabelVxy,
        calculateNoOfCorrect: calculateCorrect,
        allowedBetMethodsLabels: {
            harry,
            onlyVx: getOnlyV6Label(),
        },
    },
    V64: {
        getRows: divisionGameRows,
        costSpec: Cost.divisionGame,
        showNoOfCorrect: true,
        hideSneak: false,
        getSneakState,
        getSneakableRowsAmount,
        columns: [
            {
                selectByReceiptType: true,
                confirm: columns.confirmLegColumn,
                "bet-receipt": columns.receiptLegColumn,
            },
            columns.horseColumn,
            columns.horseCodeColumn,
            columns.sneakColumn,
            columns.winnerColumn,
            columns.videoColumn,
            columns.harryOpenColumn,
            columns.reservesColumn,
        ],
        sneakLabel: sneakLabelVxy,
        calculateNoOfCorrect: calculateCorrect,
        allowedBetMethodsLabels: {
            harry,
            onlyVx: getOnlyV6Label(),
        },
    },
    V5: {
        getRows: divisionGameRows,
        costSpec: Cost.divisionGame,
        showNoOfCorrect: true,
        hideSneak: false,
        getSneakState,
        getSneakableRowsAmount,
        columns: [
            {
                selectByReceiptType: true,
                confirm: columns.confirmLegColumn,
                "bet-receipt": columns.receiptLegColumn,
            },
            columns.horseColumn,
            columns.horseCodeColumn,
            columns.sneakColumn,
            columns.winnerColumn,
            columns.videoColumn,
            columns.harryOpenColumn,
            columns.reservesColumn,
        ],
        sneakLabel: sneakLabelVxy,
        calculateNoOfCorrect: calculateCorrect,
        allowedBetMethodsLabels: {harry},
    },
    V4: {
        getRows: divisionGameRows,
        costSpec: Cost.divisionGame,
        showNoOfCorrect: true,
        hideSneak: false,
        getSneakState,
        getSneakableRowsAmount,
        columns: [
            {
                selectByReceiptType: true,
                confirm: columns.confirmLegColumn,
                "bet-receipt": columns.receiptLegColumn,
            },
            columns.horseColumn,
            columns.horseCodeColumn,
            columns.sneakColumn,
            columns.winnerColumn,
            columns.videoColumn,
            columns.harryOpenColumn,
            columns.reservesColumn,
        ],
        sneakLabel: sneakLabelVxy,
        calculateNoOfCorrect: calculateCorrect,
        allowedBetMethodsLabels: {harry},
    },
    V3: {
        getRows: divisionGameRows,
        showNoOfCorrect: true,
        hideSneak: false,
        getSneakState,
        getSneakableRowsAmount,
        columns: [
            {
                selectByReceiptType: true,
                confirm: columns.confirmLegColumn,
                "bet-receipt": columns.receiptLegColumn,
            },
            columns.horseColumn,
            columns.horseCodeColumn,
            columns.sneakColumn,
            columns.winnerColumn,
            columns.videoColumn,
            columns.harryOpenColumn,
            columns.reservesColumn,
        ],
        sneakLabel: sneakLabelVxy,
        calculateNoOfCorrect: calculateCorrect,
        allowedBetMethodsLabels: {},
        costSpec: Cost.v3Game,
    },
    // multirace game types
    raket: {
        getRows: divisionGameRows,
        hideSneak: false,
        getSneakState,
        getSneakableRowsAmount,
        sneakLabel: sneakLabelVxy,
        calculateNoOfCorrect: calculateCorrect,
        costSpec: Cost.raketGame,
        showNoOfCorrect: false,
        columns: [
            {
                selectByReceiptType: true,
                confirm: columns.confirmRaceColumn,
                "bet-receipt": columns.raceColumn,
            },
            columns.betTypeColumn,
            columns.horseColumn,
            columns.horseCodeColumn,
            columns.sneakColumn,
            columns.videoColumn,
        ],
        allowedBetMethodsLabels: {},
    },
    dd: {
        getRows: divisionGameRows,
        showNoOfCorrect: true,
        hideSneak: false,
        getSneakState,
        getSneakableRowsAmount,
        sneakLabel: sneakLabelVxy,
        calculateNoOfCorrect: calculateCorrect,
        allowedBetMethodsLabels: {harry},
        costSpec: Cost.dubbelGame,
        columns: [
            {
                selectByReceiptType: true,
                confirm: columns.confirmLegColumn,
                "bet-receipt": columns.receiptLegColumn,
            },
            columns.horseColumn,
            columns.horseCodeColumn,
            columns.sneakColumn,
            columns.winnerColumn,
            columns.videoColumn,
            columns.harryOpenColumn,
        ],
    },
    ld: {
        getRows: divisionGameRows,
        showNoOfCorrect: true,
        hideSneak: false,
        getSneakState,
        getSneakableRowsAmount,
        sneakLabel: sneakLabelVxy,
        calculateNoOfCorrect: calculateCorrect,
        allowedBetMethodsLabels: {harry},
        costSpec: Cost.dubbelGame,
        columns: [
            {
                selectByReceiptType: true,
                confirm: columns.confirmLegColumn,
                "bet-receipt": columns.receiptLegColumn,
            },
            columns.horseColumn,
            columns.horseCodeColumn,
            columns.sneakColumn,
            columns.winnerColumn,
            columns.videoColumn,
            columns.harryOpenColumn,
        ],
    },
    // combination game types
    trio: {
        getSneakState,
        getSneakableRowsAmount,
        getRows: combinationGameRows,
        hideSneak: true,
        costSpec: Cost.komb,
        showNoOfCorrect: false,
        columns: [
            {
                selectByReceiptType: true,
                confirm: columns.confirmPlaceColumn,
                "bet-receipt": columns.receiptPlaceColumn,
            },
            columns.horseColumn,
            columns.horseCodeColumn,
            columns.sneakColumn,
            columns.harryOpenColumn,
        ],
        calculateNoOfCorrect: calculateCorrect,
        flexCostSpec: Cost.trioflex,
        allowedBetMethodsLabels: {
            harry,
            flex: getFlexLabel(),
        },
    },
    tvilling: {
        getSneakState,
        getSneakableRowsAmount,
        getRows: combinationGameRows,
        hideSneak: true,
        costSpec: Cost.komb,
        showNoOfCorrect: false,
        calculateNoOfCorrect: calculateCorrect,
        allowedBetMethodsLabels: {harry},
        columns: [
            columns.horseColumn,
            columns.horseCodeColumn,
            columns.sneakColumn,
            columns.harryOpenColumn,
        ],
    },
    komb: {
        getSneakState,
        getSneakableRowsAmount,
        getRows: combinationGameRows,
        hideSneak: true,
        costSpec: Cost.komb,
        showNoOfCorrect: false,
        columns: [
            {
                selectByReceiptType: true,
                confirm: columns.confirmPlaceColumn,
                "bet-receipt": columns.receiptPlaceColumn,
            },
            columns.horseColumn,
            columns.horseCodeColumn,
            columns.sneakColumn,
            columns.harryOpenColumn,
        ],
        calculateNoOfCorrect: calculateCorrect,
        allowedBetMethodsLabels: {harry},
    },
    // single game types
    vinnare: {
        getRows: divisionGameRows,
        getSneakState,
        getSneakableRowsAmount,
        costSpec: Cost.singleGame,
        showNoOfCorrect: false,
        hideSneak: true,
        columns: [
            columns.horseColumn,
            columns.horseCodeColumn,
            columns.sneakColumn,
            columns.harryOpenColumn,
        ],
        calculateNoOfCorrect: calculateCorrect,
        allowedBetMethodsLabels: {harry},
    },
    plats: {
        getRows: divisionGameRows,
        getSneakState,
        getSneakableRowsAmount,
        costSpec: Cost.singleGame,
        showNoOfCorrect: false,
        hideSneak: true,
        columns: [
            columns.horseColumn,
            columns.horseCodeColumn,
            columns.sneakColumn,
            columns.harryOpenColumn,
        ],
        calculateNoOfCorrect: calculateCorrect,
        allowedBetMethodsLabels: {harry},
    },
    vp: {
        getRows: divisionGameRows,
        getSneakState,
        getSneakableRowsAmount,
        showNoOfCorrect: false,
        hideSneak: true,
        columns: [
            columns.horseColumn,
            columns.horseCodeColumn,
            columns.sneakColumn,
            columns.harryOpenColumn,
        ],
        calculateNoOfCorrect: calculateCorrect,
        allowedBetMethodsLabels: {harry},
        costSpec: Cost.vpGame,
    },
    top7: {
        sneakLabel: sneakLabelVxy,
        getRows: top7GameRows,
        costSpec: Cost.komb,
        hideSneak: false,
        columns: [
            {
                selectByReceiptType: true,
                confirm: columns.confirmPlaceColumn,
                "bet-receipt": columns.receiptPlaceColumn,
            },
            columns.horseColumn,
            columns.horseCodeColumn,
            columns.sneakColumn,
            columns.resultColumn,
        ],
        showNoOfCorrect: true,
        getSneakState: getTop7SneakState,
        getSneakableRowsAmount: getTop7SneakableRowsAmount,
        allowedBetMethodsLabels: {harry},
    },
};

export default betDefs;
