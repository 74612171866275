import {some} from "lodash/fp";
import system from "@atg-shared/system";
import type {Coupon} from "@atg-horse-shared/coupon-types";
import {GameTypes} from "@atg-horse-shared/game-types";
import {hasReserves} from "./coupon";

export type CouponSettings = {
    cid: string;
    reserveMode: boolean;
    showBaseHorses: boolean;
    showSubmitErrors?: boolean;
};

function createCouponSettings(cid: string, coupon?: Coupon) {
    if (coupon && coupon.game?.type === GameTypes.top7 && hasReserves(coupon)) {
        return {
            cid,
            reserveMode: true,
            showBaseHorses: system.isApp,
        };
    }
    return {
        cid,
        reserveMode: false,
        showBaseHorses: system.isApp, // The App always has base horses showing, and they cannot be removed.
    };
}

function updateCouponSettings(
    couponSettings: CouponSettings,
    couponAttributes: Partial<CouponSettings> & {flashSubmitErrors?: boolean},
) {
    const newCouponSettings = {
        ...couponSettings,
        ...couponAttributes,
    };
    const attributes = Object.keys(couponAttributes) as Array<keyof CouponSettings>;

    const shouldChange = some(
        (attribute) =>
            !couponSettings || couponSettings[attribute] !== couponAttributes[attribute],
        attributes,
    );
    return shouldChange ? newCouponSettings : couponSettings;
}

function selectReserveMode(couponSettings: CouponSettings, reserveMode: boolean) {
    return updateCouponSettings(couponSettings, {reserveMode});
}

function showBaseHorses(couponSettings: CouponSettings, _showBaseHorses: boolean) {
    return updateCouponSettings(couponSettings, {showBaseHorses: _showBaseHorses});
}

function showSubmitErrors(
    couponSettings: CouponSettings,
    _showSubmitErrors?: boolean,
    _flashSubmitErrors?: boolean,
) {
    if (_showSubmitErrors !== undefined) {
        return updateCouponSettings(couponSettings, {
            showSubmitErrors: _showSubmitErrors,
            flashSubmitErrors: _flashSubmitErrors || false,
        });
    }
    return updateCouponSettings(couponSettings, {
        flashSubmitErrors: _flashSubmitErrors || false,
    });
}

const couponSettings = {
    createCouponSettings,
    selectReserveMode,
    showBaseHorses,
    showSubmitErrors,
};

export default couponSettings;
