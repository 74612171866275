import type {AtgResponse} from "@atg-shared/fetch-types";
import atgRequest from "@atg-shared/fetch";
import {GAME_SERVICE_URL} from "@atg-shared/service-url";
import type {Game} from "./types";

// game

/**
 * Build URL to /services/racinginfo/v1/api/games with poolId and minVersion params.
 * poolId (often called gameId), bettype_date_trackId_raceNo ex. V64_2023-08-15_7_4
 * When using this url racingInfo will return an object with @type GameAPITypes.GameResponse
 */

const createGameUrl = (poolId: string, simple?: boolean, minVersion?: number) => {
    const gameParam = poolId ? `${poolId}` : ``;
    if (simple) return `${GAME_SERVICE_URL}/${gameParam}/simple`;
    const minVersionSuffix = minVersion ? `?minVersion=${minVersion}` : "";
    return `${GAME_SERVICE_URL}/${gameParam}${minVersionSuffix}`;
};

/**
 * Fetch game for a gameId
 * API endpoint: `/services/racinginfo/v1/api/games`
 */

const fetchGame = (
    gameId: string,
    simple?: boolean,
    minVersion?: number,
): Promise<AtgResponse<Game>> =>
    atgRequest<Game>(createGameUrl(gameId, simple, minVersion));

export {createGameUrl, fetchGame};
