export const checkoutLowBalance = () => ({
    event_type: "checkout_low_balance",
});

export const purchaseLowBalanceCheaperOption = (
    desiredCost: number,
    actualCost: number,
) => ({
    event_type: "purchase_low_balance_cheaper_option",
    desired_cost: desiredCost,
    actual_cost: actualCost,
});

export const purchaseLowBalanceCancel = () => ({
    event_type: "low_balance_cancel_purchase",
});

export const depositCancel = () => ({
    event_type: "low_balance_cancel_deposit",
});
