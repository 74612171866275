import {omit, cloneDeep, isNumber} from "lodash";
import {combineReducers} from "redux";
import {applyPatch} from "fast-json-patch";

import type {GameType} from "@atg-horse-shared/game-types";
import {parseGameId} from "@atg-horse-shared/utils/gameid";
import type {Game} from "@atg-horse-shared/racing-info-api/game/types";
import * as GameActions from "./gameActions";

export type GameError =
    | {
          code: number;
          statusText: string;
          totalCount: number | null | undefined;
      }
    | {error: string};

export type GameReducerState = {
    game: Game;
    isLoading: boolean;
    error: GameError | null | undefined;
};

export type GameData = {
    [gameId: string]: GameReducerState;
};

export type Reservations = {
    [gameId: string]: number;
};

export type CurrentGameMetadata = {
    id: string | null | undefined;
    date: string | null | undefined;
    type: GameType | null | undefined;
};

/**
 * For more information on CurrentTrackId please check:
 * packages/atg-horse-game-page/GamePage.js
 */
type CurrentTrackId = {
    currentTrackId: number | null | undefined;
    clickedTrackId: number | null | undefined;
};
export type GamesState = {
    gameData: GameData;
    reservations: Reservations;
    currentTrackId: CurrentTrackId;
    currentGame: CurrentGameMetadata;
};

export type GlobalState = {
    games: GamesState;
    coupons: any;
    selectedRace: any;
    horse: any;
    reducedBets: any;
};

const INITAL_GAMES_STATE = {};

function gameData(state: GameData = INITAL_GAMES_STATE, action: GameActions.GameAction) {
    switch (action.type) {
        case GameActions.REQUEST_GAME: {
            return {
                ...state,
                [action.payload.gameId]: {
                    ...state[action.payload.gameId],
                    error: null,
                    isLoading: true,
                },
            };
        }
        case GameActions.RECEIVE_GAME: {
            if (action.error) {
                return {
                    ...state,
                    [action.payload.gameId]: {
                        game: null,
                        error: action.payload.error,
                        isLoading: false,
                    },
                };
            }

            return {
                ...state,
                [action.payload.gameId]: {
                    game: action.payload.game,
                    isLoading: false,
                    error: null,
                },
            };
        }
        case GameActions.REMOVE_GAMES: {
            return omit(state, action.payload.gameIds);
        }
        case GameActions.RECEIVE_GAME_PUSH: {
            const {data} = action.payload;

            const currentGameState = state[data.id].game;
            let newGame = data;

            if ((data as GameActions.JSONPatchData).patch) {
                // @ts-expect-error
                newGame = applyPatch(cloneDeep(currentGameState), data.patch).newDocument;
            }

            return {
                ...state,
                [data.id]: {
                    ...state[data.id],
                    game: newGame,
                },
            };
        }
        default:
            return state;
    }
}

function reservations(
    state: Reservations = INITAL_GAMES_STATE,
    action: GameActions.GameAction,
) {
    switch (action.type) {
        case GameActions.RESERVE_GAME: {
            const currentState = state[action.payload.gameId];
            if (isNumber(currentState)) {
                return {
                    ...state,
                    [action.payload.gameId]: currentState + 1,
                };
            }

            return {
                ...state,
                [action.payload.gameId]: 1,
            };
        }
        case GameActions.RELEASE_GAME: {
            const currentState = state[action.payload.gameId];
            if (isNumber(currentState)) {
                const newState = currentState - 1;

                if (newState === 0) return omit(state, action.payload.gameId);

                return {
                    ...state,
                    [action.payload.gameId]: newState,
                };
            }
            return state;
        }
        default:
            return state;
    }
}

function currentTrackId(
    state: CurrentTrackId = {currentTrackId: null, clickedTrackId: null},
    action: GameActions.GameAction,
) {
    switch (action.type) {
        case GameActions.SET_CURRENT_TRACK_ID:
            return {...state, currentTrackId: action.payload.trackId};
        case GameActions.SET_CLICKED_TRACK_ID:
            return {...state, clickedTrackId: action.payload.trackId};
        default:
            return state;
    }
}

const INITIAL_METADATA_STATE = {
    id: "",
    date: null,
    type: null,
};

function currentGame(
    state: CurrentGameMetadata = INITIAL_METADATA_STATE,
    action: GameActions.GameAction,
): CurrentGameMetadata {
    if (action.type === GameActions.SET_CURRENT_GAME_ID) {
        const {gameId} = action.payload;

        if (gameId === "missing") {
            return {
                id: gameId,
                date: null,
                type: null,
            };
        }

        const parsedGameId = parseGameId(gameId as string | undefined);
        if (!parsedGameId) return INITIAL_METADATA_STATE;

        return {
            id: gameId,
            date: parsedGameId.date,
            type: parsedGameId.gameType as GameType,
        };
    }

    return state;
}

const gameReducer = combineReducers({
    currentGame,
    currentTrackId,
    gameData,
    reservations,
});

export default gameReducer;
