import {useLocation} from "react-router-dom";
import Features, {newVerticalNavbar} from "@atg-shared/client-features";
import {Paths} from "@atg-tillsammans-shared/navigation";

const baseRoutes = {
    sport: [
        "/elclasico",
        "/sport",
        "/konto/mina-spel/sportbok",
        "/konto/mina-spel/sportpool",
        "/konto/kuponger/sportpool",
    ],
    casino: ["/casino"],
    tillsammans: [Paths.TILLSAMMANS_ROOT_PATH],
};

/**
 * This is temporary separated and can move into the `baseRoutes` once
 * the new navigation is fully implemented and the feature toggle get removed.
 */
export const newNavRoutes = {
    shops: ["/andelar", "/andelsspel", "/om-andelar", "/butiker"],
    harryboy: ["/harryboy"],
};

type Verticals = "horse" | "sport" | "casino" | "tillsammans" | "shops" | "harryboy";

/**
 *
 * @returns
 */
export function useVerticals() {
    const newNavEnabled = Features.isEnabled(newVerticalNavbar);
    const {pathname} = useLocation();
    const currentVertical = localStorage.getItem("lastVertical") as Verticals;

    // include the new navbar routes only when feature is enabled
    const routes = newNavEnabled ? {...baseRoutes, ...newNavRoutes} : baseRoutes;

    return Object.entries(routes).reduce((acc, curr) => {
        const [vertical, paths] = curr;

        const found = paths.find((path) => pathname.startsWith(path));

        if (found) {
            return vertical;
        }

        if (
            pathname.startsWith("/kundservice") ||
            pathname.startsWith("/atgcheck") ||
            pathname.startsWith("/play")
        ) {
            return currentVertical;
        }

        return acc;
    }, "horse" as string) as Verticals;
}
