import {storeApi} from "@atg-horse-shared/store-api";
import {createListenerMiddleware} from "@reduxjs/toolkit";
import configureStore from "@atg-shared/micro-frontend/configureStore";
import {frameActionMiddleware} from "atg-store-addons";
import createReducer from "./rootReducer";
import rootSaga from "./rootSaga";

const STORE_NAME = "atg-horse";

const listenerMiddleWare = createListenerMiddleware();

// Generally speaking, don't use this store directly – instead use Redux hooks to access the store state, etc.
const {store, filteredActionLog, persistor} = configureStore({
    name: STORE_NAME,
    createReducer,
    middlewares: [
        frameActionMiddleware,
        storeApi.middleware,
        listenerMiddleWare.middleware,
    ],
});
rootSaga(store);

// eslint-disable-next-line no-underscore-dangle
window._horseStore = store;

export {store, filteredActionLog, persistor};
