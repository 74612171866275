import {fetchAuthorized} from "@atg-shared/auth";
import type {BetAction} from "@atg-horse-shared/bet-types";
// Because of atg-limits -> atg-payment -> atg-main-menu
// eslint-disable-next-line @nx/enforce-module-boundaries
import {CouponUtils} from "@atg-horse-shared/coupon";
import {SHOP_SHARE_SERVICE_URL_TOKENIZED} from "@atg-shared/service-url";
import {
    SHOP_PURCHASE_START,
    SHOP_PURCHASE_FINISH,
    SHOP_PURCHASE_SET_STEP,
    SHOP_PURCHASE_SET_ERROR,
    SHOP_PURCHASE_SET_LOADING,
    type ShopShareCouponProduct,
    type ShopPurchaseSetLoadingAction,
    type ShopPurchaseSetErrorAction,
    type ShopPurchaseStartAction,
    type ShopPurchaseFinishAction,
    type ShopPurchaseSetStepAction,
    type PurchaseError,
} from "@atg-shop-shared/purchase-types/src/shopPurchaseActions";
import {
    type ShopPurchaseStep,
    type ShopPurchaseOptions,
} from "@atg-shop-shared/purchase-types/src/shopPurchaseTypes";
import {broadcastAction} from "atg-store-addons";

export const shopPurchaseStart = (
    flowOptions: ShopPurchaseOptions,
): ShopPurchaseStartAction => ({
    type: SHOP_PURCHASE_START,
    payload: {flowOptions},
});

export const shopPurchaseFinish = (): ShopPurchaseFinishAction =>
    broadcastAction({
        type: SHOP_PURCHASE_FINISH,
    });

export const setStep = (step: ShopPurchaseStep | null): ShopPurchaseSetStepAction => ({
    type: SHOP_PURCHASE_SET_STEP,
    payload: {step},
});

export const setError = ({
    message,
    errorCode,
    type = "danger",
}: PurchaseError): ShopPurchaseSetErrorAction => ({
    type: SHOP_PURCHASE_SET_ERROR,
    payload: {error: {message, errorCode, type}},
});

export const setLoading = (loading: boolean): ShopPurchaseSetLoadingAction => ({
    type: SHOP_PURCHASE_SET_LOADING,
    payload: {loading},
});

export const placeShareBet = (
    product: ShopShareCouponProduct,
    bet: BetAction,
    token: string,
) =>
    fetchAuthorized(
        `${SHOP_SHARE_SERVICE_URL_TOKENIZED}/protected/shares/${product.shopShare.id}/bet`,
        {
            method: "POST",
            headers: {token},
            body: JSON.stringify(CouponUtils.prepareForSave(bet)),
        },
        {memberFlowEnabled: false},
    );
