import {RgsLimitTypeEnum, type LimitRowVariants} from "./types";

export const DEBOUNCE_DELAY_IN_MS = 750;

export const netLossLimitContext = {limitType: RgsLimitTypeEnum.LOSS};

export const limitRowVariants: LimitRowVariants = {
    day: {
        key: "dailyLimit",
        caption: "Dagsgräns",
    },
    week: {
        key: "weeklyLimit",
        caption: "Veckogräns",
    },
    month: {
        key: "monthlyLimit",
        caption: "Månadsgräns",
    },
};
