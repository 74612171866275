import {head, uniqBy, get} from "lodash";
import type {Game} from "@atg-horse-shared/racing-info-api/game/types";
import type {
    BetResponse,
    BetTrack,
    PoolParticipation,
    Race,
} from "@atg-horse-shared/bet-types";
import type {
    Contract,
    SharedBetResponse,
    CouponTeamTop7Bet,
} from "@atg-tillsammans-shared/shared-bet-types";
import * as SharedBetUtils from "./sharedBetUtils";

/**
 * Target type to align CBS responses with old BetResponse.
 */
type LegacyBetResponse = Omit<BetResponse, "boostInfo"> & {
    shareInfo: {
        initiator?: string;
        cost: number;
        totalNrShares: number;
        ownedNrShares?: number;
        myCost: number;
    };
    betMethod: string;
    poolParticipation?: PoolParticipation;
    flexValue?: number;
    isVarenne: boolean;
    stakePerCombination?: number;
    boxedBets?: CouponTeamTop7Bet[][];
};

function isTrioFlex(contract: Contract) {
    const gameType: string = get(contract, "coupons[0].gameType", "");
    const flex: boolean = get(contract, "coupons[0].flex", false);

    return gameType === "TRIO" && flex;
}

function getBetMethod(contract?: Contract | null) {
    if (contract) {
        if (contract.type === "GENERATED_BET") return "harry";
        if (isTrioFlex(contract)) return "flex";
    }

    return "";
}

/**
 * Note:
 * This is a temporary function that maps the response from CBS to the data
 * format that the receipt component needs. Some of the information is missing in the
 * response from CBS so these (game, conditions) are provided
 * separately for now.
 *
 * The information needed from coupon-team service:
 * shareInfo, id, systems, serialNumber
 */
export const mapCouponTeamBetResult = (
    {sharedReceipt: {bets, shareInfo}}: SharedBetResponse,
    game: Game,
): LegacyBetResponse => {
    const {bet, amounts, contract} = bets[0];
    const coupon = bet.coupons[0];

    const {races, poolParticipation, boxedBets, stakePerCombination} =
        SharedBetUtils.mapSelections(game, coupon);

    const betMethod = getBetMethod(contract);

    return {
        // This swedish phrase is mapped to a sales channel and should be translated.
        // eslint-disable-next-line lingui/no-unlocalized-strings
        channel: "Andelsspel",
        checkBetCode: bet.gradingCode,
        checkable: false,
        combinations: coupon.numberOfCombinations ?? 0,
        cost: coupon.stake,
        costWithAddons: shareInfo?.cost?.total ?? 0,
        fee: amounts.sellingFee ?? 0,
        game: {
            id: game.id,
            type: game.type,
            status: game.status,
            startTime: head(game.races)?.scheduledStartTime ?? "",
            date: head(game.races)?.date,
            tracks: uniqBy<BetTrack>(
                game.races.reduce<BetTrack[]>((acc, race) => {
                    acc.push(race.track);
                    return acc;
                }, []),
                "id",
            ),
        },
        id: bet.tsn,
        /**
         * Not proud of thes typecast but will have to do for now.
         */
        races: races as Array<Race>,
        refundable: false,
        serialNumber: bet.tsn,
        shareInfo: {
            initiator: shareInfo.name,
            cost: shareInfo.cost.total ?? 0,
            totalNrShares: shareInfo.nrOfShares.total,
            ownedNrShares: shareInfo.nrOfShares.my,
            myCost: shareInfo.cost.my ?? 0,
        },
        shares: 1,
        stake: coupon.stakePerCombination,
        systems: Math.round(Number(coupon.unitsPerCombination)),
        timestamp: bet.placedAt,
        betMethod,
        boxedBets,
        stakePerCombination,
        flexValue: betMethod === "flex" ? coupon.stakePerCombination * 10 : undefined,
        poolParticipation,
        isVarenne: true,
    };
};
