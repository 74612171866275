import {combineReducers, type Reducer} from "redux";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    type CouponSortOption,
    BET_STOP_SORT_OPTION,
} from "@atg-shop-shared/shop-coupon-utils/src/couponSort";
import {
    STARTED_COUPONS_IMPORT,
    STARTED_COUPONS_IMPORT_SUCCESS,
    STARTED_COUPONS_IMPORT_FAILURE,
    STARTED_COUPONS_IMPORT_RESET,
    CHANGE_SORTING,
} from "./startedCouponsActions";
import {type Action} from "./index";

export type StartedCouponImportState = {
    isLoading: boolean;
    error: string | null;
    success: boolean | null;
};

export const couponSortingInitialState = {column: BET_STOP_SORT_OPTION, ascending: true};
const couponSorting = (
    state: CouponSortOption = couponSortingInitialState,
    action: Action,
): CouponSortOption => {
    switch (action.type) {
        case CHANGE_SORTING:
            return action.payload;
        default:
            return state;
    }
};

export const startedCouponsImportInitialState = {
    isLoading: false,
    error: null,
    success: null,
};
const startedCouponsImport = (
    state: StartedCouponImportState = startedCouponsImportInitialState,
    action: Action,
): StartedCouponImportState => {
    switch (action.type) {
        case STARTED_COUPONS_IMPORT:
            return {
                ...state,
                isLoading: true,
            };
        case STARTED_COUPONS_IMPORT_SUCCESS:
            return {
                isLoading: false,
                error: null,
                success: true,
            };
        case STARTED_COUPONS_IMPORT_FAILURE:
            return {
                isLoading: false,
                error: action.payload,
                success: false,
            };
        case STARTED_COUPONS_IMPORT_RESET:
            return startedCouponsImportInitialState;
        default:
            return state;
    }
};

export type State = {
    couponSorting: CouponSortOption;
    startedCouponsImport: StartedCouponImportState;
};

const startedCouponsReducer: Reducer<State, Action> = combineReducers({
    couponSorting,
    startedCouponsImport,
});

export default startedCouponsReducer;
