export type QubitEnv = "dev" | "prod";

export const getQubitExperienceApiUrl = (env: QubitEnv, isWeb?: boolean) => {
    if (isWeb) {
        if (env === "prod") {
            return "https://sse.qubit.com/v1/atg/experiences";
        }
        return "https://sse.qubit.com/v1/atg_dev/experiences";
    }

    if (env === "prod") {
        return "https://sse.qubit.com/v1/atg_app/experiences";
    }
    return "https://sse.qubit.com/v1/atg_app_dev/experiences";
};
