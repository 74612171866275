import {css} from "@emotion/react";

export const horseInfo = css`
    text-transform: uppercase;
    font-weight: bold;
    color: #595953;
    line-height: 22px;
    white-space: normal;
    margin-left: 25px;
`;

export const root = css`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
`;
