import {
    EXPANDED_PREVIOUS_RESULTS,
    REQUESTED_EXTENDED_RACE,
    RECEIVED_EXTENDED_RACE,
} from "./extendedStartActionTypes";
import type * as PreviousResultsAction from "./extendedStartActions.flow";
import type {ExtendedRace} from "./extendedStart.flow";

export const showPreviousResults = (
    raceId: string,
): PreviousResultsAction.ShowPreviousResultsAction => ({
    type: EXPANDED_PREVIOUS_RESULTS,
    payload: {raceId},
});

export const requestExtendedRace = (
    raceId: string,
): PreviousResultsAction.RequestExtendedRaceAction => ({
    type: REQUESTED_EXTENDED_RACE,
    payload: {raceId},
});

export const receivedExtendedRaceError = (
    raceId: string,
    error: string,
): PreviousResultsAction.ReceivedExtendedRaceErrorAction => ({
    type: RECEIVED_EXTENDED_RACE,
    error: true,
    payload: {raceId, error},
});

export const receivedExtendedRace = (
    data: ExtendedRace,
): PreviousResultsAction.ReceivedExtendedRaceAction => {
    const raceId = data.id;
    return {
        type: RECEIVED_EXTENDED_RACE,
        payload: {raceId, data},
    };
};
