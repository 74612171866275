import {fetchAuthorized} from "@atg-shared/auth";
import {MEMBER_AUTH_URL, RCS_SERVICE_URL} from "@atg-shared/service-url";
import type {MakeNullable} from "@atg/utils/typescript";
import type {
    ContactInfo,
    ContactOptionsResponse,
    SaveContactOptionsPayload,
} from "@atg-payment-shared/account-settings-types";

export const CONTACT_OPTIONS_URL = `${RCS_SERVICE_URL}/optins`;

export const CONTACT_INFO_URL = `${MEMBER_AUTH_URL}/contactinfo`;

export const fetchUserContactOptions = () =>
    fetchAuthorized<ContactOptionsResponse>(CONTACT_OPTIONS_URL);

export const saveUserContactOptions = (params: SaveContactOptionsPayload) =>
    fetchAuthorized<ContactOptionsResponse>(CONTACT_OPTIONS_URL, {
        method: "POST",
        body: JSON.stringify(params),
    });

export type SaveContactInfoPayload = MakeNullable<ContactInfo>;

export const saveContactInfo = (contactInfo: SaveContactInfoPayload) =>
    fetchAuthorized(CONTACT_INFO_URL, {
        method: "PUT",
        body: JSON.stringify(contactInfo),
    });
