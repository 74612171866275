import type {BetGradingResponse} from "@atg-horse-shared/bet-types";
import type {Game} from "@atg-horse-shared/racing-info-api/game/types";

export const REQUESTED_GRADING_GAME = "REQUESTED_GRADING_GAME";
export type RequestedGradingGameAction = {
    type: typeof REQUESTED_GRADING_GAME;
    gameId: string;
};
export const requestedGradingGame = (gameId: string): RequestedGradingGameAction => ({
    type: REQUESTED_GRADING_GAME,
    gameId,
});

export const GRADING_GAME_ERROR = "GRADING_GAME_ERROR";
export type GradingGameErrorAction = {
    type: typeof GRADING_GAME_ERROR;
    gameId: string;
};
export const gradingGameError = (gameId: string): GradingGameErrorAction => ({
    type: GRADING_GAME_ERROR,
    gameId,
});

export const RECEIVED_GRADING_GAME = "RECEIVED_GRADING_GAME";
export type ReceivedGradingGameAction = {
    type: typeof RECEIVED_GRADING_GAME;
    gameId: string;
    game: Game;
};
export const receivedGradingGame = (
    gameId: string,
    game: Game,
): ReceivedGradingGameAction => ({
    type: RECEIVED_GRADING_GAME,
    gameId,
    game,
});

export const RECEIVED_GRADING_BET = "RECEIVED_GRADING_BET";
export type ReceivedGradingBetAction = {
    type: typeof RECEIVED_GRADING_BET;
    betId: string;
    bet: BetGradingResponse;
    game: Game;
};
export const receivedGradingBet = (
    betId: string,
    bet: BetGradingResponse,
    game: Game,
): ReceivedGradingBetAction => ({
    type: RECEIVED_GRADING_BET,
    betId,
    bet,
    game,
});

export const SET_CURRENT_TAB = "SET_CURRENT_TAB";
export type SetCurrentTabAction = {
    betId: string;
    type: typeof SET_CURRENT_TAB;
    tab: string;
};
export const setCurrentTab = (
    betId: string,
    tab: string,
): {type: string; betId: string; tab: string} => ({
    type: SET_CURRENT_TAB,
    betId,
    tab,
});
