import * as React from "react";
import {Cross, Help} from "atg-ui-components/icons";
import * as styles from "./ReserveColumnLabel.styles";

type Props = {
    style?: string;
};

function ReserveColumnLabel({style}: Props) {
    const [showTooltip, setShowTooltip] = React.useState(false);

    const toggleTooltip = () => setShowTooltip(!showTooltip);

    return (
        <div css={styles.root} data-test-id="reserve-column-label">
            <div
                role="button"
                tabIndex={0}
                onClick={toggleTooltip}
                onKeyPress={toggleTooltip}
                data-test-id="tooltip-open"
                css={[styles.label, style]}
            >
                Reserver
                <Help style={styles.icon} />
            </div>

            {showTooltip && (
                <div css={styles.tooltip}>
                    Reserv träder in om en häst du spelat inte kommer till start. Om du ej
                    valt egna reserver tilldelas de enligt insatsfördelning vid
                    speltillfället.
                    <a
                        href="https://www.atg.se/nyheter/52632-atg-infor-ny-reservhantering"
                        css={styles.link}
                    >
                        Läs mer
                    </a>
                    <Cross
                        onClick={toggleTooltip}
                        onKeyPress={toggleTooltip}
                        testId="tooltip-close"
                        style={styles.close}
                    />
                </div>
            )}
        </div>
    );
}

export default ReserveColumnLabel;
