import type {FetchAction} from "@atg-shared/fetch-types";
import type {DetailedShop, SharesSortMethodType} from "@atg-shop-shared/types/shop";
import {type State} from "./index";

export const REQUEST_SHOP = "shop/REQUEST_SHOP";
export const RECEIVE_SHOP = "shop/RECEIVE_SHOP";
export const RESET_SHOP_STATUS = "shop/RESET_SHOP_STATUS";

export const REQUEST_MEMBER_DATA = "shop/REQUEST_MEMBER_DATA";
export const RECEIVE_MEMBER_DATA = "shop/RECEIVE_MEMBER_DATA";

export const REQUEST_SHARES_SET_SORT_METHOD = "shop/REQUEST_SHARES_SET_SORT_METHOD";
export const RESPONSE_SHARES_SET_SORT_METHOD = "shop/RESPONSE_SHARES_SET_SORT_METHOD";

export const UPLOAD_SHOP_IMAGE_REQUEST = "shop/shopUploadImage/uploadShopImageRequest";
export const UPLOAD_SHOP_IMAGE_RESPONSE = "shop/shopUploadImage/uploadShopImageResponse";
export const UPLOAD_SHOP_IMAGE_RESET = "shop/shopUploadImage/uploadShopImageReset";

export type ShopResponse = DetailedShop;

export type SetSortMethodContextData = {
    gameType: string;
    sortMethod: SharesSortMethodType;
};

export type ShopFetchAction = FetchAction<
    typeof REQUEST_SHOP,
    typeof RECEIVE_SHOP,
    ShopResponse
>;

export type FetchMemberDataAction = FetchAction<
    typeof REQUEST_MEMBER_DATA,
    typeof RECEIVE_MEMBER_DATA,
    {memberId: number},
    State
>;

export type SetSharesSortMethodAction = FetchAction<
    typeof REQUEST_SHARES_SET_SORT_METHOD,
    typeof RESPONSE_SHARES_SET_SORT_METHOD,
    null,
    State,
    SetSortMethodContextData
>;

export type UploadShopImageAction = FetchAction<
    typeof UPLOAD_SHOP_IMAGE_REQUEST,
    typeof UPLOAD_SHOP_IMAGE_RESPONSE,
    {shopImage: string},
    State
>;

export type UploadShopImageResetAction = {
    type: typeof UPLOAD_SHOP_IMAGE_RESET;
};

export type Action =
    | ShopFetchAction
    | FetchMemberDataAction
    | SetSharesSortMethodAction
    | UploadShopImageAction
    | UploadShopImageResetAction;
