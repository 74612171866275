import {combineReducers} from "redux";
import {createFetchReducer} from "@atg-shared/fetch-redux";
import type {FetchReducer, FetchState} from "@atg-shared/fetch-types";
// Types need to be extracted from atg-horse-bet
// eslint-disable-next-line @nx/enforce-module-boundaries
import historicalGamesReducer, {
    type HistoricalGamesFetchState,
} from "@atg-shop/historical-games/historicalGamesReducer";
import type {SortingMethodsType} from "@atg-shop-shared/types/shop";
// Types need to be extracted from atg-horse-bet
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    REQUEST_SHOP,
    RECEIVE_SHOP,
    RESET_SHOP_STATUS,
    REQUEST_MEMBER_DATA,
    RECEIVE_MEMBER_DATA,
    RESPONSE_SHARES_SET_SORT_METHOD,
    REQUEST_SHARES_SET_SORT_METHOD,
    UPLOAD_SHOP_IMAGE_RESPONSE,
    UPLOAD_SHOP_IMAGE_REQUEST,
    UPLOAD_SHOP_IMAGE_RESET,
    type ShopFetchAction,
    type UploadShopImageAction,
} from "@atg-shop-shared/shops-domain-types/shop.action.types";
// Types need to be extracted from atg-horse-bet
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {ShopState} from "@atg-shop-shared/shops-domain-types/shop.state.types";
// Types need to be extracted from atg-horse-bet
// eslint-disable-next-line @nx/enforce-module-boundaries
import {type Action} from "@atg-shop-shared/shops-domain-types";
// Types need to be extracted from atg-horse-bet
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    DELETE_SHOP_SHARE_RESPONSE,
    CREATE_SHARE_RESPONSE,
} from "@atg-shop-shared/shops-domain-types/shares.action.types";
import {LOGOUT_FINISHED} from "@atg-global-shared/user/userActionTypes";
// eslint-disable-next-line @nx/enforce-module-boundaries
import startedCouponsReducer from "@atg-shop-shared/shop-coupon-redux/src/startedCouponsReducer";
import shopShare, {type ShopShareState} from "./shopShareReducer";

type MemberDataState = {
    memberId: number | null;
};

type SetSortMethodFetchState = Record<string, unknown>;

const shop: FetchReducer<ShopState, ShopFetchAction> = createFetchReducer(
    REQUEST_SHOP,
    RECEIVE_SHOP,
    RESET_SHOP_STATUS,
    (state: ShopState, action) => {
        switch (action.type) {
            case RECEIVE_SHOP:
                if (action.error) return state;

                // Note: In case BIG9 is enabled we want to show all available games
                return {shop: action.payload};

            case DELETE_SHOP_SHARE_RESPONSE:
                if (action.error || !state.shop) return state;

                return {
                    shop: {
                        ...state.shop,
                        numShares: state.shop.numShares - 1,
                    },
                };
            case CREATE_SHARE_RESPONSE:
                if (action.error || !state.shop) return state;

                return {
                    shop: {
                        ...state.shop,
                        numShares: state.shop.numShares + 1,
                    },
                };
            case RESPONSE_SHARES_SET_SORT_METHOD: {
                if (action.error || !state.shop || !action.context) return state;

                const sortingMethods = {...state.shop.sortingMethods};
                sortingMethods[action.context.gameType as keyof SortingMethodsType] =
                    action.context.sortMethod;
                return {
                    shop: {
                        ...state.shop,
                        sortingMethods,
                    },
                };
            }
            case UPLOAD_SHOP_IMAGE_RESPONSE:
                if (action.error) return state;
                return {
                    shop: {...state.shop, shopImage: action.payload.shopImage},
                };

            case LOGOUT_FINISHED:
                return {shop: null};
            default:
                return state;
        }
    },
    {shop: null},
);

const memberId = createFetchReducer(
    REQUEST_MEMBER_DATA,
    RECEIVE_MEMBER_DATA,
    "",
    (state: MemberDataState, action: Action) => {
        switch (action.type) {
            case RECEIVE_MEMBER_DATA:
                if (action.error) return state;

                return {
                    memberId: action.payload.memberId,
                };
            // FIXME:
            // @ts-ignore
            case LOGOUT_FINISHED:
                return {memberId: null};
            default:
                return state;
        }
    },
    {memberId: null},
);

export const setSortMethod = createFetchReducer(
    REQUEST_SHARES_SET_SORT_METHOD,
    RESPONSE_SHARES_SET_SORT_METHOD,
    "",
    (state: SetSortMethodFetchState) => state,
    {},
);

type ShopUploadFetchState = Record<string, unknown>;

export const shopUploadImage: FetchReducer<ShopUploadFetchState, UploadShopImageAction> =
    createFetchReducer(
        UPLOAD_SHOP_IMAGE_REQUEST,
        UPLOAD_SHOP_IMAGE_RESPONSE,
        UPLOAD_SHOP_IMAGE_RESET,
        (state: ShopUploadFetchState) => state,
        {},
    );

export type State = {
    shop: FetchState<ShopState>;
    memberId: FetchState<MemberDataState>;
    shopShare: ShopShareState;
    setSortMethod: FetchState<SetSortMethodFetchState>;
    shopUploadImage: FetchState<ShopUploadFetchState>;
    historicalGames: FetchState<HistoricalGamesFetchState>;
};

const shopReducer = combineReducers({
    shop,
    memberId,
    shopShare,
    setSortMethod,
    startedCoupons: startedCouponsReducer,
    shopUploadImage,
    historicalGames: historicalGamesReducer,
});

export default shopReducer;
