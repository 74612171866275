import {omit} from "lodash/fp";
import * as LiveActions from "../liveactions";

export type LiveCorrectedBets = Record<
    string,
    {
        code: number | string;
        httpCode: number | string;
        message: string;
    }
>;

export function liveCorrectedBets(
    state: LiveCorrectedBets = {},
    action: {
        type: string;
        payload: {
            id: string;
            type: string;
            status: {code: number | string; error: {httpCode: number | string}};
            gameType: string;
            race: {id: string};
        };
        error: boolean;
    },
) {
    const {payload, error} = action;
    switch (action.type) {
        case LiveActions.LIVE_BET_CORRECTED:
            if (error) {
                return {
                    ...state,
                    [payload.id]: {
                        code: payload.status.code,
                        httpCode: payload.status.error.httpCode,
                        message: `Rättning av ${payload.gameType.toUpperCase()} kunde inte genomföras`,
                    },
                };
            }
            return {
                ...state,
                [payload.id]: {
                    [payload.race.id]: true,
                },
            };
        case LiveActions.FAILED_MESSAGE_CLOSE:
            return omit([payload.id], state);
        default:
            return state;
    }
}
