import {SHOP_SHARE_SERVICE_URL} from "@atg-shared/service-url";
import {FETCH, call} from "@atg-shared/fetch-redux";
import type {FetchAction} from "@atg-shared/fetch-types";
import atgFetch from "@atg-shared/fetch";
import type {ShareGame} from "@atg-shop-shared/types/share";

import type {HistoricalGamesFetchState} from "./historicalGamesReducer";

export const HISTORICAL_GAMES_FETCH_REQUEST =
    "shop/historicalGames/historicalGamesFetchRequest";
export const HISTORICAL_GAMES_FETCH_RESPONSE =
    "shop/historicalGames/historicalGamesFetchResponse";
export const HISTORICAL_GAMES_FETCH_RESET =
    "shop/historicalGames/historicalGamesFetchReset";

export type HistoricalGamesResponseData = Array<ShareGame>;

export type HistoricalGamesFetchAction = FetchAction<
    typeof HISTORICAL_GAMES_FETCH_REQUEST,
    typeof HISTORICAL_GAMES_FETCH_RESPONSE,
    HistoricalGamesResponseData,
    HistoricalGamesFetchState
>;

export type HistoricalGamesResetAction = {
    type: typeof HISTORICAL_GAMES_FETCH_RESET;
};

export type HistoricalGamesActions =
    | HistoricalGamesFetchAction
    | HistoricalGamesResetAction;

export const historicalGamesFetch = (): HistoricalGamesFetchAction => ({
    type: FETCH,
    payload: {
        requestAction: HISTORICAL_GAMES_FETCH_REQUEST,
        receiveAction: HISTORICAL_GAMES_FETCH_RESPONSE,
        callApi: call(atgFetch, `${SHOP_SHARE_SERVICE_URL}/games/historical`),
    },
});
