import * as React from "react";
import {map} from "lodash";
import * as styles from "./BoostCode.styles";
import type {BoostCodeSize, BoostCodeColor, CorrectCodeColor} from "./BoostCode.styles";

const digitMap = [
    <g>
        <path d="M169.4,66.8c-28.7,0-30,10.1-35.8,53.1c-1.9,14.3-3.4,26.8-3.4,33.2c0,15.6,5.9,20.1,24.7,20.1c28.4,0,29.4-9.8,35.5-52.8c2.1-14.6,3.7-27.1,3.7-33.7C194.1,71.3,188.3,66.8,169.4,66.8z" />
        <path d="M98.8,0L0,236.3h229.1L327.9,0C327.9,0,98.8,0,98.8,0z M149.6,219.2c-56.8,0-70.8-23.1-70.8-59.2c0-12,1.6-25.2,3.7-39.8C91.3,57.1,96.3,21,175.1,21c56.2,0,70.3,21.8,70.3,57.8c0,12.2-1.6,26.3-3.7,41.4C232.9,181.5,230,219.2,149.6,219.2z" />
        <path
            fill="none"
            d="M175.1,21c-78.8,0-83.8,36.1-92.6,99.2c-2.1,14.6-3.7,27.8-3.7,39.8c0,36.1,14,59.2,70.8,59.2c80.4,0,83.3-37.7,92.1-99c2.1-15.1,3.7-29.2,3.7-41.4C245.4,42.8,231.3,21,175.1,21z M190.4,120.4c-6.1,43-7.1,52.8-35.5,52.8c-18.8,0-24.7-4.5-24.7-20.1c0-6.4,1.5-18.9,3.4-33.2c5.8-43,7.1-53.1,35.8-53.1c18.9,0,24.7,4.5,24.7,19.9C194.1,93.3,192.5,105.8,190.4,120.4z"
        />
    </g>,
    <g>
        <path d="M98.8,0L0,236.3h229.1L327.9,0C327.9,0,98.8,0,98.8,0z M213.7,215.8H96.2l5.8-40h33.9l15.4-109h-36.9l1.1-7.7c4.8-34.7,28.1-35,49.1-35h43.7l-21.2,151.7h32.4L213.7,215.8z" />
        <path
            fill="none"
            d="M208.3,24.1h-43.7c-21,0-44.3,0.3-49.1,35l-1.1,7.7h36.9l-15.4,109H102l-5.8,40h117.5l5.8-40h-32.4L208.3,24.1z"
        />
    </g>,
    <g>
        <path d="M98.8,0L0,236.3h229.1L327.9,0C327.9,0,98.8,0,98.8,0z M187.3,215.8H73l2.1-15.9c4.8-38.4,5.3-46.7,71.1-84.1c20.7-11.7,36.9-20.9,36.9-35.5c0-11.7-9.5-13.5-22-13.5h-69c3.7-35,11.7-45.9,70.3-45.9c53.6,0,76.9,15.1,76.9,50.6c0,46.1-30,60.7-68.4,80.1c-18.8,9.5-31.3,16.4-36.9,21.5h95.7C224.9,213.1,220.2,215.8,187.3,215.8z" />
        <path
            fill="none"
            d="M170.9,151.6c38.4-19.4,68.4-34,68.4-80.1c0-35.5-23.3-50.6-76.9-50.6c-58.6,0-66.6,10.9-70.3,45.9h69c12.5,0,22,1.8,22,13.5c0,14.6-16.2,23.8-36.9,35.5c-65.8,37.4-66.3,45.7-71.1,84.1L73,215.8h114.3c32.9,0,37.6-2.7,42.4-42.7H134C139.6,168,152.1,161.1,170.9,151.6z"
        />
    </g>,
    <g>
        <path d="M98.8,0L0,236.3h229.1L327.9,0C327.9,0,98.8,0,98.8,0z M157.5,215.8H78.1v0.1v-0.1l0,0c5-35.7,10.3-42.6,46.7-42.6h28.9c15.1,0,26.5-1.3,26.5-18c0-9.3-5.3-14.3-20.7-14.3h-52l2.4-18c3.2-22,8-24.7,33.9-24.7h19.1c14.1,0,25.5-1.3,25.5-17.5c0-9.8-6.4-13.8-20.9-13.8H99.1c4.8-35.8,17.5-45.9,71.1-45.9c54.6,0,70.3,20.2,70.3,48c0,22.8-6.6,40.6-33.1,51.4c13.3,6.9,22.8,19.6,22.8,40C230.2,203.6,202.9,215.8,157.5,215.8z" />
        <path fill="none" d="M78.1,215.9v-0.1l0,0V215.9z" />
        <path
            fill="none"
            d="M207.4,120.4c26.5-10.8,33.1-28.6,33.1-51.4c0-27.8-15.7-48-70.3-48c-53.6,0-66.3,10.1-71.1,45.9h68.4c14.5,0,20.9,4,20.9,13.8c0,16.2-11.4,17.5-25.5,17.5h-19.1c-25.9,0-30.7,2.7-33.9,24.7l-2.4,18h52c15.4,0,20.7,5,20.7,14.3c0,16.7-11.4,18-26.5,18h-28.9c-36.4,0-41.7,6.9-46.7,42.6h79.4c45.4,0,72.7-12.2,72.7-55.4C230.2,140,220.7,127.3,207.4,120.4z"
        />
    </g>,
    <g>
        <path d="M98.8,0L0,236.3h229.1L327.9,0C327.9,0,98.8,0,98.8,0z M211.7,179.2h-6.6l-5,36.6h-50.9l5-36.6H65.3l3.7-26.5c2.1-15.6,4.8-22,10.3-30.5l53.6-82.7c9.8-15.4,17-15.4,32.6-15.4H227l-15.9,113H235l-2.9,20.9C229.4,177.9,226,179.2,211.7,179.2z" />
        <polygon points="160.2,137.1 171.6,56 122,137.1     " />
        <path
            fill="none"
            d="M211.1,137.1l15.9-113h-61.5c-15.6,0-22.8,0-32.6,15.4l-53.6,82.7c-5.5,8.5-8.2,14.9-10.3,30.5l-3.7,26.5h88.9l-5,36.6h50.9l5-36.6h6.6c14.3,0,17.7-1.3,20.4-21.2l2.9-20.9H211.1L211.1,137.1z M160.2,137.1H122L171.6,56L160.2,137.1z"
        />
    </g>,
    <g>
        <path d="M98.8,0L0,236.3h229.1L327.9,0C327.9,0,98.8,0,98.8,0z M202.3,66.8h-50.6l-4.2,30h15.1c49.8,0,71.3,19.1,71.3,53.6c0,47.7-24.4,65.5-78.7,65.5h-73c5.3-36.9,11.7-42.7,44.5-42.7h31c14.6,0,25.5-2.1,25.5-18.6c0-12.5-10.6-15.1-26.5-15.1h-47.5c-9,0-11.9-0.8-11.9-6.4c0-1.1,0-2.4,0.3-4l14.8-105h135L245,41.9C241.6,66.3,231.2,66.8,202.3,66.8z" />
        <path
            fill="none"
            d="M112.4,24.1l-14.8,105c-0.3,1.6-0.3,2.9-0.3,4c0,5.6,2.9,6.4,11.9,6.4h47.5c15.9,0,26.5,2.6,26.5,15.1c0,16.5-10.9,18.6-25.5,18.6h-31c-32.8,0-39.2,5.8-44.5,42.7h73c54.3,0,78.7-17.8,78.7-65.5c0-34.5-21.5-53.6-71.3-53.6h-15.1l4.2-30h50.6c28.9,0,39.3-0.5,42.7-24.9l2.4-17.8L112.4,24.1L112.4,24.1z"
        />
    </g>,
    <g>
        <path d="M130.8,155.1c0,12.5,5,18,25.5,18c16.7,0,28.4-0.5,28.4-20.4c0-12.2-8.7-14.3-27-14.3h-25.3C131.6,145,130.8,150.3,130.8,155.1z" />
        <path d="M98.8,0L0,236.3h229.1L327.9,0C327.9,0,98.8,0,98.8,0z M169,97c48.3,0,67.1,16.2,67.1,49.6c0,5.6-0.3,11.4-1.3,17.8c-5,32.6-20.7,54.6-77.4,54.6C98.5,219,81,198.6,81,156.4c0-10.6,1.1-22.8,2.9-36.3C93.2,53,100.6,20.9,177,20.9c48,0,64.2,6.1,64.2,27.8c0,5-0.8,11.1-2.4,18h-69.5v0.1c-23.1,0-27.6,6.1-31.3,32.9C144.9,98.4,154.4,97,169,97z" />
        <path
            fill="none"
            d="M169.3,66.8L169.3,66.8l69.5-0.1c1.6-6.9,2.4-13,2.4-18c0-21.7-16.2-27.8-64.2-27.8c-76.4,0-83.8,32.1-93.1,99.2c-1.8,13.5-2.9,25.7-2.9,36.3c0,42.2,17.5,62.6,76.4,62.6c56.7,0,72.4-22,77.4-54.6c1-6.4,1.3-12.2,1.3-17.8c0-33.4-18.8-49.6-67.1-49.6c-14.6,0-24.1,1.4-31,2.7C141.7,72.9,146.2,66.8,169.3,66.8z M157.7,138.4c18.3,0,27,2.1,27,14.3c0,19.9-11.7,20.4-28.4,20.4c-20.5,0-25.5-5.5-25.5-18c0-4.8,0.8-10.1,1.6-16.7H157.7z"
        />
    </g>,
    <g>
        <path d="M98.8,0L0,236.3h229.1L327.9,0C327.9,0,98.8,0,98.8,0z M236.3,96.3l-68.9,119.6h-58.1l-0.3-2.7l90.2-146.4h-92.8l1.1-6.6c5.6-35,14.3-36.1,43.5-36.1h108.2l-3.8,25.5C253.3,63.9,250.9,70.5,236.3,96.3z" />
        <path
            fill="none"
            d="M151,24.1c-29.2,0-37.9,1.1-43.5,36.1l-1.1,6.6h92.8L109,213.2l0.3,2.7h58.1l68.9-119.6c14.6-25.8,17-32.4,19.1-46.7l3.8-25.5L151,24.1L151,24.1z"
        />
    </g>,
    <g>
        <path d="M156.8,137.3c-23.1,0-27.8,3.2-30.2,18.6c-0.3,2.1-0.5,4-0.5,5.8c-0.2,10.4,6.5,13.8,26.4,13.8c24.7,0,29.7-4.5,31.6-19.6c0.3-1.6,0.3-3.2,0.3-4.8C184.4,140.2,177.8,137.3,156.8,137.3z" />
        <path d="M167.6,64.5c-20.9,0-26.3,2.9-28.4,18.3c-0.3,1.9-0.3,3.4-0.3,4.8c0,11.1,7.5,12.4,24.2,12.4c20.9,0,27-2.1,29.2-17.2c0.3-2.1,0.5-3.7,0.5-5.6C192.8,66.9,186.4,64.5,167.6,64.5z" />
        <path d="M98.8,0L0,236.3h229.1L327.9,0C327.9,0,98.8,0,98.8,0z M235.2,155.5c0,3.2-0.3,6.9-0.8,10.9c-5.8,37.9-28.1,52.8-84.8,52.8c-55.2,0-75-16.4-75-48c0-3.4,0.3-7.2,0.8-10.9c2.9-23.6,15.6-37.9,36.1-43l-0.4-0.6c-13-3.7-21.5-15.1-21.5-33.4c0-3.2,0.3-6.4,0.8-9.8c5-35,19.4-52.5,80.1-52.5c53.8,0,74,15.6,74,45.9c0,4.2-0.3,8.8-1.1,13.5c-3.4,22-14.8,32.6-33.9,38.2v0.8C225.9,126.3,235.2,136.1,235.2,155.5z" />
        <path
            fill="none"
            d="M243.4,80.4c0.8-4.7,1.1-9.3,1.1-13.5c0-30.3-20.2-45.9-74-45.9c-60.7,0-75.1,17.5-80.1,52.5c-0.5,3.4-0.8,6.6-0.8,9.8c0,18.3,8.5,29.7,21.5,33.4l0.4,0.6c-20.5,5.1-33.2,19.4-36.1,43c-0.5,3.7-0.8,7.5-0.8,10.9c0,31.6,19.8,48,75,48c56.7,0,79-14.9,84.8-52.8c0.5-4,0.8-7.7,0.8-10.9c0-19.4-9.3-29.2-25.7-36.1v-0.8C228.6,113,240,102.4,243.4,80.4z M184.1,155.9c-1.9,15.1-6.9,19.6-31.6,19.6c-19.9,0-26.6-3.4-26.4-13.8c0-1.8,0.2-3.7,0.5-5.8c2.4-15.4,7.1-18.6,30.2-18.6c21,0,27.6,2.9,27.6,13.8C184.4,152.7,184.4,154.3,184.1,155.9z M192.3,82.8c-2.2,15.1-8.3,17.2-29.2,17.2c-16.7,0-24.2-1.3-24.2-12.4c0-1.4,0-2.9,0.3-4.8c2.1-15.4,7.5-18.3,28.4-18.3c18.8,0,25.2,2.4,25.2,12.7C192.8,79.1,192.6,80.7,192.3,82.8z"
        />
    </g>,
    <g>
        <path d="M165.7,65.8c-18.6,0-24.7,0.8-27,17.5c-0.3,2.1-0.5,4-0.5,6.1c0,11.1,8.5,13.3,24.7,13.3h26.9v-0.1l0.5-3.4c1.1-7.2,1.9-12.7,1.9-17.2C192.2,69.5,186.4,65.8,165.7,65.8z" />
        <path d="M98.8,0L0,236.3h229.1L327.9,0C327.9,0,98.8,0,98.8,0z M158.4,214.7H77.6c5.3-35.3,10.1-42.7,45.3-42.7h29.4c26.8,0,28.6-3.4,31.8-28.1H151c-45.1,0-61.8-15.9-61.8-47.2c0-5.8,0.5-11.9,1.6-18.6c5-35,19.9-58.3,76.6-58.3c57.8,0,74.8,20.2,74.8,61c0,9.3-1.1,24.4-3.2,38.4C229.2,187.3,227.1,214.7,158.4,214.7z" />
        <path
            fill="none"
            d="M167.4,19.8c-56.7,0-71.6,23.3-76.6,58.3c-1.1,6.7-1.6,12.8-1.6,18.6c0,31.3,16.7,47.2,61.8,47.2h33.1c-3.2,24.7-5,28.1-31.8,28.1h-29.4c-35.2,0-40,7.4-45.3,42.7h80.8c68.7,0,70.8-27.4,80.6-95.5c2.1-14,3.2-29.1,3.2-38.4C242.2,40,225.2,19.8,167.4,19.8z M190.3,99.2l-0.5,3.4v0.1h-26.9c-16.2,0-24.7-2.2-24.7-13.3c0-2.1,0.2-4,0.5-6.1c2.3-16.7,8.4-17.5,27-17.5c20.7,0,26.5,3.7,26.5,16.2C192.2,86.5,191.4,92,190.3,99.2z"
        />
    </g>,
];

type BoostDigitProps = {
    digit: number;
    index: number;
    isCorrect: boolean;
    size?: BoostCodeSize;
    correctColor?: CorrectCodeColor;
};

function BoostDigit({digit, index, isCorrect, size, correctColor}: BoostDigitProps) {
    const combinedStyles = [
        styles.boostCodeDigit(size),
        index === 0 && styles.boostCodeDigitFirst,
        index === 1 && styles.boostCodeDigitSecond(size),
        index === 2 && styles.boostCodeDigitThird(size),
        isCorrect && styles.boostCodeDigitCorrect(correctColor),
    ];

    return (
        <div css={combinedStyles} key={index}>
            <svg
                viewBox="0 0 327.9 236.3"
                preserveAspectRatio="xMinYMin meet"
                css={styles.svg}
            >
                {digitMap[digit]}
            </svg>
        </div>
    );
}

const isCorrectDigit = (
    index: number,
    code: string,
    correctCode?: string | null,
): boolean => {
    if (!correctCode) return false;
    return code[index] === correctCode[index];
};

type BoostCodeProps = {
    code?: string | null;
    correctCode?: string | null;
    size?: BoostCodeSize;
    color?: BoostCodeColor;
    dataTestId?: string;
    correctColor?: CorrectCodeColor;
};

export function BoostCode({
    code,
    correctCode,
    size,
    color,
    dataTestId,
    correctColor,
}: BoostCodeProps) {
    if (!code) return <div>Inväntar</div>;

    // This is a current rule for boost - you win 50 if last digit is correct, 150 if two last are correct and 500 if all three are correct. Your V75 winnings will be boosted if all 3 digits are correct.
    const correctDigit = [
        isCorrectDigit(2, code, correctCode) &&
            isCorrectDigit(1, code, correctCode) &&
            isCorrectDigit(0, code, correctCode),
        isCorrectDigit(2, code, correctCode) && isCorrectDigit(1, code, correctCode),
        isCorrectDigit(2, code, correctCode),
    ];

    const codeArray = code.split("");
    const digits = map(codeArray, (digit, index: number) => (
        <BoostDigit
            key={`boost${index}`}
            digit={parseInt(digit, 10)}
            index={index}
            isCorrect={correctDigit[index]}
            size={size}
            correctColor={correctColor}
        />
    ));

    return (
        <div
            css={styles.boostCode(size, color)}
            data-test-id={dataTestId || "boost-code"}
            data-code={code}
            data-result={code === correctCode ? "correct" : "incorrect"}
        >
            {digits}
        </div>
    );
}

export function BoostCodePlaceholder({
    size,
    color,
}: {
    size?: BoostCodeSize;
    color?: BoostCodeColor;
}) {
    function PlaceholderRect() {
        return (
            <styles.PlaceholderRect css={styles.boostCodeDigit(size)}>
                <svg viewBox="0 0 36 25" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.2175 0H35.2175L24.7827 25H0.782715L11.2175 0Z"
                        fill="#2A3E50"
                    />
                </svg>
            </styles.PlaceholderRect>
        );
    }

    return (
        <div css={styles.boostCode(size, color)} data-test-id="boost-code-placeholder">
            <PlaceholderRect />
            <PlaceholderRect />
            <PlaceholderRect />
        </div>
    );
}
