import {map} from "lodash/fp";
import {combineReducers} from "redux";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import type {MediaObject} from "@atg-play-shared/media-graphql-client/__generated__/types.generated";
import type {Game} from "@atg-horse-shared/racing-info-api/game/types";
import type {GameType} from "@atg-horse-shared/game-types";
import {LOGOUT_FINISHED, LOGIN_FINISHED} from "@atg-global-shared/user/userActionTypes";
import {NO_CHANNEL, NO_CHANNEL_ID} from "../player/videoSelectors";
import * as PlayerReducer from "../player/playerReducer";
import {PlayerActionTypes} from "../player/actionconstants";
import type * as VideoTypes from "./video";
import {
    EXPAND_VIDEO_FRAME,
    TOGGLE_VIDEO_FRAME_EXPANDED,
    TOGGLE_VIDEO_ONLY,
    SET_VIDEO_ONLY,
    SET_POPOUT_FRAME,
    LOADED_CHANNEL_CONFIG,
    MEDIA_WARMUP,
    MEDIA_ARCHIVE,
    OPEN_VIDEO_POPUP,
    CLOSE_VIDEO_POPUP,
    RESET_BANNER_RIGHT_VIDEO,
    RECEIVE_CHANNELS_PUSH,
    SELECT_CHANNEL,
    V2_SET_LIVE_VIDEO_QUALITY,
    ACTIVATE_LOW_LATENCY_VIDEO,
    DEACTIVATE_LOW_LATENCY_VIDEO,
} from "./videoActionConstants";

export type Stream = {
    id: string;
    name: string;
    subscriptions?: Array<any>;
    url?: string;
    audioOnly: boolean;
};

export type TrackDetails = {
    name: string;
    number: number;
    startTime: string;
    trackId: number;
    countryCode: string;
};

export type ChannelTrack = VideoTypes.ChannelTrack;

export type ChannelNextRace = {
    id: string;
    track: ChannelTrack;
    number: number;
    startTIme: string;
    winnerGameStatus: string;
    games: Array<Game>;
};
export type Channel = VideoTypes.Channel;

export type LiveVideoState = {
    isPopoutFrame: boolean;
    channels: Array<Channel>;
    selectedQuality: string;
    selectedChannel: {
        id: string;
        name: string;
    };
    videoPopup: boolean;
};

export type LowLatencyState = {
    lowLatencyVideoActive: boolean;
};

export type VideoFrameState = {
    videoOnly: boolean;
    videoFrameExpanded: boolean;
};

// Make BannerRightVideoState exact https://jira-atg.riada.cloud/browse/LIVE-34
export type BannerRightVideoState = {
    type?: string;
    mediaId?: any;
    mediaIds?: any;
    graphicsMedia?: MediaObject;
    gameId?: string;
    gameType?: GameType;
    track?: any;
    raceNumber?: number;
    raceName?: string;
    date?: string;
    startId?: string;
    horseName?: string;
    startNumber?: string;
    countryCode?: string;
};

export type VideoState = {
    channels: Array<Channel>;
    liveVideo: LiveVideoState;
    lowLatency: LowLatencyState;
    videoFrame: VideoFrameState;
    bannerRightVideo: BannerRightVideoState;
    players: {
        live: PlayerReducer.VideoPlayerState;
        bannerRight: PlayerReducer.VideoPlayerState;
    };
};

const addAudioOnlyToChannels = (channelsData: any) =>
    map<any, any>((channel) => {
        if (channel.id !== "main") return channel;

        const streams = map(
            (stream) => ({
                ...stream,
                // Can be used for testing functionality of HD stream
                // url: stream.url
                //     ? stream.url
                //     : "https://httpcache0-00688-cacheliveedge0.dna.ip-only.net/00688-cacheliveedge0/out/u/atg_sdi_1_premium_timecode_hd.m3u8",
                audioOnly: stream.id === "main-audio",
            }),
            channel.streams,
        );
        return {
            ...channel,
            streams,
        };
    }, channelsData);

export const initialStateBannerRightVideo = () => ({});

export function bannerRightVideo(
    state: BannerRightVideoState = initialStateBannerRightVideo(),
    action: any,
) {
    const {type, payload} = action;

    switch (type) {
        case MEDIA_WARMUP: {
            const {
                mediaIds,
                gameId,
                gameType,
                track,
                countryCode,
                raceNumber,
                raceName,
                date,
                startId,
                horseName,
                startNumber,
            } = payload;
            return {
                ...state,
                type: "warmup",
                mediaId: undefined,
                mediaIds,
                gameId,
                gameType,
                track,
                countryCode,
                raceNumber,
                raceName,
                date,
                startId,
                horseName,
                startNumber,
            };
        }
        case MEDIA_ARCHIVE: {
            const {
                mediaId,
                graphicsMedia,
                gameId,
                gameType,
                track,
                raceNumber,
                raceName,
                date,
                countryCode,
            } = payload;
            return {
                ...state,
                type: "archive",
                mediaId,
                mediaIds: undefined,
                graphicsMedia, // stored in state only for archiveVideoPopoutOptions to use
                gameId,
                gameType,
                track,
                raceNumber,
                raceName,
                date,
                startId: undefined,
                horseName: undefined,
                startNumber: undefined,
                countryCode,
            };
        }
        case OPEN_VIDEO_POPUP:
            return {
                ...state,
                [payload]: true,
            };
        case CLOSE_VIDEO_POPUP:
            return {
                ...state,
                [payload]: false,
            };
        case LOGOUT_FINISHED:
        case RESET_BANNER_RIGHT_VIDEO:
            return initialStateBannerRightVideo();
        default:
            return state;
    }
}

export const initialStateVideoFrame = () => ({
    videoOnly: false,
    videoFrameExpanded: false,
});

export function videoFrame(state: any = initialStateVideoFrame(), action: any) {
    const {type, payload} = action;

    switch (type) {
        case EXPAND_VIDEO_FRAME:
            return {
                ...state,
                videoOnly: false,
                videoFrameExpanded: payload.expanded,
            };
        case TOGGLE_VIDEO_FRAME_EXPANDED:
            return {
                ...state,
                videoFrameExpanded: !state.videoFrameExpanded,
            };
        case TOGGLE_VIDEO_ONLY:
            if (!state.videoFrameExpanded) {
                return {
                    ...state,
                    videoOnly: false,
                    videoFrameExpanded: true,
                };
            }

            return {
                ...state,
                videoOnly: !state.videoOnly,
            };
        case SET_VIDEO_ONLY:
            return {
                ...state,
                videoOnly: payload.isVideoOnly,
            };
        default:
            return state;
    }
}

export const initialStateLiveVideo = () => ({
    isPopoutFrame: false,
    channels: [NO_CHANNEL],
    selectedQuality: "",
    selectedChannel: {
        id: NO_CHANNEL_ID,
        name: "Ingen livevideo",
    },
    videoPopup: false,
});

const liveVideoPersistConfig = {
    key: "horseLiveVideo",
    storage,
    whitelist: ["selectedQuality"],
};

const livePlayerPersistConfig = {
    key: "horseLiveVideoPlayer",
    storage,
    whitelist: ["volume"],
};

const mediaPlayerPersistConfig = {
    key: "horseMediaVideoPlayer",
    storage,
    whitelist: ["volume"],
};

export function liveVideo(state: any = initialStateLiveVideo(), action: any) {
    const {type, payload} = action;
    switch (type) {
        case SET_POPOUT_FRAME:
            return {
                ...state,
                isPopoutFrame: payload.isPopoutFrame,
            };
        case LOGOUT_FINISHED: {
            return initialStateLiveVideo();
        }
        case PlayerActionTypes.PLAYER_PLAY:
            return {
                ...state,
                initialStream: false,
            };
        case LOGIN_FINISHED:
            return {
                ...state,
                initialStream: true,
            };
        case LOADED_CHANNEL_CONFIG: {
            const {channels: channelsData, selectedChannel, selectedQuality} = payload;
            return {
                ...state,
                channels: addAudioOnlyToChannels(channelsData),
                selectedChannel,
                selectedQuality:
                    selectedQuality !== undefined
                        ? selectedQuality
                        : state.selectedQuality,
            };
        }
        case SELECT_CHANNEL: {
            const {channel, quality} = payload;

            return {
                ...state,
                selectedChannel: {
                    name: channel.name,
                    id: channel.id,
                },
                selectedQuality: quality,
            };
        }
        case RECEIVE_CHANNELS_PUSH: {
            const {channels: pushedChannels} = payload;

            return {
                ...state,
                channels: addAudioOnlyToChannels(pushedChannels),
            };
        }
        case V2_SET_LIVE_VIDEO_QUALITY: {
            return {
                ...state,
                selectedQuality: payload,
            };
        }
        default:
            return state;
    }
}

// HACK for the LiveApp.
// @ts-expect-error
function channels(state = [], action) {
    const {type, payload} = action;

    switch (type) {
        case RECEIVE_CHANNELS_PUSH:
        case LOADED_CHANNEL_CONFIG: {
            const {channels: channelsData} = payload;
            return addAudioOnlyToChannels(channelsData);
        }
        default:
            return state;
    }
}
// @ts-expect-error
const lowLatency = (state = {lowLatencyVideoActive: false}, action): LowLatencyState => {
    switch (action.type) {
        case ACTIVATE_LOW_LATENCY_VIDEO:
            return {...state, lowLatencyVideoActive: true};
        case DEACTIVATE_LOW_LATENCY_VIDEO:
        case LOGOUT_FINISHED:
            return {...state, lowLatencyVideoActive: false};
        default:
            return state;
    }
};

const lowLatencyPersistConfig = {
    key: "lowLatency",
    storage,
    whitelist: ["lowLatencyVideoActive"],
};
// @ts-expect-error
export default combineReducers<_, VideoState>({
    channels,
    liveVideo: persistReducer(liveVideoPersistConfig, liveVideo),
    lowLatency: persistReducer(lowLatencyPersistConfig, lowLatency),
    videoFrame,
    bannerRightVideo,
    players: combineReducers({
        live: persistReducer(
            livePlayerPersistConfig,
            PlayerReducer.default(PlayerReducer.PlayerIds.LIVE_PLAYER_ID),
        ),
        bannerRight: persistReducer(
            mediaPlayerPersistConfig,
            PlayerReducer.default(PlayerReducer.PlayerIds.WARMUP_ARCHIVE_PLAYER_ID),
        ),
    }),
});
