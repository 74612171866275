import {combineReducers} from "redux";
import {
    SHARED_BET_INVITE_CLEAR,
    SHARED_BET_INVITE_RESPONSE,
    SHARED_BET_INVITE,
    SHARED_BET_INVITE_SET_PARAMS,
    SHARED_BET_INVITE_MODAL_FLOW_START,
    SHARED_BET_INVITE_MODAL_FLOW_FINISH,
} from "./sharedBetActions";

export type Friend = {
    message: string | null;
    valid: boolean;
    value: string | null;
};

type Params = {
    friends: Array<Friend>;
};

export type SharedBetInviteReducer = {
    params: Params;
    loading: boolean;
    error: string | null;
    modalFlow: boolean;
};

export type InviteModalFlowType = {
    started: boolean;
    couponId: string;
};

const error = (state = null, action: any): string | null => {
    const {type, payload, error: actionError} = action;
    switch (type) {
        case SHARED_BET_INVITE_RESPONSE:
            return actionError ? payload : state;
        case SHARED_BET_INVITE_CLEAR:
            return null;
        default:
            return state;
    }
};

const loading = (state = false, action: any): boolean => {
    switch (action.type) {
        case SHARED_BET_INVITE:
            return true;
        case SHARED_BET_INVITE_RESPONSE:
        case SHARED_BET_INVITE_CLEAR:
            return false;
        default:
            return state;
    }
};

const initialParamsState = {
    friends: [
        {
            message: null,
            valid: true,
            value: null,
        },
    ],
};

const params = (state = initialParamsState, action: any): Params => {
    const {type, payload} = action;
    switch (type) {
        case SHARED_BET_INVITE_SET_PARAMS:
            return payload;
        case SHARED_BET_INVITE_CLEAR:
            return initialParamsState;
        default:
            return state;
    }
};

export const INVITE_MODAL_FLOW: InviteModalFlowType = {
    started: false,
    couponId: "",
};

const modalflow = (state = INVITE_MODAL_FLOW, action: any): InviteModalFlowType => {
    switch (action.type) {
        case SHARED_BET_INVITE_MODAL_FLOW_START: {
            const couponId = action.payload;
            return {
                started: true,
                couponId,
            };
        }
        case SHARED_BET_INVITE_MODAL_FLOW_FINISH:
            return {
                started: false,
                couponId: "",
            };
        default:
            return state;
    }
};

export default combineReducers({
    params,
    loading,
    error,
    modalflow,
});
