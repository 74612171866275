import {combineReducers} from "redux";
import * as ModalActions from "atg-modals/modalActionTypes";
import type {StartDetailsResponse, HistoricStart} from "./startDetailsInterfaces";
import * as StartDetailsActions from "./startDetailsActions";

function startDetailsIsPending(
    state = {},
    action:
        | StartDetailsActions.RequestStartDetails
        | StartDetailsActions.ReceiveStartDetails,
) {
    switch (action.type) {
        case StartDetailsActions.REQUESTED_START_DETAILS:
            return {
                ...state,
                [action.payload.startId]: true,
            };
        case StartDetailsActions.RECEIVED_START_DETAILS: {
            const {startId} = action.payload;
            return {
                ...state,
                [startId]: false,
            };
        }
        default:
            return state;
    }
}

type StartDetailsDataState = {
    [key: string]: StartDetailsResponse;
};

function startDetailsData(
    state: StartDetailsDataState = {},
    action:
        | StartDetailsActions.ReceiveStartDetails
        | StartDetailsActions.CloseStartDetails
        | {type: typeof ModalActions.CLOSE_ALL_MODALS}
        | {type: typeof ModalActions.CLOSE_START_DETAILS},
) {
    switch (action.type) {
        case StartDetailsActions.RECEIVED_START_DETAILS: {
            if ("error" in action) return state;
            const {startId, data} = action.payload;
            return {
                ...state,
                [startId]: data,
            };
        }
        case ModalActions.CLOSE_ALL_MODALS:
        case ModalActions.CLOSE_START_DETAILS:
            return {};
        default:
            return state;
    }
}

export function showStartDetailsFor(
    state = null,
    action:
        | StartDetailsActions.DisplayStartDetails
        | StartDetailsActions.CloseStartDetails
        | {type: typeof ModalActions.CLOSE_ALL_MODALS}
        | {type: typeof ModalActions.CLOSE_START_DETAILS},
) {
    const {type} = action;
    switch (type) {
        case ModalActions.OPEN_START_DETAILS:
            return action.payload;
        case ModalActions.CLOSE_ALL_MODALS:
        case ModalActions.CLOSE_START_DETAILS:
            return null;
        default:
            return state;
    }
}

const initHead2Head = {
    details: Array<StartDetailsResponse>(),
    error: null,
    isLoading: false,
};

export function head2Head(
    state = initHead2Head,
    action:
        | StartDetailsActions.ReceiveRaceDetails
        | StartDetailsActions.ReceiveRaceDetailsError
        | StartDetailsActions.RequestRaceDetails,
) {
    switch (action.type) {
        case StartDetailsActions.REQUEST_RACE_DETAILS:
            return {...state, isLoading: true, error: null};
        case StartDetailsActions.RECEIVED_RACE_DETAILS:
            return {
                ...state,
                details: action.payload.data,
                isLoading: false,
                error: null,
            };
        case StartDetailsActions.RECEIVED_RACE_DETAILS_ERROR: {
            const {error} = action.payload;
            return {...initHead2Head, isLoading: false, error};
        }
        default:
            return state;
    }
}

type VideoDetails = {
    race: HistoricStart | null;
    videoSource: string | null;
};
const initVideoObject = {race: null, videoSource: null};
export function startDetailsVideo(
    state: VideoDetails = initVideoObject,
    action:
        | StartDetailsActions.CloseStartDetails
        | StartDetailsActions.DisplayStartDetails
        | StartDetailsActions.CloseStartDetailsVideo
        | StartDetailsActions.StartDetailsVideo
        | StartDetailsActions.StoreStartDetailsVideo
        | {type: typeof ModalActions.CLOSE_ALL_MODALS}
        | {type: typeof ModalActions.CLOSE_START_DETAILS},
) {
    const {type} = action;
    switch (type) {
        case StartDetailsActions.STORE_START_DETAILS_VIDEO:
            return {
                ...state,
                race: action.payload.race,
                videoSource: action.payload.videoSource,
            };
        case ModalActions.CLOSE_START_DETAILS:
        case ModalActions.OPEN_START_DETAILS:
        case StartDetailsActions.CLOSE_START_DETAILS_VIDEO:
        case ModalActions.CLOSE_ALL_MODALS:
            return {...initVideoObject};
        default:
            return state;
    }
}
export type StartDetailState = {
    startDetails: {
        isPending: boolean;
        data: {[startId: string]: StartDetailsResponse};
        current: {gameId: string; startId: string} | null;
        video: VideoDetails;
        all: typeof initHead2Head;
    };
};

export const startDetails = combineReducers({
    all: head2Head,
    isPending: startDetailsIsPending,
    data: startDetailsData,
    current: showStartDetailsFor,
    video: startDetailsVideo,
});
