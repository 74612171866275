import * as LiveActions from "../liveactions";
import {type TrackHorseInfo} from "./types";

export type LiveReceiptTrackHorseInfo = Record<string, TrackHorseInfo>;

export function liveReceiptTrackHorseInfo(
    state: LiveReceiptTrackHorseInfo = {},
    action: {
        type: string;
        payload: LiveReceiptTrackHorseInfo;
        error: boolean;
    },
) {
    const {error, payload} = action;
    switch (action.type) {
        case LiveActions.TRACK_HORSE_INFO_LOADED:
            if (error) return state;
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
}
