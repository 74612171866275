import {get} from "lodash";
import {serverTime} from "@atg-shared/server-time";
import {fetchSelectors} from "@atg-shared/fetch-redux";
import {getUsername} from "@atg-global-shared/user/userSelectors";
import type {
    GlobalState,
    DisplayAreas,
    KycQuestionnaire,
    KycAnswerResponse,
} from "@atg-aml-shared/kyc-types";
import {ModalRequiredStatus} from "@atg-aml-shared/kyc-types";

export const getKycQuestionnaireState = (state: GlobalState) => state.kycQuestionnaire;

export const getQuestionnaireState = (state: GlobalState) =>
    getKycQuestionnaireState(state).questionnaire;

export const getAnswerState = (state: GlobalState) =>
    getKycQuestionnaireState(state).answerStatus;

export const getAnswerReducer = (state: GlobalState) =>
    getKycQuestionnaireState(state).questionnaireAnswer;

export const isCanSeeKycQuestionnaire = (state: GlobalState): boolean =>
    getQuestionnaireState(state).canSeeKycQuestionnaire;

export const getDisplayAreasQuestionnaire = (state: GlobalState): DisplayAreas =>
    getQuestionnaireState(state).displayAreas;

export const isKycModalBlock = (state: GlobalState): boolean =>
    getQuestionnaireState(state).modalRequired === ModalRequiredStatus.BLOCK;

export const getKycQuestionnaire = (state: GlobalState): KycQuestionnaire =>
    getQuestionnaireState(state).questionnaire;

export const getKycModalRequired = (state: GlobalState): ModalRequiredStatus =>
    getQuestionnaireState(state).modalRequired;

export const getKycLastDateBeforeBlock = (state: GlobalState): string =>
    getQuestionnaireState(state).lastDateBeforeBlock;

export const getShowAtDate = (state: GlobalState) => {
    // @ts-ignore
    const username = getUsername(state);
    if (username) {
        const {usersLastTimeModalViewed} = getQuestionnaireState(state);
        return get(usersLastTimeModalViewed, [username])?.lastTimeModalViewed;
    }
    return null;
};

export const getKycQuestionnaireAnswer = (
    state: GlobalState,
): Array<KycAnswerResponse> | undefined => getAnswerReducer(state).answers;

export const isKycQuestionnaireAnswerLoading = (state: GlobalState) =>
    fetchSelectors.isLoading(getAnswerReducer(state));

export const isKycQuestionnaireAnswerLoaded = (state: GlobalState) =>
    fetchSelectors.isLoaded(getAnswerReducer(state));

export const isAnswerError = (state: GlobalState) =>
    fetchSelectors.hasError(getAnswerState(state));

export const isAnswerLoaded = (state: GlobalState) =>
    fetchSelectors.isLoaded(getAnswerState(state));

export const isShowKycQuestionnaire = (state: GlobalState) =>
    getQuestionnaireState(state).canSeeKycQuestionnaire &&
    getQuestionnaireState(state).modalRequired !== ModalRequiredStatus.NONE;

export const isMoreThan24hSinceLastTime = (state: GlobalState) =>
    !getShowAtDate(state) || serverTime().diff(getShowAtDate(state), "hours") >= 24;

export const isModalRequiredOnLogin = (state: GlobalState): boolean =>
    (isShowKycQuestionnaire(state) && isMoreThan24hSinceLastTime(state)) ||
    isKycModalBlock(state);

export const isUserKycBlockedForBetting = (state: GlobalState): boolean =>
    isCanSeeKycQuestionnaire(state) && isKycModalBlock(state);
