import type {GameAPITypes} from "@atg-horse-shared/racing-info-api";
import dayjs from "dayjs";
import {map} from "lodash/fp";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import {BetType} from "@atg-horse-shared/bet-types";
import {GameTypes} from "@atg-horse-shared/game-types";
import type {GameType, GameSport} from "@atg-horse-shared/game-types";
import type {SportCategory} from "@atg-horse-shared/analytics/types/purchaseAnalytics.types";
import {
    getHorseName,
    getScratchedHorse,
    getSumOfPercent,
    findBaseIndex,
    getRelativeIndex,
} from "./internals";

dayjs.extend(isSameOrAfter);

export const formatProductId = (
    type: string,
    tracks: Array<GameAPITypes.GameInfoTrack> | Array<GameAPITypes.TrackIdentity>,
    date: string,
): string => {
    const trackOutput = map("name", tracks).join("-").toString();

    return `${type}_${trackOutput}_${dayjs(date).format("YYYY-MM-DD")}`;
};
/**
 * @param game English name of the sport
 * @returns Swedish name of the sport
 */
export function translateSport(sport?: GameSport): SportCategory {
    switch (sport) {
        case "gallop":
            return "galopp";
        case "monté":
            return "monté";
        case "trot":
            return "trav";
        default:
            return "trav/galopp";
    }
}

export function decorateBetDistributionData(preliminaryInvestments: any, horseData: any) {
    const decoratedData = map(
        (item) => ({
            ...item,
            name: getHorseName(item.startNumber, horseData),
            scratched: getScratchedHorse(item.startNumber, horseData),
            percentUnitSum: getSumOfPercent(item, "investments"),
        }),
        preliminaryInvestments,
    );

    const baseIndex = findBaseIndex(decoratedData, "percentUnitSum");

    return map(
        (item) => ({...item, index: getRelativeIndex(baseIndex, item, "percentUnitSum")}),
        decoratedData,
    );
}

/**
 * @param startMethod English name of the start method
 * @returns Swedish name of the start method
 */

export function translateStartMethod(startMethod: string) {
    switch (startMethod) {
        case "auto":
            return "autostart";
        case "volte":
            return "voltstart";
        case "line":
        default:
            return "linjestart";
    }
}

/**
 * Provide a mapping between the bet type from Varenne to GameType we have in some older FE components
 * @param betType
 * @return GameType
 */

export const betTypeToGameType = (betType: BetType | Uppercase<BetType>): GameType => {
    const mapping = {
        [BetType.Vinnare]: GameTypes.vinnare,
        [BetType.Plats]: GameTypes.plats,
        [BetType.Vp]: GameTypes.vp,
        [BetType.Raket]: GameTypes.raket,
        [BetType.Tvilling]: GameTypes.tvilling,
        [BetType.Komb]: GameTypes.komb,
        [BetType.Trio]: GameTypes.trio,
        [BetType.Top7]: GameTypes.top7,
        [BetType.Ld]: GameTypes.ld,
        [BetType.Dd]: GameTypes.dd,
        [BetType.V3]: GameTypes.V3,
        [BetType.V4]: GameTypes.V4,
        [BetType.V5]: GameTypes.V5,
        [BetType.V64]: GameTypes.V64,
        [BetType.V65]: GameTypes.V65,
        [BetType.V75]: GameTypes.V75,
        [BetType.V86]: GameTypes.V86,
        [BetType.Gs75]: GameTypes.GS75,
    };

    return mapping[betType];
};

export function parseRaceId(raceId: string): GameAPITypes.RaceIdParts {
    const [date, trackId, raceNumber] = raceId.split("_");

    return {
        date,
        trackId: parseInt(trackId, 10),
        raceNumber,
    };
}
