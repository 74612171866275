import {css} from "@emotion/react";

export const boostCodeWrapper = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const icon = css`
    font-size: 12px;
    vertical-align: middle;
`;
