import {map, compact, find, includes, isEmpty, uniqBy} from "lodash";
import dayjs from "dayjs";
import fp from "lodash/fp";
import {createSelector} from "reselect";
import {isLoggedIn} from "@atg-global-shared/user/userSelectors";
import log from "@atg-shared/log";
import * as Time from "@atg-shared/server-time";
import * as PlayerDomain from "./PlayerDomain";
import {PlayerIds} from "./playerReducer";

import {isPlaying as isPlayerPlaying} from "./playerSelectors";

export const NO_CHANNEL_ID = "main-nochannel";

export const NO_CHANNEL = {
    id: NO_CHANNEL_ID,
    name: "Ingen livevideo",
    nextRace: {},
    thumbnail: "",
    tracks: [],
    streams: [
        {
            id: NO_CHANNEL_ID,
            subscriptions: [],
            name: "",
            audioOnly: false,
        },
    ],
};

const STANDARD_QUALITY = {
    id: "",
    ids: ["", "free", "nochannel"],
    name: "Standard",
};

const QUALITIES = [
    STANDARD_QUALITY,
    {
        id: "capped",
        ids: ["capped", "nochannel"],
        name: "Mobilvänlig",
    },
    {
        id: "audio",
        ids: ["audio", "nochannel"],
        name: "Bara Ljud",
        icon: "sound-loud",
    },
];

export const video = (state) => state.video;
const getVideoFrame = (state) => state?.video?.videoFrame;
export const getLiveVideoState = (state) => state.video?.liveVideo;
const getBannerRightVideoState = (state) => state?.video?.bannerRightVideo;

// Live video
export const isPopoutFrame = (state) => getLiveVideoState(state).isPopoutFrame;
export const isVideoPopupOpen = (state) => Boolean(getLiveVideoState(state).videoPopup);
export const isPlaying = (state) =>
    isPlayerPlaying(state, PlayerIds.LIVE_PLAYER_ID) ||
    isPlayerPlaying(state, PlayerIds.WARMUP_ARCHIVE_PLAYER_ID);
export const isVideoOnly = (state) => Boolean(getVideoFrame(state)?.videoOnly);
export const isVideoFrameExpanded = (state) =>
    Boolean(getVideoFrame(state)?.videoFrameExpanded);
export const getSelectedQuality = (state) => getLiveVideoState(state).selectedQuality;
export const isLowLatencyVideoActive = (state) =>
    state.video?.lowLatency?.lowLatencyVideoActive;

const getChannelList = (state) => state?.video?.liveVideo?.channels;

export const getChannels = createSelector(getChannelList, (channels) => {
    if (!channels) return [NO_CHANNEL];
    return uniqBy([...channels, NO_CHANNEL], "id");
});

export const getChannelsByQuality = createSelector(getChannels, (channels) =>
    map(QUALITIES, (quality) => {
        const {id, name, icon} = quality;
        const qualityId = quality.ids;
        const channelsForQuality = compact(
            map(channels, (channel) => {
                const hasQuality = find(channel.streams, (channelQuality) => {
                    const channelQualityId = channelQuality.id.split("-")[1] || "";
                    return includes(qualityId, channelQualityId);
                });
                if (!hasQuality) return null;

                return channel;
            }),
        );

        return {
            id,
            name,
            icon,
            channels: channelsForQuality,
        };
    }),
);

export const getSelectedChannel = (state) => {
    const liveVideoState = getLiveVideoState(state);
    const channels = getChannels(state);

    return find(channels, {id: liveVideoState?.selectedChannel.id}) || {};
};
export const getSelectedStream = createSelector(
    getSelectedChannel,
    getSelectedQuality,
    (selectedChannel, selectedQuality) =>
        PlayerDomain.getSelectedStream(selectedChannel, selectedQuality),
);

export const getChannelStatus = createSelector(
    getSelectedChannel,
    getSelectedStream,
    isLoggedIn,
    (selectedChannel, selectedStream, loggedIn) => {
        if (!selectedChannel) {
            log.error("videoSelectors:getChannelStatus -> selectedChannel is undefined");
            return "no-channel";
        }
        if (isEmpty(selectedChannel) || selectedChannel.id === NO_CHANNEL_ID)
            return "no-channel";

        const stream = find(selectedChannel.streams, {id: selectedStream});

        const freeStream = includes(stream?.id, "main-");

        if (!loggedIn && !freeStream) {
            return "register";
        }

        const startTime = dayjs(selectedChannel.startTime);
        const endTime = dayjs(selectedChannel.endTime);

        const now = Time.serverTime();
        if (selectedChannel.startTime && now.isBefore(startTime)) {
            return "notStarted";
        }

        if (selectedChannel.endTime) {
            if (now.isAfter(endTime)) return "finished";
        }

        return "live";
    },
);

const addCountryCodeToTracks = (tracks, dayTracks) =>
    fp.map((track) => {
        const matchingDayTrack = find(dayTracks, {id: track.id});
        return fp.set(
            "countryCode",
            matchingDayTrack && matchingDayTrack.countryCode,
            track,
        );
    })(tracks);

/**
 * Select current streams
 */
export const getSelectedChannelTracks = (state, day) => {
    let {tracks} = getSelectedChannel(state);
    if (day) {
        tracks = addCountryCodeToTracks(tracks, day.tracks);
    }
    return tracks || [];
};

/**
 * Select tracks in channels from media broadcast
 */
export const getOtherChannelTracks = (state, day) => {
    const channels = getChannels(state);
    const selectedChannel = getSelectedChannel(state);
    let tracks = PlayerDomain.getOtherChannelTracks(channels, selectedChannel);
    if (day) {
        tracks = addCountryCodeToTracks(tracks, day.tracks);
    }
    return tracks;
};

// Archive/warmup video
export const getMediaId = (state) => getBannerRightVideoState(state)?.mediaId;
export const getMediaIds = (state) => getBannerRightVideoState(state)?.mediaIds;
export const getGraphicsMedia = (state) => getBannerRightVideoState(state)?.graphicsMedia;
export const getVideoType = (state) => getBannerRightVideoState(state)?.type || "";
export const getGameId = (state) => getBannerRightVideoState(state)?.gameId;
export const getTrack = (state) => getBannerRightVideoState(state)?.track;
export const getDate = (state) => getBannerRightVideoState(state)?.date;
export const getHorseName = (state) => getBannerRightVideoState(state)?.horseName;
export const getRaceNumber = (state) => getBannerRightVideoState(state)?.raceNumber;
export const getRaceName = (state) => getBannerRightVideoState(state)?.raceName;
export const getStartNumber = (state) => getBannerRightVideoState(state)?.startNumber;
export const getCountryCode = (state) => getBannerRightVideoState(state)?.countryCode;
export const isVideoPopup = (state, playerName) =>
    playerName ? getBannerRightVideoState(state)?.[playerName] : null;
export const isVideoActive = (state) => isPlaying(state) || isVideoPopupOpen(state);
export const archiveVideoPopoutOptions = (state) => ({
    gameId: getGameId(state),
    mediaId: getMediaId(state),
    graphicsMedia: getGraphicsMedia(state),
    countryCode: getCountryCode(state),
    date: getDate(state) || "",
    gameType: getBannerRightVideoState(state).gameType || "vinnare",
    raceNumber: getRaceNumber(state) || 0,
    raceName: getRaceName(state) || "",
    track: getTrack(state),
    newWindow: true,
});
