import {call, put, takeLatest} from "redux-saga/effects";
import {FileBetActionType} from "./fileBetActions";
import type {
    requestFileBetAction,
    receiveFileBetAction,
    errorFileBetAction,
} from "./fileBetActions";
import getFileBets from "./fileBetApi";

export function* fetchFileBets(action: requestFileBetAction): unknown {
    const {groupBetId, placedAt} = action.payload;
    try {
        const fileBets = yield call(getFileBets, groupBetId, placedAt);
        const receievefileBetAction: receiveFileBetAction = {
            type: FileBetActionType.RECEIVED_FILE_BETS,
            payload: fileBets.data,
        };

        yield put(receievefileBetAction);
    } catch (error: unknown) {
        const errorFileBetAction: errorFileBetAction = {
            type: FileBetActionType.ERROR_FILE_BETS,
            payload: true,
        };
        yield put(errorFileBetAction);
    }
}

export default function* fileBetSaga() {
    yield takeLatest(FileBetActionType.REQUEST_FILE_BETS.toString(), fetchFileBets);
}
