import {frameAction} from "atg-store-addons";
import type {
    SharedBetCreateParams,
    SharedBetPurchaseParams,
    TotalNrSharesOpenModalConditions,
} from "./sharedBet";
import {
    EDIT_TOTAL_NR_SHARES_RESET,
    type SharedBetEditTotalNrSharesResetAction,
} from "./sharedBetApi";

export const SHARED_BET_CREATE_SET_PARAMS = "sharedBet/CREATE_SET_PARAMS" as const;
export const SHARED_BET_PURCHASE_SET_PARAMS = "sharedBet/PURCHASE_SET_PARAMS" as const;
export const SHARED_BET_INVITE = "sharedBet/INVITE" as const;
export const SHARED_BET_INVITE_RESPONSE = "sharedBet/INVITE_RESPONSE" as const;
export const SHARED_BET_INVITE_STEP = "sharedBet/INVITE_STEP" as const;
export const SHARED_BET_INVITE_CLEAR = "sharedBet/INVITE_CLEAR" as const;
export const SHARED_BET_INVITE_SET_PARAMS = "sharedBet/INVITE_SET_PARAMS" as const;
export const SHARED_BET_CONDITIONS = "sharedBet/CONDITIONS" as const;
export const SHARED_BET_CONDITIONS_RESPONSE = "sharedBet/CONDITIONS_RESPONSE" as const;
export const SHARED_BET_CONDITIONS_CLEAR = "sharedBet/CONDITIONS_CLEAR" as const;
export const SHARED_BET_PURCHASE_RESPONSE = "sharedBet/PURCHASE_RESPONSE" as const;
export const SHARED_BET_RECEIPT_CLEAR = "sharedBet/RECEIPT_CLEAR" as const;
export const SHARED_BET_CANCEL = "sharedBet/CANCEL" as const;
export const SHARED_BET_CANCEL_RESPONSE = "sharedBet/CANCEL_RESPONSE" as const;
export const SHARED_BET_CANCEL_CLEAR = "sharedBet/CANCEL_CLEAR" as const;
export const SHARED_BET_INVITE_MODAL_FLOW_START =
    "sharedBet/INVITE_MODAL_FLOW_START" as const;
export const SHARED_BET_INVITE_MODAL_FLOW_FINISH =
    "sharedBet/INVITE_MODAL_FLOW_FINISH" as const;
export const SHARED_BET_OPEN_NOT_FOUND_MODAL = "sharedBet/OPEN_NOT_FOUND_MODAL" as const;
export const SHARED_BET_CLOSE_NOT_FOUND_MODAL =
    "sharedBet/CLOSE_NOT_FOUND_MODAL" as const;
export const SHARED_BET_OPEN_MAX_SHARES_EDIT_MODAL =
    "sharedBet/OPEN_MAX_SHARES_EDIT_MODAL" as const;
export const SHARED_BET_CLOSE_MAX_SHARES_EDIT_MODAL =
    "sharedBet/CLOSE_MAX_SHARES_EDIT_MODAL" as const;
export const SHARED_BET_SET_SHOW_SYSTEM_SIZE_LIMIT =
    "sharedBet/SHARED_BET_SET_SHOW_SYSTEM_SIZE_LIMIT" as const;
export const SHARED_BET_RESET_SHOW_SYSTEM_SIZE_LIMIT =
    "sharedBet/SHARED_BET_RESET_SHOW_SYSTEM_SIZE_LIMIT" as const;

export type CreateParamsAction = {
    type: typeof SHARED_BET_CREATE_SET_PARAMS;
    payload: SharedBetCreateParams;
};

export type PurchaseParamsAction = {
    type: typeof SHARED_BET_PURCHASE_SET_PARAMS;
    payload: SharedBetPurchaseParams;
};

export type InviteFriendsModalFlowAction = {
    type:
        | typeof SHARED_BET_INVITE_MODAL_FLOW_START
        | typeof SHARED_BET_INVITE_MODAL_FLOW_FINISH;
    payload?: string;
};

export type SharedBetNotFoundModalAction = {
    type:
        | typeof SHARED_BET_OPEN_NOT_FOUND_MODAL
        | typeof SHARED_BET_CLOSE_NOT_FOUND_MODAL;
};

export type SharedBetMaxSharesEditModalAction = {
    type:
        | typeof SHARED_BET_OPEN_MAX_SHARES_EDIT_MODAL
        | typeof SHARED_BET_CLOSE_MAX_SHARES_EDIT_MODAL;
    payload?: TotalNrSharesOpenModalConditions;
};

export type CancelSharedBetAction = {
    type: typeof SHARED_BET_CANCEL;
    payload: string;
};

export type SetShowSystemSizeLimitAction = {
    type: typeof SHARED_BET_SET_SHOW_SYSTEM_SIZE_LIMIT;
    payload: boolean;
};

export type ResetShowSystemSizeLimitAction = {
    type: typeof SHARED_BET_RESET_SHOW_SYSTEM_SIZE_LIMIT;
    payload: boolean;
};

export const setCreateParams = (payload: SharedBetCreateParams): CreateParamsAction => ({
    type: SHARED_BET_CREATE_SET_PARAMS,
    payload,
});
export const setPurchaseParams = (
    payload: SharedBetPurchaseParams,
): PurchaseParamsAction => ({
    type: SHARED_BET_PURCHASE_SET_PARAMS,
    payload,
});

export const inviteFriends = (
    payload: {
        [key: string]: any;
    },
    token: string,
) => ({
    type: SHARED_BET_INVITE,
    payload,
    token,
});
export const inviteFriendsSuccess = () => ({type: SHARED_BET_INVITE_RESPONSE});
export const recieptSuccessStep = () => ({type: SHARED_BET_INVITE_STEP});
export const inviteFriendsError = (payload: string) => ({
    type: SHARED_BET_INVITE_RESPONSE,
    payload,
    error: true,
});
export const inviteSetParams = (payload: {[key: string]: any}) => ({
    type: SHARED_BET_INVITE_SET_PARAMS,
    payload,
});
export const inviteClear = () => ({type: SHARED_BET_INVITE_CLEAR});

export const fetchConditions = (payload: string) => ({
    type: SHARED_BET_CONDITIONS,
    payload,
});
export const fetchConditionsSuccess = (payload: {[key: string]: any}) =>
    frameAction({
        type: SHARED_BET_CONDITIONS_RESPONSE,
        payload,
    });
export const fetchConditionsError = (payload: any) => ({
    type: SHARED_BET_CONDITIONS_RESPONSE,
    payload,
    error: true,
});
export const clearConditions = () => ({type: SHARED_BET_CONDITIONS_CLEAR});

export const clearReceipt = () => ({type: SHARED_BET_RECEIPT_CLEAR});

export const cancelSharedBet = (payload: string): CancelSharedBetAction => ({
    type: SHARED_BET_CANCEL,
    payload,
});
export const cancelSharedBetSuccess = () => ({type: SHARED_BET_CANCEL_RESPONSE});
export const cancelSharedBetError = (payload: any) => ({
    type: SHARED_BET_CANCEL_RESPONSE,
    payload,
    error: true,
});

export const inviteFriendsModalFlowStart = (
    payload: string,
): InviteFriendsModalFlowAction => ({type: SHARED_BET_INVITE_MODAL_FLOW_START, payload});
export const inviteFriendsModalFlowFinish = (): InviteFriendsModalFlowAction => ({
    type: SHARED_BET_INVITE_MODAL_FLOW_FINISH,
});

export const openNotFoundModal = (): SharedBetNotFoundModalAction => ({
    type: SHARED_BET_OPEN_NOT_FOUND_MODAL,
});
export const closeNotFoundModal = (): SharedBetNotFoundModalAction => ({
    type: SHARED_BET_CLOSE_NOT_FOUND_MODAL,
});

export const openMaxSharesEditModal = (
    payload: TotalNrSharesOpenModalConditions,
): SharedBetMaxSharesEditModalAction => ({
    type: SHARED_BET_OPEN_MAX_SHARES_EDIT_MODAL,
    payload,
});

export const closeMaxSharesEditModal = (): SharedBetMaxSharesEditModalAction => ({
    type: SHARED_BET_CLOSE_MAX_SHARES_EDIT_MODAL,
});

export const resetTotalNrSharesState = (): SharedBetEditTotalNrSharesResetAction => ({
    type: EDIT_TOTAL_NR_SHARES_RESET,
});

export const setShowSystemSizeLimit = (
    showSystemSizeLimit: boolean,
): SetShowSystemSizeLimitAction => ({
    type: SHARED_BET_SET_SHOW_SYSTEM_SIZE_LIMIT,
    payload: showSystemSizeLimit,
});

export const resetShowSystemSizeLimit = (
    showSystemSizeLimit: boolean,
): ResetShowSystemSizeLimitAction => ({
    type: SHARED_BET_RESET_SHOW_SYSTEM_SIZE_LIMIT,
    payload: showSystemSizeLimit,
});
