import {isEmpty, head, find, includes, filter, reject} from "lodash";
import dayjs from "dayjs";
import map from "lodash/fp/map";
import uniqBy from "lodash/fp/uniqBy";
import flatten from "lodash/fp/flatten";
import compact from "lodash/fp/compact";
import flow from "lodash/fp/flow";
import {MEDIA_SERVICE_URL} from "@atg-shared/service-url";
import atgRequest from "@atg-shared/fetch";
import {ThePlatform} from "@atg-horse-shared/old-archiveraces-theplatform-api";

export function getChannelDate(channel) {
    if (!channel) return null;

    const startTime =
        channel.startTime ||
        (channel.nextRace && channel.nextRace.startTime) ||
        (channel.currentRace && channel.currentRace.startTime);
    if (!startTime) return null;

    return dayjs(startTime).format("YYYY-MM-DD");
}

export function getAllTracksFromChannels(channels) {
    return flow(
        map((channel) => channel.tracks),
        flatten,
        compact,
        uniqBy((track) => track.id),
    )(channels);
}

export function getOtherChannelTracks(channels, selectedChannel) {
    const allTracks = getAllTracksFromChannels(channels);
    return filter(
        allTracks,
        (track) => !find(selectedChannel.tracks, (t) => t.id === track.id),
    );
}

export const getSelectedStream = (selectedChannel, selectedQuality) => {
    if (isEmpty(selectedChannel)) return "";

    // premium is temporary for testing HD stream
    if (
        selectedQuality === "audio" ||
        selectedQuality === "capped" ||
        selectedQuality === "premium"
    ) {
        return `${selectedChannel.id}-${selectedQuality}`;
    }

    const streamsWithoutCappedOrAudio = reject(
        selectedChannel.streams,
        (stream) => includes(stream.id, "capped") || includes(stream.id, "audio"),
    );

    return head(streamsWithoutCappedOrAudio).id;
};

export const fetchStreamByMediaId = (mediaId) =>
    atgRequest(`${MEDIA_SERVICE_URL}/${mediaId}`)
        .then((response) => ThePlatform.parse(response.data))
        .then((parsedVideoData) => {
            const stream = find(parsedVideoData.streams, {type: "application/x-mpegURL"});
            const url = (stream && stream.url) || "";
            if (url.indexOf("https") < 0)
                return url.replace(
                    "//link.theplatform.eu",
                    "https://link.theplatform.eu",
                );
            return url;
        })
        .then((streamUrl) => fetch(streamUrl))
        .then((response) => response.url);
