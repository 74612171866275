import {flow, keys, reduce} from "lodash/fp";
import log from "@atg-shared/log";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as GameSelectors from "atg-horse-game/domain/gameSelectors";
import * as CouponValidation from "./couponValidation";

/**
 * This will is a check for the specific coupon and game
 * if it has changed then run the rule again.
 *
 * @param {Object} flatStateForRules - this is not redux state, but a flat slice
 * @param {Object} oldFlatStateForRules - this is not old redux state, but an old flat slice
 */
function shouldRunRules(flatStateForRules: any, oldFlatStateForRules: any) {
    return (
        oldFlatStateForRules.game !== flatStateForRules.game ||
        oldFlatStateForRules.coupon !== flatStateForRules.coupon ||
        oldFlatStateForRules.couponSettings !== flatStateForRules.couponSettings ||
        oldFlatStateForRules.reducedBets !== flatStateForRules.reducedBets
    );
}

/*
 * will check if a coupon changed and run rules
 * @param oldState - old redux state
 * @param couponRules - rules function
 * @return ruleReducer(state, cid) - function that takes in latest redux state and cid and will run logic
 */
function runRulesOnCoupon(oldState: any, couponRules: any) {
    return function ruleReducer(state: any, cid: string) {
        const coupon = state.coupons[cid];
        if (coupon.type === "bag" || coupon.type === "subscription") return state;

        if (!coupon.game) {
            // just return state, otherwise it will crash on next line
            return state;
        }
        const gameId = coupon.game.id;

        // create a "flat" slice from the part of redux state
        const flatStateForRules = {
            coupon,
            couponSettings: state.couponSettings[cid],
            couponValidation: CouponValidation.create(),
            game: GameSelectors.getGameById(state, gameId),
            reducedBets: state.horse?.reducedBets,
        };

        const oldFlatStateForRules = {
            coupon: oldState.coupons[cid],
            couponSettings: oldState.couponSettings[cid],
            couponValidation: oldState.couponValidations[cid],
            game: GameSelectors.getGameById(oldState, gameId),
            reducedBets: oldState.horse?.reducedBets,
        };

        if (!shouldRunRules(flatStateForRules, oldFlatStateForRules)) return state;
        log.debug("Rules - Run for coupon", cid, coupon.id);

        // here we put in the "flat" substate, ie not of the same structure as in redux.
        // and get out "flat" substate
        const flatStateAfterRules = couponRules(flatStateForRules, oldFlatStateForRules);

        // returns an updated redux state
        // does not need to update the reducedBets, since it does not change them
        return {
            ...state,
            coupons: {
                ...state.coupons,
                [cid]: flatStateAfterRules.coupon,
            },
            couponSettings: {
                ...state.couponSettings,
                [cid]: flatStateAfterRules.couponSettings,
            },
            couponValidations: {
                ...state.couponValidations,
                [cid]: flatStateAfterRules.couponValidation,
            },
        };
    };
}

/*
 * will check each coupon and run rules if coupon changed
 * @param state - redux state
 * @param oldState - previus redux state
 * @param couponRules - rules function
 */
function runRulesOnAllCoupons(state: any, oldState: any, couponRules: any) {
    return flow(
        keys,
        reduce(runRulesOnCoupon(oldState, couponRules), state),
    )(state.coupons);
}

/**
 * This will run on every change in the global redux state
 * and if the conditions is met it will run the rules for the coupon
 *
 * @param {Object} state - redux state
 * @param {Object} oldState - old redux state
 */
function shouldRunRulesOnAllCoupons(state: any, oldState: any) {
    return (
        oldState.games !== state.games ||
        oldState.coupons !== state.coupons ||
        oldState.couponSettings !== state.couponSettings ||
        oldState.horse.reducedBets !== state.horse.reducedBets
    );
}

export default function couponRulesReducerDecorator(reducer: any, couponRules: any) {
    return (oldState: any = {}, action: any) => {
        const state = reducer(oldState, action);
        // Right now the couponRules is running over every reducer in redux.
        // This check if the coupon is loaded in the redux reducer tree.
        // Since coupon is now dynamically loaded into the reducer.
        if (
            !oldState.coupons ||
            !state.coupons ||
            !oldState.couponSettings ||
            !state.couponSettings
        )
            return state;

        if (!shouldRunRulesOnAllCoupons(state, oldState)) return state;
        return runRulesOnAllCoupons(state, oldState, couponRules);
    };
}
