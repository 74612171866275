export function getFlexLabel() {
    return "Flex";
}

export function getOnlyV6Label() {
    return "V6 - endast vinst vid 6 rätt";
}

export function getOnlyGS7Label() {
    return "GS7 - endast vinst vid 7 rätt";
}
