import {type GameType, getGameTypeFromString} from "@atg-horse-shared/game-types";
import dayjs from "dayjs";

/**
 * A game ID uniquely identifies a single game.
 *
 * A game is a unique combination of:
 * - a specific bet type (e.g. Dagens Dubbel)
 * - a specific track (e.g. Axevalla)
 * - one or several races (e.g. races 10 and 11)
 *
 * example: "Dagens Dubbel on Axevalla on 2020-07-18, race 10 and race 11".
 *
 * Format: `<type>_<date>_<track>_<race number>`

 * Example: `V4_2020-07-17_27_4`
 * -  V4 = game type
 * - 2020-07-17 = the date that all the races will happen
 * - 27 = Umåker (track)
 * - 4 = the race number (for that track) for the first leg of this game
 */
export type GameId = string;

export type ParsedGameId = {
    gameType: GameType;
    date: string;
    trackId: number;
    raceNumber: string;
};

export function parseGameId(gameId?: string | null): ParsedGameId | null {
    if (!gameId) return null;

    const [gameType, date, trackId, raceNumber] = gameId.split("_");

    const parsedGameType = getGameTypeFromString(gameType);
    if (!parsedGameType) return null;

    return {
        gameType: parsedGameType,
        date,
        trackId: parseInt(trackId, 10),
        raceNumber,
    };
}

export function getGameType(gameId?: string): GameType | null {
    if (!gameId) return null;

    const parsedGameId = parseGameId(gameId);
    if (!parsedGameId) return null;

    return parsedGameId.gameType;
}

export function getDateFromGameId(gameId: string, format: string): string {
    const {date} = parseGameId(gameId) || {date: null};
    if (date) return dayjs(date).format(format);
    return "";
}

export const formatProductId = (
    type: string,
    tracks: Array<{track: string}>,
    date: string,
): string => {
    const trackOutput = tracks
        .map((track) => track)
        .join("-")
        .toString();

    return `${type}_${trackOutput}_${dayjs(date).format("YYYY-MM-DD")}`;
};

export function getGameTypeByGameId(
    gameId: string | null | undefined,
): GameType | null | undefined {
    const parsedGameId = parseGameId(gameId);
    return parsedGameId ? parsedGameId.gameType : null;
}
