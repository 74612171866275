import {sortBy, get, map} from "lodash";
import {gameTypePriorityOrder} from "@atg-horse-shared/game-types";
import type {ShareCoupon} from "@atg-shop-shared/types/shareCoupon";

export const GAME_TYPE_SORT_OPTION = "game.type";
export const MODIFIED_SORT_OPTION = "modified";
export const TRACKS_SORT_OPTION = "game.tracks";
export const BET_COST_SORT_OPTION = "betCost";
export const BET_STOP_SORT_OPTION = "game.startTime";
export const SHARE_NAME_SORT_OPTIONS = "name";

export type CouponSortOption = {
    column: string;
    ascending: boolean;
};

export const sortCoupons = (
    coupons: Array<ShareCoupon>,
    sortOption: CouponSortOption,
): Array<ShareCoupon> => {
    let sortedCoupons;

    switch (sortOption.column) {
        case GAME_TYPE_SORT_OPTION:
            sortedCoupons = sortBy(
                coupons,
                (coupon) => -gameTypePriorityOrder[coupon.game.type],
            ); // Minus here to reverse the order
            break;
        case TRACKS_SORT_OPTION:
            sortedCoupons = sortBy(coupons, (coupon) =>
                map(coupon.game.tracks, (track) => track.name).join(""),
            );
            break;
        default:
            sortedCoupons = sortBy(coupons, (coupon) => get(coupon, sortOption.column));
    }

    if (!sortOption.ascending) return sortedCoupons.reverse();
    return sortedCoupons;
};
