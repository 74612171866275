import {combineReducers} from "redux";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {PurchaseActions} from "@atg-horse-shared/purchase";
import {
    type ResetShowSystemSizeLimitAction,
    SHARED_BET_CREATE_SET_PARAMS,
    SHARED_BET_RESET_SHOW_SYSTEM_SIZE_LIMIT,
    SHARED_BET_SET_SHOW_SYSTEM_SIZE_LIMIT,
    type SetShowSystemSizeLimitAction,
    type CreateParamsAction,
} from "./sharedBetActions";
import type {SharedBetCreateParams, SharedBetShowSystemSizeLimit} from "./sharedBet";

export type SharedBetCreateReducer = {
    params: SharedBetCreateParams;
    showSystemSizeLimit: SharedBetShowSystemSizeLimit;
};

const initialParamsState = {
    nrShares: 1,
    shareCost: 0,
    totalNrShares: 10,
};

const params = (
    state: SharedBetCreateParams = initialParamsState,
    action: CreateParamsAction,
) => {
    const {type, payload} = action;

    switch (type) {
        case SHARED_BET_CREATE_SET_PARAMS:
            return {
                ...state,
                ...payload,
            };
        // @ts-expect-error
        case PurchaseActions.FINISH_PURCHASE_FLOW:
            return initialParamsState;
        default:
            return state;
    }
};

const showSystemSizeLimit = (
    state = false,
    action: SetShowSystemSizeLimitAction | ResetShowSystemSizeLimitAction,
) => {
    const {type, payload} = action;
    switch (type) {
        case SHARED_BET_SET_SHOW_SYSTEM_SIZE_LIMIT:
            return payload;
        case SHARED_BET_RESET_SHOW_SYSTEM_SIZE_LIMIT:
            return payload;
        default:
            return state;
    }
};

export default combineReducers({
    params,
    showSystemSizeLimit,
});
