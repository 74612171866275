import {type AuthActions} from "@atg-shared/auth";

export const TRIGGER_AUTH_CHECK = "TRIGGER_AUTH_CHECK";
export const KEEP_USER_IN_BETTING_STATE = "KEEP_USER_IN_BETTING_STATE";

export type AuthCheckWrapperAction = Readonly<{
    type: typeof TRIGGER_AUTH_CHECK;
    action: Readonly<{type: string}>;
}>;

/**
 * This "higher-order action creator" is used to dispatch an action only if the user is currently
 * logged in.
 *
 * If the user is not already logged in prompt a login modal and dispatch the original action IFF
 * they login successfully.
 *
 * @param actionCreator a function which will return the action that should be dispatched IFF the
 * user is currently logged in
 */
export const authCheckWrapper =
    <P>(
        actionCreator: (arg0: P) => Readonly<{type: string}>,
    ): ((arg0: P) => AuthCheckWrapperAction) =>
    (payload) => ({
        type: TRIGGER_AUTH_CHECK,
        action: actionCreator(payload),
    });

export const keepUserInBettingState = (
    shouldTriggerLogin = true,
): AuthActions.CheckAuthAction => ({
    type: KEEP_USER_IN_BETTING_STATE,
    payload: {shouldTriggerLogin},
});
