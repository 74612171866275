export const TABLE_APPLY_OPTIONS = "atg-display-options/tableApplyOptions" as const;
export const TABLE_TOGGLE_FLAG_BY_VALUE =
    "atg-display-options/tableToggleFlagByValue" as const;

export type ResultsFlags = {
    ageAndSex?: boolean;
    horseCompact?: boolean;
    nationalities?: boolean;
    trainerShortName?: boolean;
};

export type StartlistFlags = {
    ageAndSex?: boolean;
    betDistributionDetailed?: boolean;
    horseCompact?: boolean;
    nationalities?: boolean;
    trainer?: boolean; // app only
    trainerShortName?: boolean;
    showColumnHeaders?: boolean; // app only
};

export type ApplyDisplayOptionsAction = {
    type: typeof TABLE_APPLY_OPTIONS;
    payload: {
        isNewAnpassa: boolean;
        columns: Array<{
            id: string;
            value: boolean;
        }>;
        flags: StartlistFlags | ResultsFlags;
        target: string;
    };
};

export type ToggleFlagByValueAction = {
    type: typeof TABLE_TOGGLE_FLAG_BY_VALUE;
    payload: {
        flagId: string;
        target: string;
        value: boolean;
    };
};

export const applyOptions = (
    columns: Array<{
        id: string;
        value: boolean;
    }>,
    flags: StartlistFlags | ResultsFlags,
    target: string,
    isNewAnpassa = false,
): ApplyDisplayOptionsAction => ({
    type: TABLE_APPLY_OPTIONS,
    payload: {
        columns,
        flags,
        target,
        isNewAnpassa,
    },
});

export const toggleFlagByValue = (
    flagId: string,
    target: string,
    value: boolean,
): ToggleFlagByValueAction => ({
    type: TABLE_TOGGLE_FLAG_BY_VALUE,
    payload: {
        flagId,
        target,
        value,
    },
});
