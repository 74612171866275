import {take, select, call, put, takeLatest} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import * as Storage from "@atg-shared/storage";
import * as UserActions from "@atg-global-shared/user/userActions";
import * as UserActionTypes from "@atg-global-shared/user/userActionTypes";
import * as UserSelectors from "@atg-global-shared/user/userSelectors";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {CouponSelectors} from "@atg-horse-shared/coupon";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    SharedBetProducts,
    SharedBetSelectors,
    type SharedBetCreateProduct,
    type SharedBetPurchaseParams,
} from "@atg-horse/shared-bet";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {PLAYED_BET} from "@atg-horse/horse-bet";
import {DepositActionConstants} from "@atg-payment-shared/deposit-types";
import type {PurchaseState} from "./purchaseReducer";
import type * as Products from "./products";
import * as PurchaseSelectors from "./purchaseSelectors";
import * as PurchaseActions from "./purchaseActions";

export const PURCHASE_PERSIST_KEY = "savedPurchaseState";

export function* getCouponPurchaseState(
    product: Products.CouponProduct | SharedBetCreateProduct,
): SagaIterator<{purchase: PurchaseState}> {
    const coupon = yield select(CouponSelectors.getCoupon, product.cid);
    const purchaseState = yield select(PurchaseSelectors.getPurchaseState);

    return {
        purchase: {
            ...purchaseState,
            currentStep: {
                ...purchaseState.currentStep,
                product: {
                    ...product,
                    coupon,
                },
            },
        },
    };
}

export function* clearPurchaseState(): SagaIterator<void> {
    yield call(Storage.removeItem, PURCHASE_PERSIST_KEY);
}

export function* getPurchaseState(product: Products.Product): SagaIterator<{
    purchase: PurchaseState;
    balance: number | null | undefined;
    params?: SharedBetPurchaseParams;
}> {
    const balance = yield select(UserSelectors.getBalanceAmount);
    switch (product.type) {
        case SharedBetProducts.SHARED_BET_SHARE_PRODUCT: {
            const sharePurchaseState = yield select(PurchaseSelectors.getPurchaseState);
            const shareParams = yield select(SharedBetSelectors.getPurchaseParams);
            return {
                purchase: sharePurchaseState,
                params: shareParams,
                balance,
            };
        }
        case SharedBetProducts.SHARED_BET_CREATE_PRODUCT: {
            const createPurchaseState = yield call(getCouponPurchaseState, product);
            const createParams = yield select(SharedBetSelectors.getCreateParams);
            return {
                ...createPurchaseState,
                params: createParams,
                balance,
            };
        }
        default: {
            // @ts-expect-error
            const couponPurchaseState = yield call(getCouponPurchaseState, product);
            return {
                ...couponPurchaseState,
                balance,
            };
        }
    }
}

export function* loadPurchaseState(): SagaIterator<void> {
    const savedPurchaseState = yield call(Storage.getItem, PURCHASE_PERSIST_KEY);

    if (!savedPurchaseState) {
        yield call(clearPurchaseState);
        return;
    }

    try {
        const parsedState = yield call(JSON.parse, savedPurchaseState);
        yield put(UserActions.fetchUser());
        yield take(UserActionTypes.RECEIVE_USER);
        yield put(PurchaseActions.restoreProduct(parsedState));
    } catch (e: unknown) {
        yield call(clearPurchaseState);
    }
}

export function* persistPurchaseState(): SagaIterator<void> {
    const product = yield select(PurchaseSelectors.getProduct);
    if (!product) return;
    const stateToPersist = yield call(getPurchaseState, product);
    yield call(Storage.setItem, PURCHASE_PERSIST_KEY, JSON.stringify(stateToPersist));
}

export function* startPersistingState(): SagaIterator<void> {
    const product = yield select(PurchaseSelectors.getProduct);
    if (!product) return;

    yield call(persistPurchaseState);
    yield take([
        PLAYED_BET,
        PurchaseActions.HARRY_SUBSCRIPTION_PURCHASED,
        PurchaseActions.FINISH_PURCHASE_FLOW,
        DepositActionConstants.DEPOSIT_SUCCESS,
        DepositActionConstants.DEPOSIT_FAILURE,
    ]);
    yield call(clearPurchaseState);
}

export default function* persistPurchaseSaga(): SagaIterator<void> {
    yield takeLatest(DepositActionConstants.DEPOSIT_MONEY, startPersistingState);
    yield takeLatest(PurchaseActions.LOAD_PURCHASE_STATE, loadPurchaseState);
    yield takeLatest(
        [
            PLAYED_BET,
            PurchaseActions.HARRY_SUBSCRIPTION_PURCHASED,
            PurchaseActions.FINISH_PURCHASE_FLOW,
            DepositActionConstants.DEPOSIT_SUCCESS,
            DepositActionConstants.DEPOSIT_FAILURE,
        ],
        clearPurchaseState,
    );
}
