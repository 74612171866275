// eslint-disable-next-line @nx/enforce-module-boundaries
import {CouponActions} from "@atg-horse-shared/coupon";

import {
    REQUEST_HARRY_SUBSCRIPTION_AVAILABLE,
    RECEIVE_HARRY_SUBSCRIPTION_AVAILABLE,
} from "./harryActions";

export const setHarrySubscriptionAmount = (cid: string, amount: number) => ({
    type: CouponActions.SET_HARRY_SUBSCRIPTION_AMOUNT,
    payload: {cid, amount},
});

export const setHarrySubscriptionDeliveryOption = (
    cid: string,
    deliveryOption: string,
) => ({
    type: CouponActions.SET_HARRY_SUBSCRIPTION_DELIVERY_OPTION,
    payload: {cid, deliveryOption},
});

export const setHarryBag = (
    cid: string,
    bag: {
        [key: string]: any;
    },
    version: number,
) => ({
    type: CouponActions.SET_HARRY_BAG,
    payload: {
        bag: {
            ...bag,
            version,
        },
        cid,
    },
});

export const setHarrySubscriptionSelected = (
    cid: string,
    subscriptionSelected: boolean,
    subscriptionCost: number,
) => ({
    type: CouponActions.SET_HARRY_SUBSCRIPTION_SELECTED,
    payload: {
        subscriptionSelected,
        subscriptionCost,
        cid,
    },
});

export const requestHarrySubscriptionAvailable = () => ({
    type: REQUEST_HARRY_SUBSCRIPTION_AVAILABLE,
});

export const receiveHarrySubscriptionAvailable = (
    harrySubscriptionAvailable: boolean,
) => ({
    type: RECEIVE_HARRY_SUBSCRIPTION_AVAILABLE,
    payload: {
        harrySubscriptionAvailable,
    },
});

export const receiveHarrySubscriptionAvailableError = (status: {[key: string]: any}) => ({
    type: RECEIVE_HARRY_SUBSCRIPTION_AVAILABLE,
    error: true,
    payload: {status},
});
