import type {SerializedStyles} from "@emotion/react";
import * as React from "react";

type Props = {
    /** @deprecated use emotion@10 and the `style` prop instead */
    className?: string;

    style?: SerializedStyles;
};

function BoostLogo({className, style}: Props) {
    return (
        <svg
            css={style}
            viewBox="0 0 1363.8 358.2"
            preserveAspectRatio="xMinYMin meet"
            // TODO: remove `className` prop forwarding when mirated to emotion@10
            className={className}
        >
            <g>
                <path d="M1153.2,61l-98.8,236.2H1265L1363.8,61C1363.8,61,1153.2,61,1153.2,61z M1300.9,127.1h-39l-66.8,148.8h-58l66.6-148.8H1145l3.3-7.4c15.3-34.2,21.1-35.2,58.4-35.2h127.8l-15.5,34.7C1315.8,126,1312.7,127.1,1300.9,127.1z" />
                <path d="M182.5,124.4h-28.8l-15.3,36.7h23.8c21.9,0,28.4-4.4,33.7-17.5c0.8-1.9,1.4-4.2,1.7-5.3C200.2,128.6,196.6,124.4,182.5,124.4z" />
                <path d="M157,193.4h-28.8L113,230h21.3c16.4,0,30.3-3.4,36.5-17.4c0.7-1.6,1.7-4.2,2.1-5.7C175.7,196.2,169.7,193.4,157,193.4z" />
                <path d="M98.8,61L0,297.2h211.2L310,61C310,61,98.8,61,98.8,61z M248.6,139.4c-8.6,21.6-22.2,33-42.7,39.3c12.4,5.8,19.3,18.2,13.7,38.5c-0.1,0.5-0.7,2.4-1.8,5.5c-13.3,37-39,52.8-88.6,52.8H38.7l78.9-190.8h83.1c43,0,58.9,16.1,50.9,45.4C251.1,132,250.3,135.1,248.6,139.4z" />
                <path d="M338.2,37.4L219.6,320.8h242.7L580.9,37.4H338.2z M496.8,179.5c-30.3,71.3-51,112.6-139.6,112.6c-72.7,0-82.5-26.9-70.4-69.8c3.7-13,9.2-27.2,15.8-42.9c30.8-72.7,51.2-112.8,141.3-112.8c70.5,0,81.1,26.6,68.8,70.1C509,149.6,503.5,163.8,496.8,179.5z" />
                <path d="M425.4,118.8c-33.2,0-38.4,11.8-59.4,60.7c-6.8,16.3-11.7,28.4-14.3,37.4c-5,17.8,0.1,22.9,22.1,22.9c33.2,0,39-11.5,59.6-60.4c6.8-16.3,12-29.3,14.3-37.4C452.8,124.3,447.8,118.8,425.4,118.8z" />
                <path d="M935,37.4L816.4,320.8H1026l118.6-283.5L935,37.4L935,37.4z M1086.9,116.5H1010c-14.4,0-21.5,2.5-24.2,11.8c-2.1,7.5,6.1,12.5,25,31.8c26.1,26.8,39.1,45.4,30.9,74.4c-1.1,4-2.7,8.4-4.6,13.1c-11,24.3-29.8,47.6-106.6,47.6c-56.4,0-73.7-7.8-65.2-38c1.3-4.7,3.1-10,5.8-15.9h75.3c18.9,0,25.8-1.9,28.6-11.8c2.6-9.3-5.1-13.7-27.9-36.1c-22.6-22.1-34.8-38-27-65.7c1.3-4.7,3.4-9.6,5.9-15.3c10.9-24,31-49.8,103.7-49.8c58,0,73.4,7.8,66.6,31.7C1094.6,100.6,1091.3,107.8,1086.9,116.5z" />
                <path d="M614.8,0L465,358.2h317.3L932.2,0H614.8z M827.6,179.2c-40.5,95.1-68.1,150.3-186.3,150.3c-97,0-110.1-35.9-94-93.1c4.9-17.3,12.3-36.3,21.1-57.2c41.1-97.1,68.2-150.7,188.4-150.7c94.1,0,108.2,35.5,91.8,93.5C843.8,139.3,836.4,158.3,827.6,179.2z" />
                <path d="M732.2,98.2c-44.4,0-51.3,15.7-79.2,81c-9,21.8-15.7,37.9-19.1,50c-6.7,23.8,0.1,30.6,29.5,30.6c44.4,0,52-15.3,79.5-80.6c9-21.8,16-39.1,19.1-50C768.7,105.5,762.1,98.2,732.2,98.2z" />
            </g>
        </svg>
    );
}

export default BoostLogo;
