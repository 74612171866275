import {filter, groupBy, isEmpty, some, uniqBy, memoize} from "lodash";
import {
    ERROR,
    SUBMIT_ERROR,
    SUBMIT_WARNING,
    type Message,
    type MessageId,
    type MessageLevel,
} from "@atg-horse-shared/coupon-messages";
import {FixedSizeMap} from "@atg/utils";

export type CouponValidation = {
    messages: Array<MessageId>;
    couponMessageIds: Array<MessageId>;
    messagesById: {
        [key in MessageId]: Message;
    };
    messagesByRaceId: {
        [key: string]: Array<Message>;
    };
    messagesByStartId: {
        [key: string]: Array<Message>;
    };
};

type MessagesByLevel = {
    [key in MessageLevel]: Array<Message>;
};

type GetMessagesFunction = (couponValidation: CouponValidation) => Array<Message>;

const EMPTY_VALIDATION: CouponValidation = {
    messages: [],
    couponMessageIds: [],
    messagesById: {},
    messagesByRaceId: {},
    messagesByStartId: {},
};

export function create() {
    return EMPTY_VALIDATION;
}

function concatMessageId(
    messages: Array<MessageId>,
    messageId: MessageId,
): Array<MessageId> {
    return messages ? messages.concat(messageId) : [messageId];
}

function concatMessages(messages: Array<Message>, message: Message) {
    return messages ? messages.concat(message) : [message];
}

/**
 * Show a warning or error message to the user when the coupon is not filled in correctly.
 *
 * @param couponValidation the current "validation state"
 * @param message the error message that should be shown to the user
 * @param raceId ID of the race that has the error (if applicable)
 * @param startId ID of the start (horse) that has the error (if applicable)
 */
export function addMessage(
    couponValidation: CouponValidation,
    message: Message | undefined | null,
    raceId?: string,
    startId?: string,
) {
    if (!message) return couponValidation;

    const messageId = message.id;
    const messages = concatMessageId(couponValidation.messages, messageId);
    const {messagesByRaceId} = couponValidation;
    const raceIdMessages = raceId ? messagesByRaceId[raceId] : [];
    const updatedMessagesByRaceId = raceId
        ? {
              ...messagesByRaceId,
              [raceId]: concatMessages(raceIdMessages, message),
          }
        : messagesByRaceId;

    const {messagesByStartId} = couponValidation;
    const startIdMessages = startId ? messagesByStartId[startId] : [];
    const updatedMessagesByStartId = startId
        ? {
              ...messagesByStartId,
              [startId]: concatMessages(startIdMessages, message),
          }
        : messagesByStartId;

    const messagesById = {
        ...couponValidation.messagesById,
        [messageId]: message,
    };

    const {couponMessageIds} = couponValidation;
    const updatedCouponMessageIds =
        !raceId && !startId
            ? concatMessageId(couponMessageIds, messageId)
            : couponMessageIds;

    return {
        ...couponValidation,
        messages,
        messagesById,
        messagesByRaceId: updatedMessagesByRaceId,
        messagesByStartId: updatedMessagesByStartId,
        couponMessageIds: updatedCouponMessageIds,
    };
}

export const getMessagesForRaceId = (
    couponValidation: CouponValidation,
    raceId: string,
    prefix?: string,
) => {
    const messagesForRace = couponValidation.messagesByRaceId[raceId];
    if (isEmpty(messagesForRace)) return [];
    if (!prefix) return messagesForRace;

    return filter(
        messagesForRace,
        (message) => Boolean(message.context) && message.context.prefix === prefix,
    );
};

const getMessagesForStartId = (
    couponValidation: CouponValidation,
    startId: string,
    prefix?: string,
) => {
    const messagesForStart = couponValidation.messagesByStartId[startId];
    if (isEmpty(messagesForStart)) return [];
    if (!prefix) return messagesForStart;

    return filter(
        messagesForStart,
        (message) => Boolean(message.context) && message.context.prefix === prefix,
    );
};

export const getUniqueMessages = memoize<GetMessagesFunction>(
    (couponValidation: CouponValidation): Array<Message> =>
        couponValidation
            ? uniqBy(
                  couponValidation.messages.map(
                      (messageId) => couponValidation.messagesById[messageId],
                  ),
                  "text",
              )
            : [],
    (couponValidation: CouponValidation): string => {
        if (!couponValidation) return "";

        return couponValidation.messages
            .map((messageId) => couponValidation.messagesById[messageId].text)
            .join("_");
    },
);

getUniqueMessages.cache = new FixedSizeMap(3);

export const hasStartError = (
    couponValidation: CouponValidation,
    startId: string,
    prefix?: string,
) => {
    const messagesForStart = getMessagesForStartId(couponValidation, startId, prefix);

    return some(messagesForStart, (message: Message) => message.level === ERROR);
};

const getMessagesByLevel = (couponValidation: CouponValidation): MessagesByLevel =>
    // @ts-expect-error
    groupBy(getUniqueMessages(couponValidation), (message) => message.level);

export const getErrorMessages = (couponValidation: CouponValidation): Array<Message> =>
    getMessagesByLevel(couponValidation)[ERROR];

export const getSubmitErrorMessages = (
    couponValidation: CouponValidation,
): Array<Message> => getMessagesByLevel(couponValidation)[SUBMIT_ERROR];

export const getSubmitWarningMessages = (
    couponValidation: CouponValidation,
): Array<Message> => getMessagesByLevel(couponValidation)[SUBMIT_WARNING];

export const getMessagesWithoutSubmitWarningMessages = memoize<GetMessagesFunction>(
    (couponValidation: CouponValidation): Array<Message> =>
        getUniqueMessages(couponValidation).filter(
            (message) => message.level !== SUBMIT_WARNING,
        ),
    (couponValidation: CouponValidation): string =>
        getUniqueMessages(couponValidation).reduce(
            (acc: string, message: Message): string => {
                if (message.level !== SUBMIT_WARNING) {
                    return `${acc}_${message.id}_${message.text}`;
                }

                return acc;
            },
            "",
        ),
);

getMessagesWithoutSubmitWarningMessages.cache = new FixedSizeMap(3);

export const getMessagesWithoutSubmitMessages = (
    couponValidation: CouponValidation,
): Array<Message> =>
    getMessagesWithoutSubmitWarningMessages(couponValidation).filter(
        (message) => message.level !== SUBMIT_ERROR,
    );

export const hasErrors = (couponValidation: CouponValidation) =>
    Boolean(getErrorMessages(couponValidation));

export const hasSubmitErrors = (couponValidation: CouponValidation) =>
    Boolean(getSubmitErrorMessages(couponValidation));

export const hasSubmitWarnings = (couponValidation: CouponValidation) =>
    Boolean(getSubmitWarningMessages(couponValidation));

export const isDisabledByRules = (couponValidation: CouponValidation) =>
    hasErrors(couponValidation) || hasSubmitErrors(couponValidation);
