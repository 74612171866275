import * as React from "react";
import * as styles from "./HorseFavoritesToast.styles";

const getText = (type: string) => {
    if (type === "add") {
        return "Tillagd i Bevakningar";
    }
    if (type === "remove") {
        return "Borttagen från Bevakningar";
    }
    return "";
};

type Props = {
    type: "add" | "remove";
    showLink: boolean;
};

function HorseFavoritesToast({type, showLink}: Props) {
    return (
        <div css={styles.notification}>
            <p css={styles.notificationMessage}>{getText(type)}</p>
            {showLink && (
                <a css={styles.link} href="/konto/bevakningar">
                    Visa
                </a>
            )}
        </div>
    );
}

export default HorseFavoritesToast;
