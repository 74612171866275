import {combineReducers} from "redux";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {PurchaseActions} from "@atg-horse-shared/purchase";
import type {SharedBetPurchaseParams} from "./sharedBet";
import {
    SHARED_BET_PURCHASE_SET_PARAMS,
    SHARED_BET_OPEN_NOT_FOUND_MODAL,
    SHARED_BET_CLOSE_NOT_FOUND_MODAL,
    type PurchaseParamsAction,
    type SharedBetNotFoundModalAction,
    type SharedBetMaxSharesEditModalAction,
    SHARED_BET_OPEN_MAX_SHARES_EDIT_MODAL,
    SHARED_BET_CLOSE_MAX_SHARES_EDIT_MODAL,
} from "./sharedBetActions";

export type SharedBetPurchaseReducer = {
    params: SharedBetPurchaseParams;
};

const initialParamsState = {
    nrShares: 1,
};

const isNotFoundModalOpen = (
    state = false,
    action: SharedBetNotFoundModalAction,
): boolean => {
    switch (action.type) {
        case SHARED_BET_OPEN_NOT_FOUND_MODAL:
            return true;
        case SHARED_BET_CLOSE_NOT_FOUND_MODAL:
            return false;
        default:
            return state;
    }
};

const isMaxSharesEditModalOpen = (
    state = false,
    action: SharedBetMaxSharesEditModalAction,
): boolean => {
    switch (action.type) {
        case SHARED_BET_OPEN_MAX_SHARES_EDIT_MODAL:
            return true;
        case SHARED_BET_CLOSE_MAX_SHARES_EDIT_MODAL:
            return false;
        default:
            return state;
    }
};

const params = (
    state: SharedBetPurchaseParams = initialParamsState,
    action: PurchaseParamsAction,
) => {
    const {type, payload} = action;

    switch (type) {
        case SHARED_BET_PURCHASE_SET_PARAMS:
            return payload;
        // @ts-expect-error
        case PurchaseActions.FINISH_PURCHASE_FLOW:
            return initialParamsState;
        default:
            return state;
    }
};

export default combineReducers({
    params,
    isNotFoundModalOpen,
    isMaxSharesEditModalOpen,
});
