import type {AtgErrorResponse} from "@atg-shared/fetch-types";

export enum Reason {
    CONDITIONS_TOO_STRICT = "CONDITIONS_TOO_STRICT",
    STAKE_TOO_HIGH = "STAKE_TOO_HIGH",
    STAKE_TOO_LOW = "STAKE_TOO_LOW",
    GENERATOR_NOT_READY = "GENERATOR_NOT_READY",
    INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
    RGS_DEPOSIT_LIMIT_NOT_SET = "RGS_DEPOSIT_LIMIT_NOT_SET",
    DEPOSIT_LIMIT_NOT_SET = "DEPOSIT_LIMIT_NOT_SET",
    RGS_SELF_EXCLUSION = "RGS_SELF_EXCLUSION",
    SELF_EXCLUSION = "SELF_EXCLUSION",
    TEMPORARY_SELF_EXCLUSION = "TEMPORARY_SELF_EXCLUSION",
    RGS_NAR_EXCLUSION = "RGS_NAR_EXCLUSION",
    NAR_EXCLUSION = "NAR_EXCLUSION",
    RGS_NAR_UNDETERMINED = "RGS_NAR_UNDETERMINED",
    NAR_UNDETERMINED = "NAR_UNDETERMINED",
    NETLOSS_LIMIT_EXCEEDED = "NETLOSS_LIMIT_EXCEEDED",
    KYC_QUESTIONNAIRE_NOT_SUBMITTED = "KYC_QUESTIONNAIRE_NOT_SUBMITTED",
    SELL_NOT_OPEN = "SELL_NOT_OPEN",
}

const genericError =
    "Osäker status på ditt spel. Var god uppdatera och kontrollera lagda spel under Mitt konto > Mina pengar > Kontohistorik.";

export const get400ErrorMessageFromReason = (reason?: Reason) => {
    switch (reason) {
        case Reason.SELL_NOT_OPEN:
            return "Stängt för spel.";
        case Reason.GENERATOR_NOT_READY:
            return "Ett tekniskt fel uppstod, försök igen senare.";
        case Reason.CONDITIONS_TOO_STRICT:
            return "Du har inte gett Harry tillräckligt med utrymme att jobba. Prova att höja insatsen eller ta bort några hästar.";
        case Reason.STAKE_TOO_HIGH:
            return "För hög insats";
        case Reason.STAKE_TOO_LOW:
            return "För låg insats markerad för spelform på denna tävling. Särskilda regler gäller.";
        case Reason.INSUFFICIENT_FUNDS:
            return "Ditt saldo är för lågt för att lägga spelet.";
        case Reason.RGS_DEPOSIT_LIMIT_NOT_SET:
        case Reason.DEPOSIT_LIMIT_NOT_SET:
            return "Nya regler för spel. Du behöver sätta en insättningsgräns innan du kan lägga ditt spel.";
        case Reason.RGS_SELF_EXCLUSION:
        case Reason.SELF_EXCLUSION:
            return "Ditt konto är spärrat för spel.";
        case Reason.TEMPORARY_SELF_EXCLUSION:
            return "Ditt konto är tillfälligt spärrat för spel. Vänligen kontakta ATG Kundservice.";
        case Reason.RGS_NAR_EXCLUSION:
        case Reason.NAR_EXCLUSION:
            return "Ditt konto är spärrat via Spelpaus.se.";
        case Reason.RGS_NAR_UNDETERMINED:
        case Reason.NAR_UNDETERMINED:
            return "Tjänsten spelpaus.se svarar inte just nu. Om du inte har stängt av dig för spel hos spelpaus.se vänligen logga ut och in på nytt.";
        case Reason.NETLOSS_LIMIT_EXCEEDED:
            return "Du har nått din förlustgräns. Kostnaden för spelet överskrider din satta förlustgräns.";
        case Reason.KYC_QUESTIONNAIRE_NOT_SUBMITTED:
            return "Du behöver besvara en kundkännedomsenkät under Mina pengar på atg.se";
        default:
            return genericError;
    }
};

export const get400ShopErrorMessage = (response: AtgErrorResponse): string => {
    if (!response.data) return genericError;

    const reason = response.data.reason as Reason;

    return get400ErrorMessageFromReason(reason);
};

export const getShopShareResponseStatus = (response: AtgErrorResponse) => {
    const errorCodes = {
        AMOUNT_NOT_ALLOWED: "Ej tillåtet belopp.",
        TRY_AGAIN: "Tekniskt fel, försök igen.",
        RACE_CLOSED_V1: "Spel till detta lopp är stängt.",
        RACE_CLOSED_V2: "Spel mot denna tävling är stängt.",
        RACE_CLOSED_V3: "Spel mot detta program är stängt.",
        V75_CLOSED: "V75-spelet är avstängt.",
        V86_CLOSED: "V86-spelet är avstängt.",
        GS75_CLOSED: "GS75-spelet är avstängt.",
        OBSOLETE_MARKS: "Spel på struken häst.",
        INVALID_MARKS: "Spel på ogiltig häst.",
        TOO_HIGH_AMOUNT: "För hög insats.",
        TOO_LOW_AMOUNT:
            "För låg insats markerad för spelform på denna tävling. Särskilda regler gäller.",
        PAYOUT_NOT_POSSIBLE_V1: "Utbetalning ej möjlig.",
        PAYOUT_NOT_POSSIBLE_V2: "Utbetalning ej möjlig.",
        PAYOUT_NOT_POSSIBLE_V3: "Utbetalning ej möjlig.",
        PAYOUT_NOT_POSSIBLE_V4: "Utbetalning ej möjlig.",
        MANUAL_PAYOUT: "Manuell utbetalning.",
        OBSOLETE_GAME_V1: "För gammalt spel.",
        OBSOLETE_GAME_V2: "För gammalt spel.",
        BETTING_CLOSED: "Spel är ej tillåtet just nu, kontrollera öppettider.",
        ACCOUNT_GAMES_CLOSED: "Kontospel avstängt.",
        INSUFFICIENT_FUNDS: "Ej tillräckligt med pengar på kontot.",
        BETTING_SYSTEM_CLOSED: "Stängt för spel.",
        SYSTEM_LIMIT_EXCEEDED: "Systemgräns nådd.",
        UNKNOWN_ERROR: "Tekniskt fel.",
    };

    // @ts-ignore errorCode seems to exist in some responses. Response was previously set to type Object in flow.
    const errorCode = errorCodes[response.data.errorCode] || "Tekniskt fel.";

    const status = {
        code: "failed",
        isLoading: false,
        error: {
            httpCode: response.meta.code,
            message: errorCode,
        },
    };

    return status;
};
