import * as React from "react";
import type {SagaIterator} from "redux-saga";
import {takeLatest, put, call, select, take, all, takeLeading} from "redux-saga/effects";
import log, {serializeError} from "@atg-shared/log";
import {AuthActions, AuthSelectors} from "@atg-shared/auth";
import {notification} from "@atg-global-shared/notification";
import Toast from "../components/HorseFavoritesToast";
import * as styles from "../components/HorseFavoritesToast.styles";
import * as HorseFavoritesActions from "./horseFavoritesActions";
import * as HorseFavoritesSelectors from "./horseFavoritesSelectors";
import * as HorseFavoritesApi from "./horseFavoritesApi";

const notificationOptions = {
    icon: false,
    position: notification.POSITION.BOTTOM_RIGHT,
    autoClose: 4000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    closeButton: false,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
};

export function* getHorseInfo(
    favoriteHorse: HorseFavoritesActions.FavoriteHorse,
): SagaIterator<Record<string, unknown>> {
    try {
        const res = yield call(HorseFavoritesApi.getUpcomingStarts, favoriteHorse.id);

        return {
            id: favoriteHorse.id,
            name: res.data.name,
            sport: res.data.sport,
            upcomingStarts: res.data.upcomingStarts,
            created: favoriteHorse.created,
        };
    } catch (error: unknown) {
        yield put(HorseFavoritesActions.setError());
        log.error("horseFavoriteSaga:getHorseInfo error. ", {
            id: favoriteHorse.id,
            error: serializeError(error),
        });
        return {};
    }
}

export function* getFavoriteHorsesWithAllInfo(): SagaIterator<void> {
    const hasError = yield select(HorseFavoritesSelectors.getHasError);
    if (hasError) {
        yield put(HorseFavoritesActions.clearError());
    }
    yield put(HorseFavoritesActions.loadingStarted());
    try {
        const favorites = yield call(HorseFavoritesApi.getFavoriteHorses);
        const horsesWithCompleteInfo = yield all(
            favorites.data.map((favorite: HorseFavoritesApi.Favorite) =>
                call(getHorseInfo, favorite),
            ),
        );

        yield put(HorseFavoritesActions.storeAllFavoriteHorses(horsesWithCompleteInfo));
        yield put(HorseFavoritesActions.loadingFinished());
    } catch (error: unknown) {
        yield put(HorseFavoritesActions.setError());
        yield put(HorseFavoritesActions.storeAllFavoriteHorses([]));
        yield put(HorseFavoritesActions.loadingFinished());
        log.error("horseFavoriteSaga:getFavoriteHorsesWithAllInfo error. ", {
            error: serializeError(error),
        });
    }
}

export function* getOnlyFavoriteHorses(): SagaIterator<void> {
    try {
        const favorites = yield call(HorseFavoritesApi.getFavoriteHorses);
        const filteredData = favorites.data.map((favorite: any) => ({
            id: favorite.id,
        }));
        yield put(HorseFavoritesActions.storeAllFavoriteHorses(filteredData));
    } catch (error: unknown) {
        log.error("horseFavoriteSaga:getOnlyFavoriteHorses error. ", {
            error: serializeError(error),
        });
    }
}

export function* getFavoritesPreferences(): SagaIterator<void> {
    try {
        const preferencesResponse: {data: HorseFavoritesActions.Preferences} = yield call(
            HorseFavoritesApi.getFavoritesPreferences,
        );
        const {notificationMethod} = preferencesResponse.data;
        if (!notificationMethod) return;
        yield put(
            HorseFavoritesActions.storeFavoritesPreferences({
                notificationMethod,
            }),
        );
    } catch (error: unknown) {
        log.error("horseFavoriteSaga:getFavoritesPreferences error. ", {
            error: serializeError(error),
        });
    }
}

export function* setFavoritesPreferences(
    action: HorseFavoritesActions.SetFavoritesPreferencesAction,
): SagaIterator<void> {
    try {
        const setFavoriteResponse = yield call(
            HorseFavoritesApi.setFavoritesPreferences,
            action.preferences,
        );
        yield put(
            HorseFavoritesActions.storeFavoritesPreferences({
                notificationMethod: setFavoriteResponse.data.notificationMethod,
            }),
        );
    } catch (error: unknown) {
        log.error("horseFavoriteSaga:setFavoritesPreferences error. ", {
            error: serializeError(error),
        });
    }
}

export function* addFavoriteHorse(
    action: HorseFavoritesActions.AddFavoriteHorseAction,
): SagaIterator<void> {
    try {
        yield call(HorseFavoritesApi.addFavoriteHorse, action.horse.id);
        yield put(HorseFavoritesActions.storeFavoriteHorse(action.horse));
        yield call(getOnlyFavoriteHorses);

        const favoritesToast = <Toast type="add" showLink />;

        yield call(notification.dark, favoritesToast, {
            ...notificationOptions,
            toastId: `add-${action.horse.id}`,
            style: styles.wrapper,
        });
    } catch (error: unknown) {
        log.error("horseFavoriteSaga:addFavoriteHorse error. ", {
            id: action.horse.id,
            error: serializeError(error),
        });
    }
}

export function* removeFavoriteHorseFromStartlist(
    action: HorseFavoritesActions.RemoveFavoriteHorseFromStartlistAction,
): SagaIterator<void> {
    try {
        yield call(HorseFavoritesApi.removeFavoriteHorse, action.horseId);
        yield put(HorseFavoritesActions.removeFavoriteHorseFromStore(action.horseId));
        yield call(getOnlyFavoriteHorses);
        const favoritesToast = <Toast type="remove" showLink />;

        yield call(notification.dark, favoritesToast, {
            ...notificationOptions,
            toastId: `remove-${action.horseId}`,
            style: styles.wrapper,
        });
    } catch (error: unknown) {
        log.error("horseFavoriteSaga:removeFavoriteHorseFromStartlist error. ", {
            id: action.horseId,
            error: serializeError(error),
        });
    }
}

export function* removeFavoriteHorseFromFavoritesPage(
    action: HorseFavoritesActions.RemoveFavoriteHorseFromFavoritesPageAction,
): SagaIterator<void> {
    try {
        yield call(HorseFavoritesApi.removeFavoriteHorse, action.horseId);
        yield put(HorseFavoritesActions.removeFavoriteHorseFromStore(action.horseId));
        const favoritesToast = <Toast type="remove" showLink={false} />;

        yield call(notification.dark, favoritesToast, {
            ...notificationOptions,
            toastId: `remove-${action.horseId}`,
            style: styles.wrapper,
        });
    } catch (error: unknown) {
        log.error("horseFavoriteSaga:removeFavoriteHorseFromFavoritesPage error. ", {
            id: action.horseId,
            error: serializeError(error),
        });
    }
}

export function* notificationPreferences(
    action: HorseFavoritesActions.StartNotificationPreferences,
): SagaIterator<void> {
    // open the preference modal
    yield put(HorseFavoritesActions.showHorseFavoritesSettingsModal());

    // wait until user sets the preferences
    const response = yield take([
        HorseFavoritesActions.SET_FAVORITES_PREFERENCES,
        HorseFavoritesActions.CLOSE_HORSE_FAVORITES_SETTINGS_MODAL,
    ]);

    const userSavedPreferences =
        response.type === HorseFavoritesActions.SET_FAVORITES_PREFERENCES;

    if (userSavedPreferences) {
        // proceed with action
        yield put(HorseFavoritesActions.addFavoriteHorse(action.horse));
    }
}

export function* handleFavoriteHorse(
    action: HorseFavoritesActions.AddFavoriteHorseAction,
): SagaIterator<void> {
    const isLoggedIn: boolean = yield select(AuthSelectors.isLoggedIn);
    if (!action.horse.id) return;

    if (isLoggedIn) {
        const preferences = yield select(HorseFavoritesSelectors.getPreferences);
        let fetchedPreferences;

        if (!preferences || !preferences.notificationMethod) {
            const res = yield call(HorseFavoritesApi.getFavoritesPreferences);
            yield put(HorseFavoritesActions.storeFavoritesPreferences(res.data));
            fetchedPreferences = res.data;
        }

        if (
            (preferences && preferences.notificationMethod !== "NOT_SET") ||
            (fetchedPreferences && fetchedPreferences?.notificationMethod !== "NOT_SET")
        ) {
            const isHorseMarkedAsFavorite = yield select(
                HorseFavoritesSelectors.isHorseMarkedAsFavorite(action.horse.id),
            );
            if (isHorseMarkedAsFavorite) {
                yield put(
                    HorseFavoritesActions.removeFavoriteHorseFromStartlist(
                        action.horse.id,
                    ),
                );
            } else {
                yield put(HorseFavoritesActions.addFavoriteHorse(action.horse));
            }
        } else {
            yield put(HorseFavoritesActions.startNotificationPreferences(action.horse));
        }

        return;
    }
    yield put(AuthActions.checkAuth());
}

export default function* favoriteSaga() {
    yield takeLeading(HorseFavoritesActions.HANDLE_FAVORITE_HORSE, handleFavoriteHorse);
    yield takeLatest(HorseFavoritesActions.ADD_FAVORITE_HORSE, addFavoriteHorse);
    yield takeLatest(
        HorseFavoritesActions.REMOVE_FAVORITE_HORSE_FROM_FAVORITES_PAGE,
        removeFavoriteHorseFromFavoritesPage,
    );
    yield takeLatest(
        HorseFavoritesActions.REMOVE_FAVORITE_HORSE_FROM_STARTLIST,
        removeFavoriteHorseFromStartlist,
    );
    yield takeLatest(
        HorseFavoritesActions.GET_ONLY_FAVORITE_HORSES,
        getOnlyFavoriteHorses,
    );
    yield takeLatest(
        HorseFavoritesActions.GET_FAVORITE_HORSES_WITH_ALL_INFO,
        getFavoriteHorsesWithAllInfo,
    );
    yield takeLatest(
        HorseFavoritesActions.SET_FAVORITES_PREFERENCES,
        setFavoritesPreferences,
    );
    yield takeLatest(
        HorseFavoritesActions.GET_FAVORITES_PREFERENCES,
        getFavoritesPreferences,
    );
    yield takeLatest(
        HorseFavoritesActions.START_NOTIFICATION_PREFERENCES,
        notificationPreferences,
    );
}
