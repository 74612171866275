import type {Coupon} from "@atg-horse-shared/coupon-types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {CouponSortOption} from "@atg-shop-shared/shop-coupon-utils/src/couponSort";

export const STARTED_COUPONS_IMPORT = "shop/startedCoupons/import";
export const STARTED_COUPONS_IMPORT_SUCCESS = "shop/startedCoupons/importSuccess";
export const STARTED_COUPONS_IMPORT_FAILURE = "shop/startedCoupons/importFailure";
export const STARTED_COUPONS_IMPORT_RESET = "shop/startedCoupons/importReset";

export const CHANGE_SORTING = "shop/startedCoupons/changeSorting";

export type StartedCouponsImportPayload = {
    fromCoupon: Coupon;
    toCoupon: Coupon;
};
export type StartedCouponsImportAction = {
    type: typeof STARTED_COUPONS_IMPORT;
    payload: StartedCouponsImportPayload;
};

export type StartedCouponsImportSuccessAction = {
    type: typeof STARTED_COUPONS_IMPORT_SUCCESS;
};

export type StartedCouponsImportFailureAction = {
    type: typeof STARTED_COUPONS_IMPORT_FAILURE;
    payload: string;
};

type StartedCouponsImportResetAction = {
    type: typeof STARTED_COUPONS_IMPORT_RESET;
};

type ChangeSortingAction = {
    type: typeof CHANGE_SORTING;
    payload: CouponSortOption;
};

export type Action =
    | StartedCouponsImportAction
    | StartedCouponsImportSuccessAction
    | StartedCouponsImportFailureAction
    | StartedCouponsImportResetAction
    | ChangeSortingAction;

export const importCoupon = (
    fromCoupon: Coupon,
    toCoupon: Coupon,
): StartedCouponsImportAction => ({
    type: STARTED_COUPONS_IMPORT,
    payload: {fromCoupon, toCoupon},
});

export const importCouponFailure = (
    payload: string,
): StartedCouponsImportFailureAction => ({
    type: STARTED_COUPONS_IMPORT_FAILURE,
    payload,
});

export const importCouponSuccess = (): StartedCouponsImportSuccessAction => ({
    type: STARTED_COUPONS_IMPORT_SUCCESS,
});

export const importCouponReset = (): StartedCouponsImportResetAction => ({
    type: STARTED_COUPONS_IMPORT_RESET,
});

export const changeSorting = (payload: CouponSortOption): ChangeSortingAction => ({
    type: CHANGE_SORTING,
    payload,
});
