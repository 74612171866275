import root from "window-or-global";

const env = root?.clientConfig?.env ?? "qa";

const atgAuthApiUrl = `${env !== "prod" ? `${env}.hh.` : ""}`;

const atgPublicApiUrl = `${env !== "prod" ? `api.${env}.hh` : "api"}`;

export const AUTH_URL = `https://www.${atgAuthApiUrl}atg.se/services/tokenized-proxy/elli-gateway/api/horse`;

export const PUBLIC_URL = `https://${atgPublicApiUrl}.atg.se/elli-gateway/api-public/horse`;
