import {omit} from "lodash/fp";
import * as LiveActions from "../liveactions";

export type ShowRaceAttributes = Record<string, boolean>;
export type LiveShowRacesState = {
    resultTimer: ShowRaceAttributes;
    ongoing: ShowRaceAttributes;
};

export function liveShowRaces(
    state: LiveShowRacesState = {resultTimer: {}, ongoing: {}},
    action: {type: string; payload: {id: string}},
) {
    const {type, payload} = action;
    switch (type) {
        case LiveActions.RESULT_TIMER_STARTED:
            return {
                ...state,
                resultTimer: {
                    [payload.id]: true,
                },
            };
        case LiveActions.RESULT_TIMER_ELAPSED:
            return {
                ...state,
                resultTimer: omit([payload.id], state.resultTimer),
            };
        case LiveActions.ONGOING_RACE:
            return {
                ...state,
                ongoing: {
                    [payload.id]: true,
                },
            };
        case LiveActions.FINISHED_RACE:
            return {
                ...state,
                ongoing: omit([payload.id], state.ongoing),
            };
        default:
            return state;
    }
}
