import {broadcastAction} from "atg-store-addons";
import type {
    NativeExperienceActionPayload,
    NativeExperienceTriggeredAction,
} from "@atg-global-shared/personalization-types";
// eslint-disable-next-line import/no-extraneous-dependencies
import {NATIVE_EXPERIENCE_TRIGGERED} from "@atg-global-shared/personalization-types";

export const nativeExperienceTriggered = (
    payload: NativeExperienceActionPayload,
): NativeExperienceTriggeredAction =>
    broadcastAction({
        type: NATIVE_EXPERIENCE_TRIGGERED,
        payload,
    });
