import {fetchSelectors} from "@atg-shared/fetch-redux";
import type {
    ContactInfoState,
    GlobalAccountSettingsState,
} from "@atg-payment-shared/account-settings-types";

export const getChangeAvatarModalState = (state: GlobalAccountSettingsState): boolean =>
    state.accountSettings.triggerChangeAvatarModal;
export const getError = (state: GlobalAccountSettingsState): string =>
    state.accountSettings.error;

const getContactInfoState = (state: GlobalAccountSettingsState): ContactInfoState =>
    state.accountSettings.contactInfo;

export const {
    isLoading: isContactInfoLoading,
    isLoaded: isContactInfoLoaded,
    hasError: hasContactInfoError,
} = fetchSelectors.createSelectors(getContactInfoState);

export const getContactInfo = (state: GlobalAccountSettingsState) =>
    getContactInfoState(state).data;

const getContactOptionsState = (state: GlobalAccountSettingsState) =>
    state.accountSettings.contactOptions;

export const {
    isLoading: isContactOptionsLoading,
    isLoaded: isContactOptionsLoaded,
    hasError: hasContactOptionsError,
} = fetchSelectors.createSelectors(getContactOptionsState);

export const getContactOptions = (state: GlobalAccountSettingsState) =>
    getContactOptionsState(state).data;
