import {defaults, forEach} from "lodash/fp";
import type {SagaIterator} from "redux-saga";
import {call, select, put, takeLatest, take} from "redux-saga/effects";
import * as AuthSelectors from "@atg-shared/auth/domain/authSelectors";
import type {MediaObject} from "@atg-play-shared/media-graphql-client/__generated__/types.generated";
import * as AuthActions from "@atg-shared/auth/domain/authActions";
import * as Analytics from "@atg-shared/analytics";
import {LOGOUT_FINISHED} from "@atg-global-shared/user/userActionTypes";
import {MemberActions} from "@atg-global-shared/member-data-access";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {canWatchArchiveRace} from "@atg-horse-shared/utils/video/can-watch-archive-race";
import {isVideoPopup} from "../player/videoSelectors";
import * as VideoActions from "./videoActions";
import {
    MEDIA_WARMUP_WINDOW,
    MEDIA_ARCHIVE_START,
    MEDIA_ARCHIVE_WINDOW,
    CLOSE_VIDEO_POPUP_START,
} from "./videoActionConstants";
import type {VideoArchive} from "./video";

const WARMUP_POPUP_WIDTH = 883;
const WARMUP_POPUP_HEIGHT = 602;

const ARCHIVE_POPUP_WIDTH = 940;
const ARCHIVE_POPUP_HEIGHT = 500;
const LIVE_VIDEO_POPUP_NAME = "liveVideo";
export const WARMUP_VIDEO_POPUP_NAME = "warmupVideoPopup";
export const ARCHIVE_VIDEO_POPUP_NAME = "archiveVideoPopup";
const DIRECT_POPUP_ARCHIVE_VIDEO = "directPopupArchiveVideo";
type WindowNames =
    | typeof LIVE_VIDEO_POPUP_NAME
    | typeof WARMUP_VIDEO_POPUP_NAME
    | typeof ARCHIVE_VIDEO_POPUP_NAME
    | typeof DIRECT_POPUP_ARCHIVE_VIDEO;

type VideoWindows = Partial<Record<WindowNames, Window>>;

function toFeaturesString(features: any) {
    return Object.keys(features)
        .map((key) => `${key}=${features[key]}`)
        .join(",");
}

function createArchiveUrl(mediaId: string, gameId: string, graphicsMedia: MediaObject) {
    const url = `/video/archive/${mediaId}`;
    const param = graphicsMedia ? `?useGraphicsMedia=1` : ``;
    if (gameId) return `${url}/${gameId}${param}`;
    return `${url}${param}`;
}

function createWarmupUrl(startId: string) {
    let url = "";
    if (startId) {
        url += `/video/warmup/${startId}`;
    }
    return url;
}

const videoWindows = {} as VideoWindows;

function openPopup(options: {
    name: WindowNames;
    url: string;
    fromBanner?: boolean;
    features: any;
}) {
    const windowName = options.name;
    const {features = {}} = options;
    Analytics.deprecated_logEvent({
        ux_event: `popout_${windowName}`,
    });
    const winHeight = 480;
    const winWidth = window.outerWidth < 890 ? window.outerWidth : 890;
    const y = window.outerHeight / 2 + window.screenY - winHeight / 2;
    const x = window.outerWidth / 2 + window.screenX - winWidth / 2;
    const combinedFeatures = defaults(
        {
            menubar: "no",
            location: "no",
            personalbar: "no",
            resizable: "yes",
            toolbar: "no",
            scrollbars: "yes",
            width: winWidth,
            height: winHeight,
            top: y,
            left: x,
        },
        features,
    );

    const popupFeaturesString = toFeaturesString(combinedFeatures);
    const oldVideoWindow = videoWindows[windowName];
    if (oldVideoWindow) {
        oldVideoWindow.location.href = options.url;
        oldVideoWindow.focus();
        return;
    }

    if (!window) return;

    const videoWindow = window.open(options.url, windowName, popupFeaturesString);
    if (!videoWindow) return;
    videoWindows[windowName] = videoWindow;

    window.addEventListener("pagehide", () => videoWindows[windowName]?.close(), false);
    let timer: NodeJS.Timeout;
    const checkChild = () => {
        if (videoWindows[windowName]?.closed) {
            // eslint-disable-next-line no-underscore-dangle
            window._horseStore.dispatch(VideoActions.closeVideoPopup(options.name));
            window.removeEventListener(
                "pagehide",
                () => videoWindows[windowName]?.close(),
                false,
            );
            delete videoWindows[windowName];

            clearInterval(timer);
        }
    };
    timer = setInterval(checkChild, 500);
}

// eslint-disable-next-line require-yield
function* closeAllVideoPopoutsFlow() {
    forEach((videoWindow) => {
        videoWindow?.close();
    }, videoWindows);
}

export function* closeVideoPopup(action: {payload: WindowNames; type: string}) {
    const videoWindow = videoWindows[action.payload];
    if (videoWindow) {
        videoWindow.close();
    }
    yield put(VideoActions.closeVideoPopup(action.payload));
}

export function* mediaArchive({
    payload: options,
}: {
    payload: VideoArchive;
    type: string;
}): SagaIterator<void> {
    const isLoggedIn: boolean = yield select(AuthSelectors.isLoggedIn);
    const {date, countryCode} = options;
    const canWatch: boolean = yield call(canWatchArchiveRace, {
        date,
        countryCode,
        isLoggedIn,
    });
    let canProceedWithVideo = true;

    if (!canWatch) {
        yield put(AuthActions.checkAuth());

        // wait until user takes action on the login modal
        const response = yield take([
            MemberActions.CANCELLED_LOGIN_FLOW,
            MemberActions.FINISH_MEMBER_FLOW,
        ]);
        // if user does not log in but rather close the modal, we stop the user from proceeding
        canProceedWithVideo = response.type === MemberActions.FINISH_MEMBER_FLOW;
    }
    // if user was already logged in or has logged in successfully in step above, we proceed with video
    if (canProceedWithVideo) {
        yield put(VideoActions.setMediaArchive(options));
        const videoPopup: boolean = yield select(isVideoPopup, ARCHIVE_VIDEO_POPUP_NAME);
        if (videoPopup) {
            yield call(closeVideoPopup, {
                payload: ARCHIVE_VIDEO_POPUP_NAME,
                type: "CLOSE_VIDEO_POPUP",
            });
        }
    }
}

export function* mediaArchiveWindow({
    payload: options,
}: {
    payload: any;
    type: string;
}): SagaIterator<void> {
    const isLoggedIn: boolean = yield select(AuthSelectors.isLoggedIn);
    const {gameId, mediaId, graphicsMedia, date, countryCode} = options;

    const canWatch: boolean = yield call(canWatchArchiveRace, {
        date,
        countryCode,
        isLoggedIn,
    });
    let canProceedWithVideo = true;
    const windowName = options.fromBanner
        ? ARCHIVE_VIDEO_POPUP_NAME
        : DIRECT_POPUP_ARCHIVE_VIDEO;

    if (!canWatch) {
        yield put(AuthActions.checkAuth());

        // wait until user takes action on the login modal
        const response = yield take([
            MemberActions.CANCELLED_LOGIN_FLOW,
            MemberActions.FINISH_MEMBER_FLOW,
        ]);
        // if user does not log in but rather close the modal, we stop the user from proceeding
        canProceedWithVideo = response.type === MemberActions.FINISH_MEMBER_FLOW;
    }

    if (canProceedWithVideo) {
        openPopup({
            name: windowName,
            url: createArchiveUrl(mediaId, gameId, graphicsMedia),
            features: {
                width: ARCHIVE_POPUP_WIDTH,
                height: ARCHIVE_POPUP_HEIGHT,
            },
            fromBanner: options.fromBanner,
        });
        yield put(VideoActions.openVideoPopup(windowName));
    }
}

function* playWarmupInWindow({payload: options}: {payload: any; type: string}) {
    const {startId} = options;

    openPopup({
        name: WARMUP_VIDEO_POPUP_NAME,
        url: createWarmupUrl(startId),
        features: {
            width: WARMUP_POPUP_WIDTH,
            height: WARMUP_POPUP_HEIGHT,
            scrollbars: "no",
        },
    });
    yield put(VideoActions.openVideoPopup(WARMUP_VIDEO_POPUP_NAME));
}

export default function* videoPlaySaga(): SagaIterator<void> {
    yield takeLatest(MEDIA_ARCHIVE_START, mediaArchive);
    yield takeLatest(MEDIA_ARCHIVE_WINDOW, mediaArchiveWindow);
    yield takeLatest(MEDIA_WARMUP_WINDOW, playWarmupInWindow);
    yield takeLatest(CLOSE_VIDEO_POPUP_START, closeVideoPopup);
    yield takeLatest(LOGOUT_FINISHED, closeAllVideoPopoutsFlow);
}
