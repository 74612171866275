import type {ProductState} from "./navigationReducer";
import type {State} from "./index";

export const getProductState = (state: State): ProductState => state.navigation.product;
export const getProductStateGameTypes = (state: State): string[] =>
    Object.keys(getProductState(state));

export const getLastVisitedStartlistForGameType = (state: State, gameType: string) =>
    state.navigation.product[gameType]
        ? state.navigation.product[gameType].lastVisitedStartlist
        : null;

export const getLastVisitedGameIdForGameType = (state: State, gameType: string) =>
    gameType && state.navigation.product[gameType]
        ? state.navigation.product[gameType].lastVisitedGameId
        : null;

export const isGameSpecificPage = (
    state: State,
    gameType: string,
    pageType: string,
): boolean => {
    const lastVisitedGameId = getLastVisitedGameIdForGameType(state, gameType);
    return pageType === "tips" && lastVisitedGameId !== null;
};

export const getTimeAddedForGameType = (state: State, gameType: string) =>
    state.navigation.product[gameType]
        ? state.navigation.product[gameType].timeAdded
        : null;
