export const SET_LAST_VISITED_START_LIST = "navigation/SET_LAST_VISITED_START_LIST";

export type SetLastVisitedStartListAction = {
    type: typeof SET_LAST_VISITED_START_LIST;
    payload: {
        gameType: string;
        url: string;
        gameId: string;
    };
};

export type NavigationAction = SetLastVisitedStartListAction;

export const setLastVisitedStartlist = (
    gameType: string,
    url: string,
    gameId: string,
): SetLastVisitedStartListAction => ({
    type: SET_LAST_VISITED_START_LIST,
    payload: {
        gameType,
        url,
        gameId,
    },
});
