import type {FetchAction, ReceiveAction} from "@atg-shared/fetch-types";
import {FETCH, call} from "@atg-shared/fetch-redux";

import type {
    ContactOptionsResponse,
    ContactInfo,
    ContactOptions,
} from "@atg-payment-shared/account-settings-types";
import {frameAction} from "atg-store-addons";
import * as AccountSettingsApi from "./accountSettingsApi";

export const ACCOUNT_SETTINGS_EDIT_CONTACT_INFO = "ACCOUNT_SETTINGS_EDIT_CONTACT_INFO";
export const COMMUNICATION_OPTIONS_EDIT_SETTINGS = "COMMUNICATION_OPTIONS_EDIT_SETTINGS";
export const COMMUNICATION_OPTIONS_EDIT_SETTINGS_SUCCESS =
    "COMMUNICATION_OPTIONS_EDIT_SETTINGS_SUCCESS";
export const COMMUNICATION_OPTIONS_EDIT_SETTINGS_FAILURE =
    "COMMUNICATION_OPTIONS_EDIT_SETTINGS_FAILURE";
export const ACCOUNT_SETTINGS_CLEAR = "ACCOUNT_SETTINGS_CLEAR";
export const EDIT_PHONE_NUMBER = "EDIT_PHONE_NUMBER";
export const EDIT_EMAIL = "EDIT_EMAIL";
export const RESET_STATUS = "ACCOUNT_SETTINGS_RESET_STATUS";
export const SET_CONTACT_INFO_REQUEST = "SET_CONTACT_INFO_REQUEST";
export const SET_CONTACT_INFO_RECEIVE = "SET_CONTACT_INFO_RECEIVE";
export const OPEN_CHANGE_AVATAR_MODAL = "OPEN_CHANGE_AVATAR_MODAL";

export const USER_CONTACT_OPTIONS = "USER_CONTACT_OPTIONS";
export const USER_CONTACT_OPTIONS_RESPONSE = "USER_CONTACT_OPTIONS_RESPONSE";

export type OpenChangeModalAvatarAction = {
    type: typeof OPEN_CHANGE_AVATAR_MODAL;
};
export type EditContactInfoAction = {
    type: typeof ACCOUNT_SETTINGS_EDIT_CONTACT_INFO;
    payload: EditContactInfoPayload;
};
export type EditCommunicationOptionsAction = {
    type: typeof COMMUNICATION_OPTIONS_EDIT_SETTINGS;
    payload: ContactOptions;
};
export type EditCommunicationOptionsActionSuccess = {
    type: typeof COMMUNICATION_OPTIONS_EDIT_SETTINGS_SUCCESS;
    payload: ContactOptions;
};
export type EditCommunicationOptionsActionFailure = {
    type: typeof COMMUNICATION_OPTIONS_EDIT_SETTINGS_FAILURE;
};
type AccountSettingsClearAction = {
    type: typeof ACCOUNT_SETTINGS_CLEAR;
};
type AccountSettingsResetStatusAction = {
    type: typeof RESET_STATUS;
};

export type UserContactOptionsFetchAction = FetchAction<
    typeof USER_CONTACT_OPTIONS,
    typeof USER_CONTACT_OPTIONS_RESPONSE,
    Omit<ContactOptions, "SHOP_SHARES"> & {shopShare: boolean},
    any,
    any
>;

export type UserContactOptionsReceiveAction = ReceiveAction<
    typeof USER_CONTACT_OPTIONS_RESPONSE,
    ContactOptionsResponse,
    undefined
>;

export type Action =
    | OpenChangeModalAvatarAction
    | EditContactInfoAction
    | AccountSettingsClearAction
    | AccountSettingsResetStatusAction
    | UserContactOptionsFetchAction
    | SetContactInfoAction
    | EditCommunicationOptionsAction
    | EditCommunicationOptionsActionSuccess
    | EditCommunicationOptionsActionFailure;

export type EditContactInfoPayload = {
    type: string;
};

export const openChangeAvatarModal = (): OpenChangeModalAvatarAction =>
    frameAction({
        type: OPEN_CHANGE_AVATAR_MODAL,
    });

export const editContactInfo = (
    payload: EditContactInfoPayload,
): EditContactInfoAction => ({
    type: ACCOUNT_SETTINGS_EDIT_CONTACT_INFO,
    payload,
});
export const editCommunicationOptions = (
    payload: ContactOptions,
): EditCommunicationOptionsAction => ({
    type: COMMUNICATION_OPTIONS_EDIT_SETTINGS,
    payload,
});
export const editCommunicationOptionsSuccess = (
    payload: ContactOptions,
): EditCommunicationOptionsActionSuccess => ({
    type: COMMUNICATION_OPTIONS_EDIT_SETTINGS_SUCCESS,
    payload,
});
export const editCommunicationOptionsFailure =
    (): EditCommunicationOptionsActionFailure => ({
        type: COMMUNICATION_OPTIONS_EDIT_SETTINGS_FAILURE,
    });

export const accountSettingsClear = (): AccountSettingsClearAction => ({
    type: ACCOUNT_SETTINGS_CLEAR,
});

export type SetContactInfoAction = FetchAction<
    typeof SET_CONTACT_INFO_REQUEST,
    typeof SET_CONTACT_INFO_RECEIVE,
    any,
    any,
    ContactInfo
>;

export const setUserContactInfo = (
    contactInfo: AccountSettingsApi.SaveContactInfoPayload,
): SetContactInfoAction => ({
    type: FETCH,
    payload: {
        requestAction: SET_CONTACT_INFO_REQUEST,
        receiveAction: SET_CONTACT_INFO_RECEIVE,
        callApi: call(AccountSettingsApi.saveContactInfo, contactInfo),
    },
});
export const resetAccountSettingsStatus = (): AccountSettingsResetStatusAction => ({
    type: RESET_STATUS,
});

export const fetchUserContactOptions = (): UserContactOptionsFetchAction => ({
    type: FETCH,
    payload: {
        requestAction: USER_CONTACT_OPTIONS,
        receiveAction: USER_CONTACT_OPTIONS_RESPONSE,
        callApi: call(AccountSettingsApi.fetchUserContactOptions),
    },
});
