import type {Message} from "../types";

export const CHAT_KEY = "elli-horse/messages";

export const getChatStorage = (): Record<string, Message> | null => {
    try {
        const json = sessionStorage.getItem(CHAT_KEY);

        if (!json) {
            return null;
        }

        return JSON.parse(json);
    } catch (e: unknown) {
        return null;
    }
};

export const setChatStorage = (messages: Record<string, Message> | null): void => {
    if (!messages) {
        return;
    }

    sessionStorage.setItem(CHAT_KEY, JSON.stringify(messages));
};

export const clearChatStorage = (): void => {
    sessionStorage.removeItem(CHAT_KEY);
};
