import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {SettingId} from "@atg-horse-shared/startlist-table/src/domain/utils/startlistCellData";
import * as StartlistActions from "./startlistActions";

export type StartlistSettings = {
    isStartlistCustomizationDrawerOpen: boolean;
    hasFinishedOnboarding: boolean;
    onboardings: StartlistActions.Onboardings;
    staticStartlists: Array<StartlistActions.StaticStartlist>;
    customStartlists: Array<StartlistActions.CustomStartlist>;
    globalStartlistSettings: StartlistActions.GlobalStartlistSettings;
    currentStartlistId: string;
    expandAllExtendedStarts: boolean;
    highlightedPicks: StartlistActions.HighlightedPicks;
    selectedSettingsForResultsTable: Array<keyof SettingId>;
    sortOptionsResult: StartlistActions.SortOptionsStartlist;
    sortOptionsStartlist: StartlistActions.SortOptionsStartlist;
    sortOptionsExtendedStart: StartlistActions.SortOptionsExtendedStart;
};

const INITIAL_STATE: StartlistSettings = {
    isStartlistCustomizationDrawerOpen: false,
    hasFinishedOnboarding: false,
    onboardings: {
        swipe: false,
        compactViews: false,
        bettingTrends: false,
    },
    currentStartlistId: "",
    staticStartlists: [
        {
            name: "enkel lista",
            id: "simple",
            selectedSettings: {
                startlist: [
                    "defaultOdds",
                    "pOdds",
                    "driverJerseySvg",
                    "trainerShortName",
                ],
                results: ["defaultOdds", "time"],
            },
        },
        {
            name: "populär",
            id: "popular",
            selectedSettings: {
                startlist: [
                    "driverJerseySvg",
                    "defaultOdds",
                    "vOdds",
                    "pOdds",
                    "trainer",
                    "trainerShortName",
                    "cartInfo",
                    "ageAndSex",
                    "showDagensSpelComment",
                    "bettingTrends",
                    "shoeInfo",
                    "handicap",
                    "plusValue",
                    "weight",
                ],
                results: ["defaultOdds", "time"],
            },
        },
        {
            name: "detaljerad",
            id: "detailed",
            selectedSettings: {
                startlist: [
                    "driverJerseySvg",
                    "defaultOdds",
                    "vOdds",
                    "pOdds",
                    "earnings",
                    "record",
                    "winPercent",
                    "bettingTrends",
                    "currentYearStats",
                    "shoeInfo",
                    "cartInfo",
                    "warmupVideo",
                    "trainerShortName",
                    "ageAndSex",
                    "handicap",
                    "plusValue",
                    "weight",
                ],
                results: ["defaultOdds", "time"],
            },
        },
    ],
    customStartlists: [],
    selectedSettingsForResultsTable: ["enforcedVOdds", "enforcedPOdds"],
    highlightedPicks: {
        gameId: "",
        selectedReceiptId: "",
        selection: undefined,
    },

    globalStartlistSettings: {
        showRaceComments: true,
        showBetDistributionDecimals: false,
        showCompactStartlist: false,
        showVerticalStartlists: false,
    },
    expandAllExtendedStarts: false,
    sortOptionsStartlist: {
        order: "asc",
        value: "horse",
    },
    sortOptionsResult: {
        order: "asc",
        value: "place",
    },
    sortOptionsExtendedStart: {
        order: "desc",
        value: "date",
    },
};

export const startlistSettings = (
    state: StartlistSettings = INITIAL_STATE,
    action: StartlistActions.Types,
) => {
    switch (action.type) {
        case StartlistActions.FINISH_ONBOARDING:
            return {
                ...state,
                hasFinishedOnboarding: true,
            };

        case StartlistActions.CLOSE_ONBOARDING_ITEM:
            return {
                ...state,
                onboardings: {
                    ...state.onboardings,
                    [action.payload]: true,
                },
            };

        case StartlistActions.SET_CURRENT_STARTLIST_ID:
            return {
                ...state,
                currentStartlistId: action.payload,
            };

        case StartlistActions.SET_CUSTOM_STARTLISTS: {
            return {
                ...state,
                customStartlists: action.payload,
            };
        }

        case StartlistActions.REMOVE_CUSTOM_STARTLIST: {
            return {
                ...state,
                customStartlists: state.customStartlists.filter(
                    (startlist) => startlist.id !== action.payload.id,
                ),
            };
        }

        case StartlistActions.EDIT_CUSTOM_STARTLIST: {
            const editableStartlist = state.customStartlists.find(
                (startlist) => startlist.id === action.payload.id,
            );
            const filteredStartlists = state.customStartlists.filter(
                (startlist) => startlist.id !== action.payload.id,
            );
            const updatedStartlist = editableStartlist
                ? {
                      ...editableStartlist,
                      selectedSettings: {
                          ...editableStartlist.selectedSettings,
                          [action.payload.target]: action.payload.selectedSettings,
                      },
                  }
                : ({
                      selectedSettings: {startlist: [], results: []},
                  } as StartlistActions.CustomStartlist);
            return {
                ...state,
                customStartlists: [...filteredStartlists, updatedStartlist],
            };
        }

        case StartlistActions.ADD_STARTLIST_SETTING: {
            const editableStartlist = state.customStartlists.find(
                (startlist) => startlist.id === action.payload.startlistId,
            );
            const filteredStartlists = state.customStartlists.filter(
                (startlist) => startlist.id !== action.payload.startlistId,
            );
            const updatedStartlist = editableStartlist
                ? {
                      ...editableStartlist,
                      selectedSettings: {
                          ...editableStartlist.selectedSettings,
                          [action.payload.target]: [
                              ...editableStartlist.selectedSettings[
                                  action.payload.target
                              ],
                              action.payload.settingId,
                          ],
                      },
                  }
                : ({
                      selectedSettings: {startlist: [], results: []},
                  } as StartlistActions.CustomStartlist);

            return {
                ...state,
                customStartlists: [...filteredStartlists, updatedStartlist],
            };
        }

        case StartlistActions.ADD_ALL_STARTLIST_SETTINGS: {
            const editableStartlist = state.customStartlists.find(
                (startlist) => startlist.id === action.payload.startlistId,
            );
            const filteredStartlists = state.customStartlists.filter(
                (startlist) => startlist.id !== action.payload.startlistId,
            );
            const updatedStartlist = editableStartlist
                ? {
                      ...editableStartlist,
                      selectedSettings: {
                          ...editableStartlist.selectedSettings,
                          [action.payload.target]: action.payload.selectedSettings,
                      },
                  }
                : ({
                      selectedSettings: {startlist: [], results: []},
                  } as StartlistActions.CustomStartlist);

            return {
                ...state,
                customStartlists: [...filteredStartlists, updatedStartlist],
            };
        }

        case StartlistActions.REMOVE_STARTLIST_SETTING: {
            const editableStartlist = state.customStartlists.find(
                (startlist) => startlist.id === action.payload.startlistId,
            );
            const filteredStartlists = state.customStartlists.filter(
                (startlist) => startlist.id !== action.payload.startlistId,
            );
            const updatedStartlist = editableStartlist
                ? {
                      ...editableStartlist,
                      selectedSettings: {
                          ...editableStartlist.selectedSettings,
                          [action.payload.target]: editableStartlist.selectedSettings[
                              action.payload.target
                          ].filter((id) => id !== action.payload.settingId),
                      },
                  }
                : ({
                      selectedSettings: {startlist: [], results: []},
                  } as StartlistActions.CustomStartlist);

            return {
                ...state,
                customStartlists: [...filteredStartlists, updatedStartlist],
            };
        }

        case StartlistActions.REMOVE_ALL_STARTLIST_SETTINGS: {
            const editableStartlist = state.customStartlists.find(
                (startlist) => startlist.id === action.payload.startlistId,
            );
            const filteredStartlists = state.customStartlists.filter(
                (startlist) => startlist.id !== action.payload.startlistId,
            );
            const updatedStartlist = editableStartlist
                ? {
                      ...editableStartlist,
                      selectedSettings: {
                          ...editableStartlist.selectedSettings,
                          [action.payload.target]: ["enforcedVOdds", "enforcedPOdds"],
                      },
                  }
                : ({
                      selectedSettings: {
                          startlist: [],
                          results: [],
                      },
                  } as StartlistActions.CustomStartlist);

            return {
                ...state,
                customStartlists: [...filteredStartlists, updatedStartlist],
            };
        }

        case StartlistActions.ADD_RESULTS_TABLE_SETTING: {
            return {
                ...state,
                selectedSettingsForResultsTable: [
                    ...state.selectedSettingsForResultsTable,
                    action.payload.settingId,
                ],
            };
        }

        case StartlistActions.ADD_ALL_RESULTS_TABLE_SETTINGS: {
            return {
                ...state,
                selectedSettingsForResultsTable: action.payload.selectedSettings,
            };
        }

        case StartlistActions.REMOVE_RESULTS_TABLE_SETTING: {
            return {
                ...state,
                selectedSettingsForResultsTable: [
                    ...state.selectedSettingsForResultsTable.filter(
                        (setting) => setting !== action.payload.settingId,
                    ),
                ],
            };
        }

        case StartlistActions.REMOVE_ALL_RESULTS_TABLE_SETTINGS: {
            return {
                ...state,
                selectedSettingsForResultsTable: [
                    "enforcedVOdds",
                    "enforcedPOdds",
                ] as Array<keyof SettingId>,
            };
        }

        case StartlistActions.EDIT_RESULTS_TABLE_SETTINGS: {
            return {
                ...state,
                selectedSettingsForResultsTable: action.payload.selectedSettings,
            };
        }

        case StartlistActions.UPDATE_STARTLIST_NAME: {
            const editableStartlist = state.customStartlists.find(
                (startlist) => startlist.id === action.payload.startlistId,
            );
            const filteredStartlists = state.customStartlists.filter(
                (startlist) => startlist.id !== action.payload.startlistId,
            );
            const updatedStartlist = {
                ...editableStartlist,
                name: action.payload.name,
            } as StartlistActions.CustomStartlist;

            return {
                ...state,
                customStartlists: [...filteredStartlists, updatedStartlist],
            };
        }

        case StartlistActions.SET_SORT_OPTIONS_STARTLIST:
            return {
                ...state,
                sortOptionsStartlist: action.payload,
            };

        case StartlistActions.SET_SORT_OPTIONS_STARTLIST_RESULT:
            return {
                ...state,
                sortOptionsResult: action.payload,
            };

        case StartlistActions.SET_SORT_OPTIONS_EXTENDED_STARTLIST:
            return {
                ...state,
                sortOptionsExtendedStart: action.payload,
            };

        case StartlistActions.TOGGLE_ALL_EXTENDED_STARTS:
            return {
                ...state,
                expandAllExtendedStarts: !state.expandAllExtendedStarts,
            };

        case StartlistActions.TOGGLE_GLOBAL_STARTLIST_SETTING:
            return {
                ...state,
                globalStartlistSettings: {
                    ...state.globalStartlistSettings,
                    [action.payload]: !state.globalStartlistSettings[action.payload],
                },
            };

        case StartlistActions.TOGGLE_IS_STARTLIST_CUSTOMIZATION_DRAWER_OPEN:
            return {
                ...state,
                isStartlistCustomizationDrawerOpen:
                    !state.isStartlistCustomizationDrawerOpen,
            };

        case StartlistActions.ADD_HIGHLIGHTED_PICKS_GAME_ID:
            return {
                ...state,
                highlightedPicks: {
                    ...state.highlightedPicks,
                    gameId: action.payload,
                },
            };

        case StartlistActions.ADD_HIGHLIGHTED_SELECTION:
            return {
                ...state,
                highlightedPicks: {
                    ...state.highlightedPicks,
                    selection: action.payload,
                },
            };

        case StartlistActions.ADD_HIGHLIGHTED_PICKS_SELECTED_RECEIPT_ID:
            return {
                ...state,
                highlightedPicks: {
                    ...state.highlightedPicks,
                    selectedReceiptId: action.payload,
                },
            };

        default:
            return state;
    }
};

export const PERSIST_KEY = "startlist";

const startlistSettingsPersistConfig = {
    storage,
    key: PERSIST_KEY,
    version: 0,
    blacklist: ["staticStartlists"],
};

export default persistReducer(startlistSettingsPersistConfig, startlistSettings);
