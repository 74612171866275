import type {ShopShare} from "@atg-shop-shared/types/shopShare";
import type {Shop} from "@atg-shop-shared/types/shop";
import type {ShopPushDataState} from "@atg-shop/push-data-redux-types/pushData";
import type {State} from "../index";

export const getShopPushState = (state: State): ShopPushDataState =>
    state.shopPushData.data;

export const getShopPushData = (state: State): Partial<Shop> | null | undefined =>
    getShopPushState(state).shop;

export const getShopSharePushData = (
    state: State,
    shareId: string,
): Partial<ShopShare> | null | undefined =>
    getShopPushState(state).shares[shareId] || null;
