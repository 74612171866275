import {select, call, put, takeEvery} from "redux-saga/effects";
import * as API from "./extendedStartApi";
import {EXPANDED_PREVIOUS_RESULTS} from "./extendedStartActionTypes";
import * as Actions from "./extendedStartActions";
import {
    getExtendedStartsForRace,
    getIsLoadingExtendedStart,
} from "./extendedStartSelectors";

export function* fetchPreviousResults({payload: {raceId}}: any) {
    // @ts-expect-error
    const extendedResults = yield select(getExtendedStartsForRace, raceId);
    // @ts-expect-error
    const isLoadingPreviousResults = yield select(getIsLoadingExtendedStart, raceId);

    if (extendedResults || isLoadingPreviousResults) return;

    yield put(Actions.requestExtendedRace(raceId));

    let results;
    try {
        // @ts-expect-error
        results = yield call(API.fetchPreviousResults, raceId);
    } catch (error: unknown) {
        // @ts-expect-error
        yield put(Actions.receivedExtendedRaceError(raceId, error));
        return; // Don't continue the saga if we didn't get a successful response
    }

    yield put(Actions.receivedExtendedRace(results.data));
}

export default function* PreviousResultsSaga() {
    yield takeEvery(EXPANDED_PREVIOUS_RESULTS, fetchPreviousResults);
}
