import {createFetchReducer} from "@atg-shared/fetch-redux";
import type {FetchReducer} from "@atg-shared/fetch-types";
import type {ShareGame} from "@atg-shop-shared/types/share";

import {
    type HistoricalGamesFetchAction,
    type HistoricalGamesActions,
    HISTORICAL_GAMES_FETCH_REQUEST,
    HISTORICAL_GAMES_FETCH_RESET,
    HISTORICAL_GAMES_FETCH_RESPONSE,
} from "./historicalGamesActions";

export type HistoricalGamesFetchState = {
    games: Array<ShareGame>;
};

const INITIAL_HISTORICAL_GAMES_STATE: HistoricalGamesFetchState = {
    games: [],
};

export const historicalGamesReducer: FetchReducer<
    HistoricalGamesFetchState,
    HistoricalGamesFetchAction
> = createFetchReducer(
    HISTORICAL_GAMES_FETCH_REQUEST,
    HISTORICAL_GAMES_FETCH_RESPONSE,
    HISTORICAL_GAMES_FETCH_RESET,
    (state: HistoricalGamesFetchState, action: HistoricalGamesActions) => {
        switch (action.type) {
            case HISTORICAL_GAMES_FETCH_RESPONSE:
                if (action.error) return state;
                return {
                    games: action.payload,
                };
            case HISTORICAL_GAMES_FETCH_RESET:
                return INITIAL_HISTORICAL_GAMES_STATE;
            default:
                return state;
        }
    },
    INITIAL_HISTORICAL_GAMES_STATE,
);

export default historicalGamesReducer;
