import type {SagaIterator} from "redux-saga";
import {takeLatest} from "redux-saga/effects";
import {LOGIN_FINISHED} from "@atg-global-shared/user/userActionTypes";
import purchaseFlowSaga from "./purchaseFlowSaga";
import {
    START_PURCHASE_FLOW,
    RESTORE_PURCHASE_FLOW,
} from "./purchaseActions";
import purchaseAnalyticsSaga, {
    handleProductPurchase,
} from "./purchaseAnalyticsSaga";
import harrySubscriptionUpSellSaga from "./harrySubscriptionUpSellSaga";

export default function* purchaseSaga(): SagaIterator<void> {
    yield takeLatest(RESTORE_PURCHASE_FLOW, handleProductPurchase);
    yield takeLatest(
        [START_PURCHASE_FLOW, RESTORE_PURCHASE_FLOW, LOGIN_FINISHED],
        harrySubscriptionUpSellSaga,
    );
    // @ts-expect-error
    yield takeLatest(START_PURCHASE_FLOW, purchaseAnalyticsSaga);
    yield takeLatest([START_PURCHASE_FLOW, RESTORE_PURCHASE_FLOW], purchaseFlowSaga);
}
