import {isUndefined, padStart, some, includes} from "lodash";
import {formatDecimalAmount, oddsToString} from "@atg/utils/strings";
import type {GameAPITypes} from "@atg-horse-shared/racing-info-api";
import type {GameType} from "@atg-horse-shared/game-types";
import {GameTypes} from "@atg-horse-shared/game-types";

/**
 * A start ID uniquely identifies a single horse in a specific race.
 *
 * A start is a unique combination of:
 * - a specific race (on a specific track on a specific day)
 * - a specific horse
 *
 * example: "Dante Godiva (2) in race 4 in Umåker 2020-07-17".
 *
 * Format: `<date>_<track>_<race number>_<start number>`

 * Example: `2020-07-17_27_4_2`
 * - 2020-07-17 = the date that all the races will happen
 * - 27 = Umåker (track)
 * - 4 = the forth race of the day on this track
 * - 2 = the second horse ("Dante Godiva" in this example)
 */
export type StartId = string;

export type Person = {
    firstName: string;
    lastName: string;
};

export type Place = "s" | "d" | "utg" | number;

export const getPlace = (start: GameAPITypes.Start): Place | null | undefined => {
    const {result} = start;
    if (!result) return undefined;

    if (start.scratched) return "s";
    if (result.disqualified) return "d";
    if (start.out) return "utg";

    return result.place;
};

export const getFinishOrder = (start: GameAPITypes.Start): number | null | undefined => {
    const {result} = start;
    if (!result) return undefined;

    if (!result.finishOrder) return result.place;

    return result.finishOrder;
};

// For Elitloppet, where you can have starts before you have horses and drivers
export function extendedInfoUnavailable(start: GameAPITypes.Start): boolean {
    return !start.horse && !start.driver;
}

export function getName(person: Person | null | undefined): string | null | undefined {
    if (!person) return null;

    return [person.firstName, person.lastName].join(" ");
}

export function getDriverName(
    driver:
        | {
              [key: string]: any;
          }
        | null
        | undefined,
): string | null | undefined {
    if (!driver) return null;
    // @ts-expect-error missing args
    const name = getName(driver);
    return name || "";
}

export function getTrainerName(
    trainer:
        | {
              [key: string]: any;
          }
        | null
        | undefined,
): string | null | undefined {
    if (!trainer) return null;

    // @ts-expect-error missing args
    const name = getName(trainer);
    if (!trainer.level) return name;

    return `${name || ""} ${trainer.level.slice(0, 1).toLowerCase()}`;
}

export function getTrainerShortName(
    trainer:
        | {
              [key: string]: any;
          }
        | null
        | undefined,
): string | null | undefined {
    if (!trainer) return null;

    const shortName = trainer.shortName ? `(${trainer.shortName}) ` : "";
    const {level} = trainer;

    return level ? shortName + level.slice(0, 1).toLowerCase() : shortName;
}

export const createStartId = (raceId: string, startNumber: number): string =>
    `${raceId}_${startNumber}`;

export function getStartNumberFromId(startId: StartId): number | null | undefined {
    if (!startId) return null;
    const startNumberPos = startId.lastIndexOf("_") + 1;
    return parseInt(startId.substring(startNumberPos), 10);
}

export function getStartEarnings(
    start: GameAPITypes.Start | null | undefined,
    sport: string,
): number | null | undefined {
    if (!start || !start.horse || !start.horse.statistics || !start.horse.statistics.life)
        return null;

    const {life: stats} = start.horse.statistics;
    if (sport !== "gallop" && stats.forcedEarnings) {
        return stats.earnings + stats.forcedEarnings;
    }

    return stats.earnings;
}

export function parseStartId(startId: StartId | null | undefined) {
    if (!startId) return null;
    const [date, trackId, raceNumber, startNumber] = startId.split("_");
    const raceId = [date, trackId, raceNumber].join("_");

    return {
        date,
        trackId: parseInt(trackId, 10),
        raceNumber: parseInt(raceNumber, 10),
        startNumber: parseInt(startNumber, 10),
        raceId,
    };
}

export const isStartInRace = (
    startId: StartId | null | undefined,
    raceId: string,
): boolean => {
    const result = parseStartId(startId);
    if (!result) return false;

    return result.raceId === raceId;
};

const getTime = (start: any, sport: GameAPITypes.Sport): string => {
    const {result} = start;
    if (!result) return "";

    const timeProperty = sport === "gallop" ? "time" : "kmTime";
    const time = result[timeProperty];
    if (!time) return "";

    if (time.code !== undefined) return time.code.toString();

    const minutes = `${time.minutes}.`;
    return `${minutes}${padStart(time.seconds.toString(10), 2, "0")},${time.tenths}`;
};

export const getKilometreTime = (start: GameAPITypes.Start) => getTime(start, "trot");
export const getGallopResultTime = (start: GameAPITypes.Start) =>
    getTime(start, "gallop");

export const getBetDistribution = (
    start: GameAPITypes.Start,
    gameType: GameType,
): string | null => {
    // @ts-expect-error
    const pool = start.pools && start.pools[gameType];
    const {betDistribution} = pool;
    if (isUndefined(betDistribution)) return null;
    return `${formatDecimalAmount(betDistribution)}%`;
};

export const getPlaceOdds = (pool?: GameAPITypes.PlatsStartPool, mergedPools?: any) => {
    if (isUndefined(pool)) return null;

    if (pool.minOdds === 0 && pool.maxOdds === 0) {
        return oddsToString(0);
    }
    if (pool.odds) {
        return oddsToString(pool.odds);
    }

    const minOdds = oddsToString(pool.minOdds);
    const maxOdds = oddsToString(pool.maxOdds);

    // For "Plats odds" we usually show a range from "minimum odds - maximum odds" (instead of fixed
    // odds), since this game type is very sensitive to scratched horses. However, if the plats pool
    // is part of a merged pool, the pool becomes more stable and we don't want to show an interval.
    const showExactOdds = some(mergedPools, (mergedPool) =>
        includes(mergedPool.betTypes, GameTypes.plats),
    );

    return showExactOdds ? maxOdds : `${minOdds} - ${maxOdds}`;
};
