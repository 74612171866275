import * as React from "react";
import {ClassNames} from "@emotion/react";
import {formatWinning} from "@atg/utils/strings";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {BoostCode} from "@atg-horse-shared/ui/components/BoostCode/BoostCode";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {BoostLogo} from "@atg-horse-shared/ui";
import {Clock, Eye, TriangleRight} from "atg-ui-components/icons";
import {gradingCorrectClassic} from "atg-ui/css/overridableVariables.styles";
import * as styles from "./receiptcolumnviews.styles";

const clockSymbol = <Clock style={styles.icon} data-test-icon="clock" />;

type WinnerProps = {
    column: {
        [key: string]: any;
    };
    data?: string | any;
    phase?: string;
    className?: string;
};

type Props = {
    tableProps?: {
        [key: string]: any;
    };
    boost?: boolean;
    race: string | any;
    column: {
        [key: string]: any;
    };
    data: {
        phase: string;
        columns: {
            [key: string]: any;
        };
        betType: string;
    };
    onClickAction: (...args: Array<any>) => any;
    className?: string;
    code?: string;
    correctCode?: string;
};

export function WinnersView({column, data, phase, className}: WinnerProps) {
    let winnerData: any;
    switch (phase) {
        case "clock":
            winnerData = clockSymbol;
            break;
        case "win":
            winnerData = data;
            break;
        default:
            winnerData = "";
            break;
    }
    const classes = `${column.id}-col`;

    return (
        <ClassNames>
            {({cx}) => <td className={cx(classes, className)}>{winnerData}</td>}
        </ClassNames>
    );
}

export function SneakButton({
    boost,
    race,
    column,
    data,
    onClickAction,
    className,
}: Props) {
    const markAsSneaked = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        onClickAction(race);
    };

    const renderButton = () => {
        const classes = `${column.id}-col pointer`;

        return (
            <ClassNames>
                {({cx}) => (
                    <td className={cx(classes, className)} onClick={markAsSneaked}>
                        <Eye style={styles.icon} data-test-icon="eye" />
                    </td>
                )}
            </ClassNames>
        );
    };

    const renderWinners = () => (
        <WinnersView
            column={{id: "winner"}}
            data={formatWinning(data.columns.sneak.data, data.betType)}
            phase={data.phase}
            className={className}
        />
    );

    const renderBoostCode = () => (
        <td className={`${column.id}-col`}>
            <div css={styles.boostCodeWrapper}>
                <BoostCode code={data.columns["boost-sneak"].data.code} />
            </div>
        </td>
    );

    const classes = `${column.id}-col pointer`;

    switch (data.phase) {
        case "none":
            return (
                <ClassNames>
                    {({cx}) => <td className={cx(classes, className)} />}
                </ClassNames>
            );
        case "clock":
            return (
                <ClassNames>
                    {({cx}) => <td className={cx(classes, className)}>{clockSymbol}</td>}
                </ClassNames>
            );
        case "eye":
            return renderButton();
        case "win":
            return boost ? renderBoostCode() : renderWinners();
        default:
            return <td />;
    }
}

export function VideoButton({tableProps, race, column}: Props) {
    const playVideo = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        tableProps?.actions.playVideo(tableProps.receipt.bet, race);
    };

    const videoButtonStyles = [
        `${column.id}-col`,
        race?.mediaId && "pointer",
        !race?.mediaId && `${column.id}-col-disabled`,
    ]
        .filter(Boolean)
        .join(" ");

    return (
        <td className={videoButtonStyles} onClick={race.mediaId && playVideo}>
            <TriangleRight style={styles.icon} data-test-icon="play" />
        </td>
    );
}

export function BoostLogoColumn({column}: Props) {
    return (
        <td className={`${column.id}-col`}>
            <BoostLogo className="receipt__boost-logo" />
        </td>
    );
}

export function BoostCodeColumn({code, correctCode, column}: Props) {
    return (
        <td className={`${column.id}-col`}>
            <div css={styles.boostCodeWrapper}>
                <BoostCode
                    code={code}
                    correctCode={correctCode}
                    correctColor={gradingCorrectClassic}
                />
            </div>
        </td>
    );
}

export default {
    SneakButton,
    VideoButton,
    WinnersView,
    BoostLogoColumn,
    BoostCodeColumn,
};
