import {fetchAuthorized} from "@atg-shared/auth";

const LEGACY_FILE_BET_URL =
    "/services/tokenized-proxy/service-horse-bet-history/api/v1/groupbets";

const getFileBets = (groupBetId: string, placedAt: string) =>
    fetchAuthorized(`${LEGACY_FILE_BET_URL}/${groupBetId}?timestamp=${placedAt}`, {
        method: "GET",
    });

export default getFileBets;
