import {isString, map, includes, isEmpty, omit, isUndefined, isNumber} from "lodash";
import {md5} from "js-md5";
import dayjs from "dayjs";
import type {GameAPITypes, CalendarAPITypes} from "@atg-horse-shared/racing-info-api";
import {getHarryFlavorInfo} from "@atg-horse-shared/utils/harry";
import {CouponTypes} from "@atg-horse-shared/coupon-types";
import * as gameUtils from "@atg-horse-shared/utils/game";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {CouponUtils} from "@atg-horse-shared/coupon";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {getReducedPurchaseAnalyticsValue, reducedBetType} from "@atg-horse/reduced-bets";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    SHOP_SHARE_PRODUCT,
    SHOP_SHARE_COUPON_PRODUCT,
} from "@atg-shop-shared/purchase-redux/src/shopShareCouponProducts";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    SHARED_BET_COUPON_PRODUCT,
    SHARED_BET_CREATE_PRODUCT,
    SHARED_BET_SHARE_PRODUCT,
} from "@atg-horse/shared-bet";
import {
    FILE_BET_PRODUCT,
    TOP7_PRODUCT,
    COUPON_PRODUCT,
    HARRY_PRODUCT,
    HARRY_BAG_PRODUCT,
    HARRY_BAG_PRODUCT_VARENNE,
} from "./products";

const getStartTime = (
    game: GameAPITypes.Game | CalendarAPITypes.CalendarGame | GameAPITypes.GameInfo,
): dayjs.Dayjs => {
    // `game.startTime` only exists for `CalendarGame` and `GameInfo`
    if (game.startTime) return dayjs(game.startTime);

    // not `CalendarGame` or `GameInfo --> `game.races[]` are objects and not strings
    return dayjs(
        (game.races[0] as GameAPITypes.GameRace | GameAPITypes.GameInfoRace).startTime,
    );
};

const getGameDate = (game: GameAPITypes.Game | GameAPITypes.GameInfo): dayjs.Dayjs => {
    if (game.date) return dayjs(game.date);
    if (game.races && game.races[0] && (game.races[0] as GameAPITypes.GameRace).date) {
        return dayjs((game.races[0] as GameAPITypes.GameRace).date);
    }

    return getStartTime(game);
};

// @ts-expect-error
export const formatProductId = (type, tracks, date) => {
    const trackOutput = isString(tracks) ? tracks : map(tracks, "name").join("-");
    return `${type}_${trackOutput}_${dayjs(date).format("YYYY-MM-DD")}`;
};

// @ts-expect-error
const hasBoost = (bet) => bet && includes(bet.addOns, "boost");
// @ts-expect-error
const hasFee = (bet) => bet && Boolean(bet.fee);
// @ts-expect-error
const hasShopShares = (bet) => bet && Boolean(bet.shopShareInfo);

// @ts-expect-error
const getPurchaseRaceData = (raceDateFormatted, daysToRace, isBoost, isFee) => {
    if (isBoost && isFee) {
        return {
            product_racedate: [raceDateFormatted, raceDateFormatted, raceDateFormatted],
            product_daystorace: [daysToRace, daysToRace, daysToRace],
        };
    }
    if (isBoost || isFee) {
        return {
            product_racedate: [raceDateFormatted, raceDateFormatted],
            product_daystorace: [daysToRace, daysToRace],
        };
    }

    return {
        product_racedate: [raceDateFormatted],
        product_daystorace: [daysToRace],
    };
};

// @ts-expect-error
const getShareData = (conditions) => {
    const sharesLeft = conditions.totalNrShares - conditions.totalNrSoldShares;
    const ownPrice = conditions.shareCost * conditions.nrShares;
    return {
        product_num_shares: [conditions.totalNrShares],
        product_num_shares_left: [sharesLeft],
        product_share_cost: conditions.shareCost && [
            (conditions.shareCost / 100).toString(),
        ],
        product_ownprice: ownPrice && [(ownPrice / 100).toString()],
    };
};

// @ts-expect-error
export const getPurchaseData = (bet, game, conditions) => {
    const isBoost = hasBoost(bet);
    const isFee = hasFee(bet);
    const raceDate = gameUtils.getStartTime(game);
    const raceDateFormatted = raceDate.format("YYYY-MM-DD");
    const daysToRace = raceDate.diff(bet.timestamp, "days");

    const purchaseRaceData = getPurchaseRaceData(
        raceDateFormatted,
        daysToRace,
        isBoost,
        isFee,
    );

    if (!isEmpty(conditions)) {
        const shareData = getShareData(conditions);
        if (!bet.serialNumber) {
            return {
                ...purchaseRaceData,
                ...shareData,
            };
        }
        return {
            ...purchaseRaceData,
            ...shareData,
            purchase_id: md5(bet.serialNumber.toString()),
        };
    }
    // Reduced bets has not serialNumber
    // it instead gets an id from the batchBet endpoint
    if (bet.type === reducedBetType) {
        return {
            ...purchaseRaceData,
            // Encode purchase_id to UTF-8, for better support on GTM
            purchase_id: md5(bet.id),
        };
    }
    if (!bet.serialNumber) {
        return purchaseRaceData;
    }
    return {
        ...purchaseRaceData,
        purchase_id: md5(bet.serialNumber.toString()),
    };
};

// @ts-expect-error
const getProductCostAndBoostCostFromCoupon = (coupon) => ({
    boostCost: CouponUtils.getBoostCost(coupon),
    productCost: coupon.cost,
});

// @ts-expect-error
const getProductCostAndBoostCostFromBet = (bet) => {
    const boostCost = bet.boostInfo ? bet.boostInfo.cost || 0 : 0;
    const productCost = bet.cost - boostCost;
    return {
        boostCost,
        productCost,
    };
};

// @ts-expect-error
export const getBoostProductPrice = (bet) => {
    const {productCost, boostCost} = bet.boostInfo
        ? getProductCostAndBoostCostFromBet(bet)
        : getProductCostAndBoostCostFromCoupon(bet);
    return [(productCost / 100).toString(), (boostCost / 100).toString()];
};

// @ts-expect-error
export const getBoostAndFeeProductPrice = (bet) => [
    ...getBoostProductPrice(bet),
    (bet.fee / 100).toString(),
];

// @ts-expect-error
const withoutUndefined = (obj) => omit(obj, isUndefined);

// NOTE: `bet` is deprecated, try to use `coupon` + `reductionTerms` instead
// ref: [HRS1-7724]
// @ts-expect-error
export const getBetData = (bet, coupon, reductionTerms) => {
    if (!bet) return {};

    if (hasShopShares(bet)) {
        const {totalBetAmount, multiplicator} = bet.shopShareInfo;
        const cost = totalBetAmount || bet.cost || bet.harryBetLimit || bet.betCost;
        return withoutUndefined({
            product_quantity: [(multiplicator || bet.systems || 1).toString()],
            product_price: cost && [(cost / 100).toString()],
            product_controlcode: bet.checkBetCode && [bet.checkBetCode],
        });
    }

    // NOTE: `coupon` is undefined for the "file bet product"
    if (coupon?.type === CouponTypes.REDUCED) {
        const {price, quantity} = getReducedPurchaseAnalyticsValue(
            coupon,
            reductionTerms,
        );
        return withoutUndefined({
            product_quantity: [quantity],
            product_price: [price],
            product_controlcode: bet.checkBetCode && [bet.checkBetCode],
        });
    }

    if (hasBoost(bet) && hasFee(bet)) {
        const productQuantity = (bet.systems || 1).toString();
        return withoutUndefined({
            product_quantity: [productQuantity, productQuantity, productQuantity],
            product_price: bet.cost && getBoostAndFeeProductPrice(bet),
            product_controlcode: bet.checkBetCode && [
                bet.checkBetCode,
                bet.checkBetCode,
                bet.checkBetCode,
            ],
        });
    }

    if (hasBoost(bet)) {
        const productQuantity = (bet.systems || 1).toString();
        return withoutUndefined({
            product_quantity: [productQuantity, productQuantity],
            product_price: bet.cost && getBoostProductPrice(bet),
            product_controlcode: bet.checkBetCode && [bet.checkBetCode, bet.checkBetCode],
        });
    }

    if (hasFee(bet)) {
        const productQuantity = (bet.systems || 1).toString();
        return withoutUndefined({
            product_quantity: [productQuantity, productQuantity],
            product_price: bet.cost && [
                (bet.cost / 100).toString(),
                (bet.fee / 100).toString(),
            ],
            product_controlcode: bet.checkBetCode && [bet.checkBetCode, bet.checkBetCode],
        });
    }
    const cost = bet.cost || bet.harryBetLimit || bet.betCost;
    return withoutUndefined({
        product_quantity: [(bet.systems || 1).toString()],
        product_price: cost && [(cost / 100).toString()],
        product_controlcode: bet.checkBetCode && [bet.checkBetCode],
    });
};

// @ts-expect-error
function getTop7ProProductVariant(coupon) {
    const {betMethod, userDefinedSystem, systemId} = coupon;

    if (userDefinedSystem) {
        return `pro_custom_${systemId}`;
    }

    const isBankerSystem = CouponUtils.isBankerSystem(coupon);

    if (betMethod === "harry") return isBankerSystem ? "spik auto" : "auto";

    return isBankerSystem ? "spik" : "pro";
}

// @ts-expect-error
function getHarryProductVariant(coupon) {
    if (CouponUtils.hasBets(coupon)) return "flex harry";

    return "harry boy";
}

// @ts-expect-error
export function getCouponProductVariant(game, coupon, context = {}) {
    if (!coupon) return "kupong";
    if (game.type === "top7") return getTop7ProProductVariant(coupon);
    // @ts-expect-error
    const {isLiveBet, isLivePage} = context;
    if (isLiveBet) return "live";
    if (isLivePage) return "mobil live";
    const {betMethod, type} = coupon;
    if (betMethod === "harry") return getHarryProductVariant(coupon);
    if (type === CouponTypes.REDUCED) return "reducedCoupon";
    return "kupong";
}

// @ts-expect-error
export function getCouponProductNameFromGame(game) {
    const {type} = game;
    if (type === "top7") return "top7_pro";
    return type;
}

// @ts-expect-error
export function getCategory(game) {
    const sport = gameUtils.getSport(game);
    if (sport) return sport === "trot" ? "trav" : "galopp";

    return "trav/galopp";
}

// @ts-expect-error
export const toProductEventData = (game, bet, {name, variant, category, track}) => {
    const productId = formatProductId(name, track, getGameDate(game));

    if (hasBoost(bet) && hasFee(bet)) {
        return {
            product_id: [productId, productId, productId],
            product_category: [category, category, category],
            product_variant: [variant, variant, variant],
            product_name: [name, `${name} Boost`, "HarryFee"],
            product_track: [track, track, track],
        };
    }
    if (hasBoost(bet)) {
        return {
            product_id: [productId, productId],
            product_category: [category, category],
            product_variant: [variant, variant],
            product_name: [name, `${name} Boost`],
            product_track: [track, track],
        };
    }
    if (hasFee(bet)) {
        return {
            product_id: [productId, productId],
            product_category: [category, category],
            product_variant: [variant, variant],
            product_name: [name, "HarryFee"],
            product_track: [track, track],
        };
    }
    return {
        product_id: [productId],
        product_category: [category],
        product_variant: [variant],
        product_name: [name],
        product_track: [track],
    };
};

export const toProductAndBetEventData = (
    // @ts-expect-error
    eventType,
    // @ts-expect-error
    game,
    // @ts-expect-error
    bet,
    // @ts-expect-error
    coupon,
    // @ts-expect-error
    reductionTerms,
    // @ts-expect-error
    conditions,
    // @ts-expect-error
    {name, variant, category, track},
) => {
    const productData = {
        purchase_event: eventType,
        ...toProductEventData(game, bet, {
            name,
            variant,
            category,
            track,
        }),
        ...getBetData(bet, coupon, reductionTerms),
    };

    if (eventType !== "purchase" && eventType !== "buy_share") return productData;
    return {
        ...productData,
        ...getPurchaseData(bet, game, conditions),
    };
};

export const toShareEventData = (
    // @ts-expect-error
    productType,
    // @ts-expect-error
    eventType,
    // @ts-expect-error
    game,
    // @ts-expect-error
    coupon,
    // @ts-expect-error
    reductionTerms,
    // @ts-expect-error
    conditions,
    // @ts-expect-error
    {name, variant, category, track},
) => {
    // SharedBet purchases are handled in a slight different way then normal,
    // due to its not a accutal purchase, therefore the product type is tracked instead of the standard eventType.
    const productData = {
        event: productType,
        ...toProductEventData(game, coupon, {
            name,
            variant,
            category,
            track,
        }),
        // `getBetData` still uses the legacy `bet` object, but for this particular flow we only
        // have (and need) the `coupon` object
        ...getBetData(coupon, coupon, reductionTerms),
    };
    if (eventType !== "purchase" && eventType !== "buy_share") return productData;
    return {
        ...productData,
        ...getPurchaseData(coupon, game, conditions),
    };
};

// @ts-expect-error
export const productEvent = (eventType, product, context) => {
    const {game, coupon, reductionTerms, bet, conditions} = product;
    const betOrCoupon = bet || coupon;

    switch (product.type) {
        case TOP7_PRODUCT:
            return toProductAndBetEventData(
                eventType,
                game,
                betOrCoupon,
                coupon,
                reductionTerms,
                conditions,
                {
                    name: "top7",
                    variant: "auto",
                    category: getCategory(game),
                    track: gameUtils.getTracksNames(game, "-"),
                },
            );
        case FILE_BET_PRODUCT:
            return toProductAndBetEventData(
                eventType,
                game,
                betOrCoupon,
                coupon,
                reductionTerms,
                conditions,
                {
                    name: game.type,
                    variant: "fil",
                    category: getCategory(game),
                    track: gameUtils.getTracksNames(game, "-"),
                },
            );
        case HARRY_PRODUCT: {
            const harryFlavorName = coupon.harryFlavour
                ? ` ${getHarryFlavorInfo(coupon.harryFlavour).name}`
                : "";

            return toProductAndBetEventData(
                eventType,
                game,
                betOrCoupon,
                coupon,
                reductionTerms,
                conditions,
                {
                    name: game.type,
                    variant: `harry boy${harryFlavorName}`,
                    category: getCategory(game),
                    track: gameUtils.getTracksNames(game, "-"),
                },
            );
        }
        case SHARED_BET_COUPON_PRODUCT: {
            // Lägg andelsspel
            return toProductAndBetEventData(
                eventType,
                game,
                betOrCoupon,
                coupon,
                reductionTerms,
                conditions,
                {
                    name: getCouponProductNameFromGame(game),
                    variant: `${getCouponProductVariant(
                        game,
                        coupon,
                        context,
                    )}_andel_privat`,
                    track: gameUtils.getTracksNames(game, "-"),
                    category: getCategory(game),
                },
            );
        }
        case SHARED_BET_CREATE_PRODUCT:
        case SHARED_BET_SHARE_PRODUCT: {
            // Köp andel till andelsspel
            return toShareEventData(
                product.type,
                eventType,
                game,
                coupon,
                reductionTerms,
                conditions,
                {
                    name: getCouponProductNameFromGame(game),
                    variant: `${getCouponProductVariant(
                        game,
                        coupon,
                        context,
                    )}_andel_privat`,
                    track: gameUtils.getTracksNames(game, "-"),
                    category: getCategory(game),
                },
            );
        }
        case SHOP_SHARE_COUPON_PRODUCT: {
            return toProductAndBetEventData(
                eventType,
                game,
                betOrCoupon,
                coupon,
                reductionTerms,
                conditions,
                {
                    name: getCouponProductNameFromGame(game),
                    variant: "andel",
                    track: gameUtils.getTracksNames(game, "-"),
                    category: getCategory(game),
                },
            );
        }
        case SHOP_SHARE_PRODUCT: {
            return toShareEventData(
                product.type,
                eventType,
                game,
                coupon,
                reductionTerms,
                conditions,
                {
                    name: getCouponProductNameFromGame(game),
                    variant: "andel",
                    track: gameUtils.getTracksNames(game, "-"),
                    category: getCategory(game),
                },
            );
        }
        case COUPON_PRODUCT: {
            return toProductAndBetEventData(
                eventType,
                game,
                betOrCoupon,
                coupon,
                reductionTerms,
                conditions,
                {
                    name: getCouponProductNameFromGame(game),
                    variant: getCouponProductVariant(game, coupon, context),
                    track: gameUtils.getTracksNames(game, "-"),
                    category: getCategory(game),
                },
            );
        }
        // TODO: verify with analytics and fix
        case HARRY_BAG_PRODUCT_VARENNE: {
            if (eventType !== "purchase") return null;

            const betGame = bet.game;
            return toProductAndBetEventData(
                eventType,
                betGame,
                betOrCoupon,
                coupon,
                reductionTerms,
                conditions,
                {
                    name: betGame.type,
                    variant: "harry bag",
                    category: getCategory(betGame),
                    track: gameUtils.getTracksNames(betGame, "-"),
                },
            );
        }
        case HARRY_BAG_PRODUCT: {
            if (eventType !== "purchase") return null;

            const betGame = bet.game;
            return toProductAndBetEventData(
                eventType,
                betGame,
                betOrCoupon,
                coupon,
                reductionTerms,
                conditions,
                {
                    name: betGame.type,
                    variant: "harry bag",
                    category: getCategory(betGame),
                    track: gameUtils.getTracksNames(betGame, "-"),
                },
            );
        }
        default:
            return null;
    }
};

// @ts-expect-error
export const productDetails = (product, context = {}) =>
    productEvent("product_detail", product, context);

// @ts-expect-error
export const productAdd = (product, context = {}) =>
    productEvent("product_add", product, context);

// @ts-expect-error
export const productCheckout = (product, context = {}) =>
    productEvent("checkout", product, context);

// @ts-expect-error
export const productPurchase = (product, context = {}) =>
    productEvent("purchase", product, context);

// @ts-expect-error
export const buyShare = (product, context = {}) =>
    productEvent("buy_share", product, context);

// @ts-expect-error
export function getSubscriptionEventData({maxAmount: amount, betType} = {}) {
    if (!betType || !amount) return null;

    const value = isNumber(amount) ? (amount / 100).toString() : "";
    const productName = `pren_${betType}_harry boy`;

    return {
        purchase_event: "subscription",
        product_subscription_name: [productName],
        product_subscription_value: [value],
    };
}

// @ts-expect-error
export function getSubscriptionData(bet) {
    if (!bet || isEmpty(bet.harrySubscription)) return null;

    return getSubscriptionEventData(bet.harrySubscription);
}

// @ts-expect-error
export const productSubscribe = ({bet}) => getSubscriptionData(bet);
