import * as LiveActions from "../liveactions";

export const INITIAL_SORT_OPTIONS_STATE = {column: "starttime", ascending: true};
export type LiveBetSortOptionsState = {
    columnn: string;
    ascending: boolean;
};
export function liveBetSortOptions(
    state: LiveActions.SortOptions = INITIAL_SORT_OPTIONS_STATE,
    action: {type: string; payload: LiveActions.SortOptions},
) {
    const {type, payload} = action;
    switch (type) {
        case LiveActions.CHANGE_BET_SORT_OPTIONS:
            return payload;
        default:
            return state;
    }
}
