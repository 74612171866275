export enum ErrorCodes {
    /**
     * "Too many requests. Please try again later."), // The job queue on this pod is full, please retry and see if you end up on other pod with room. If this happens however it's bad and we need to analyze what to do. Start more pods? If so, can reduction calculator and other services cope with that without scaling them up?
     */
    HBB_00011,

    /**
     * "Incoming bet was rejected due to bad/missing properties in the coupon"), // For example if CouponType is not REDUCED or stake is null
     */
    HBB_00101,

    /**
     * "This coupon has already been used to place a bet"), // Coupon's batchBetId property is already populated with a number
     */
    HBB_00102,

    /**
     * "The user already have an active batch bet job"), // User already have a (recent) job with status ACCEPTED or IN_PROGRESS - there are internal admin endpoints to change status if it's incorrect and user is in a hurry, otherwise after a few minutes those statuses will no longer have any effect
     */
    HBB_00111,

    /**
     * "Unable to find a job matching the given batch bet job id"), // No job in database matched this batchBetJobId
     */
    HBB_00201,

    /**
     * "Unable to find a job matching the given coupon id"), // No job in database matched this couponId
     */
    HBB_00202,

    /**
     * "Unable to persist job (bet)"), // Unable to write/persist the posted job to storage/database. Connectivity issues to database perhaps?
     */
    HBB_00501,

    /**
     * "Bet did not validate against racing information"), // Check vs Racing Info found some errors, like you tried to mark a horse start number in a leg that doesn't exist in that leg
     */
    HBB_10021,

    /**
     * "Unable to validate system due to some unexpected communication problem"), // Unknown error when talking to Racing Info, see logs for more information
     */
    HBB_10022,

    /**
     * "There were 0 coupons (rows) left in the system after reduction. The restrictions are set too hard or the frame is incomplete."), // User "error" = user don't know how to properly use the reduction tool...
     */
    HBB_10031,

    /**
     * "Unable to reduce system due to some unexpected communication problem"), // Unknown error when talking to Reduction Calculator, see logs for more information
     */
    HBB_10032,

    /**
     * "Final reduction didn't match the supplied reduction metadata"), // When placing the bet, the coupon include metadata from the last metadata request. Information in that metadata is compared to information from the final reduction and optimization to make sure the number of systems and rows (=cost) presented to the user when hitting "place bet" is still identical after the final reduction, so bet doesn't end up costing something else. But you need to see https://jira-atg.riada.cloud/browse/HRS2-1345 - as that ticket currently makes this check useless
     */
    HBB_10041,

    /**
     * "The given system would exceed the user's total amount of allowed systems for this pool"), // The check done with HBS if such a batch bet would be allowed to hand in resulted in a negative answer. The amount of systems will exceed what ever amount of systems the user have left to place in the given pool.
     */
    HBB_10051,

    /**
     * "The batch bet was not accepted by the system"), // Already when we did a POST to HBS with the BatchBetRequest we got REJECTED in return. Not sure if this scenario is possible actually or if REJECTED only can be returned after confirming the bet. If it happens there will hopefully be more information returned to the client, as well as in the logs.
     */
    HBB_10081,

    /**
     * "Failed to create the batch bet due to some unexpected communication problem"), // Unknown error when talking to Horse Betting System, see logs for more information
     */
    HBB_10082,

    /**
     * "Unable to read coupon to determine if it has been used already to place a bet"), // Unknown error when talking to Coupon Service, see logs for more information
     */
    HBB_10092,

    /**
     * "The bet was rejected when trying to confirm it"), // HBS responded with REJECTED directly when confirming the batch bet. Not sure if this is even possible, it should always respond with IN_QUEUE and then after polling for result, then you can get status REJECTED... If this happens hopefully there will be more information in the logs in HBS
     */
    HBB_10101,

    /**
     * "Failed to confirm the batch bet"), // Unknown error when talking to Horse Betting System, see logs for more information
     */
    HBB_10102,

    /**
     * "The batch bet was rejected by HBS, one likely cause is that pool was closed (no more bets)."), // HBS responded with REJECTED instead of DONE. This happens for example if bet was closed for bets from the time we created the bet until we confirm it (this has happened), if so there should be more information in the logs and now hopefully also back to the client. We've seen this as well when CBI were unable to place bet in Norway, but then HBS did not log anything... only core betting systems had logs saying the problem was in CBI/Norway
     */
    HBB_10121,

    /**
     * "The batch bet failed after confirming it in HBS (it was neither accepted, nor rejected)"), // This should not happen, check logs for more information. But this for example if HBS would have replied with MISSING when we started to poll for the confirmed batch bet. So you need to check HBS logs for more information..
     */
    HBB_10122,

    /**
     * "Unexpected communication problem when attempting to update coupon with batchBetId"), // Unknown problem when talking to coupon service, see logs for more information
     */
    HBB_10131,

    /**
     * "Unexpected (error) reply once polling of batch bet was completed"), // This probably can't happen. Polling HBS for result of betting resulted is 'DONE' (=successful) but HBS responded with an error message anyways...
     */
    HBB_10199,

    /**
     * "One or more of the coupons generated when performing reduction and coupons optimization does not validate correctly"),
     */
    HBB_20001,

    /**
     *("Unable to turn the reduced system and optimized into a valid bet request"), // Something happened when calling BatchBetRequestFromCouponTranslator.translate() that caused an exception. Either one of the coupons returned from reduction calculator failed in validation or somehow there was an attempt to create a batch bet for a non-supported BetType
     */
    HBB_20011,

    /**
     * ("General error") // Non specific errors
     */
    HBB_99001,

    /**
     * "Unknown Error",
     */
    U_99991,

    /**
     * "Unknown Error",
     */
    U_99999,

    /**
     * "There was a problem parsing the payload. Please double check your request.",
     */
    PAYLOAD_00001,

    /**
     * "It seems there is some mandatory parameter or payload that is missing or incorrect in the request.",
     */
    PAYLOAD_00101,

    /**
     * "It was possible to deserialize the payload, but it failed validation",
     */
    PAYLOAD_10001,

    /**
     * "There was an unexpected internal server error. Please try again or contact support if problem persists.",
     */
    BASE_00101,

    /**
     * "There was an unexpected internal server error. Please try again or contact support if problem persists.",
     */
    BASE_00201,

    /**
     * "There was an unexpected http reply. Please try again or contact support if problem persists.",
     */
    CLIENT_00499,

    /**
     * "There was an unexpected http communication error. Please try again or contact support if problem persists.",
     */
    CLIENT_00599,

    /**
     * "There was an unexpected error. Please try again or contact support if problem persists.",
     */
    CLIENT_UNKNOWN,

    /**
     * "Most likely the client request was incorrect in some way, see HTTP status and reason",
     */
    HTTP_STATUS,

    /**
     * "The posted data appears not to be valid json. Please double check.",
     */
    JSON_00001,

    /**
     * "While content appear to be valid json, the data structure was not recognized. Please double check.",
     */
    JSON_00101,

    /**
     * "System temporarily overloaded. Please try again.",
     */
    R4BLK_00001,

    /**
     * "Coupon update conflict, the stored coupon is newer than the one you are trying to save", // Bad 'lastUpdated' timestamp in the coupon when doing a PUT
     */
    HCS_00121,

    /**
     * "Coupon not found", // We got a 404 from coupon service when asking for a couponId
     */
    HCS_00404,

    /**
     * "Authentication problem", // Service had an invalid s2s jwt, so Coupon Service denied the request
     */
    HCS_50001,

    /**
     * "Unknown error",
     */
    HCS_99991,

    /**
     * "User is not allowed to place the bet due to for example responsible gambling issues or insufficient funds. See details for more information.",
     */
    HBS_00101,

    /**
     * "User is not allowed to play such a bet to the given pool", // The amount of systems would exceed the user's remaining systems in that pool
     */
    HBS_00102,

    /**
     * "User is temporarily excluded from placing bets by Customer Service"
     */
    HBS_00103,

    /**
     * "Bet was rejected",
     */
    HBS_00111,

    /**
     * "There is no such bet", // We got a 404 from HBS when we asked for a batchBetId
     */
    HBS_00404,

    /**
     * "Authentication problem", // Service had an invalid s2s jwt, so Horse Betting Service denied the request
     */
    HBS_50001,

    /**
     * "New bets are currently not accepted", // Betting system is closed for the day
     */
    HBS_99901,

    /**
     * "There is a temporary connectivity problem",
     */
    HBS_99951,

    /**
     * "Unknown error",
     */
    HBS_99991,

    /**
     * "The response appears not to be valid json", // JsonParseException
     */
    HTTPCE_00001,

    /**
     * "While the response appear to be valid json, the data structure was not recognized.", // JsonProcessingException
     */
    HTTPCE_00101,

    /**
     * "There was a problem parsing the incoming data",
     */
    HTTPCE_00199,

    /**
     * "There was some unexpected problem after communication was established. Please try again or contact support if problem persists.",
     */
    HTTPCE_50001,

    /**
     * "There was an unexpected error. Please try again or contact support if problem persists.",
     */
    HTTPCE_UNKNOWN,

    /**
     * "There was an unexpected problem in internal communications. Please try again or contact support if problem persists.",
     */
    HTTPCE_004xx,

    /**
     * "There was an unexpected http communication error. Please try again or contact support if problem persists.",
     */
    HTTPCE_005xx,

    /**
     * "Game or pool not found", // We got a 404 in return from Racing Info when asking for a specific game or pool
     */
    RI_00404,

    /**
     * "Unknown error",
     */
    RI_99991,

    /**
     * "The coupon you are trying to reduce is not valid", // Bean validation or other extra validation failed
     */
    HRC_00101,

    /**
     * "Any single leg can not have more than 20 starting horses", // Some french V4 races have up to 20 starting horses per leg. This is the maximum supported amount of horses both here and in the core betting system.
     */
    HRC_00111,

    /**
     * "There must be an integer array called bets in each race in the races array", // Bad coupon was posted to the calculator
     */
    HRC_00112,

    /**
     * "Only MarkingBetPool is supported by expected outcome restrictions", // In order to calculate expected outcome we need the MarkingBetPool. For Vxy games all info is there, but for Vx games we also need returnToPlayer information. If error message include pool name 'Pool', means to pool was sent at all and the default constructor kicked in.
     */
    HRC_00113,

    /**
     * "The BetType is not a supported bet type for system reduction",
     */
    HRC_00114,

    /**
     * "Coupon was valid for reduction, but the response from the reducer could not be read", // The coupon was accepted for reduction, we got a 200 OK in return, but the payload could not be read. Bad network connection (dropped packages) maybe???
     */
    HRC_80001,

    /**
     * "Unknown error",
     */
    HRC_99991,
}
