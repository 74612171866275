import {RouterActions} from "@atg-shared/router";
import {SELECT_RACE, CHANGE_GAME_PATH} from "atg-horse-game/domain/gameActions";

const selectedRace = (state = {}, action: any) => {
    const {type, payload} = action;
    switch (type) {
        case RouterActions.ROUTER_ON_LOCATION_CHANGED:
            return {};
        case SELECT_RACE: {
            const {game, raceId} = payload;
            return {
                id: raceId,
                gameId: game.id,
            };
        }
        case CHANGE_GAME_PATH:
            return {
                ...state,
                path: payload.url,
            };
        default:
            return state;
    }
};

export default selectedRace;
