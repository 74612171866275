// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    CouponSelectors,
    HarryBoyFeeSelectors,
    CouponUtils,
    HarryBetLimitConverters,
} from "@atg-horse-shared/coupon";
import {CouponTypes} from "@atg-horse-shared/coupon-types";
import type {Coupon} from "@atg-horse-shared/coupon-types";
import * as UserSelectors from "@atg-global-shared/user/userSelectors";
import * as LoadingStatusUtils from "@atg-shared/response-mapping/deprecated_loadingStatus";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {ReducedBetsSelectors, getReducedCost} from "@atg-horse/reduced-bets";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    SharedBetSelectors,
    SharedBetProducts,
    type SharedBetShareProduct,
} from "@atg-horse/shared-bet";
import * as Products from "./products";
import type {Product, CouponProduct, Top7Product} from "./products";
import * as PurchaseSteps from "./purchaseSteps";
import type {State} from "./index";

type ProductState = Product | null;

export const getPurchaseState = (state: State) => state.purchase;

// since our reducers are lazy now, they are not always present.
export const getCurrentPurchaseStep = (state: State) => state.purchase?.currentStep;

export const getCurrentPurchaseStepId = (state: State) =>
    getCurrentPurchaseStep(state)?.id || null;

export const isPurchasing = (state: State): boolean =>
    getCurrentPurchaseStepId(state) !== null;

export const getReceipt = (state: State) => getCurrentPurchaseStep(state).receipt || null;

export const getReducedBet = (state: State) =>
    getCurrentPurchaseStep(state).reducedBet || null;

export const getProduct = (state: State): ProductState | null =>
    getCurrentPurchaseStep(state)?.product || null;

export const getReducedProduct = (state: State): ProductState | null => {
    const product = getCurrentPurchaseStep(state)?.product || null;
    return (
        // @ts-expect-error
        (product?.coupon?.type === CouponTypes.REDUCED &&
            // @ts-expect-error
            product.reductionTerms &&
            product) ||
        null
    );
};

export const shouldShowProductCost = (state: State): boolean => {
    const product: ProductState = getProduct(state);

    if (!product) return false;
    const {type} = product;
    if (
        type === SharedBetProducts.SHARED_BET_CREATE_PRODUCT ||
        type === SharedBetProducts.SHARED_BET_COUPON_PRODUCT
    )
        return true;
    return getCurrentPurchaseStepId(state) !== PurchaseSteps.CONFIRM;
};

export const getCid = (state: State): string | null | undefined => {
    const product: ProductState = getProduct(state);
    // @ts-expect-error
    return product ? product.cid : null;
};

export const isConfirming = (state: State): boolean =>
    getCurrentPurchaseStepId(state) === PurchaseSteps.CONFIRM;

export const isDepositing = (state: State): boolean =>
    getCurrentPurchaseStepId(state) === PurchaseSteps.DEPOSIT;

export const isReceipt = (state: State): boolean =>
    getCurrentPurchaseStepId(state) === PurchaseSteps.RECEIPT;

export const getCoupon = (state: State): Coupon | null | undefined => {
    const cid: string | null | undefined = getCid(state);

    if (!cid) return null;
    return CouponSelectors.getCoupon(state, cid);
};

const getHarryProductCost = (state: State): number | null => {
    const coupon = getCoupon(state);
    return coupon ? CouponUtils.getCostWithAddons(coupon) : null;
};

/**
 * Get the total cost, including fee, of the Top 7 product
 *
 * Note that if the fee is unknown (e.g. because the backend is slow to respond), we return `false`.
 */
const getTop7ProductCost = (
    state: State,
    product: Top7Product,
): number | boolean | null => {
    // product.amount is available when playing from product page
    const productAmount = product.amount;
    // product.amount is not available when playing from Banner/URL
    const top7Coupon = getCoupon(state);

    const feeBrackets = HarryBoyFeeSelectors.getHarryBoyFeeBrackets(state);
    const stake = top7Coupon && top7Coupon.stake;
    const finalAmount = productAmount || stake || 0;

    // TODO: check with fee brackets
    return product.coupon && feeBrackets && product.coupon.betMethod === "harry"
        ? HarryBetLimitConverters.addFeeToBetLimit(finalAmount, feeBrackets, "top7")
        : finalAmount;
};

const getHarryBagProductCost = (state: State): number | null => {
    const harryBagsCoupon = getCoupon(state);
    return harryBagsCoupon && harryBagsCoupon.cost !== undefined
        ? harryBagsCoupon.cost
        : null;
};

const getHarrySubscriptionProductCost = (state: State): number | null => {
    const harrySubCoupon = getCoupon(state);
    return harrySubCoupon && harrySubCoupon.amount !== undefined
        ? harrySubCoupon.amount
        : null;
};

const getCouponProductCost = (state: State, product: CouponProduct): number | null => {
    const coupon = CouponSelectors.getCoupon(state, product.cid);
    if (!coupon) return null;

    const reductionTerms = ReducedBetsSelectors.getReductionTerms(state, product.cid);

    // Ugly hack just to get top7 to behave correctly
    if (coupon.game && coupon.game.type === "top7" && coupon.betMethod === "harry") {
        return coupon.harryBetLimit;
    }

    if (coupon.type === CouponTypes.REDUCED) {
        return getReducedCost(coupon, reductionTerms);
    }

    return CouponUtils.getCostWithAddons(coupon);
};

const getSharedBetShareMyShareCost = (
    state: State,
    product: SharedBetShareProduct,
): number | null => {
    const params = SharedBetSelectors.getPurchaseParams(state);
    const {conditions} = product;
    const nrShares = params && params.nrShares;
    const shareCost = conditions && conditions.shareCost;
    // @ts-expect-error
    return nrShares && shareCost ? nrShares * shareCost : null;
};

export const getSharedBetCreateMyShareCost = (state: State): number | null => {
    const params = SharedBetSelectors.getCreateParams(state);
    return params && params.shareCost && params.nrShares
        ? params.nrShares * params.shareCost
        : null;
};

export const getProductCost = (state: State): number | null => {
    const product: ProductState = getProduct(state);

    if (!product) return null;

    switch (product.type) {
        case Products.HARRY_PRODUCT:
            return getHarryProductCost(state);
        case Products.TOP7_PRODUCT: {
            const top7cost = getTop7ProductCost(state, product);
            if (typeof top7cost === "boolean") return null;
            return top7cost;
        }
        case Products.HARRY_BAG_PRODUCT:
            return getHarryBagProductCost(state);
        case Products.HARRY_SUBSCRIPTION_PRODUCT:
            return getHarrySubscriptionProductCost(state);
        case SharedBetProducts.SHARED_BET_SHARE_PRODUCT:
            return getSharedBetShareMyShareCost(state, product);
        case SharedBetProducts.SHARED_BET_CREATE_PRODUCT:
        case SharedBetProducts.SHARED_BET_COUPON_PRODUCT:
        case Products.COUPON_PRODUCT:
            // @ts-expect-error
            return getCouponProductCost(state, product);
        default:
            return null;
    }
};

export const getProductDepositCost = (state: State): number | null => {
    const product: ProductState = getProduct(state);

    if (!product) return null;

    switch (product.type) {
        case SharedBetProducts.SHARED_BET_CREATE_PRODUCT:
            return getSharedBetCreateMyShareCost(state);
        default:
            return getProductCost(state);
    }
};

export const isEnoughMoney = (state: State): boolean => {
    const cost = getProductCost(state);
    if (cost === null) return true;

    const balanceAmount = UserSelectors.getBalanceAmount(state);
    return (balanceAmount || 0) - cost >= 0;
};

export const getPurchaseStatus = (state: State): any => state.purchase.status;

export const isLoading = (state: State): boolean => {
    const status: any = getPurchaseStatus(state);
    return LoadingStatusUtils.isLoading(status);
};

export const getClosePurchaseModalDisabled = (state: State): boolean =>
    getPurchaseStatus(state).purchaseInProcess;

export const isHarryOrTop7Product = (state: State): boolean => {
    const product = getProduct(state);
    if (!product) return false;
    return [
        Products.HARRY_BAG_PRODUCT,
        Products.HARRY_PRODUCT,
        Products.HARRY_SUBSCRIPTION_PRODUCT,
        Products.TOP7_PRODUCT,
    ].includes(product?.type);
};

export const isCouponProduct = (state: State): boolean => {
    const product = getProduct(state);
    if (!product) return false;
    return [Products.COUPON_PRODUCT].includes(product?.type);
};
