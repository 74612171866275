import type {FetchAction} from "@atg-shared/fetch-types";
import {FETCH, call} from "@atg-shared/fetch-redux";
import * as TrackAPI from "./trackApi";
import type {ProgramChanges} from "./trackApi";

export const REQUEST_PROGRAM_CHANGES = "calendar/requestProgramChanges";
export const RECEIVE_PROGRAM_CHANGES = "calendar/receiveProgramChanges";

export type FetchProgramChangesAction = FetchAction<
    typeof REQUEST_PROGRAM_CHANGES,
    typeof RECEIVE_PROGRAM_CHANGES,
    ProgramChanges
>;

type Payload = {
    trackId: string;
    date: string;
};

export const fetchProgramChanges = ({
    trackId,
    date,
}: Payload): FetchProgramChangesAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_PROGRAM_CHANGES,
        receiveAction: RECEIVE_PROGRAM_CHANGES,
        callApi: call(TrackAPI.fetchProgramChanges, trackId, date),
    },
});
