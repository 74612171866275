import {map, some, find, uniq, compact, last, tail, get} from "lodash";
import {join} from "lodash/fp";
import root from "window-or-global";
import dayjs from "dayjs";
import {slugifyNoDash} from "@atg/utils/strings";
import {getDivisionNumberFromPath} from "@atg-horse-shared/utils/game";
import {
    isGameType,
    isSingleRaceGameType,
    type GameType,
} from "@atg-horse-shared/game-types";

function createTrackSlug(track: any) {
    const tracks = [].concat(track).map((t: any) => slugifyNoDash(t.name));
    return tracks.join("-");
}

/**
 * Gets search query string from URL and adds back
 * @param {Array<string>} urlParts
 */
function joinWithSearchQuery(urlParts: Array<string>) {
    const queryString = get(root, "location.search", "");

    return `${urlParts.join("/")}${queryString}`;
}

export function buildFromParts({date, tracks, gameType, race, showResults}: any) {
    const parts = ["/spel", dayjs(date).format("YYYY-MM-DD")];

    if (gameType) parts.push(gameType);
    if (tracks) parts.push(createTrackSlug(tracks));
    if (isSingleRaceGameType(gameType)) parts.push(`lopp/${race.number}`);
    if (showResults) parts.push("resultat");

    return parts.join("/");
}

export const buildSimpleUrl = ({trackNames, raceId, gameType}: any) => {
    if (raceId) return `/spel/${raceId}`;

    return `/spel/${gameType}/${trackNames.join("-")}`;
};

export const buildSingleRaceUrl = ({
    game,
    race,
    showResults = false,
    type,
    addQuery = false,
}: any) => {
    const gameRace = game.races[0];
    const startTime = gameRace.scheduledStartTime || gameRace.startTime;
    const datePart = gameRace.date ?? dayjs(startTime).format("YYYY-MM-DD");
    const gamePart = game.type;
    const trackPart = slugifyNoDash(gameRace.track.name);
    const isTop7 = game.type === "top7";
    const raceNumberPart = !isTop7 && race && race.number ? `lopp/${race.number}` : "";
    const resultsPart = showResults ? "resultat" : "";

    const urlParts = compact([
        "/spel",
        type,
        datePart,
        gamePart,
        trackPart,
        raceNumberPart,
        resultsPart,
    ]);

    if (addQuery) {
        // we want to add search query back
        return joinWithSearchQuery(urlParts);
    }

    return urlParts.join("/");
};

export const buildDivisionGameUrl = ({
    game,
    legNumber,
    showResults = false,
    useRaceNumberInsteadOfleg = false,
    type,
    addQuery = false,
}: any) => {
    const gameRace = game.races[0];
    const startTime = gameRace.scheduledStartTime || gameRace.startTime;
    const datePart = dayjs(startTime).format("YYYY-MM-DD");
    const gamePart = game.type;
    const tracks = uniq(map(game.races, (_race) => slugifyNoDash(_race.track.name)));
    const tracksPart = tracks.join("-");

    const legPrefix = useRaceNumberInsteadOfleg ? "lopp" : "avd";

    const legNumberPart = legNumber ? `${legPrefix}/${legNumber}` : "";
    const resultsPart = showResults ? "resultat" : "";

    const urlParts = compact([
        "/spel",
        type,
        datePart,
        gamePart,
        tracksPart,
        legNumberPart,
        resultsPart,
    ]);

    if (addQuery) {
        // we want to add search query back
        return joinWithSearchQuery(urlParts);
    }

    return urlParts.join("/");
};

export const buildWithId = (game: any) => {
    let url = `/spel/${game.id}`;

    const legNumber = game.leg;
    if (legNumber) {
        url += `/avd/${legNumber}`;
    }

    return url;
};

export const getGamePath = (pathname: string): Array<string> =>
    tail(tail(pathname.split("/")));

export const getGameTypeFromPath = (
    path?: Array<string>,
): GameType | null | undefined => {
    if (!path || !path.length) return null;

    const result = path.reduce(
        // @ts-expect-error
        (acc, part: string) => (isGameType(part) ? part : acc),
        null,
    );
    return result as GameType;
};

export const getDateFromPath = (path?: Array<string>): string | null | undefined => {
    if (!path || !path.length) return null;

    const pathDateRegExp = /^\d{4}-\d{2}-\d{2}$/;
    return find(path, (part) => pathDateRegExp.test(part)) || null;
};

export const getTrackFromPath = (path?: Array<string>): string | null | undefined => {
    if (!path || path.length < 2) return null;

    if (path[0] === "kalender") return null;

    if (path.length === 2) {
        if (isGameType(path[1])) return null; // URL: /date/gameType

        if (path[1] === "resultat") return null; // URL: /gameType/resultat

        return path[1];
    }

    if (path[0] === "reducerat") {
        return path[3];
    }

    return path[2];
};

export const getGameIdWithTrackNameFromPath = (path: string) => {
    const gamePath = getGamePath(path);
    return `${getGameTypeFromPath(gamePath)}_${getDateFromPath(
        gamePath,
    )}_${getTrackFromPath(gamePath)}`;
};

export const getRaceNumberFromPath = (
    path?: Array<string>,
): number | null | undefined => {
    if (!path || path.length < 4) return null;

    const raceParam = path.indexOf("lopp");
    if (raceParam === -1) return null;
    const raceNumber = path[raceParam + 1];

    return parseInt(raceNumber, 10);
};

export const getResultFromPath = (path?: Array<string>): boolean => {
    if (!path || path.length < 2) return false;

    return some(path, (part) => part === "resultat");
};

export const getReducedCoupon = (path: any) =>
    find(path, (pathItem) => pathItem === "reducerat");

export const getHarryBoy = (locationSearch?: string): boolean => {
    if (!locationSearch) return false;
    return locationSearch.includes("harryboy");
};

export type ParsedPath = {
    date: string | null | undefined;
    divisionNumber: number | null | undefined;
    gameType: GameType | null | undefined;
    harryBoy: boolean;
    raceNumber: number | null | undefined;
    result: boolean;
    track: string | null | undefined;
    type: string | null | undefined;
};

export const parsePath = (path?: Array<string>, search?: string): ParsedPath => ({
    date: getDateFromPath(path),
    divisionNumber: getDivisionNumberFromPath(path),
    gameType: getGameTypeFromPath(path),
    harryBoy: getHarryBoy(search),
    raceNumber: getRaceNumberFromPath(path),
    result: getResultFromPath(path),
    track: getTrackFromPath(path),
    type: getReducedCoupon(path),
});

/**
 * Creates a reducerad path based on the `gamePath`
 */
export const createReducedGamePath = (pathname: string) =>
    join("/", ["/spel/reducerat", ...getGamePath(pathname)]);
