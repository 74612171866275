import {takeEvery, select, call} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import {difference} from "lodash";
import {fetchGame} from "@atg-horse-shared/racing-info-api";
import {
    subscribe,
    checkVersionedPushMessageState,
    REFETCH_NEEDED,
    PUSH_IN_SYNC,
} from "@atg-frame-shared/push";
import * as GameSelectors from "./gameSelectors";
import {
    RESERVE_GAME,
    RELEASE_GAME,
    receiveGamePush,
    type ReserveGameAction,
    type JSONPatchData,
} from "./gameActions";

type PushListener = {
    unsubscribe: () => void;
};

type PushListeners = {
    [key: string]: PushListener;
};

type SubscribeFunction = (
    topicName: string,
    messageCallBack: (...args: Array<any>) => any,
) => Promise<() => Promise<void>>;

const PUSH_TOPIC_ROOT: "racinginfo/game/" = "racinginfo/game/" as const;

export const createMessageCallback =
    (
        dispatch: (...args: Array<any>) => any,
        getState: (...args: Array<any>) => any,
        topic: string,
        gameId: string,
    ) =>
    async (message: JSONPatchData) => {
        const localVersion = GameSelectors.getGameVersionById(getState(), gameId);

        const pushStatus: string = checkVersionedPushMessageState(
            topic,
            localVersion as number,
            message,
        );

        switch (pushStatus) {
            case PUSH_IN_SYNC:
                dispatch(receiveGamePush(message));
                break;
            case REFETCH_NEEDED: {
                const response = await fetchGame(gameId, false, message.version);
                dispatch(receiveGamePush(response.data));
                break;
            }
            default:
                break;
        }
    };

export function* createListener(
    gameId: string,
    subscribeFn: SubscribeFunction,
    dispatch: (...args: Array<any>) => any,
    getState: (...args: Array<any>) => any,
): SagaIterator<{unsubscribe: () => unknown}> {
    const topic = PUSH_TOPIC_ROOT + gameId;
    const messageCallback = yield call(
        createMessageCallback,
        dispatch,
        getState,
        topic,
        gameId,
    );

    const unsubscribe = yield call(subscribeFn, topic, messageCallback);

    return {unsubscribe};
}

export const unsubscribeAndDeletePushListeners = (
    gameIds: Array<string>,
    listeners: PushListeners,
): void => {
    gameIds.forEach((gameId) => {
        const listener = listeners[gameId];
        if (!listener) return;

        listener.unsubscribe();
        delete listeners[gameId];
    });
};

export function* removePushListener(listeners: PushListeners): SagaIterator<void> {
    const reservedGames = yield select(GameSelectors.getGameReservations);

    const reservedGameIds = Object.keys(reservedGames);
    const listeningGameIds = Object.keys(listeners);

    const listenersToRemove = difference(listeningGameIds, reservedGameIds);
    yield call(unsubscribeAndDeletePushListeners, listenersToRemove, listeners);
}

export function* addPushListener(
    dispatch: (...args: Array<any>) => any,
    getState: (...args: Array<any>) => any,
    subscribeFn: SubscribeFunction,
    listeners: PushListeners,
    action: ReserveGameAction,
): SagaIterator<void> {
    const {gameId} = action.payload;
    if (listeners[gameId]) return;

    listeners[gameId] = yield call(
        createListener,
        gameId,
        subscribeFn,
        dispatch,
        getState,
    );
}

export const createInitialListeners = (): PushListeners => ({});
export const getSubscribeFn = (): SubscribeFunction => subscribe;

export function* gamePushListenerSaga(
    dispatch: (...args: Array<any>) => any,
    getState: (...args: Array<any>) => any,
): SagaIterator<void> {
    const listeners = yield call(createInitialListeners);
    const subscribeFn = yield call(getSubscribeFn);

    yield takeEvery(
        RESERVE_GAME,
        addPushListener,
        dispatch,
        getState,
        subscribeFn,
        listeners,
    );
    yield takeEvery(RELEASE_GAME, removePushListener, listeners);
}

export default gamePushListenerSaga;
