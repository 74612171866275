import {find, curry} from "lodash/fp";
import {createSelector} from "reselect";
import type {Channel} from "../video/videoReducer";
import type {GlobalVideoState} from "../video";
// eslint-disable-next-line import/no-cycle
import * as VideoSelectors from "./videoSelectors";
import * as PlayerReducer from "./playerReducer";

export const getSelectedStream = (state: GlobalVideoState) =>
    state.video.liveVideo.selectedQuality;
export const getSelectedChannel = (state: GlobalVideoState) =>
    state.video.liveVideo.selectedChannel;
export const getChannels = (state: GlobalVideoState) => state.video.liveVideo.channels;
export const existsChannels = (state: GlobalVideoState) =>
    Boolean(state.video.liveVideo.channels);
export const getChannel = (
    state: GlobalVideoState,
    channelName: string,
): Channel | null => {
    if (!state?.video?.liveVideo?.channels || !channelName) return null;

    // @ts-ignore
    return find((channel) => {
        if (channel.id === channelName || channelName.indexOf(`${channel.id}-`) === 0) {
            return channel;
        }
        return null;
    }, state.video.liveVideo.channels);
};
export const getChannelStream = (state: GlobalVideoState, channelName: string) => {
    const channel = getChannel(state, channelName);
    return channel && find((stream) => stream.id === channelName, channel.streams);
};
export const getChannelThumbnail = (state: GlobalVideoState, channelName: string) => {
    const channel = getChannel(state, channelName);
    return channel && channel.thumbnail;
};

export const getPlayer = (id: PlayerReducer.PlayerIds) => (state: GlobalVideoState) => {
    if (!state?.video?.players) return undefined;
    return state.video.players[id];
};

export const getIsAirplayAvailable =
    (id: PlayerReducer.PlayerIds) => (state: GlobalVideoState) =>
        getPlayer(id)(state)?.isAirplayAvailable ?? false;
export const getIsBuffering =
    (id: PlayerReducer.PlayerIds) => (state: GlobalVideoState) =>
        getPlayer(id)(state)?.isBuffering ?? false;
export const isInitialised = (
    state: GlobalVideoState,
    id: PlayerReducer.PlayerIds,
): boolean =>
    // @ts-ignore is not used anywhere should we still keep it?
    getPlayer(id)(state).initialised ?? false;
export const isPlaying = (
    state: GlobalVideoState,
    id: PlayerReducer.PlayerIds,
): boolean => getPlayer(id)(state)?.playing ?? false;
export const getIsPlaying =
    (id: PlayerReducer.PlayerIds) =>
    (state: GlobalVideoState): boolean =>
        getPlayer(id)(state)?.playing ?? false;
export const isTrackCompleted = (
    state: GlobalVideoState,
    id: PlayerReducer.PlayerIds,
): boolean => getPlayer(id)(state)?.trackCompleted ?? false;
export const getStreams = (state: GlobalVideoState, id: PlayerReducer.PlayerIds) =>
    getPlayer(id)(state)?.streams ?? null;

export const getQualityLevel = (state: GlobalVideoState, id: PlayerReducer.PlayerIds) =>
    getPlayer(id)(state)?.qualityLevel;

export const getLowLatencyChromeCastAvailable =
    (id: PlayerReducer.PlayerIds) => (state: GlobalVideoState) =>
        getPlayer(id)(state)?.isLowLatencyChromeCastAvailable ?? null;
export const getVolume = (state: GlobalVideoState, id: PlayerReducer.PlayerIds) =>
    getPlayer(id)(state)?.volume ?? 0;
export const getIsAirplayCalled = (
    state: GlobalVideoState,
    id: PlayerReducer.PlayerIds,
) => getPlayer(id)(state)?.isAirplayCalled ?? false;
export const v2getVolume = (id: PlayerReducer.PlayerIds) => (state: GlobalVideoState) =>
    getPlayer(id)(state)?.volume ?? 0;
export const isAudioOnly = (
    state: GlobalVideoState,
    id: PlayerReducer.PlayerIds,
): boolean => getPlayer(id)(state)?.audioOnly ?? false;
export const isAutoPlay = (
    state: GlobalVideoState,
    id: PlayerReducer.PlayerIds,
): boolean => getPlayer(id)(state)?.autoPlay ?? false;

export const getSource = (state: GlobalVideoState, id: PlayerReducer.PlayerIds) =>
    getPlayer(id)(state)?.source ?? null;
export const getPlaylist = (
    state: GlobalVideoState,
    id: PlayerReducer.PlayerIds,
): Array<string> | null => getPlayer(id)(state)?.playlist ?? null;
export const getPlaylistPos = (state: GlobalVideoState, id: PlayerReducer.PlayerIds) =>
    getPlayer(id)(state)?.playlistPos ?? null;
export const getWarmupPlaylistPos = (state: GlobalVideoState) =>
    getPlaylistPos(state, PlayerReducer.PlayerIds.WARMUP_ARCHIVE_PLAYER_ID);
export const getMediaPlaylistPos = (
    state: GlobalVideoState,
    id: PlayerReducer.PlayerIds,
) =>
    // @ts-ignore does this exist?
    getPlayer(id)(state).mediaplayListPosition ?? 0;
export const getQualityLevelsCount = (
    state: GlobalVideoState,
    id: PlayerReducer.PlayerIds,
) => getPlayer(id)(state)?.qualityLevelsCount ?? null;
export const isFullscreen = (state: GlobalVideoState, id: PlayerReducer.PlayerIds) =>
    Boolean(getPlayer(id)(state)?.fullscreen);
export const getIsFullscreen =
    (id: PlayerReducer.PlayerIds) => (state: GlobalVideoState) =>
        Boolean(getPlayer(id)(state)?.fullscreen);
export const getStreamName = (state: GlobalVideoState, id: PlayerReducer.PlayerIds) =>
    getPlayer(id)(state)?.streamName ?? "";
export const getImageURL = (state: GlobalVideoState, id: PlayerReducer.PlayerIds) =>
    getPlayer(id)(state)?.imageURL ?? "";
export const isPlaceAvailableinLowLatencyPlayer = (state: GlobalVideoState) =>
    Boolean(state.video.players.live.isPlaceAvailableInLowLatency);

export const loadedMetadata =
    (id: PlayerReducer.PlayerIds) => (state: GlobalVideoState) =>
        getPlayer(id)(state)?.loadedMetadata;

export const isRequestFullscreen = (id: PlayerReducer.PlayerIds) =>
    createSelector(getPlayer(id), (player) => player?.requestFullscreen);

export const isVideoSettingsVisible = curry(
    (id: PlayerReducer.PlayerIds, state: GlobalVideoState) =>
        getPlayer(id)(state)?.activeMenu === PlayerReducer.ActiveMenu.VIDEO_SETTINGS,
);

export const isRadarMenuVisible = curry(
    (id: PlayerReducer.PlayerIds, state: GlobalVideoState) =>
        getPlayer(id)(state)?.activeMenu === PlayerReducer.ActiveMenu.RADAR_MENU,
);

export const videoMenuVisible =
    (id: PlayerReducer.PlayerIds) => (state: GlobalVideoState) =>
        Boolean(isVideoSettingsVisible(id, state) || isRadarMenuVisible(id, state));

export const videoPlayerConfig = (id: PlayerReducer.PlayerIds) =>
    createSelector(
        VideoSelectors.isVideoFrameExpanded,
        getIsPlaying(id),
        getIsFullscreen(id),
        isVideoSettingsVisible(id),
        isRadarMenuVisible(id),
        (
            isVideoFrameExpanded,
            playing,
            fullscreen,
            videoSettingsVisible,
            radarMenuVisible,
        ) => ({
            isVideoFrameExpanded,
            playing,
            fullscreen,
            isVideoSettingsVisible: videoSettingsVisible,
            isRadarMenuVisible: radarMenuVisible,
        }),
    );
