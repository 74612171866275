import {defer, reduce} from "lodash";
import log from "@atg-shared/log";
import {type AtgResponse} from "@atg-shared/fetch-types";
import * as storage from "@atg-shared/storage";
import * as Time from "@atg-shared/server-time";
import type {CouponType, Coupon, HarryCouponTypes} from "@atg-horse-shared/coupon-types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {type ReductionTerms} from "@atg-horse/reduced-bets";
import * as CouponUtils from "./coupon";

const COUPON_PREFIX = "coupon";
// any characters in range [A-Za-z0-9] followed by a date, track id and leg number
const GAME_ID_PATTERN = "[A-Za-z0-9]+_20[0-9][0-9]-[0-1][0-9]-[0-3][0-9]_[0-9]+_[0-9]+";

export const key = (
    id: string,
    localOnly: boolean | null | undefined = false,
    type?: CouponType | HarryCouponTypes,
): string => {
    const localOnlySuffix = localOnly ? "-local" : "";
    const typeOfCoupon = type ? `-${type}` : "";
    return `${COUPON_PREFIX}.${id}${localOnlySuffix}${typeOfCoupon}`;
};

export const fetchCoupons = (): Record<string, unknown> =>
    storage.filterByKey(new RegExp(`^${COUPON_PREFIX}\\.${GAME_ID_PATTERN}`));

export const fetchCouponByGameId = (
    gameId: string,
    localOnly: boolean,
    types: Array<CouponType>,
): Promise<AtgResponse<Coupon>> =>
    new Promise((resolve, reject) => {
        // try to find a coupon for any of the types.
        // return the first found (will not be really latest, but should be good enough for logged-out state)
        const localCoupon = reduce(
            types,
            (foundCoupon, type) => {
                if (foundCoupon) return foundCoupon;
                return storage.getItem(key(gameId, localOnly, type));
            },
            null, // start with null
        );
        if (!localCoupon) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({
                response: {
                    error: true,
                    meta: {
                        message: "No local coupon with that game id in storage",
                        code: 409,
                    },
                },
            });
            return;
        }
        defer(() => {
            log.info("Sync coupon <= local storage", localCoupon);
            resolve(CouponUtils.createCouponFromJSON(JSON.parse(localCoupon)));
        });
    });

export const saveCoupon = (
    coupon: Coupon,
    modified?: string,
    reductionTerms?: ReductionTerms,
): {modified: string} => {
    log.info("couponLocalApi:saveCoupon", {coupon, modified});
    const JSONCoupon = JSON.stringify(
        CouponUtils.joinCouponData(coupon, modified, reductionTerms),
    );

    storage.setItem(key(coupon.game.id, coupon.localOnly, coupon.type), JSONCoupon);

    return {
        modified: Time.serverTime().format("YYYY-MM-DDTHH:mm:ss"),
    };
};

export const removeCoupon = (couponKey: string): void => {
    storage.removeItem(couponKey);
};
