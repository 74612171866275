import {combineReducers} from "redux";
import sharedBetCreateReducer, {
    type SharedBetCreateReducer,
} from "./sharedBetCreateReducer";
import sharedBetCancelReducer, {
    type SharedBetCancelReducer,
} from "./sharedBetCancelReducer";
import sharedBetInviteReducer, {
    type SharedBetInviteReducer,
} from "./sharedBetInviteReducer";
import sharedBetConditionsReducer, {
    type SharedBetConditionsReducer,
} from "./sharedBetConditionsReducer";
import sharedBetReceiptReducer, {
    type SharedBetReceiptReducer,
} from "./sharedBetReceiptReducer";
import sharedBetPurchaseReducer, {
    type SharedBetPurchaseReducer,
} from "./sharedBetPurchaseReducer";

export type SharedBetReducer = {
    create: SharedBetCreateReducer;
    purchase: SharedBetPurchaseReducer;
    cancel: SharedBetCancelReducer;
    invite: SharedBetInviteReducer;
    conditions: SharedBetConditionsReducer;
    receipt: SharedBetReceiptReducer;
};

export default combineReducers({
    create: sharedBetCreateReducer,
    purchase: sharedBetPurchaseReducer,
    cancel: sharedBetCancelReducer,
    invite: sharedBetInviteReducer,
    conditions: sharedBetConditionsReducer,
    receipt: sharedBetReceiptReducer,
});
