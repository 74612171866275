import {
    concat,
    filter,
    find,
    findKey,
    flow,
    head,
    identity,
    includes,
    isEqual,
    join,
    last,
    map,
    parseInt,
    range,
    reduce,
    size,
    sortBy,
    split,
    times,
    uniq,
} from "lodash/fp";
import * as storage from "@atg-shared/storage";
import log from "@atg-shared/log";
import type {Coupon, Top7Coupon} from "@atg-horse-shared/coupon-types";
import CouponDefs, {type Top7CouponDefs} from "../coupon-defs/couponDefs";
import winningCombinations from "./winningCombinations";

export const MAXIMUM_NUMBER_OF_GROUPS = 5;
const MINIMUM_NUMBER_OF_CORRECTS = 2;
const MAXIMUM_NUMBER_OF_CORRECTS = 7;

export const SAVED_FIXED_SYSTEM = "top7_pro_custom_saved_fixed_system";
export const SAVED_CUSTOM_SYSTEM = "top7_pro_custom_saved_custom_system";

export const CREATE_GROUPS_ONBOARDING_STEP_1 =
    "top7_pro_custom_create_groups_onboarding_step_1";
export const CREATE_GROUPS_ONBOARDING_STEP_2 =
    "top7_pro_custom_create_groups_onboarding_step_2";
export const DISSOLVE_GROUPS_ONBOARDING = "top7_pro_custom_dissolve_groups_onboarding";

export const isValidSystem = (identifier: string) =>
    includes(identifier, CouponDefs.top7.systems);

export const getCouponDefinition = (coupon: Coupon) => {
    const gameType = coupon.game.type;
    return CouponDefs[gameType] as Top7CouponDefs;
};

export const hasNotCompletedOnboarding = (
    onboardingId: string,
    sessionStorage?: boolean,
) => {
    const item = storage.getItem(
        onboardingId,
        sessionStorage ? storage.SESSION : storage.LOCAL,
    );

    return item === null || !JSON.parse(item).completed;
};

export const hasNotCompletedCreateGroupOnboarding = () => {
    const firstStep = storage.getItem(CREATE_GROUPS_ONBOARDING_STEP_1);
    const secondStep = storage.getItem(CREATE_GROUPS_ONBOARDING_STEP_2);

    const firstStepNotCompleted = firstStep === null || !JSON.parse(firstStep).completed;
    const secondStepNotCompleted =
        secondStep === null || !JSON.parse(secondStep).completed;

    return firstStepNotCompleted || secondStepNotCompleted;
};

export const hasNotCompletedAllOnboardingSteps = () =>
    hasNotCompletedOnboarding(DISSOLVE_GROUPS_ONBOARDING) ||
    hasNotCompletedCreateGroupOnboarding();

export const setOnboardingCompleted = (onboardingId: string, sessionStorage = false) => {
    storage.setItem(
        onboardingId,
        JSON.stringify({completed: true}),
        sessionStorage ? storage.SESSION : storage.LOCAL,
    );
};

export const saveSystem = (key: string, systemAsString: string) => {
    storage.setItem(key, JSON.stringify({system: systemAsString}));
};

export const restoreSystem = (key: string) => {
    const savedSystem = storage.getItem(key);

    if (savedSystem !== null) {
        return JSON.parse(savedSystem).system;
    }

    return null;
};

export const resetSystem = () => {
    storage.removeItem(SAVED_FIXED_SYSTEM);
    storage.removeItem(SAVED_CUSTOM_SYSTEM);
};

export const mapToSystem = (identifier: string) => {
    if (!isValidSystem(identifier)) {
        log.error("System with identifier not found", {identifier});
    }

    const splitPartition = split(",");
    const isRange = includes("-");
    const splitRange = split("-");
    const parse = parseInt(10);

    const toGroups = flow(
        splitPartition,
        map((group) => (isRange(group) ? splitRange(group) : [group])),
        map((group) =>
            size(group) > 1
                ? range(parse(group[0]), parse(group[1]) + 1)
                : // @ts-expect-error Argument of type 'string[]' is not assignable to parameter of type 'string'
                  [parse(group)],
        ),
    );

    return toGroups(identifier);
};

export const mapSystemToString = (system: Array<Array<number>>) => {
    const toSystemString = flow(
        filter((part: Array<string>) => size(part) > 0),
        map((part) =>
            isEqual(1, size(part)) ? part[0].toString() : `${head(part)}-${last(part)}`,
        ),
        join(","),
    );

    return toSystemString(system);
};

export const findSystemBasedOnProperty = (systems: Array<string>, property: string) => {
    const index = property === "default" ? 0 : 1;
    return systems[index];
};

export const getSystemByCost = (cost: number, coupon: Coupon & {banker?: boolean}) => {
    const couponDefinition = getCouponDefinition(coupon);
    return findSystemBasedOnProperty(
        couponDefinition.costToSystemIds[cost],
        coupon.banker ? "banker" : "default",
    );
};

export const getDefaultFixedSystem = (coupon: Coupon) => {
    const couponDef = getCouponDefinition(coupon);
    const stake = head(couponDef.harryBetLimits) as number;
    const systems = couponDef.costToSystemIds[stake];

    return findSystemBasedOnProperty(systems, "default");
};
export const getInitialCustomSystem = (coupon: Coupon) => {
    const couponDef = getCouponDefinition(coupon);
    const stake = last(couponDef.stakeOptions) as number;
    const systems = couponDef.costToSystemIds[stake];

    return findSystemBasedOnProperty(systems, "default");
};

export const getCostForSystem = (systemTemplate: string) => {
    const costMap = CouponDefs.top7.costToSystemIds;

    const findCost = findKey(find((system) => system === systemTemplate));
    const cost = findCost(costMap);

    if (!cost) log.error("Couldn't find cost for system", {systemTemplate});

    // @ts-expect-error fix type for parseInt
    return parseInt(10, cost);
};

export const getWinningRowsForPayout = (payout: Record<string, any>) => {
    const winningRange = range(
        MINIMUM_NUMBER_OF_CORRECTS,
        MAXIMUM_NUMBER_OF_CORRECTS + 1,
    );
    return map<any, number>((winning) => payout[winning] || 0)(winningRange);
};

export const getCurrentDividers = (system: Array<Array<number>>) => {
    let counter = 0;

    const reduceToGroups = reduce((dividers, group: Array<any>) => {
        const index = group.length + counter;
        counter += group.length;

        return concat(dividers, index);
    }, [] as Array<any>);

    return reduceToGroups(system);
};

export const getNewDividers = (
    currentSystem: Array<Array<number>>,
    systemData: Record<string, any>,
): Array<number> => {
    const {index: position, originalIndex, originalDividers, dividerIndex} = systemData;
    const allDividers = concat(getCurrentDividers(currentSystem), dividerIndex);

    const filterSortedDividers = flow(
        filter(
            (divider) =>
                isEqual(originalIndex, dividerIndex) || !isEqual(divider, originalIndex),
        ),
        filter(
            (divider) =>
                isEqual(divider, position) || includes(divider, originalDividers),
        ),
        uniq,
        sortBy(identity),
    );

    // @ts-expect-error Type 'unknown' is not assignable to type 'number'
    return filterSortedDividers(allDividers);
};

export const applyNewDividers = (dividers: Array<number>) => {
    const positions = [1, 2, 3, 4, 5, 6, 7];
    let lastIndex = 0;

    // @ts-expect-error No overload matches this call.
    const reduceApplyDividers = reduce<Array<number>>((system, divider) => {
        const group = positions.slice(lastIndex, divider);

        const isGroup = group.length > 0;

        if (isGroup) {
            lastIndex = divider;
        }

        return isGroup ? concat(system, [group]) : system;
    }, []);

    return reduceApplyDividers(dividers as any);
};

const getDividersCount = (
    groupIndex: number,
    groupSize: number,
    currentSystem: Array<Array<number>>,
    isLastGroup: boolean,
    canBeSplit: boolean,
) => {
    let numberOfDividers;
    const firstInCurrentGroup = head(currentSystem[groupIndex]);
    const lastInCurrentGroup = last(currentSystem[groupIndex]);

    if (groupSize > 1) {
        if (canBeSplit) {
            numberOfDividers = firstInCurrentGroup;
        } else {
            numberOfDividers = isLastGroup ? lastInCurrentGroup : firstInCurrentGroup;
        }
    } else {
        numberOfDividers = lastInCurrentGroup;
    }

    return numberOfDividers;
};

export const getSuggestedSystems = (invokedFromGroup: number, systemAsString: string) => {
    const groupIndex = invokedFromGroup - 1;

    const currentSystem = mapToSystem(systemAsString);
    let currentDividers = getCurrentDividers(currentSystem);

    const isLastGroup = currentSystem.length === invokedFromGroup;
    const canBeSplit = currentDividers.length < MAXIMUM_NUMBER_OF_GROUPS;
    const groupSize = currentSystem[groupIndex].length;

    let numberOfDividers = getDividersCount(
        groupIndex,
        groupSize,
        currentSystem,
        isLastGroup,
        canBeSplit,
    );

    if (groupSize > 1 && canBeSplit && isLastGroup) {
        currentDividers.splice(groupIndex, 0, groupSize);
    }

    // @ts-expect-error Object is possibly 'undefined'.
    const timesToRun = CouponDefs.top7.maxNumberOfBets + 1 - numberOfDividers;

    const systemDividers = times(() => {
        // @ts-expect-error Object is possibly 'undefined'.
        currentDividers[groupIndex] = numberOfDividers++;
        const dividers = uniq(currentDividers);
        currentDividers = dividers;

        return [...dividers];
    }, timesToRun);

    return flow(
        // @ts-expect-error Argument of type 'unknown' is not assignable to parameter of type 'number[]'
        map((dividers) => applyNewDividers(dividers)),
        // @ts-expect-error Argument of type 'LodashReduce1x3<number[], number[]>' is not assignable to parameter of type 'number[][]'.
        map((system) => mapSystemToString(system)),
    )(systemDividers);
};

export const splitGroupAtIndex = (system: Array<Array<number>>, splitAtIndex: number) => {
    const dividers = concat(getCurrentDividers(system), splitAtIndex);

    const uniqueDividers = flow(uniq, sortBy(identity))(dividers);

    return applyNewDividers(uniqueDividers);
};

export const removeDividerAtIndex = (
    system: Array<Array<number>>,
    removeAtIndex: number,
) => {
    const currentDividers = getCurrentDividers(system);
    const filteredDividers = filter<number>((divider) => divider !== removeAtIndex)(
        currentDividers,
    );

    return applyNewDividers(filteredDividers);
};

export const getNumberOfWinningCombinations = (coupon: Top7Coupon) => {
    const system = coupon.systemId;
    return winningCombinations[system].winningCombinations;
};
