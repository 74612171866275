import atgRequest from "@atg-shared/fetch";
import {fetchAuthorized} from "@atg-shared/auth";
import {HORSE_SERVICE_URL} from "@atg-shared/service-url";
import type {Preferences} from "./horseFavoritesActions";

const HORSE_FAVORITE_URL = "/services/tokenized-proxy/horse-favorites/api/v2/favorite";

/** authorized: add, remove, get startbevakning */

export const addFavoriteHorse = (horseId: number) =>
    fetchAuthorized(`${HORSE_FAVORITE_URL}/${horseId}`, {
        method: "POST",
    });

export const removeFavoriteHorse = (horseId: number) =>
    fetchAuthorized(`${HORSE_FAVORITE_URL}/${horseId}`, {
        method: "DELETE",
    });

export const getFavoriteHorses = () => fetchAuthorized(`${HORSE_FAVORITE_URL}`);

/** unathorized: get a horse´s upcoming starts */

export const getUpcomingStarts = (horseId: number) =>
    atgRequest(`${HORSE_SERVICE_URL}/${horseId}/upcoming-starts`);

/** authorized: user preferences */

const HORSE_PREFERENCES_URL =
    "/services/tokenized-proxy/horse-favorites/api/v2/favorites/preferences";

export const getFavoritesPreferences = () => fetchAuthorized(HORSE_PREFERENCES_URL);

export const setFavoritesPreferences = (preferences: Preferences) =>
    fetchAuthorized(
        `${HORSE_PREFERENCES_URL}?notificationMethod=${preferences.notificationMethod}`,
        {
            method: "PATCH",
        },
    );

/** types */

export type Favorite = {
    id: number;
    type: "HORSE";
    created: string;
    accountIndex: number;
    comment: string | null;
};
