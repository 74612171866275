export const NEXT_GUIDE_STEP = "NEXT_GUIDE_STEP";
export const PREV_GUIDE_STEP = "PREV_GUIDE_STEP";
export const CLOSE_GUIDE = "CLOSE_GUIDE";
export const START_GUIDE = "START_GUIDE";
export const GO_TO_GUIDE_STEP = "GO_TO_GUIDE_STEP";

export const startGuide = (
    guide: any,
    isAutoStart = false,
    isClosedBeforeStart = false,
) => ({
    type: START_GUIDE,
    payload: {
        guide,
        isAutoStart,
        isClosedBeforeStart,
    },
});

export const nextStep = () => ({
    type: NEXT_GUIDE_STEP,
});

export const prevStep = () => ({
    type: PREV_GUIDE_STEP,
});

export const goToStep = (stepNameId: string) => ({
    type: GO_TO_GUIDE_STEP,
    payload: stepNameId,
});

export const closeGuide = (id: string) => ({
    type: CLOSE_GUIDE,
    payload: {
        id,
    },
});
