import * as React from "react";
import type {Props as SvgIconPropType} from "../components/SvgIcon";
import SvgIcon from "../components/SvgIcon";

export function CheckAlt(props: SvgIconPropType) {
    return (
        <SvgIcon {...props}>
            <path d="M30.7 5.4c-1.9-1.9-5.1-1.9-7 0L13.6 15.5l-5.3-5.3c-.8-.8-2.1-1.4-3.4-1.4C2.2 8.8 0 11 0 13.7c0 1.3.6 2.5 1.4 3.4l8.7 8.7c1.9 1.9 5 1.9 6.9 0l13.6-13.5c1.9-1.9 1.9-5 .1-6.9z" />
        </SvgIcon>
    );
}

export default CheckAlt;
