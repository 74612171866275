import type {AtgRequestError} from "@atg-shared/fetch-types";
import type {SmsRecoveryData, SmsRecoveryAnswer} from "./types";

export const SMS_RECOVERY_FETCH_QUESTIONS_REQUEST =
    "SMS_RECOVERY_FETCH_QUESTIONS_REQUEST";

export const SMS_RECOVERY_FETCH_REQUEST = "SMS_RECOVERY_FETCH_REQUEST";
export const SMS_RECOVERY_FETCH_RESPONSE = "SMS_RECOVERY_FETCH_RESPONSE";

export const SMS_RECOVERY_SAVE_REQUEST = "SMS_RECOVERY_SAVE_REQUEST";
export const SMS_RECOVERY_SAVE_RESPONSE = "SMS_RECOVERY_SAVE_RESPONSE";

export const SMS_RECOVERY_DELETE_REQUEST = "SMS_RECOVERY_DELETE_REQUEST";
export const SMS_RECOVERY_DELETE_RESPONSE = "SMS_RECOVERY_DELETE_RESPONSE";

type SmsRecoveryFetchQuestionsAction = {
    type: typeof SMS_RECOVERY_FETCH_QUESTIONS_REQUEST;
};

type SmsRecoveryFetchQuestionsAndAnswerAction = {
    type: typeof SMS_RECOVERY_FETCH_REQUEST;
};

type SmsRecoveryFetchQuestionsAndAnswerErrorAction = {
    type: typeof SMS_RECOVERY_FETCH_RESPONSE;
    error: true;
    payload: AtgRequestError;
};

type SmsRecoveryFetchQuestionsAndAnswerSuccessAction = {
    type: typeof SMS_RECOVERY_FETCH_RESPONSE;
    error: false;
    payload: SmsRecoveryData;
};

export type SmsRecoverySavedAnswerAction = {
    type: typeof SMS_RECOVERY_SAVE_REQUEST;
    payload: SmsRecoveryAnswer;
};

type SmsRecoverySavedAnswerSuccessAction = {
    type: typeof SMS_RECOVERY_SAVE_RESPONSE;
    error: false;
    payload: SmsRecoveryAnswer;
};

type SmsRecoverySavedAnswerFailedAction = {
    type: typeof SMS_RECOVERY_SAVE_RESPONSE;
    error: true;
    payload: AtgRequestError;
};

export type SmsRecoverySavedAnswerResponseAction =
    | SmsRecoverySavedAnswerSuccessAction
    | SmsRecoverySavedAnswerFailedAction;

export type SmsRecoveryDeletedAnswerAction = {
    type: typeof SMS_RECOVERY_DELETE_REQUEST;
};

type SmsRecoveryDeletedAnswerSuccessAction = {
    type: typeof SMS_RECOVERY_DELETE_RESPONSE;
    error: false;
    payload: Record<string, any>;
};

type SmsRecoveryDeletedAnswerFailedAction = {
    type: typeof SMS_RECOVERY_DELETE_RESPONSE;
    error: true;
    payload: AtgRequestError;
};

export type SmsRecoveryDeletedAnswerResponseAction =
    | SmsRecoveryDeletedAnswerSuccessAction
    | SmsRecoveryDeletedAnswerFailedAction;

export type SmsRecoveryAction =
    | SmsRecoveryFetchQuestionsAction
    | SmsRecoveryFetchQuestionsAndAnswerAction
    | SmsRecoveryFetchQuestionsAndAnswerErrorAction
    | SmsRecoveryFetchQuestionsAndAnswerSuccessAction
    | SmsRecoverySavedAnswerAction
    | SmsRecoverySavedAnswerResponseAction
    | SmsRecoveryDeletedAnswerAction
    | SmsRecoveryDeletedAnswerResponseAction;

export const fetchQuestions = (): SmsRecoveryFetchQuestionsAction => ({
    type: SMS_RECOVERY_FETCH_QUESTIONS_REQUEST,
});

export const fetchQuestionsAndAnswer = (): SmsRecoveryFetchQuestionsAndAnswerAction => ({
    type: SMS_RECOVERY_FETCH_REQUEST,
});

export const fetchQuestionsAndAnswerFailed = (
    error: AtgRequestError,
): SmsRecoveryFetchQuestionsAndAnswerErrorAction => ({
    type: SMS_RECOVERY_FETCH_RESPONSE,
    error: true,
    payload: error,
});

export const fetchQuestionsAndAnswerSuccess = (
    payload: SmsRecoveryData,
): SmsRecoveryFetchQuestionsAndAnswerSuccessAction => ({
    type: SMS_RECOVERY_FETCH_RESPONSE,
    error: false,
    payload,
});

export const saveAnswer = (answer: SmsRecoveryAnswer): SmsRecoverySavedAnswerAction => ({
    type: SMS_RECOVERY_SAVE_REQUEST,
    payload: answer,
});

export const saveAnswerSuccess = (
    answer: SmsRecoveryAnswer,
): SmsRecoverySavedAnswerSuccessAction => ({
    type: SMS_RECOVERY_SAVE_RESPONSE,
    error: false,
    payload: answer,
});

export const saveAnswerFailed = (error: AtgRequestError) => ({
    type: SMS_RECOVERY_SAVE_RESPONSE,
    error: true,
    payload: error,
});

export function deleteAnswer(): SmsRecoveryDeletedAnswerAction {
    return {
        type: SMS_RECOVERY_DELETE_REQUEST,
    };
}

export const deleteAnswerSuccess = (
    data: Record<string, any>,
): SmsRecoveryDeletedAnswerSuccessAction => ({
    type: SMS_RECOVERY_DELETE_RESPONSE,
    error: false,
    payload: data,
});

export const deleteAnswerFailed = (
    error: AtgRequestError,
): SmsRecoveryDeletedAnswerFailedAction => ({
    type: SMS_RECOVERY_DELETE_RESPONSE,
    error: true,
    payload: error,
});
