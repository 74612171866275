export const TABLE_UPDATE_SORTING = "atg-display-options/tableUpdateSorting" as const;

export const TABLE_TOGGLE_FLAG = "atg-display-options/tableToggleFlag" as const;

export const TABLE_LOAD_FROM_LOCAL_STORAGE =
    "atg-display-options/tableLoadFromLocalStorage" as const;

/**
 * dispatched when a specific start in a specific race of a specific game is expanded or contracted
 */
export const TABLE_TOGGLE_EXPANDED_START =
    "atg-display-options/tableToggleExpandedStart" as const;

/**
 * dispatched when a specific start in a specific race of a specific game is pinned or unpinned
 * NOTE: pinned starts are always expanded, even if the "Minimize startlist" button is pressed or
 * another start is selected.
 */
export const TABLE_TOGGLE_PINNED_START =
    "atg-display-options/tableTogglePinnedStart" as const;

/**
 * dispatched when the user clicks the "UTÖKA ALLA" or "MINSKA ALLA" button to expand/contract all
 * starts in a specific startlist
 */
export const TABLE_TOGGLE_ALL_STARTS_EXPANDED =
    "atg-display-options/tableToggleAllStartsExpanded" as const;

/**
 * dispatched when the user expands or contracts the race details
 */
export const TABLE_TOGGLE_RACE_INFO_EXPANDED =
    "atg-display-options/tableToggleRaceInfoExpanded" as const;
