import {
    split,
    flow,
    get,
    identity,
    isEqual,
    toNumber,
    map,
    curry,
    compact,
} from "lodash/fp";
import type {
    CalendarAPITypes,
    GameAPITypes,
    RaceAPITypes,
} from "@atg-horse-shared/racing-info-api";
import type {GameRace, Start} from "@atg-horse-shared/racing-info-api/game/types";

type TrackNumber = number;
type RaceNumber = number;

/**
 * A race ID uniquely identifies a specific race.
 *
 * A race is a unique combination of:
 * - a race
 * - on a specific track
 * - on a specific day
 *
 * example: "race number 4 on Umåker 2020-07-17".
 *
 * Format: `<date>_<track>_<race number>`

 * Example: `2020-07-17_27_4`
 * - 2020-07-17 = the date that all the races will happen
 * - 27 = Umåker (track)
 * - 4 = the forth race of the day on this track
 */
export type RaceId = `${string}_${TrackNumber}_${RaceNumber}`;

/**
 * Format: `<date>_<track>_<race number>`
 * The corresponding `split("_")` array would be  -> `Date = 0` `TrackNumber = 1` `RaceNumber = 2`
 * @returns a Enum to represent the different values from a `split("_")`.
 */
export enum RaceIdValues {
    Date = 0,
    TrackNumber = 1,
    RaceNumber = 2,
}

/**
 *
 * @param raceId
 * @returns true if type align with `Format: <date>_<track>_<race number>`
 */
export function isRaceId(raceId?: string | null): raceId is RaceId {
    if (!raceId) return false;
    const props = raceId.split("_");
    if (props.length !== 3) return false;
    const [date, trackNumber, raceNumber] = props;
    const matchNumber = /^\d*$/g;
    return Boolean(
        date.match(
            /(19|20)\d\d{1,2}(-)([0][1-9]|[1][0-2])(-)(0[1-9]|[12]\d|3[0-2])/g, // \d[00-99] === \d[0-9]{1,2} ???
        ) &&
            trackNumber.match(matchNumber) &&
            raceNumber.match(matchNumber),
    );
}
export function getBettableStarts(race: GameRace | null): Array<Start> {
    if (race && race.status === "upcoming") {
        return race.starts || [];
    }
    return [];
}

const getRaceId = (
    races: Array<CalendarAPITypes.CalendarRace> | null,
    raceId: RaceId,
    step = 1,
) => {
    if (!races) return null;
    const currentRaceIndex = races.findIndex((race) => race.id === raceId);
    if (currentRaceIndex < 0) return null;
    const raceIndex = currentRaceIndex + step;
    if (raceIndex >= races.length) return raceId;
    const raceFound = races[raceIndex];
    if (!raceFound) return null;

    return raceFound.id;
};

export const getPropsFromRaceId = (raceId?: RaceId | null) => {
    if (!raceId) return {date: null, trackId: null, raceNumber: null};
    const props = raceId.split("_");
    return {
        date: props[0],
        trackId: Number(props[1]),
        raceNumber: Number(props[2]),
    };
};

/**
 * Converts a `gameId` to a `raceId`
 */
export const convertGameIdToRaceId = (gameId: string) =>
    gameId.substr(gameId.indexOf("_") + 1);
/**
 * @returns given `RaceIdValues` from a `RaceId` string, if `trackNumber` or `raceNumber` it returns as type `number`
 */
export const selectValueFromRaceIdString = curry((raceId: RaceId, value: RaceIdValues) =>
    flow(
        split("_"),
        get(value),
        isEqual(RaceIdValues.Date, value) ? identity : toNumber,
    )(raceId),
);
/**
 * @returns all values from raceId as an array of following values [`date:string`, `trackNumber:number`, `raceNumber:number`]
 */
export const getAllValuesFromRaceIdString = (raceId: RaceId) =>
    compact(map(selectValueFromRaceIdString(raceId), RaceIdValues));

export function getNextUpcomingRaceId(
    races: Array<CalendarAPITypes.CalendarRace> | null,
) {
    if (!races) return null;

    const upcomingRace = races.find((race) => race.status === "upcoming");
    return upcomingRace ? upcomingRace.id : races[races.length - 1].id;
}

export function getPreviousRaceId(
    races: Array<CalendarAPITypes.CalendarRace> | null,
    raceId: RaceId,
) {
    return getRaceId(races, raceId, -1);
}

export function getNextRaceId(
    races: Array<CalendarAPITypes.CalendarRace> | null,
    raceId: RaceId,
) {
    return getRaceId(races, raceId, 1);
}

/**
 *
 * @param prize :string from GameAPITypes.GameRace.prize
 ** @returns a filtered string with the most relevant information
 */
export function filterPrize(prize: string) {
    if (!prize) return undefined;

    let modifiedPrize = prize;

    // remove "Lägst 1.500 kr till alla tävlande." from prize money if it is there
    const splitIndex = modifiedPrize.indexOf(". Lägst");
    if (splitIndex > 0) modifiedPrize = modifiedPrize.substring(0, splitIndex + 1);

    // remove "Prispengar max total..."
    const splitIndex2 = modifiedPrize.indexOf(". Prispengar max");
    if (splitIndex2 > 0) modifiedPrize = modifiedPrize.substring(0, splitIndex2 + 1);

    return modifiedPrize;
}
