import * as Sfx from "redux-saga/effects";
import type {Dispatch} from "redux";
import {storeApi} from "@atg-horse-shared/store-api";
import {LOGIN_FINISHED, LOGOUT_FINISHED} from "@atg-global-shared/user/userActionTypes";

function cleanStoreApi(dispatch: Dispatch) {
    dispatch(storeApi.util.resetApiState());
}

export default function* cleanupHorseStoreApiSaga(dispatch: Dispatch) {
    yield Sfx.takeEvery(LOGOUT_FINISHED, cleanStoreApi, dispatch);
    yield Sfx.takeEvery(LOGOUT_FINISHED, () => {
        dispatch({type: "elli/resetElli"});
    });
    yield Sfx.takeEvery(LOGIN_FINISHED, () => {
        dispatch({
            type: "forceReload",
        });
        sessionStorage.removeItem("spelkvitton_filter");
    });
}
