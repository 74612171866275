import {map, some, isString, isArray, isNumber, includes, find} from "lodash/fp";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import type {GameType} from "@atg-horse-shared/game-types";
import type {GameRace} from "@atg-horse-shared/racing-info-api/game/types";
import {
    GameTypes,
    isCombinationGameType,
    isDivisionGameType,
    isGameType,
} from "@atg-horse-shared/game-types";
import type {GameAPITypes, CalendarAPITypes} from "@atg-horse-shared/racing-info-api";
import {parseGameId} from "../gameid";
// eslint-disable-next-line import/no-cycle
import * as Getters from "./getters";

dayjs.extend(isSameOrAfter);

/**
 * Guard to check if game is single race
 * @param game
 * @returns boolean
 */

export function isSingleRace(
    game: GameAPITypes.Game | CalendarAPITypes.CalendarGame | GameAPITypes.GameInfo,
) {
    return isArray(game.races) && game.races.length === 1;
}

export function isValidGameId(gameId: string | null | undefined): boolean {
    if (!gameId) return false;
    const {gameType, date, trackId, raceNumber} = parseGameId(gameId) || {};
    return (
        isGameType(gameType) &&
        isString(date) &&
        isNumber(trackId) &&
        isString(raceNumber)
    );
}

export function isActive(
    game: GameAPITypes.Game | CalendarAPITypes.CalendarGame,
): boolean {
    return game.status === "bettable" || game.status === "ongoing";
}

export const hasResults = (game: GameAPITypes.Game | null | undefined): boolean => {
    if (!game) return false;

    const gameHasResults = game.status === "results";
    const someRacesHasResults = some({status: "results"}, game.races);

    return gameHasResults || someRacesHasResults;
};

export const isGameRunInCountries = (
    countries: Array<string>,
    game: GameAPITypes.Game,
) => {
    const {races} = game;
    const countryCodes = map((race) => race.track.countryCode, races);

    return some((country) => countries.includes(country), countryCodes);
};

/**
 * Guard to check if game is GameInfo with date
 * @param game
 * @returns true if game is GameInfo with date
 */
export function isGameWithDate(
    game: GameAPITypes.Game | GameAPITypes.GameInfo,
): game is GameAPITypes.GameInfo {
    return Boolean("date" in game);
}

/**
 * Check if sport is gallop
 */
export const isGallopGame = (game: GameAPITypes.Game) =>
    some((race) => race.sport === "gallop", game.races);

export function hasStableOdds(game: GameAPITypes.Game): boolean {
    if (game.type !== GameTypes.vinnare && game.type !== GameTypes.vp) return false;

    const {pools} = Getters.getFirstRace(game);
    if (!pools) return false;

    return Boolean(pools.vinnare?.stables);
}
export function isDivisionGame(game: GameAPITypes.Game): boolean {
    return isDivisionGameType(game.type);
}
export function isCombinationGame(game: GameAPITypes.Game): boolean {
    return isCombinationGameType(game.type);
}

export const isGameInMergedPool = (gameType: GameType, race: GameRace) =>
    find(({betTypes}) => includes(gameType, betTypes), race.mergedPools ?? []); // move to app
