import {combineReducers} from "redux";
import {persistReducer} from "redux-persist";
// @ts-expect-error
import storage from "redux-persist/lib/storage/index";
import {
    NEXT_GUIDE_STEP,
    PREV_GUIDE_STEP,
    START_GUIDE,
    CLOSE_GUIDE,
    GO_TO_GUIDE_STEP,
} from "./guideActions";
// @ts-expect-error
function shown(state = {}, action) {
    switch (action.type) {
        case CLOSE_GUIDE: {
            const {payload} = action;
            const {id} = payload;
            return {
                ...state,
                [id]: true,
            };
        }
        default:
            return state;
    }
}

const INITIAL_ACTIVE_GUIDE_STATE = {
    currentStepId: null,
};
// @ts-expect-error
function active(state = INITIAL_ACTIVE_GUIDE_STATE, action) {
    switch (action.type) {
        case START_GUIDE: {
            const {payload} = action;
            const {
                guide: {steps, id, isClosedBeforeStart},
            } = payload;
            return {
                ...state,
                id,
                steps,
                currentStepId: steps[0],
                isClosedBeforeStart,
            };
        }
        case GO_TO_GUIDE_STEP: {
            const stepToGo = action.payload;

            return {
                ...state,
                currentStepId: stepToGo,
            };
        }
        case NEXT_GUIDE_STEP:
            return {
                ...state,
                currentStepId: getNextStepId(state),
            };
        case PREV_GUIDE_STEP:
            return {
                ...state,
                currentStepId: getPreviousStepId(state),
            };
        case CLOSE_GUIDE:
            return INITIAL_ACTIVE_GUIDE_STATE;
        default:
            return state;
    }
}

const shownPersistConfig = {
    storage,
    key: "shown",
    version: 0,
};

const guide = combineReducers({
    shown: persistReducer(shownPersistConfig, shown),
    active,
});

export default guide;
// @ts-expect-error
export function getCurrentStepIndex(state) {
    const {steps = [], currentStepId} = state;
    return steps.indexOf(currentStepId);
}
// @ts-expect-error
function getNextStepId(state) {
    const {steps} = state;
    const currentStepIndex = getCurrentStepIndex(state);
    return steps[currentStepIndex + 1] || null;
}
// @ts-expect-error
function getPreviousStepId(state) {
    const {steps} = state;
    const currentStepIndex = getCurrentStepIndex(state);
    const previousStepIndex = currentStepIndex > 0 ? currentStepIndex - 1 : 0;
    return steps[previousStepIndex] || null;
}
