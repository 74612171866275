import {css} from "@emotion/react";

export const textLinkSecondary = "#469BD4";
export const textLinkHoverState = "#014F9F";
export const atgGrayscale130 = "#1D2B38";

export const notification = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 20px;
    background: ${atgGrayscale130};
    position: relative;
    pointer-events: auto;
    width: 100%;
`;

export const notificationMessage = (theme: any) => css`
    color: ${theme.palette.background.paper};
    font-weight: 400;
    margin: 0;
`;

export const link = css`
    margin: 0;
    font-weight: 500;
    color: ${textLinkSecondary};

    &:hover {
        color: ${textLinkHoverState};
    }
`;

export const wrapper = {
    margin: "16px",
    borderRadius: "4px",
    backgroundColor: "#1D2B38",
};
