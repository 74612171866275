import atgRequest from "@atg-shared/fetch";
import {RACE_SERVICE_URL} from "@atg-shared/service-url";
import type {TipsComments, StatisticsComments} from "./types";

const parseStartId = (startId: string | null | undefined) => {
    if (!startId) return null;
    const [date, trackId, raceNumber, startNumber] = startId.split("_");
    const raceId = [date, trackId, raceNumber].join("_");

    return {
        date,
        trackId: parseInt(trackId, 10),
        raceNumber: parseInt(raceNumber, 10),
        startNumber: parseInt(startNumber, 10),
        raceId,
    };
};

export function fetchStartDetails(startId: string) {
    const {raceId, startNumber} = parseStartId(startId) || {};
    return atgRequest(`${RACE_SERVICE_URL}/${raceId}/start/${startNumber}`);
}

export function fetchRaceDetails(raceId: string) {
    return atgRequest(`${RACE_SERVICE_URL}/${raceId}/start/`);
}

export function fetchPreviousResults(raceId: string) {
    return atgRequest(`${RACE_SERVICE_URL}/${raceId}/extended`);
}

/**
 * @param raceId
 * @returns URL to fetch tips, comments and rank for a race
 * When using this url racingInfo will return an object with @type RaceAPITypes.TipsComments
 */
export const createTipsCommentsUrl = (raceId: string) =>
    `${RACE_SERVICE_URL}/${raceId}/tips-comments`;

export function fetchTipsComments(raceId: string) {
    return atgRequest<TipsComments>(createTipsCommentsUrl(raceId));
}

/**
 * @param raceId
 * @returns URL to fetch horse comments for a race
 * * When using this url racingInfo will return an object with @type RaceAPITypes.StatisticsComments
 */
export const createStatisticsCommentsUrl = (raceId: string) =>
    `${RACE_SERVICE_URL}/${raceId}/comments`;

export function fetchStatisticsComments(raceId: string) {
    return atgRequest<StatisticsComments>(createStatisticsCommentsUrl(raceId));
}
