import {flow, map, filter} from "lodash/fp";

export function getBoxedBetsWinners(boxedBets: any) {
    return map(
        (group) =>
            flow(
                filter((placement: any) => placement?.win),
                map("number"),
            )(group),
        boxedBets,
    );
}
