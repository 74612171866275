import {takeEvery, takeLatest, call, put, select, all} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import {map, difference} from "lodash/fp";
import {isApp} from "@atg-shared/system";
import {parseGameId} from "@atg-horse-shared/utils/gameid";
import {fetchGame} from "@atg-horse-shared/racing-info-api";
import {CouponSelectors, CouponActions} from "@atg-horse-shared/coupon";
import * as GameActions from "./gameActions";
import * as GameSelectors from "./gameSelectors";
import type {FetchGameAction} from "./gameActions";

const webLimit = 16;
const MAX_GAMES_IN_MEMORY = isApp ? 35 : webLimit;

export function* shouldFetchGame(gameId: string): SagaIterator<boolean> {
    const currentGame = yield select(GameSelectors.getGameById, gameId);
    const currentGameIsLoading = yield select(
        GameSelectors.getGameLoadingStatusById,
        gameId,
    );
    return !currentGame && !currentGameIsLoading;
}

export function* fetchGameFlow({payload}: FetchGameAction): SagaIterator<void> {
    const {gameId, forceFetch} = payload;

    const shouldGameBeFetched = forceFetch || (yield call(shouldFetchGame, gameId));
    if (!shouldGameBeFetched) return;

    yield put(GameActions.requestGame(gameId));

    let response;
    let game;
    try {
        response = yield call(fetchGame, gameId);
        game = response.data;
    } catch (err: unknown) {
        // @ts-expect-error
        yield put(GameActions.receiveGameError(gameId, err.response.meta));
        return;
    }

    yield put(GameActions.receiveGame(gameId, game));
}

export function* fetchAllGamesForGameType({
    gameIds,
}: GameActions.RequestAllGamesForGameTypeAndTrackAction): SagaIterator<void> {
    for (let i = 0; i < gameIds.length; i += 1) {
        yield call(fetchGameFlow, GameActions.fetchGame(gameIds[i]));
    }
}

export function* releaseGame({payload: {gameId}}: FetchGameAction): SagaIterator<void> {
    const gameReservations: number | null | undefined = yield select(
        GameSelectors.getGameReservationsById,
        gameId,
    );
    if (!gameReservations) {
        const couponCidsForGame = yield select(CouponSelectors.couponCidsForGame, gameId);
        yield all(map((cid) => call(removeCouponByCid, cid), couponCidsForGame));
    }
}

function* removeCouponByCid(cid: string | undefined): SagaIterator<void> {
    const coupon = yield select(CouponSelectors.getCoupon, cid);
    yield put(CouponActions.removeCoupon(coupon));
}

export function* cleanGameData(): SagaIterator<void> {
    const gameData = yield select(GameSelectors.getGameData);
    const currentGameId = yield select(GameSelectors.getCurrentGameId);
    const gameIds = Object.keys(gameData);
    if (gameIds.length < MAX_GAMES_IN_MEMORY) return;

    const reservedGames = yield select(GameSelectors.getGameReservations);
    const reservedGameIds = Object.keys(reservedGames);

    const {gameType, date, trackId} = parseGameId(currentGameId) || {};
    const gameIdWithoutRaceId = `${gameType}_${date}_${trackId}`;
    // put aside the races from the same game since we dont want them to be released
    const racesNotBelongingToCurrentGame = gameIds.filter(
        (gameId) => !gameId.includes(gameIdWithoutRaceId),
    );
    const gamesToRemove = difference(racesNotBelongingToCurrentGame, reservedGameIds);

    yield put(GameActions.removeGames(gamesToRemove));
}
export default function* gameSaga(): SagaIterator<void> {
    yield takeEvery(GameActions.FETCH_GAME, fetchGameFlow);
    yield takeEvery(GameActions.RELEASE_GAME, releaseGame);
    yield takeEvery(
        [
            GameActions.REQUEST_GAME,
            GameActions.RECEIVE_GAME,
            GameActions.RECEIVE_GAME_PUSH,
        ],
        cleanGameData,
    );
    yield takeLatest(
        GameActions.REQUEST_ALL_GAMES_FOR_GAME_TYPE_AND_TRACK,
        fetchAllGamesForGameType,
    );
}
