import {call, put, takeLatest} from "redux-saga/effects";
import type {AtgRequestError} from "@atg-shared/fetch-types";
import type {
    errorBetSpeedPayoutAction,
    receiveBetSpeedPayoutAction,
    requestBetSpeedPayoutAction,
} from "./speedPayoutActions";
import {SpeedPayoutActionType} from "./speedPayoutActions";
import speedPayoutApi from "./speedPayoutApi";

const isAtgRequestError = (error: any): error is AtgRequestError => error?.response;

export function* requestBetSpeedPayout(action: requestBetSpeedPayoutAction): unknown {
    const {betId, placedAt} = action.payload;
    try {
        const speedPayout = yield call(speedPayoutApi, betId, placedAt);
        const receieveSpeedPayoutAction: receiveBetSpeedPayoutAction = {
            type: SpeedPayoutActionType.RECEIVE_BET_SPEED_PAYOUT,
            payload: speedPayout.data,
        };

        yield put(receieveSpeedPayoutAction);
    } catch (error: unknown) {
        let errorCode = 0;
        if (isAtgRequestError(error)) {
            errorCode = error.response.meta.code;
        }
        const errorBetSpeedPayoutAction: errorBetSpeedPayoutAction = {
            type: SpeedPayoutActionType.ERROR_SPEED_PAYOUT,
            payload: errorCode,
        };
        yield put(errorBetSpeedPayoutAction);
    }
}

export default function* betSpeedPayoutSaga() {
    yield takeLatest(
        SpeedPayoutActionType.REQUEST_BET_SPEED_PAYOUT.toString(),
        requestBetSpeedPayout,
    );
}
