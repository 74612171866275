import {flow, tail, head, find, compact, map} from "lodash/fp";
import {reduce, omitBy, memoize} from "lodash";
import {createSelector} from "reselect";
import type Dayjs from "dayjs";
import {FixedSizeMap} from "@atg/utils";
import type {CalendarAPITypes} from "@atg-horse-shared/racing-info-api";
import {
    type GameType,
    type ComboGameType,
    isCombinationGameType,
} from "@atg-horse-shared/game-types";
import type {
    Game,
    GameRace,
    Start,
    BasePool,
    DivisionGamePool,
    ComboGamePool,
    Top7GamePool,
} from "@atg-horse-shared/racing-info-api/game/types";
import {isActive, getStartTime} from "@atg-horse-shared/utils/game";
import * as GameUtils from "@atg-horse-shared/utils/game";
import * as Track from "@atg-horse-shared/utils/track";
import type {
    GlobalState,
    GameData,
    CurrentGameMetadata,
    Reservations,
    GameReducerState,
} from "./gameReducer";

export type HorseData = {
    name: string;
    startNumber: number;
    scratched?: true;
};

export type Investment = {
    place: number;
    investmentPercent: number;
};

export type PreliminaryInvestment = {
    startNumber: number;
    investments: Array<Investment>;
};

export const getGameById = (
    state: GlobalState,
    id: string | null | undefined,
): Game | null | undefined => {
    if (!id) return null;

    if (!state.games?.gameData) return null;
    return state.games.gameData[id] ? state.games.gameData[id].game : null;
};

// return all the games from the given id's
export const getGamesByIds = createSelector(
    (state: GlobalState) => state.games.gameData,
    (_, ids: Array<string>) => ids,
    (gameData, ids) => {
        if (!gameData) return [];

        return ids.map((id) => {
            if (gameData[id] && gameData[id].game?.races) {
                return gameData[id].game?.races[0];
            }
            return null;
        });
    },
);

export const getBettableGameById = (
    state: GlobalState,
    id: string | null | undefined,
): Game | null | undefined => {
    const game = getGameById(state, id);
    if (!game) return null;

    if (game.status !== "bettable") return null;

    return game;
};

export const getGameStateById = (
    state: GlobalState,
    id: string,
): GameReducerState | null | undefined => state.games.gameData[id] || {};

export const getGameLoadingStatusById = (state: GlobalState, id: string): boolean =>
    state.games?.gameData?.[id] ? state.games.gameData[id].isLoading : false;

export const getGameVersionById = (
    state: GlobalState,
    gameId: string,
): number | null | undefined => {
    const game = getGameById(state, gameId);
    if (!game) return null;
    return game.version;
};

export const getComboOddsByGameId = (
    state: GlobalState,
    gameId: string,
): Array<Array<any>> | null | undefined => {
    const game = getGameById(state, gameId);
    if (!game) return null;

    if (!game.pools) return null;
    const {pools} = game;

    if (!isCombinationGameType(game.type)) return null;

    const gameType: ComboGameType = game.type;

    const pool = pools[gameType];
    if (!pool) return null;
    return pool.comboOdds || null;
};

export const getAllGames = (
    state: GlobalState,
): {
    [gameId: string]: Game;
} => {
    const {gameData} = state.games;

    return reduce(
        gameData,
        (result, gameState, gameId) => ({
            ...result,
            [gameId]: {
                ...gameState.game,
            },
        }),
        {},
    );
};

export const getActiveGamesOnly = (
    state: GlobalState,
): {
    [gameId: string]: Game;
} => {
    const allGames = getAllGames(state);
    return omitBy(allGames, (game) => !isActive(game));
};

export const getGameData = (state: GlobalState): GameData => state.games.gameData;

export const getGameReservations = (state: GlobalState): Reservations =>
    state.games.reservations;

export const getGameReservationsById = (
    state: GlobalState,
    gameId: string,
): number | null | undefined => state.games.reservations[gameId];

export const getHotGames = (state: GlobalState) => state.horse.hotGames;

export const getGameByCouponId = (
    state: GlobalState,
    couponId: string,
): Game | null | undefined => {
    const coupon = state.coupons[couponId];

    if (!coupon || !coupon.game) return null;

    const gameId = coupon.game.id;
    return getGameById(state, gameId);
};

export const getGameTypeByCouponId =
    (couponId: string) =>
    (state: GlobalState): GameType | null | undefined => {
        const game = getGameByCouponId(state, couponId);

        return game?.type;
    };

export const getFirstGameRace = (state: GlobalState, id: string) => {
    const game = getGameById(state, id);

    if (!game) return null;

    return game.races[0];
};

export const getCurrentGameRaceId = (
    state: GlobalState,
    id: string | null | undefined,
): string | null | undefined => {
    if (!id) return null;

    const race = getFirstGameRace(state, id);
    if (!race) return null;

    return race.id;
};

export const getGameRace = memoize(
    (state: GlobalState, gameId: string | null | undefined, raceId: string) => {
        const game = getGameById(state, gameId);
        if (!game) return null;

        return find({id: raceId}, game.races);
    },
    (state: GlobalState, gameId: string | null | undefined, raceId: string): string => {
        const game = getGameById(state, gameId);
        if (!game || !gameId) return "";

        return `${game.version}_${gameId}_${raceId}`;
    },
);

getGameRace.cache = new FixedSizeMap(10);

export const getGameRaceStatus = (
    state: GlobalState,
    gameId: string | null | undefined,
    raceId: string,
): CalendarAPITypes.RaceStatus | null | undefined => {
    const race = getGameRace(state, gameId, raceId);
    if (!race) return null;

    return (race as GameRace).status;
};

export const getHorsesData = (
    state: GlobalState,
    gameId: string,
): Array<HorseData> | null | undefined => {
    const game = getGameById(state, gameId);

    if (!game) {
        return null;
    }

    const race = head(game.races);

    if (!race) {
        return null;
    }

    return race.starts?.map((start) => ({
        name: start.horse?.name ?? "",
        startNumber: start.number,
        scratched: start.scratched,
    }));
};

export const getPreliminaryInvestments = (
    state: GlobalState,
    gameId: string,
): Array<PreliminaryInvestment> | null | undefined => {
    const game = getGameById(state, gameId);

    if (!game) {
        return null;
    }

    if (!game.pools) {
        return null;
    }

    const pool = game.pools.top7;

    if (!pool) {
        return null;
    }

    return pool.preliminaryInvestments;
};

export const renderBetDistributionForTop7 = (
    state: GlobalState,
    gameId: string,
): boolean => {
    const game = getGameById(state, gameId);

    if (!game) {
        return false;
    }

    if (!game.pools) {
        return false;
    }

    const pool = game.pools.top7;

    if (!pool) {
        return false;
    }

    return pool.turnover / 100 > 100000;
};

export const getCurrentTrackId = (state: GlobalState): number | null | undefined =>
    state.games.currentTrackId.currentTrackId;

export const getClickedTrackId = (state: GlobalState): number | null | undefined =>
    state.games.currentTrackId.clickedTrackId;

export const getCurrentGameId = (state: GlobalState): string | null | undefined =>
    state.games.currentGame.id || null;

export const getCurrentGameMetadata = (state: GlobalState): CurrentGameMetadata =>
    state.games.currentGame;

export const getCurrentGameType = (state: GlobalState): GameType | null | undefined =>
    state.games.currentGame.type;

export const getSelectedRace = (state: GlobalState) => state.selectedRace;

export const getSelectedRaceId = (state: GlobalState): string | null =>
    getSelectedRace(state).id || null;

export const getGamePath = (state: GlobalState): string | null => {
    const {path} = getSelectedRace(state);

    if (!path) return null;

    return flow([compact, tail])(path.split("/"));
};

export const getTrackNameForGameId = (
    state: GlobalState,
    gameId: string | null | undefined,
): string | null | undefined => {
    const game = getGameById(state, gameId);
    if (!game) return null;

    const tracks = map("track", game.races);
    return Track.getGameTrackName(compact(tracks));
};

export const getStartTimeForGameId = (
    state: GlobalState,
    gameId: string | null | undefined,
): Dayjs.Dayjs | null | undefined => {
    const game = getGameById(state, gameId);
    if (!game) return null;

    return getStartTime(game);
};

export const getStart = memoize(
    (state: GlobalState, gameId: string, startId: string): Start | null | undefined => {
        const game = getGameById(state, gameId);
        if (!game) return null;

        return GameUtils.getStartById(game, startId) || null;
    },
    (state: GlobalState, gameId: string, startId: string): string => {
        const game = getGameById(state, gameId);
        if (!game) return "";

        return `${game.version}_${gameId}_${startId}`;
    },
);

getStart.cache = new FixedSizeMap(120);

export const getPool = (
    state: GlobalState,
    id: string,
    type: GameType,
):
    | (BasePool | null | undefined)
    | (DivisionGamePool | null | undefined)
    | (ComboGamePool | null | undefined)
    | (Top7GamePool | null | undefined) => state.games.gameData[id]?.game?.pools?.[type];
