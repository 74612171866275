import dayjs from "dayjs";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import type {LogoutFinishedAction} from "@atg-global-shared/user/userActions";
import {LOGOUT_FINISHED} from "@atg-global-shared/user/userActionTypes";
import {PlayerActionTypes} from "../../../player/actionconstants";
import {type PlayAction} from "../../../player/playerActions";

export type LiveFreeSlotState = {
    /**
     * keep track of when the most recent "free 10 min of live stream" slot was started
     * (ISO string)
     */
    startTime?: string;
};

/**
 * A logged out user is permitted 10 minutes of live stream watching per day. This reducer:
 * - starts the 10 min countdown if the user starts playing the live stream
 * - gives a user 10 new minutes if they just logged out (example scenario: shared computer)
 * - makes sure the 10 min slot is reset once every day
 */
export const liveFreeSlot = persistReducer(
    {storage, key: "horse.liveFreeSlot"},
    (
        state: LiveFreeSlotState = {startTime: undefined},
        action: PlayAction | LogoutFinishedAction,
    ) => {
        switch (action.type) {
            // if a user goes from logged in (unlimited live stream) to logged out (10 min per day),
            // reset the slot so that the user gets 10 minutes counting from now
            case LOGOUT_FINISHED: {
                return {startTime: undefined};
            }
            case PlayerActionTypes.PLAYER_PLAY: {
                if (action.payload.player !== "live") return state;

                const now = dayjs();

                // start a new "free live stream" slot if there's no previous value stored, or if
                // the previous value is old (you should get 10 minutes per day)
                if (!state.startTime || !now.isSame(state.startTime, "day")) {
                    return {
                        ...state,
                        startTime: now.toISOString(),
                    };
                }

                return state;
            }
            default:
                return state;
        }
    },
);
