import {fetchAuthorized} from "@atg-shared/auth";

const BET_SPEED_PAYOUT_BASE_URL = "/services/v1/user/bets";

const speedPayoutApi = (betId: string, placedAt: string) =>
    fetchAuthorized(`${BET_SPEED_PAYOUT_BASE_URL}/${placedAt}_${betId}/speedpayout`, {
        method: "POST",
    });

export default speedPayoutApi;
