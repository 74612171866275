import {isEmpty, endsWith, trim} from "lodash";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {Products} from "@atg-horse-shared/purchase";
import {
    SHARED_BET_COUPON_PRODUCT,
    SHARED_BET_CREATE_PRODUCT,
    SHARED_BET_SHARE_PRODUCT,
} from "./sharedBetProducts";

export const MIN_SHARE_COST = 500;
export const MAX_SHARE_COST = 4999900;
export const MIN_SYSTEM_SIZE_LIMIT = 1;

export type SharedBetCreateParams = {
    nrShares: number;
    shareCost: number;
    totalNrShares: number;
    systemSizeLimit?: number;
};

export type SharedBetPurchaseParams = {
    nrShares: number;
};

type SharedBetShareHolder = {
    name: string;
    nrShares: number;
};

export type SharedBetConditions = {
    couponId: string;
    gameId: string;
    shareCost: number;
    systemSizeLimit?: number;
    totalNrShares: number;
    totalNrSoldShares: number;
    ownedNrShares: number;
    initiator: SharedBetShareHolder;
    shareHolders: Array<SharedBetShareHolder>;
    teamId: number;
    shareStatus: "PLACED" | "PLACED_NOT_VERIFIED" | "INITIATED";
};

export type SharedBetShareInfo = {
    totalNrShares: number;
    ownedNrShares: number;
    shareCost: number;
    initiator: string;
    myCost?: number;
};

export type TotalNrSharesOpenModalConditions = {
    couponId: string;
    totalNrSoldShares: number;
    totalNrShares: number;
};

export type SharedBetShowSystemSizeLimit = {
    showSystemSizeLimit: boolean;
};

export const getTeamWalletTotal = (conditions: SharedBetConditions | null): number =>
    conditions ? conditions.shareCost * conditions.totalNrSoldShares : 0;

export const hasSharedBetConditions = (conditions?: SharedBetConditions): boolean =>
    !isEmpty(conditions);

export const isSharedBetCreateProduct = (product: Products.Product): boolean =>
    Boolean(product?.type === SHARED_BET_CREATE_PRODUCT);

export const isSharedBetShareProduct = (product: Products.Product): boolean =>
    Boolean(product?.type === SHARED_BET_SHARE_PRODUCT);

export const isSharedBetCouponProduct = (product: Products.Product): boolean =>
    Boolean(product?.type === SHARED_BET_COUPON_PRODUCT);

export const isSharedBet = (product: Products.Product): boolean =>
    isSharedBetCouponProduct(product) ||
    isSharedBetShareProduct(product) ||
    isSharedBetCreateProduct(product);

export const getMyShareCost = (
    totalCost: number,
    totalNrShares: number,
    ownedNrShares: number,
): number => Math.round((totalCost / totalNrShares) * ownedNrShares);

export const getMySharePayout = (
    totalPayout: number,
    shareInfo: SharedBetShareInfo,
): number => // need to use Math.floor, not round, since that is how Betting system is calculating. In new horse bet history api we are getting this from the server directly.
    Math.floor((totalPayout / shareInfo.totalNrShares) * shareInfo.ownedNrShares);

export const getTeamName = (name: string, suffix = "andelsspel"): string =>
    endsWith(name, "s") || endsWith(name, "x")
        ? trim(`${name} ${suffix}`)
        : trim(`${name}s ${suffix}`);

export const getShareCost = (params: SharedBetCreateParams): number =>
    params.shareCost || 0;

export const getNrShares = (params: SharedBetCreateParams): number => params.nrShares;

export const getTotalCost = (params: SharedBetCreateParams): number =>
    getNrShares(params) * getShareCost(params);
