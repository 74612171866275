import type {UserState} from "./userReducer";
import type {UserAction} from "./userActions";

export const USER_STORAGE_KEY = "user";

export type GlobalUserState = {
    user: UserState;
};

export type State = GlobalUserState;
export type Action = UserAction;
