import {createSelector} from "reselect";
import {GameType} from "@atg-shop-shared/types/game";
import type {ShopShare} from "@atg-shop-shared/types/shopShare";
import {LOADING_STATUS} from "@atg-shared/fetch-types";
import {fetchSelectors} from "@atg-shared/fetch-redux";
// Types need to be extracted from atg-horse-bet
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {State} from "@atg-shop-shared/shops-domain-types";
// Types need to be extracted from atg-horse-bet
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as ShopPushDataSelectors from "@atg-shop/push-data-redux/src/shopPushDataSelectors";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {MaxStakeDataState} from "@atg-shop-shared/shops-domain-types/shares.action.types";

// FIXME:
const getShopSharesState = (state: any) => state.shop.shopShare;

const getShopShareFetchState = (state: State) => getShopSharesState(state).shopShare;
const getCreateShareState = (state: State) => getShopSharesState(state).createShare;
const getSetGameInitiatorState = (state: State) =>
    getShopSharesState(state).setGameInitiatorStatus;
const getUpdateShareState = (state: State) => getShopSharesState(state).updateShare;
const getDeleteShareState = (state: State) => getShopSharesState(state).deleteShare;
const getEditShareNameState = (state: State) =>
    getShopSharesState(state).setEditShareNameStatus;
const getCouponVisibilityState = (state: State) =>
    getShopSharesState(state).couponVisibility;
const getMaxStakePerSystemDataState = (state: State) =>
    getShopSharesState(state).setMaxStakePerSystemData;

export const {
    isLoading: isShopShareLoading,
    isLoaded: isShopShareLoaded,
    hasError: hasShopShareError,
} = fetchSelectors.createSelectors(getShopShareFetchState);

export const {
    isLoading: isCreateShareLoading,
    isLoaded: isCreateShareLoaded,
    hasError: hasCreateShareError,
} = fetchSelectors.createSelectors(getCreateShareState);

export const {
    isLoading: isUpdateShareLoading,
    isLoaded: isUpdateShareLoaded,
    hasError: hasUpdateShareError,
} = fetchSelectors.createSelectors(getUpdateShareState);

export const {
    isLoading: isDeleteShareLoading,
    isLoaded: isDeleteShareLoaded,
    hasError: hasDeleteShareError,
} = fetchSelectors.createSelectors(getDeleteShareState);

export const {
    isLoading: isEditShareNameLoading,
    isLoaded: isEditShareNameLoaded,
    hasError: hasEditShareNameError,
} = fetchSelectors.createSelectors(getEditShareNameState);

export const {
    isLoading: isMaxStakePerSystemDataLoading,
    isLoaded: isMaxStakePerSystemDataLoaded,
    hasError: hasMaxStakePerSystemDataError,
} = fetchSelectors.createSelectors(getMaxStakePerSystemDataState);

export const {
    isLoading: isCouponVisibilityLoading,
    isLoaded: isCouponVisibilityLoaded,
    hasError: hasCouponVisibilityError,
} = fetchSelectors.createSelectors(getCouponVisibilityState);

const SHOP_SHARE_ALREADY_PLACED_ERROR_MESSAGE =
    "Andelsspelet är redan inlämnat. Testa att ladda om sidan.";

export const getCouponVisibilityStatus = (state: State) =>
    fetchSelectors.getLoadingStatus(getCouponVisibilityState(state));

export const getCouponVisibilityException = (state: State): string => {
    const loadingStatus = getCouponVisibilityStatus(state);

    const error =
        loadingStatus.status === LOADING_STATUS.ERROR ? loadingStatus.exception : null;
    if (!error) return "";

    const errorCode = error?.response?.meta?.code || 0;
    switch (errorCode) {
        case 409:
            return SHOP_SHARE_ALREADY_PLACED_ERROR_MESSAGE;
        default:
            return "Ett oförutsett tekniskt fel har inträffat. Testa att ladda om sidan, eller försök senare.";
    }
};

const NAME_ALREADY_TAKEN_ERROR_MESSAGE = "Namnet är dessvärre upptaget.";

export const getEditShareNameLoadingStatus = (state: State) =>
    fetchSelectors.getLoadingStatus(getEditShareNameState(state));

export const getEditShareNameException = (state: State): string => {
    const loadingStatus = getEditShareNameLoadingStatus(state);
    const error =
        loadingStatus.status === LOADING_STATUS.ERROR ? loadingStatus.exception : null;
    if (!error) return "";

    const errorCode = error?.response?.meta?.code || 0;
    switch (errorCode) {
        case 409:
            return NAME_ALREADY_TAKEN_ERROR_MESSAGE;
        default:
            return "Tyvärr verkar något ha gått fel hos oss. Vi kunde inte ändra namnet på andelsspelet. Försök igen om en stund.";
    }
};

export const getCreateShareLoadingStatus = (state: State) =>
    fetchSelectors.getLoadingStatus(getCreateShareState(state));

export const getCreateShareException = (state: State): string => {
    const loadingStatus = getCreateShareLoadingStatus(state);
    const error =
        loadingStatus.status === LOADING_STATUS.ERROR ? loadingStatus.exception : null;
    if (!error) return "";

    const errorCode = error?.response?.meta?.code || 0;
    switch (errorCode) {
        case 409:
            return NAME_ALREADY_TAKEN_ERROR_MESSAGE;
        default:
            return "Tekniskt fel. Vi kan tyvärr inte skapa ditt andelsspel just nu. Vänligen försök igen.";
    }
};

export const {
    isLoading: isSetGameInitiatorLoading,
    isLoaded: isSetGameInitiatorLoaded,
    hasError: hasSetGameInitiatorError,
} = fetchSelectors.createSelectors(getSetGameInitiatorState);

export const getMaxStakePerSystemData = (state: State) => {
    const maxStakePerSystemData: MaxStakeDataState = getMaxStakePerSystemDataState(state);
    return maxStakePerSystemData || null;
};

export const getShopShare = (state: State): ShopShare | null =>
    getShopShareFetchState(state).shopShare;

export const getShopShareWithPushData: (State: State) => ShopShare | null =
    createSelector(
        getShopShare,
        // FIXME:
        // @ts-ignore
        ShopPushDataSelectors.getShopPushState,
        (shopShare, pushData) => {
            if (!shopShare) return shopShare;

            const shopSharePushedData = pushData.shares[shopShare.id];

            if (!shopSharePushedData) return shopShare;

            return {
                ...shopShare,
                ...shopSharePushedData,
            };
        },
    );

export const getShopShareLoadingStatus = (state: State) =>
    fetchSelectors.getLoadingStatus(getShopShareFetchState(state));

export const getSharesPerSystem = (state: State) => {
    const shopShare = getShopShare(state);
    return shopShare ? shopShare.sharesPerSystem : 5;
};

export const getCreatedShareId = (state: State) => {
    const createShare = getCreateShareState(state);
    return createShare ? createShare.shareId : null;
};

export const getShopShareId = (state: State) => {
    const shopShare = getShopShare(state);
    return shopShare ? shopShare.id : null;
};
export const getShopShareName = (state: State) => {
    const shopShare = getShopShare(state);
    return shopShare ? shopShare.name : null;
};

export const isShopShareCouponVisible = (state: State): boolean => {
    const shopShare = getShopShare(state);
    return shopShare ? !!shopShare.isCouponPublic : false;
};

export const getGameType = (state: State): GameType => {
    const shopShare = getShopShare(state);
    return shopShare ? shopShare.game.type : GameType.V75;
};

export const hasJackpot = (state: State): boolean => {
    const shopShare = getShopShare(state);
    return Boolean(shopShare && shopShare.game.jackpot);
};

const getShopShareCouponDetailsState = (state: State) =>
    getShopSharesState(state).shopShareCouponDetails;

export const getShopShareByCouponId = (
    state: State,
    couponId: string,
): ShopShare | null => {
    const couponDetails = getShopShareCouponDetailsState(state).shopShareCouponDetails;
    return couponDetails[couponId] || null;
};

export const getReceiptStatus = (state: State): string =>
    getShopSharesState(state).setReceiptStatus;

export const hasSoldShares = (state: State): boolean => {
    const shopShare = getShopShare(state);

    if (!shopShare || !shopShare.sales) return false;

    return shopShare.sales.soldShares > 0;
};

export const getShopShareStatus = (state: State) => {
    const shopShare = getShopShare(state);
    return shopShare ? shopShare.status : null;
};
