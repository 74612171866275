import {createSelector} from "reselect";
import {get, find, uniqBy} from "lodash/fp";

import {serverDate} from "@atg-shared/server-time";
import type {CalendarAPITypes} from "@atg-horse-shared/racing-info-api";
import type {User} from "@atg-global-shared/user/user.types";
import type {CalendarTrack} from "@atg-horse-shared/racing-info-api/game/types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as CalendarSelectors from "@atg-horse-shared/calendar/domain/calendarSelectors";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {State as GlobalCalendarState} from "@atg-horse-shared/calendar/domain";
import * as VideoSelectors from "../../player/videoSelectors";
import * as QuickplaySelectors from "../../quickplay/quickplaySelectors";
// @ts-expect-error
import type {QuickplayState} from "../../quickplay/quickplayActions";
import type {Channel} from "../../video/videoReducer";
import type {GlobalVideoState} from "../../video";
import * as Live from "../liveUtils";
import type * as LiveReducer from "./livereducer";
import type {GlobalState} from ".";

/**
 * Select current loading status of the liveBet
 */
export const getLiveBetLoadingStatus = (state: GlobalState) =>
    state?.liveBetLoadingStatus;

/**
 * Select loading state for the `liveBet`
 */
export const isLoading = (state: GlobalState) => {
    const statusState = getLiveBetLoadingStatus(state);
    if (!statusState.status) return true;
    return statusState.status.isLoading;
};

export const isLoadingVarenne = (state: GlobalState) =>
    state?.liveBetLoadingStatus.isLoadingVarenne;
export const shouldLoadVarenneBets = (state: GlobalState) =>
    state?.liveBetLoadingStatus.shouldLoadVarenneBets;
/**
 * Select the startTime of the free live stream
 * @returns startTime when the free live stree begun
 */

export const getFreeLiveStartTime = (state: GlobalState) => state?.liveFreeSlot.startTime;

export const getLiveShowRaces = (state: GlobalState): LiveReducer.LiveShowRacesState =>
    state.liveShowRaces;

export const getLiveCorrectedBets = (state: GlobalState): LiveReducer.LiveCorrectedBets =>
    state.liveCorrectedBets;

export const getLiveTrackHorseInfo = (state: GlobalState) =>
    state?.liveReceiptTrackHorseInfo;

export const getLiveBetSortOptions = (
    state: GlobalState,
): LiveReducer.LiveBetSortOptionsState => state.liveBetSortOptions;

const getLiveBetsState = (state: GlobalState): LiveReducer.LiveBetsState =>
    state.liveBets;

export const getLiveBetsFilter = createSelector(getLiveBetsState, get("filter"));
export const getLiveBetsHideButton = createSelector(getLiveBetsState, get("hideButton"));
export const getLiveBets = createSelector(getLiveBetsState, get("bets"));
export const getShouldFetchHBHLiveBets = (state: GlobalState) =>
    state.liveBets.shouldFetchHBHLiveBets;

export const getSelectedChannel = (state: GlobalVideoState): Channel => {
    const {selectedChannel} = VideoSelectors.getLiveVideoState(state);
    const channels = VideoSelectors.getChannels(state);
    // @ts-expect-error
    return find<Channel, Channel>({id: selectedChannel.id}, channels);
};

// If an archive video is active/clicked and viewed in the livebar or in popout
export const isArchiveVideoActive: (state: GlobalVideoState) => boolean = createSelector(
    VideoSelectors.getMediaId,
    VideoSelectors.getMediaIds,
    (mediaId, mediaIds) => Boolean(mediaId || mediaIds),
);

/**
 * Return state of video depending on redux video toggling state,
 * and screen size, since mobile always have expanded video
 */
export const isLiveVideoExpanded: (state: GlobalVideoState) => boolean = createSelector(
    VideoSelectors.isVideoFrameExpanded,
    (isVideoFrameExpanded) => Boolean(isVideoFrameExpanded),
);

// someChecker for liveBets toggling need to handle this better
// if the url is /live the ui should look a bit different
export const isLiveBetsToggled: (state: GlobalVideoState) => boolean = createSelector(
    isArchiveVideoActive,
    isLiveVideoExpanded,
    (archiveVideoActive, liveVideoExpanded) => !archiveVideoActive && liveVideoExpanded,
);

/**
 * Check if the receipt header should be shown
 */
export const showReceipts: (state: GlobalVideoState) => boolean = createSelector(
    VideoSelectors.isVideoFrameExpanded,
    VideoSelectors.isVideoOnly,
    isArchiveVideoActive,
    (videoFrameExpanded, videoOnly, archiveVideoActive) =>
        videoFrameExpanded && !archiveVideoActive && !videoOnly,
);

/**
 * Select all live tracks
 * By selecting the channel and convert it to `CalendarTracks`
 * These tracks are the ones that are scheduled or are being streamed
 */
// @ts-expect-error
export const getSelectedTracksForStream: (
    state: GlobalCalendarState,
) => Array<CalendarTrack> = createSelector(
    VideoSelectors.getSelectedChannel,
    // @ts-expect-error
    (state) => CalendarSelectors.getCalendarDay(state, serverDate()),
    (channel, day) => {
        if (!day) return [];
        return Live.convertChannelTrackToCalendarTrack(channel.tracks, day.tracks) || [];
    },
);

/**
 *  Return the ongoing or upcoming `CalendarTrack` and `CalendarRace` for the selected stream
 */
export const getLiveTrackAndRace: (
    state: GlobalCalendarState,
) => [
    CalendarTrack | null | undefined,
    CalendarAPITypes.CalendarRace | null | undefined,
    CalendarAPITypes.CalendarRace | null | undefined,
] = createSelector(getSelectedTracksForStream, (tracks) =>
    Live.getClosestTrackWithRace(tracks),
);

/**
 * Get all tracks sorted for today.
 */
type CalendarTracksState = GlobalCalendarState & User & GlobalVideoState;

export const getAllCalendarTracksForToday: (
    state: CalendarTracksState,
) => Array<CalendarTrack> = createSelector(
    CalendarSelectors.getTodaysTracks,
    getSelectedTracksForStream,
    (state) => CalendarSelectors.getCalendarDay(state, serverDate()),
    (tracks, selectedStreamTracks, day) => {
        if (!day) return [];
        // Remove not live tracks
        const cleanTracks = Live.removePassedTracks(tracks);
        // Remove not live tracks
        const cleanSelectedStreamTracks = Live.removePassedTracks(selectedStreamTracks);
        const calendarTracks = [
            // @ts-expect-error
            ...Live.sortOngoingTracks(day, cleanTracks),
            // @ts-expect-error
            ...Live.sortUpComingTracks(cleanTracks),
        ];
        const streamTracks = [
            // @ts-expect-error
            ...Live.sortOngoingTracks(day, cleanSelectedStreamTracks),
            // @ts-expect-error
            ...Live.sortUpComingTracks(cleanSelectedStreamTracks),
        ];
        return uniqBy(get("id"), [...streamTracks, ...calendarTracks]);
    },
);

/**
 * Get the selected live track
 */
export const getSelectedLiveTrack: (
    state: QuickplayState,
) => CalendarTrack | null | undefined = createSelector(
    QuickplaySelectors.getSelectedTrack,
    // @ts-expect-error
    getAllCalendarTracksForToday,
    (selectedTrackNumber, currentLiveTracks) =>
        find<CalendarTrack>(
            (liveTrack: CalendarTrack) => liveTrack.id === selectedTrackNumber,
            currentLiveTracks,
        ),
);

/**
 * Get live race id from next upcoming races or the selected one by the user
 */
export const getLiveRaceId: (state: QuickplayState) => string = createSelector(
    QuickplaySelectors.getSelectedRaceId,
    (selectedRaceId) => selectedRaceId || "",
);
