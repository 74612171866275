import {includes, map, compact, find} from "lodash";

export const getFormatType = (format: any) => {
    switch (format) {
        case "m3u":
            return "application/x-mpegURL";
        case "mpeg4":
            return "video/mp4";
        case "mpeg-dash":
            return "application/xml+dash";
        default:
            return null;
    }
};

export const isAudioOnly = (assetTypes: any) => includes(assetTypes, "Audio");

export const getStreamingUrl = (releases: any) => {
    const release = find(releases, {delivery: "streaming"});
    if (!release) return null;

    return release.url.replace("http://", "//");
};

export const parse = (data: any) => {
    const {id, title, defaultThumbnailUrl} = data;
    const media = {
        id,
        isAudioOnly: false,
        poster: defaultThumbnailUrl,
        title,
    };

    const streams = compact(
        map(data.content, (content) => {
            const streamIsAudioOnly = isAudioOnly(content.assetTypes);
            media.isAudioOnly = streamIsAudioOnly;

            const format = content.format.toLowerCase();
            const formatType = getFormatType(format);
            if (!formatType) return null; // Stream not suitable

            const streamUrl = getStreamingUrl(content.releases);
            if (!streamUrl) return null;

            return {
                bitrate: content.bitrate,
                isAudioOnly: streamIsAudioOnly,
                isLive: content.expression === "nonstop",
                url: streamUrl,
                type: formatType,
            };
        }),
    );

    if (streams.length === 0) return null;

    // @ts-expect-error
    media.streams = streams;
    return media;
};
