type WinningCombinations = Record<
    string,
    {
        combinations: number;
        winningCombinations: number;
        cost: number;
        payouts: Record<string, Record<string, number>>;
    }
>;

const winningCombinations: WinningCombinations = {
    "1,2,3,4-5,6-7": {
        combinations: 4,
        winningCombinations: 4,
        cost: 16,
        payouts: {
            "2": {
                "2": 4,
            },
            "3": {
                "3": 4,
            },
            "4": {
                "3": 2,
                "4": 2,
            },
            "5": {
                "3": 2,
                "5": 2,
            },
            "6": {
                "3": 2,
                "5": 1,
                "6": 1,
            },
            "7": {
                "3": 2,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1,2,3-4,5,6-7": {
        combinations: 4,
        winningCombinations: 4,
        cost: 16,
        payouts: {
            "2": {
                "2": 4,
            },
            "3": {
                "2": 2,
                "3": 2,
            },
            "4": {
                "2": 2,
                "4": 2,
            },
            "5": {
                "2": 2,
                "5": 2,
            },
            "6": {
                "2": 2,
                "5": 1,
                "6": 1,
            },
            "7": {
                "2": 2,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1,2,3-4,5-6,7": {
        combinations: 4,
        winningCombinations: 4,
        cost: 16,
        payouts: {
            "2": {
                "2": 4,
            },
            "3": {
                "2": 2,
                "3": 2,
            },
            "4": {
                "2": 2,
                "4": 2,
            },
            "5": {
                "2": 2,
                "4": 1,
                "5": 1,
            },
            "6": {
                "2": 2,
                "4": 1,
                "6": 1,
            },
            "7": {
                "2": 2,
                "4": 1,
                "7": 1,
            },
        },
    },
    "1,2-3,4,5,6-7": {
        combinations: 4,
        winningCombinations: 2,
        cost: 16,
        payouts: {
            "2": {
                "2": 2,
            },
            "3": {
                "3": 2,
            },
            "4": {
                "4": 2,
            },
            "5": {
                "5": 2,
            },
            "6": {
                "5": 1,
                "6": 1,
            },
            "7": {
                "5": 1,
                "7": 1,
            },
        },
    },
    "1,2-3,4,5-6,7": {
        combinations: 4,
        winningCombinations: 2,
        cost: 16,
        payouts: {
            "2": {
                "2": 2,
            },
            "3": {
                "3": 2,
            },
            "4": {
                "4": 2,
            },
            "5": {
                "4": 1,
                "5": 1,
            },
            "6": {
                "4": 1,
                "6": 1,
            },
            "7": {
                "4": 1,
                "7": 1,
            },
        },
    },
    "1,2-3,4-5,6,7": {
        combinations: 4,
        winningCombinations: 2,
        cost: 16,
        payouts: {
            "2": {
                "2": 2,
            },
            "3": {
                "3": 2,
            },
            "4": {
                "3": 1,
                "4": 1,
            },
            "5": {
                "3": 1,
                "5": 1,
            },
            "6": {
                "3": 1,
                "6": 1,
            },
            "7": {
                "3": 1,
                "7": 1,
            },
        },
    },
    "1-2,3,4,5,6-7": {
        combinations: 4,
        winningCombinations: 2,
        cost: 16,
        payouts: {
            "2": {
                "2": 2,
            },
            "3": {
                "3": 2,
            },
            "4": {
                "4": 2,
            },
            "5": {
                "5": 2,
            },
            "6": {
                "5": 1,
                "6": 1,
            },
            "7": {
                "5": 1,
                "7": 1,
            },
        },
    },
    "1-2,3,4,5-6,7": {
        combinations: 4,
        winningCombinations: 2,
        cost: 16,
        payouts: {
            "2": {
                "2": 2,
            },
            "3": {
                "3": 2,
            },
            "4": {
                "4": 2,
            },
            "5": {
                "4": 1,
                "5": 1,
            },
            "6": {
                "4": 1,
                "6": 1,
            },
            "7": {
                "4": 1,
                "7": 1,
            },
        },
    },
    "1-2,3,4-5,6,7": {
        combinations: 4,
        winningCombinations: 2,
        cost: 16,
        payouts: {
            "2": {
                "2": 2,
            },
            "3": {
                "3": 2,
            },
            "4": {
                "3": 1,
                "4": 1,
            },
            "5": {
                "3": 1,
                "5": 1,
            },
            "6": {
                "3": 1,
                "6": 1,
            },
            "7": {
                "3": 1,
                "7": 1,
            },
        },
    },
    "1-2,3-4,5,6,7": {
        combinations: 4,
        winningCombinations: 2,
        cost: 16,
        payouts: {
            "2": {
                "2": 2,
            },
            "3": {
                "2": 1,
                "3": 1,
            },
            "4": {
                "2": 1,
                "4": 1,
            },
            "5": {
                "2": 1,
                "5": 1,
            },
            "6": {
                "2": 1,
                "6": 1,
            },
            "7": {
                "2": 1,
                "7": 1,
            },
        },
    },
    "1,2,3,4,5-7": {
        combinations: 6,
        winningCombinations: 6,
        cost: 24,
        payouts: {
            "2": {
                "2": 6,
            },
            "3": {
                "3": 6,
            },
            "4": {
                "4": 6,
            },
            "5": {
                "4": 4,
                "5": 2,
            },
            "6": {
                "4": 4,
                "5": 1,
                "6": 1,
            },
            "7": {
                "4": 4,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1,2,3,4-6,7": {
        combinations: 6,
        winningCombinations: 6,
        cost: 24,
        payouts: {
            "2": {
                "2": 6,
            },
            "3": {
                "3": 6,
            },
            "4": {
                "3": 4,
                "4": 2,
            },
            "5": {
                "3": 4,
                "4": 1,
                "5": 1,
            },
            "6": {
                "3": 4,
                "4": 1,
                "6": 1,
            },
            "7": {
                "3": 4,
                "4": 1,
                "7": 1,
            },
        },
    },
    "1,2,3-5,6,7": {
        combinations: 6,
        winningCombinations: 6,
        cost: 24,
        payouts: {
            "2": {
                "2": 6,
            },
            "3": {
                "2": 4,
                "3": 2,
            },
            "4": {
                "2": 4,
                "3": 1,
                "4": 1,
            },
            "5": {
                "2": 4,
                "3": 1,
                "5": 1,
            },
            "6": {
                "2": 4,
                "3": 1,
                "6": 1,
            },
            "7": {
                "2": 4,
                "3": 1,
                "7": 1,
            },
        },
    },
    "1,2-4,5,6,7": {
        combinations: 6,
        winningCombinations: 2,
        cost: 24,
        payouts: {
            "2": {
                "2": 2,
            },
            "3": {
                "2": 1,
                "3": 1,
            },
            "4": {
                "2": 1,
                "4": 1,
            },
            "5": {
                "2": 1,
                "5": 1,
            },
            "6": {
                "2": 1,
                "6": 1,
            },
            "7": {
                "2": 1,
                "7": 1,
            },
        },
    },
    "1-3,4,5,6,7": {
        combinations: 6,
        winningCombinations: 1,
        cost: 24,
        payouts: {
            "2": {
                "2": 1,
            },
            "3": {
                "3": 1,
            },
            "4": {
                "4": 1,
            },
            "5": {
                "5": 1,
            },
            "6": {
                "6": 1,
            },
            "7": {
                "7": 1,
            },
        },
    },
    "1,2-3,4-5,6-7": {
        combinations: 8,
        winningCombinations: 4,
        cost: 32,
        payouts: {
            "2": {
                "2": 4,
            },
            "3": {
                "3": 4,
            },
            "4": {
                "3": 2,
                "4": 2,
            },
            "5": {
                "3": 2,
                "5": 2,
            },
            "6": {
                "3": 2,
                "5": 1,
                "6": 1,
            },
            "7": {
                "3": 2,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1-2,3,4-5,6-7": {
        combinations: 8,
        winningCombinations: 4,
        cost: 32,
        payouts: {
            "2": {
                "2": 4,
            },
            "3": {
                "3": 4,
            },
            "4": {
                "3": 2,
                "4": 2,
            },
            "5": {
                "3": 2,
                "5": 2,
            },
            "6": {
                "3": 2,
                "5": 1,
                "6": 1,
            },
            "7": {
                "3": 2,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1-2,3-4,5,6-7": {
        combinations: 8,
        winningCombinations: 4,
        cost: 32,
        payouts: {
            "2": {
                "2": 4,
            },
            "3": {
                "2": 2,
                "3": 2,
            },
            "4": {
                "2": 2,
                "4": 2,
            },
            "5": {
                "2": 2,
                "5": 2,
            },
            "6": {
                "2": 2,
                "5": 1,
                "6": 1,
            },
            "7": {
                "2": 2,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1-2,3-4,5-6,7": {
        combinations: 8,
        winningCombinations: 4,
        cost: 32,
        payouts: {
            "2": {
                "2": 4,
            },
            "3": {
                "2": 2,
                "3": 2,
            },
            "4": {
                "2": 2,
                "4": 2,
            },
            "5": {
                "2": 2,
                "4": 1,
                "5": 1,
            },
            "6": {
                "2": 2,
                "4": 1,
                "6": 1,
            },
            "7": {
                "2": 2,
                "4": 1,
                "7": 1,
            },
        },
    },
    "1,2,3-4,5-7": {
        combinations: 12,
        winningCombinations: 12,
        cost: 48,
        payouts: {
            "2": {
                "2": 12,
            },
            "3": {
                "2": 6,
                "3": 6,
            },
            "4": {
                "2": 6,
                "4": 6,
            },
            "5": {
                "2": 6,
                "4": 4,
                "5": 2,
            },
            "6": {
                "2": 6,
                "4": 4,
                "5": 1,
                "6": 1,
            },
            "7": {
                "2": 6,
                "4": 4,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1,2,3-5,6-7": {
        combinations: 12,
        winningCombinations: 12,
        cost: 48,
        payouts: {
            "2": {
                "2": 12,
            },
            "3": {
                "2": 8,
                "3": 4,
            },
            "4": {
                "2": 8,
                "3": 2,
                "4": 2,
            },
            "5": {
                "2": 8,
                "3": 2,
                "5": 2,
            },
            "6": {
                "2": 8,
                "3": 2,
                "5": 1,
                "6": 1,
            },
            "7": {
                "2": 8,
                "3": 2,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1,2-3,4,5-7": {
        combinations: 12,
        winningCombinations: 6,
        cost: 48,
        payouts: {
            "2": {
                "2": 6,
            },
            "3": {
                "3": 6,
            },
            "4": {
                "4": 6,
            },
            "5": {
                "4": 4,
                "5": 2,
            },
            "6": {
                "4": 4,
                "5": 1,
                "6": 1,
            },
            "7": {
                "4": 4,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1,2-3,4-6,7": {
        combinations: 12,
        winningCombinations: 6,
        cost: 48,
        payouts: {
            "2": {
                "2": 6,
            },
            "3": {
                "3": 6,
            },
            "4": {
                "3": 4,
                "4": 2,
            },
            "5": {
                "3": 4,
                "4": 1,
                "5": 1,
            },
            "6": {
                "3": 4,
                "4": 1,
                "6": 1,
            },
            "7": {
                "3": 4,
                "4": 1,
                "7": 1,
            },
        },
    },
    "1,2-4,5,6-7": {
        combinations: 12,
        winningCombinations: 4,
        cost: 48,
        payouts: {
            "2": {
                "2": 4,
            },
            "3": {
                "2": 2,
                "3": 2,
            },
            "4": {
                "2": 2,
                "4": 2,
            },
            "5": {
                "2": 2,
                "5": 2,
            },
            "6": {
                "2": 2,
                "5": 1,
                "6": 1,
            },
            "7": {
                "2": 2,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1,2-4,5-6,7": {
        combinations: 12,
        winningCombinations: 4,
        cost: 48,
        payouts: {
            "2": {
                "2": 4,
            },
            "3": {
                "2": 2,
                "3": 2,
            },
            "4": {
                "2": 2,
                "4": 2,
            },
            "5": {
                "2": 2,
                "4": 1,
                "5": 1,
            },
            "6": {
                "2": 2,
                "4": 1,
                "6": 1,
            },
            "7": {
                "2": 2,
                "4": 1,
                "7": 1,
            },
        },
    },
    "1-2,3,4,5-7": {
        combinations: 12,
        winningCombinations: 6,
        cost: 48,
        payouts: {
            "2": {
                "2": 6,
            },
            "3": {
                "3": 6,
            },
            "4": {
                "4": 6,
            },
            "5": {
                "4": 4,
                "5": 2,
            },
            "6": {
                "4": 4,
                "5": 1,
                "6": 1,
            },
            "7": {
                "4": 4,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1-2,3,4-6,7": {
        combinations: 12,
        winningCombinations: 6,
        cost: 48,
        payouts: {
            "2": {
                "2": 6,
            },
            "3": {
                "3": 6,
            },
            "4": {
                "3": 4,
                "4": 2,
            },
            "5": {
                "3": 4,
                "4": 1,
                "5": 1,
            },
            "6": {
                "3": 4,
                "4": 1,
                "6": 1,
            },
            "7": {
                "3": 4,
                "4": 1,
                "7": 1,
            },
        },
    },
    "1-2,3-5,6,7": {
        combinations: 12,
        winningCombinations: 6,
        cost: 48,
        payouts: {
            "2": {
                "2": 6,
            },
            "3": {
                "2": 4,
                "3": 2,
            },
            "4": {
                "2": 4,
                "3": 1,
                "4": 1,
            },
            "5": {
                "2": 4,
                "3": 1,
                "5": 1,
            },
            "6": {
                "2": 4,
                "3": 1,
                "6": 1,
            },
            "7": {
                "2": 4,
                "3": 1,
                "7": 1,
            },
        },
    },
    "1-3,4,5,6-7": {
        combinations: 12,
        winningCombinations: 2,
        cost: 48,
        payouts: {
            "2": {
                "2": 2,
            },
            "3": {
                "3": 2,
            },
            "4": {
                "4": 2,
            },
            "5": {
                "5": 2,
            },
            "6": {
                "5": 1,
                "6": 1,
            },
            "7": {
                "5": 1,
                "7": 1,
            },
        },
    },
    "1-3,4,5-6,7": {
        combinations: 12,
        winningCombinations: 2,
        cost: 48,
        payouts: {
            "2": {
                "2": 2,
            },
            "3": {
                "3": 2,
            },
            "4": {
                "4": 2,
            },
            "5": {
                "4": 1,
                "5": 1,
            },
            "6": {
                "4": 1,
                "6": 1,
            },
            "7": {
                "4": 1,
                "7": 1,
            },
        },
    },
    "1-3,4-5,6,7": {
        combinations: 12,
        winningCombinations: 2,
        cost: 48,
        payouts: {
            "2": {
                "2": 2,
            },
            "3": {
                "3": 2,
            },
            "4": {
                "3": 1,
                "4": 1,
            },
            "5": {
                "3": 1,
                "5": 1,
            },
            "6": {
                "3": 1,
                "6": 1,
            },
            "7": {
                "3": 1,
                "7": 1,
            },
        },
    },
    "1-2,3-4,5-7": {
        combinations: 24,
        winningCombinations: 12,
        cost: 96,
        payouts: {
            "2": {
                "2": 12,
            },
            "3": {
                "2": 6,
                "3": 6,
            },
            "4": {
                "2": 6,
                "4": 6,
            },
            "5": {
                "2": 6,
                "4": 4,
                "5": 2,
            },
            "6": {
                "2": 6,
                "4": 4,
                "5": 1,
                "6": 1,
            },
            "7": {
                "2": 6,
                "4": 4,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1-2,3-5,6-7": {
        combinations: 24,
        winningCombinations: 12,
        cost: 96,
        payouts: {
            "2": {
                "2": 12,
            },
            "3": {
                "2": 8,
                "3": 4,
            },
            "4": {
                "2": 8,
                "3": 2,
                "4": 2,
            },
            "5": {
                "2": 8,
                "3": 2,
                "5": 2,
            },
            "6": {
                "2": 8,
                "3": 2,
                "5": 1,
                "6": 1,
            },
            "7": {
                "2": 8,
                "3": 2,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1-3,4-5,6-7": {
        combinations: 24,
        winningCombinations: 4,
        cost: 96,
        payouts: {
            "2": {
                "2": 4,
            },
            "3": {
                "3": 4,
            },
            "4": {
                "3": 2,
                "4": 2,
            },
            "5": {
                "3": 2,
                "5": 2,
            },
            "6": {
                "3": 2,
                "5": 1,
                "6": 1,
            },
            "7": {
                "3": 2,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1,2,3,4-7": {
        combinations: 24,
        winningCombinations: 24,
        cost: 96,
        payouts: {
            "2": {
                "2": 24,
            },
            "3": {
                "3": 24,
            },
            "4": {
                "3": 18,
                "4": 6,
            },
            "5": {
                "3": 18,
                "4": 4,
                "5": 2,
            },
            "6": {
                "3": 18,
                "4": 4,
                "5": 1,
                "6": 1,
            },
            "7": {
                "3": 18,
                "4": 4,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1,2,3-6,7": {
        combinations: 24,
        winningCombinations: 24,
        cost: 96,
        payouts: {
            "2": {
                "2": 24,
            },
            "3": {
                "2": 18,
                "3": 6,
            },
            "4": {
                "2": 18,
                "3": 4,
                "4": 2,
            },
            "5": {
                "2": 18,
                "3": 4,
                "4": 1,
                "5": 1,
            },
            "6": {
                "2": 18,
                "3": 4,
                "4": 1,
                "6": 1,
            },
            "7": {
                "2": 18,
                "3": 4,
                "4": 1,
                "7": 1,
            },
        },
    },
    "1,2-5,6,7": {
        combinations: 24,
        winningCombinations: 6,
        cost: 96,
        payouts: {
            "2": {
                "2": 6,
            },
            "3": {
                "2": 4,
                "3": 2,
            },
            "4": {
                "2": 4,
                "3": 1,
                "4": 1,
            },
            "5": {
                "2": 4,
                "3": 1,
                "5": 1,
            },
            "6": {
                "2": 4,
                "3": 1,
                "6": 1,
            },
            "7": {
                "2": 4,
                "3": 1,
                "7": 1,
            },
        },
    },
    "1-4,5,6,7": {
        combinations: 24,
        winningCombinations: 2,
        cost: 96,
        payouts: {
            "2": {
                "2": 2,
            },
            "3": {
                "2": 1,
                "3": 1,
            },
            "4": {
                "2": 1,
                "4": 1,
            },
            "5": {
                "2": 1,
                "5": 1,
            },
            "6": {
                "2": 1,
                "6": 1,
            },
            "7": {
                "2": 1,
                "7": 1,
            },
        },
    },
    "1,2-4,5-7": {
        combinations: 36,
        winningCombinations: 12,
        cost: 144,
        payouts: {
            "2": {
                "2": 12,
            },
            "3": {
                "2": 6,
                "3": 6,
            },
            "4": {
                "2": 6,
                "4": 6,
            },
            "5": {
                "2": 6,
                "4": 4,
                "5": 2,
            },
            "6": {
                "2": 6,
                "4": 4,
                "5": 1,
                "6": 1,
            },
            "7": {
                "2": 6,
                "4": 4,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1-3,4,5-7": {
        combinations: 36,
        winningCombinations: 6,
        cost: 144,
        payouts: {
            "2": {
                "2": 6,
            },
            "3": {
                "3": 6,
            },
            "4": {
                "4": 6,
            },
            "5": {
                "4": 4,
                "5": 2,
            },
            "6": {
                "4": 4,
                "5": 1,
                "6": 1,
            },
            "7": {
                "4": 4,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1-3,4-6,7": {
        combinations: 36,
        winningCombinations: 6,
        cost: 144,
        payouts: {
            "2": {
                "2": 6,
            },
            "3": {
                "3": 6,
            },
            "4": {
                "3": 4,
                "4": 2,
            },
            "5": {
                "3": 4,
                "4": 1,
                "5": 1,
            },
            "6": {
                "3": 4,
                "4": 1,
                "6": 1,
            },
            "7": {
                "3": 4,
                "4": 1,
                "7": 1,
            },
        },
    },
    "1,2-3,4-7": {
        combinations: 48,
        winningCombinations: 24,
        cost: 192,
        payouts: {
            "2": {
                "2": 24,
            },
            "3": {
                "3": 24,
            },
            "4": {
                "3": 18,
                "4": 6,
            },
            "5": {
                "3": 18,
                "4": 4,
                "5": 2,
            },
            "6": {
                "3": 18,
                "4": 4,
                "5": 1,
                "6": 1,
            },
            "7": {
                "3": 18,
                "4": 4,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1,2-5,6-7": {
        combinations: 48,
        winningCombinations: 12,
        cost: 192,
        payouts: {
            "2": {
                "2": 12,
            },
            "3": {
                "2": 8,
                "3": 4,
            },
            "4": {
                "2": 8,
                "3": 2,
                "4": 2,
            },
            "5": {
                "2": 8,
                "3": 2,
                "5": 2,
            },
            "6": {
                "2": 8,
                "3": 2,
                "5": 1,
                "6": 1,
            },
            "7": {
                "2": 8,
                "3": 2,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1-2,3,4-7": {
        combinations: 48,
        winningCombinations: 24,
        cost: 192,
        payouts: {
            "2": {
                "2": 24,
            },
            "3": {
                "3": 24,
            },
            "4": {
                "3": 18,
                "4": 6,
            },
            "5": {
                "3": 18,
                "4": 4,
                "5": 2,
            },
            "6": {
                "3": 18,
                "4": 4,
                "5": 1,
                "6": 1,
            },
            "7": {
                "3": 18,
                "4": 4,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1-2,3-6,7": {
        combinations: 48,
        winningCombinations: 24,
        cost: 192,
        payouts: {
            "2": {
                "2": 24,
            },
            "3": {
                "2": 18,
                "3": 6,
            },
            "4": {
                "2": 18,
                "3": 4,
                "4": 2,
            },
            "5": {
                "2": 18,
                "3": 4,
                "4": 1,
                "5": 1,
            },
            "6": {
                "2": 18,
                "3": 4,
                "4": 1,
                "6": 1,
            },
            "7": {
                "2": 18,
                "3": 4,
                "4": 1,
                "7": 1,
            },
        },
    },
    "1-4,5,6-7": {
        combinations: 48,
        winningCombinations: 4,
        cost: 192,
        payouts: {
            "2": {
                "2": 4,
            },
            "3": {
                "2": 2,
                "3": 2,
            },
            "4": {
                "2": 2,
                "4": 2,
            },
            "5": {
                "2": 2,
                "5": 2,
            },
            "6": {
                "2": 2,
                "5": 1,
                "6": 1,
            },
            "7": {
                "2": 2,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1-4,5-6,7": {
        combinations: 48,
        winningCombinations: 4,
        cost: 192,
        payouts: {
            "2": {
                "2": 4,
            },
            "3": {
                "2": 2,
                "3": 2,
            },
            "4": {
                "2": 2,
                "4": 2,
            },
            "5": {
                "2": 2,
                "4": 1,
                "5": 1,
            },
            "6": {
                "2": 2,
                "4": 1,
                "6": 1,
            },
            "7": {
                "2": 2,
                "4": 1,
                "7": 1,
            },
        },
    },
    "1,2,3-7": {
        combinations: 120,
        winningCombinations: 120,
        cost: 480,
        payouts: {
            "2": {
                "2": 120,
            },
            "3": {
                "2": 96,
                "3": 24,
            },
            "4": {
                "2": 96,
                "3": 18,
                "4": 6,
            },
            "5": {
                "2": 96,
                "3": 18,
                "4": 4,
                "5": 2,
            },
            "6": {
                "2": 96,
                "3": 18,
                "4": 4,
                "5": 1,
                "6": 1,
            },
            "7": {
                "2": 96,
                "3": 18,
                "4": 4,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1,2-6,7": {
        combinations: 120,
        winningCombinations: 24,
        cost: 480,
        payouts: {
            "2": {
                "2": 24,
            },
            "3": {
                "2": 18,
                "3": 6,
            },
            "4": {
                "2": 18,
                "3": 4,
                "4": 2,
            },
            "5": {
                "2": 18,
                "3": 4,
                "4": 1,
                "5": 1,
            },
            "6": {
                "2": 18,
                "3": 4,
                "4": 1,
                "6": 1,
            },
            "7": {
                "2": 18,
                "3": 4,
                "4": 1,
                "7": 1,
            },
        },
    },
    "1-5,6,7": {
        combinations: 120,
        winningCombinations: 6,
        cost: 480,
        payouts: {
            "2": {
                "2": 6,
            },
            "3": {
                "2": 4,
                "3": 2,
            },
            "4": {
                "2": 4,
                "3": 1,
                "4": 1,
            },
            "5": {
                "2": 4,
                "3": 1,
                "5": 1,
            },
            "6": {
                "2": 4,
                "3": 1,
                "6": 1,
            },
            "7": {
                "2": 4,
                "3": 1,
                "7": 1,
            },
        },
    },
    "1-3,4-7": {
        combinations: 144,
        winningCombinations: 24,
        cost: 576,
        payouts: {
            "2": {
                "2": 24,
            },
            "3": {
                "3": 24,
            },
            "4": {
                "3": 18,
                "4": 6,
            },
            "5": {
                "3": 18,
                "4": 4,
                "5": 2,
            },
            "6": {
                "3": 18,
                "4": 4,
                "5": 1,
                "6": 1,
            },
            "7": {
                "3": 18,
                "4": 4,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1-4,5-7": {
        combinations: 144,
        winningCombinations: 12,
        cost: 576,
        payouts: {
            "2": {
                "2": 12,
            },
            "3": {
                "2": 6,
                "3": 6,
            },
            "4": {
                "2": 6,
                "4": 6,
            },
            "5": {
                "2": 6,
                "4": 4,
                "5": 2,
            },
            "6": {
                "2": 6,
                "4": 4,
                "5": 1,
                "6": 1,
            },
            "7": {
                "2": 6,
                "4": 4,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1-2,3-7": {
        combinations: 240,
        winningCombinations: 120,
        cost: 960,
        payouts: {
            "2": {
                "2": 120,
            },
            "3": {
                "2": 96,
                "3": 24,
            },
            "4": {
                "2": 96,
                "3": 18,
                "4": 6,
            },
            "5": {
                "2": 96,
                "3": 18,
                "4": 4,
                "5": 2,
            },
            "6": {
                "2": 96,
                "3": 18,
                "4": 4,
                "5": 1,
                "6": 1,
            },
            "7": {
                "2": 96,
                "3": 18,
                "4": 4,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1-5,6-7": {
        combinations: 240,
        winningCombinations: 12,
        cost: 960,
        payouts: {
            "2": {
                "2": 12,
            },
            "3": {
                "2": 8,
                "3": 4,
            },
            "4": {
                "2": 8,
                "3": 2,
                "4": 2,
            },
            "5": {
                "2": 8,
                "3": 2,
                "5": 2,
            },
            "6": {
                "2": 8,
                "3": 2,
                "5": 1,
                "6": 1,
            },
            "7": {
                "2": 8,
                "3": 2,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1,2-7": {
        combinations: 720,
        winningCombinations: 120,
        cost: 2880,
        payouts: {
            "2": {
                "2": 120,
            },
            "3": {
                "2": 96,
                "3": 24,
            },
            "4": {
                "2": 96,
                "3": 18,
                "4": 6,
            },
            "5": {
                "2": 96,
                "3": 18,
                "4": 4,
                "5": 2,
            },
            "6": {
                "2": 96,
                "3": 18,
                "4": 4,
                "5": 1,
                "6": 1,
            },
            "7": {
                "2": 96,
                "3": 18,
                "4": 4,
                "5": 1,
                "7": 1,
            },
        },
    },
    "1-6,7": {
        combinations: 720,
        winningCombinations: 24,
        cost: 2880,
        payouts: {
            "2": {
                "2": 24,
            },
            "3": {
                "2": 18,
                "3": 6,
            },
            "4": {
                "2": 18,
                "3": 4,
                "4": 2,
            },
            "5": {
                "2": 18,
                "3": 4,
                "4": 1,
                "5": 1,
            },
            "6": {
                "2": 18,
                "3": 4,
                "4": 1,
                "6": 1,
            },
            "7": {
                "2": 18,
                "3": 4,
                "4": 1,
                "7": 1,
            },
        },
    },
    "1-7": {
        combinations: 5040,
        winningCombinations: 120,
        cost: 20160,
        payouts: {
            "2": {
                "2": 120,
            },
            "3": {
                "2": 96,
                "3": 24,
            },
            "4": {
                "2": 96,
                "3": 18,
                "4": 6,
            },
            "5": {
                "2": 96,
                "3": 18,
                "4": 4,
                "5": 2,
            },
            "6": {
                "2": 96,
                "3": 18,
                "4": 4,
                "5": 1,
                "6": 1,
            },
            "7": {
                "2": 96,
                "3": 18,
                "4": 4,
                "5": 1,
                "7": 1,
            },
        },
    },
};

export default winningCombinations;
