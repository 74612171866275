import _ from "lodash";
import * as BetUtils from "../domain/bet";
import type {State} from "../domain/index";

export const getRaceBets = (state: State, raceId: string) => {
    // @ts-expect-error legacyBet does not exist
    _.filter(state.legacyBet?.bets, (bet) => {
        const [date] = bet.timestamp.split("T");
        return _.some(bet.races, (r) => {
            const betRaceId = [date, r.trackId, r.raceNumber].join("_");
            return betRaceId === raceId;
        });
    });
};

export const getBet = (state: State, betId: string) =>
    // @ts-expect-error legacyBet does not exist
    state.legacyBet.bets[BetUtils.removeTimePart(betId)];
export const getBets = (state: State, betIds: Array<string>) =>
    _.map(betIds, (betId) => getBet(state, betId));

export const getFileBetSummaries = (state: State, fileBetSummaryId: string) =>
    // @ts-expect-error legacyBet does not exist
    state.legacyBet.betSummaries[fileBetSummaryId];

export const getFileBetSummaryLoadingStatus = (state: State, fileBetSummaryId: string) =>
    // @ts-expect-error legacyBet does not exist
    state.legacyBet.betSummaryLoadingStatuses[fileBetSummaryId];
