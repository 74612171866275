import {call, select, put, takeEvery, takeLatest, takeLeading} from "redux-saga/effects";
import dayjs from "dayjs";
import _, {sortBy} from "lodash";
import {map, isUndefined, isEmpty} from "lodash/fp";
import type {MediaObject} from "@atg-play-shared/media-graphql-client/__generated__/types.generated";
import {serverTime} from "@atg-shared/server-time";
import {browser, device} from "@atg/utils";
import log, {serializeError} from "@atg-shared/log";
import {MEDIA_SERVICE_URL} from "@atg-shared/service-url";
import {OldStreamingApi} from "@atg-horse-shared/old-archiveraces-theplatform-api";
import * as LivePageConstants from "@atg-horse-shared/live-page-constants";
import {RouterSelectors} from "@atg-shared/router";
import * as ReduxVideoActions from "../video/videoActions";
import * as VideoActionConstants from "../video/videoActionConstants";
import {PlayerIds} from "./playerReducer";
import {PlayerActionTypes, PlayerPlayTargets} from "./actionconstants";
import {resolveBonsaiStream} from "./streamingApi";
import {
    getStreamName,
    getMediaPlaylistPos,
    isFullscreen,
    getChannelThumbnail,
    getChannelStream,
    getVolume,
} from "./playerSelectors";
import * as VideoSelectors from "./videoSelectors";
import * as PlayerActions from "./playerActions";

export function* playFullscreenOnTouchAndroid({payload}) {
    const {player, target} = payload;
    const isFullscreenActive = yield select(isFullscreen, player);
    const location = yield select(RouterSelectors.getLocation);

    if (
        !isFullscreenActive &&
        device.isAndroid() &&
        location.pathname !== LivePageConstants.LIVE_PAGE_URL &&
        target !== PlayerPlayTargets.ANDROID_PLAYER_TOUCH_FULLSCREEN &&
        target !== PlayerPlayTargets.ANDROID_PLAYER_PLAY_BUTTON
    ) {
        yield put(PlayerActions.requestFullscreen(player, true));
    }
}

export function* resolveSourceFromBackend(source) {
    const {resolvePlatformURL, callBackendEndpoint, fetchGraphicsVideoUrlWithPublicUrl} =
        OldStreamingApi;

    let sourceResponse;
    let resolvedSource;
    try {
        sourceResponse = yield call(callBackendEndpoint, source);
    } catch (err) {
        return null;
    }
    if (!sourceResponse) return null;

    // mpx/mediaId endpoint returns sourceResponse as an object
    // mpx2/graphcsVideo endpoint returns sourceResponse as string
    const stream = sourceResponse.streams
        ? _.find(
              sourceResponse.streams,
              (item) =>
                  item.type === "application/x-mpegURL" || item.type === "video/mp4",
          )
        : sourceResponse;

    try {
        if (typeof stream === "string")
            resolvedSource = fetchGraphicsVideoUrlWithPublicUrl(stream);
        if (typeof stream === "object")
            resolvedSource = yield call(resolvePlatformURL, stream.url, source);
    } catch (err) {
        return null;
    }

    return {
        resolvedSource,
        stream,
        metaData: sourceResponse,
    };
}

export function* resolveArchiveOrWarmupSource({
    player,
    volume,
    autoPlay,
    source,
    playlist,
}) {
    const sourceData = yield call(resolveSourceFromBackend, source);
    if (!sourceData) return;
    const {resolvedSource, metaData} = sourceData;
    const sourcePayload = {
        streamName: source,
        source: resolvedSource,
        autoPlay,
        volume,
        playlist,
        audioOnly: false,
        imageURL: metaData.poster,
    };

    yield put(PlayerActions.source(player, sourcePayload));
}

export function* resolveLiveSourceFromBonsai({player, volume, autoPlay, source}) {
    // When using bonsai we get all this meta data in the initial channels request
    const selectedStream = yield select(VideoSelectors.getSelectedStream);
    const channelThumbnail = yield select(getChannelThumbnail, selectedStream);
    const stream = yield select(getChannelStream, selectedStream);

    // The free channels have all we need at once.
    if (stream.url) {
        const sourcePayload = {
            streamName: source,
            source: stream.url,
            autoPlay,
            volume,
            audioOnly: stream.audioOnly,
            imageURL: channelThumbnail,
        };

        yield put(PlayerActions.source(player, sourcePayload));
        return;
    }

    // If we don't get the URL, we fetch it from bonsai
    let bonsaiResponse;
    let bonsaiData;
    try {
        bonsaiResponse = yield call(resolveBonsaiStream, source);
        bonsaiData = bonsaiResponse.data;
    } catch (err) {
        // TODO: error handling
        log.error("resolveLiveSourceFromBonsai: live source should be loaded on init. ", {
            origin,
            error: serializeError(err),
        });
        return;
    }

    const sourcePayload = {
        streamName: source,
        source: bonsaiData.url,
        autoPlay,
        volume,
        audioOnly: stream.audioOnly,
        imageURL: channelThumbnail,
    };
    yield put(PlayerActions.source(PlayerIds.LIVE_PLAYER_ID, sourcePayload));
}

export function* resolveSourceFlow({payload}) {
    const {player, source} = payload;
    const streamName = yield select(getStreamName, player);
    if (streamName === source || source === "main-nochannel") return;

    if (player === PlayerIds.LIVE_PLAYER_ID) {
        yield call(resolveLiveSourceFromBonsai, payload);
        return;
    }

    if (player === PlayerIds.WARMUP_ARCHIVE_PLAYER_ID) {
        yield call(resolveArchiveOrWarmupSource, payload);
    }
}

/**
 * Get live source url from redux state or `racingInfo`
 */
export function* resolveLiveSource() {
    const streamName = yield select(getStreamName, PlayerIds.LIVE_PLAYER_ID);
    const selectedStream = yield select(VideoSelectors.getSelectedStream);

    // Find channel and cached channels in redux state
    const stream = yield select(getChannelStream, selectedStream);
    const channelThumbnail = yield select(getChannelThumbnail, selectedStream);
    const volume = yield select(getVolume, PlayerIds.LIVE_PLAYER_ID);

    // if a user has picked "Ingen video" we will not have a stream object
    if (stream?.url) {
        const sourcePayload = {
            streamName,
            source: stream.url,
            autoPlay: false,
            volume: volume ?? 0,
            audioOnly: stream.audioOnly,
            imageURL: channelThumbnail,
        };

        yield put(PlayerActions.source(PlayerIds.LIVE_PLAYER_ID, sourcePayload));
    }

    // Temporary for testing HD stream
    const selectedQuality = yield select(VideoSelectors.getSelectedQuality);
    const selectedChannel = yield select(VideoSelectors.getSelectedChannel);
    if (!stream?.url && selectedQuality === "premium") {
        yield put(
            ReduxVideoActions.selectChannel({
                channel: selectedChannel,
                quality: "",
            }),
        );
    }
}

export function* resolveGraphicsMediaUrlFromMPX2({
    player,
    volume,
    autoPlay,
    publicUrl,
    imageURL,
    streamName,
}) {
    const streamUrlFromMPX2 = yield call(
        OldStreamingApi.fetchGraphicsVideoUrlWithPublicUrl,
        publicUrl,
    );
    const streamPayload = {
        streamName,
        source: streamUrlFromMPX2,
        autoPlay,
        volume,
        audioOnly: false,
        imageURL,
    };
    yield put(PlayerActions.source(player, streamPayload));
}

/**
 *
 * @param {*} param0
 */
export function* resolveMediaSource({payload}) {
    const mediaId = yield select(VideoSelectors.getMediaId);
    const mediaIds = yield select(VideoSelectors.getMediaIds);
    const isPopout = yield select(VideoSelectors.isPopoutFrame);

    const {graphicsMedia}: MediaObject = payload;

    const mediaplayListPos = yield select(
        getMediaPlaylistPos,
        PlayerIds.WARMUP_ARCHIVE_PLAYER_ID,
    );
    const sortedMediaIds = sortBy(mediaIds, (video) => video.timestamp);

    const playlist = map(
        (video) => `${MEDIA_SERVICE_URL}/${video.mediaId}`,
        sortedMediaIds,
    );

    const media = !isEmpty(sortedMediaIds)
        ? sortedMediaIds?.[mediaplayListPos || 0]?.mediaId
        : mediaId;

    const volume = yield select(getVolume, PlayerIds.WARMUP_ARCHIVE_PLAYER_ID);
    // hts/tv4 graphicsMedia has expiration date
    // (the clean feed we get from mediaId has no expiration date)
    const expirationDate = graphicsMedia && dayjs(graphicsMedia.expirationDate);
    const shouldUseGraphicsMedia =
        expirationDate && serverTime().isBefore(dayjs(expirationDate));

    let autoPlay = isUndefined(payload.autoPlay) ? true : payload.autoPlay;
    if (browser.isSafari() && isPopout) {
        autoPlay = false;
    }
    if (shouldUseGraphicsMedia) {
        yield call(resolveGraphicsMediaUrlFromMPX2, {
            player: PlayerIds.WARMUP_ARCHIVE_PLAYER_ID,
            volume,
            autoPlay,
            publicUrl: graphicsMedia.publicUrl,
            imageURL: graphicsMedia.defaultThumbnailUrl,
            streamName: graphicsMedia.title,
        });
    } else {
        yield call(resolveArchiveOrWarmupSource, {
            player: PlayerIds.WARMUP_ARCHIVE_PLAYER_ID,
            volume,
            autoPlay,
            source: `${MEDIA_SERVICE_URL}/${media}`,
            playlist,
        });
    }
}

export default function* playerSaga() {
    yield takeEvery(PlayerActionTypes.START_PLAYER_SOURCE, resolveSourceFlow);

    // New live bar new horse live
    yield takeLatest(PlayerActionTypes.PLAYER_PLAY, playFullscreenOnTouchAndroid);
    yield takeEvery(
        [
            PlayerActionTypes.START_PLAYER_LIVE_SOURCE,
            VideoActionConstants.LOADED_CHANNEL_CONFIG,
            VideoActionConstants.LOAD_VIDEO_CHANNELS_START,
            VideoActionConstants.SELECT_CHANNEL,
        ],
        resolveLiveSource,
    );
    yield takeLeading(
        [
            VideoActionConstants.MEDIA_ARCHIVE,
            VideoActionConstants.MEDIA_WARMUP,
            PlayerActionTypes.START_PLAYER_MEDIA_SOURCE,
            PlayerActionTypes.PLAYER_NEXT_TRACK,
            PlayerActionTypes.PLAYER_PREVIOUS_TRACK,
        ],
        resolveMediaSource,
    );
}
