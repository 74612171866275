export * from "atg-ui/css/overridableVariables.styles";

// Top7 coupon variables
export const startOrderBorderColor = "#c7d1d9";
export const liveToggleButtonBgColor = "#1f313f";
export const liveToggleButtonTextColor = "#fff";
export const startOrderPlaceBackgroundGradientEnd = "#dbe4ef";
export const startOrderReserveBackgroundColor = "#566473";
export const top7PositionWidth = "34px";
export const top7PositionWidthSmall = "30px";
export const top7StartLineHeight = "30px";
export const top7StartLineHeightSmall = "26px";
