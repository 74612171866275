import * as React from "react";
import type {Props as SvgIconPropType} from "../components/SvgIcon";
import SvgIcon from "../components/SvgIcon";

export function TriangleRight(props: SvgIconPropType) {
    return (
        <SvgIcon {...props}>
            <path d="M6.6 0l18.8 16L6.6 32V0z" />
        </SvgIcon>
    );
}

export default TriangleRight;
