import {find} from "lodash";
import {flow, map, keyBy, compact, isObject} from "lodash/fp";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {CouponUtils} from "@atg-horse-shared/coupon";
import * as Bet from "../domain/legacyBet";
import receiptColumnData from "./receiptColumnData";

// @ts-expect-error not convert does not exist on map
const mapWithIndex = map.convert({cap: false});

const placeBetAttributes = ["firstPlaceBets", "secondPlaceBets", "thirdPlaceBets"];
const placeBetLabels = {
    firstPlaceBets: "1:a",
    secondPlaceBets: "2:a",
    thirdPlaceBets: "3:a",
};

function setPhase(row: any) {
    let phase;
    if (!row.resultExists) phase = "clock";
    if (row.resultExists && row.hideResult) phase = "eye";
    if (row.sneaked) phase = "win";

    return {
        ...row,
        phase,
    };
}

function checkRow(row: any, race: any, receipt: any) {
    row.resultExists = race.result || race.positionResults;
    row.sneaked =
        Bet.isSneaked(receipt, row) ||
        receipt.selectedTab === "result" ||
        receipt.showWinners;
    row.hideResult = receipt.selectedTab === "receipt" && Bet.hideResult(receipt, row);

    return setPhase(row);
}

function createRow(data: any, columns: any, receipt: any): any {
    return {
        columns: flow(
            map((column: any) => ({
                id: column.id,
                data: receiptColumnData(column, receipt, data),
            })),
            keyBy((column) => column.id),
        )(columns),
        id: data.id,
    };
}

export function divisionGameRows(receipt: any, columns: any) {
    const {bet} = receipt;
    return bet.races.map((race: any, index: number) => {
        const data = {
            ...race,
            id: index,
            legNo: index + 1,
        };

        const row = createRow(data, columns, receipt);
        return checkRow(row, race, receipt);
    });
}

export function combinationGameRows(receipt: any, columns: any) {
    const {bet} = receipt;
    const race = bet.races[0];

    return flow(
        mapWithIndex((attribute: any, index: number) => {
            const bets = race[attribute];
            if (!bets) return null;

            const data = {
                ...race,
                id: index,
                bets,
                // @ts-expect-error
                place: placeBetLabels[attribute],
                harryOpen: race[`${attribute}HarryOpen`],
            };

            const row = createRow(data, columns, receipt);
            return checkRow(row, race, columns);
        }),
        compact,
    )(placeBetAttributes);
}

export function top7GameRows(receipt: any, columns: any) {
    const {bet} = receipt;
    const boxedBets = bet.boxedBets ? bet.boxedBets : CouponUtils.getBoxedBets(bet);
    const race = bet.races[0];
    const nonSparsedBets = CouponUtils.top7GetNonSparsedBets(race.bets, 7);
    const ranking = bet.betCode ? boxedBets : nonSparsedBets;

    return ranking.map((_starts: any, index: number) => {
        const bets = flow(
            map((start) => {
                if (!start) return null;

                if (isObject(start)) return start;
                return find(race.bets, {number: start});
            }),
            compact,
        )(boxedBets[index]);
        const results = race.positionResults && race.positionResults[index + 1];

        const data = {
            id: index,
            place: `${index + 1}:a`,
            result: race.result,
            bets,
            results,
            boxedBets,
        };

        const row = createRow(data, columns, receipt);
        return checkRow(row, race, receipt);
    });
}

export function boostRows(receipt: any, columns: any) {
    const {bet} = receipt;
    const row = createRow({}, columns, receipt);

    row.resultExists =
        Bet.isSneakingOrViewingResults(bet, receipt) &&
        bet.boostInfo &&
        bet.boostInfo.boostWinningPlan &&
        bet.boostInfo.boostWinningPlan.code;
    row.sneaked =
        receipt.boostSneaked || receipt.selectedTab === "result" || receipt.showWinners;
    row.hideResult =
        receipt.selectedTab === "receipt" &&
        (!receipt.showSneak || !receipt.boostSneaked);

    return [setPhase(row)];
}
