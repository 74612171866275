import {difference} from "lodash/fp";
import {isPositionSneaked} from "./is-position-sneaked";

export const collectWinStats =
    (sneakedPositions?: Array<any>) => (prev: any, groupWinners: any, index: number) => {
        if (!prev.win) return prev;

        if (!isPositionSneaked(sneakedPositions, index))
            return {...prev, numCorrect: prev.numCorrect + 1, isCertain: false};

        const uniqueGroupWinners = difference(groupWinners, prev.winners);
        const hasWinner = uniqueGroupWinners.length !== 0;
        if (!hasWinner) return {...prev, win: false};

        return {
            ...prev,
            // @ts-expect-error
            winners: [...prev.winners, uniqueGroupWinners[0]],
            numCorrect: prev.numCorrect + 1,
        };
    };
