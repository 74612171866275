import {fetchSelectors, createInitialState} from "@atg-shared/fetch-redux";
import {type FetchState} from "@atg-shared/fetch-types";
import type {GameSchedule, GameScheduleUpcoming} from "./product";
import type {GameResults, GameScheduleState} from "./productReducer";
import type {State} from "./index";

export const getGameSchedules = (state: State): GameScheduleState =>
    state.product.gameSchedules;

export const getGameScheduleByGameType = (
    state: State,
    gameType: string,
): FetchState<GameSchedule> =>
    getGameSchedules(state)[gameType] || createInitialState({});

export const isScheduleLoading = (state: State, gameType: string): boolean =>
    fetchSelectors.isLoading(getGameScheduleByGameType(state, gameType));

export const isScheduleLoaded = (state: State, gameType: string): boolean =>
    fetchSelectors.isLoaded(getGameScheduleByGameType(state, gameType));

export const hasScheduleError = (state: State, gameType: string): boolean =>
    fetchSelectors.hasError(getGameScheduleByGameType(state, gameType));

export const isScheduleOk = (state: State, gameType: string): boolean =>
    isScheduleLoaded(state, gameType) && !hasScheduleError(state, gameType);

export const getUpcomingGamesByGameType = (
    state: State,
    gameType: string,
): Array<GameScheduleUpcoming> =>
    getGameScheduleByGameType(state, gameType).upcoming || [];

export const getClosestUpcomingGameByGameType = (
    state: State,
    gameType: string,
): GameScheduleUpcoming => getUpcomingGamesByGameType(state, gameType)[0];

export const getGameResultsByGameType = (state: State, gameType: string): GameResults =>
    state.product.gameResults[gameType] || {data: null, error: null};
