import type {GameType, GameTypeWithoutLegacy} from "@atg-horse-shared/game-types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {CalendarAPITypes, GameAPITypes} from "@atg-horse-shared/racing-info-api";
import type {
    BetMethod,
    HarryFlavor,
    PoolParticipation,
} from "@atg-horse-shared/bet-types";

export type CouponGame<G extends GameTypeWithoutLegacy> = {
    date: string;
    id: string;
    startTime: string;
    tracks: Array<{
        id: number;
        name: string;
    }>;
    type: G;
};

export enum CouponTypes {
    PRIVATE = "PRIVATE", // Includes Harry
    PRIVATE_TEAM = "PRIVATE_TEAM",
    PRIVATE_TEAM_REDUCED = "PRIVATE_TEAM_REDUCED",
    SHOP_SHARED = "SHOP_SHARED",
    REDUCED = "REDUCED",
    SHARED = "SHARED",
}

export enum HarryCouponTypes {
    SUBSCRIPTION = "subscription",
    BAG = "bag",
}

export type CouponType = keyof typeof CouponTypes;

export type BaseCoupon<G extends GameTypeWithoutLegacy> = {
    /**
     * added by the batch-bet-service to coupons fetched from the coupon service after a reduced bet has been placed
     *
     * ref: https://confluence-atg.riada.cloud/display/HDDev/Horse+Batch+Betting
     */
    batchBetId?: number;
    harry?: boolean;
    betCost: number;
    betMethod?: BetMethod;
    game: CouponGame<G>;
    id: string;
    cid: string;
    modified: string;
    readOnly?: boolean;
    stake: number;
    systems: number;
    teamId?: string;
    type: CouponTypes | HarryCouponTypes;
    shareId?: string;
    addOns: Array<string>;
    name?: string;
    localOnly?: boolean;
    cost?: number;
    parentCid?: string; // coupons that are cloned for the bet placement get a parentCid

    // these could have been on HarryCoupon only, but then Flow would complain in a few places.
    harryFlavour?: HarryFlavor;
    harryBetLimit?: number;
    harrySubscriptionOrder?: {amount: number};
    amount?: number;
    rules?: string; // optional country rules
};

/**
 * How it's stored in redux
 */
export type CouponStartStored = string;

export type DivisionGameCouponRace = {
    bets: Array<CouponStartStored>;
    harryOpen: boolean;
    id: string;
    number: number;
    reserves: Array<CouponStartStored>;
    status: CalendarAPITypes.RaceStatus;
};

export type DivisionGameCoupon<G extends GameTypeWithoutLegacy> = BaseCoupon<G> & {
    races: Array<DivisionGameCouponRace>;
    rows: number;
    cost: number;
};

type V75Coupon = DivisionGameCoupon<"V75">;
type V86Coupon = DivisionGameCoupon<"V86">;
type GS75Coupon = DivisionGameCoupon<"GS75">;
type V65Coupon = DivisionGameCoupon<"V65">;
export type V64Coupon = DivisionGameCoupon<"V64">;
type V5Coupon = DivisionGameCoupon<"V5">;
type V4Coupon = DivisionGameCoupon<"V4">;
type V3Coupon = DivisionGameCoupon<"V3">;

export type VXYCoupon =
    | V75Coupon
    | V86Coupon
    | GS75Coupon
    | V65Coupon
    | V64Coupon
    | V5Coupon
    | V4Coupon
    | V3Coupon;

export type CombinationGameCouponRace = {
    bets: Array<CouponStartStored>;
    harryOpen: boolean;
    id: string;
    number: number;
};

type V3LegacyCoupon = BaseCoupon<"V3"> & {
    races: Array<CombinationGameCouponRace>;
    rows: number;
};

export type DDCoupon = BaseCoupon<"dd"> & {
    races: Array<CombinationGameCouponRace>;
    rows: number;
};

export type LDCoupon = BaseCoupon<"ld"> & {
    races: Array<CombinationGameCouponRace>;
    rows: number;
};

type TvillingCouponRace = {
    baseBets: Array<CouponStartStored>;
    bets: Array<CouponStartStored>;
    harryOpen: boolean;
    id: string;
    number: number;
};

export type TvillingCoupon = BaseCoupon<"tvilling"> & {
    races: Array<TvillingCouponRace>;
    combinations: number;
};

export type Top7Coupon = BaseCoupon<"top7"> & {
    systemId: string;
    userDefinedSystem: boolean;
    combinations: number;
    banker: boolean;
    races: Array<DivisionGameCouponRace>;
};

type TrioCouponRace = {
    firstPlaceBets: Array<CouponStartStored>;
    firstPlaceHarryOpen: boolean;
    id: string;
    number: number;
    secondPlaceBets: Array<CouponStartStored>;
    secondPlaceHarryOpen: boolean;
    thirdPlaceBets: Array<CouponStartStored>;
    thirdPlaceHarryOpen: boolean;
};
export type TrioCoupon = BaseCoupon<"trio"> & {
    combinations: number;
    flexBetCost: number;
    flexValue: number;
    races: Array<TrioCouponRace>;
};

type VinnarePlaceCouponRace = {
    number: number;
    id: string;
    bets: Array<CouponStartStored>;
};

export type VinnareCoupon = BaseCoupon<"vinnare"> & {
    races: Array<VinnarePlaceCouponRace>;
};

type KombCouponRace = {
    firstPlaceBets: Array<CouponStartStored>;
    firstPlaceHarryOpen: boolean;
    id: string;
    number: number;
    secondPlaceBets: Array<CouponStartStored>;
    secondPlaceHarryOpen: boolean;
};

export type KombCoupon = BaseCoupon<"komb"> & {
    races: Array<KombCouponRace>;
    combinations: number;
};

export type PlatsCoupon = BaseCoupon<"plats"> & {
    races: Array<VinnarePlaceCouponRace>;
};

export type VPCoupon = BaseCoupon<"vp"> & {
    races: Array<VinnarePlaceCouponRace>;
};

export type RaketCoupon = BaseCoupon<"raket"> & {
    system: "string";
    races: Array<{
        bets: Array<CouponStartStored>;
        betType?: "plats" | "vinnare";
        id: string;
        number: number;
    }>;
};

type HarrySupportingCoupon =
    | V75Coupon
    | V86Coupon
    | GS75Coupon
    | V65Coupon
    | V64Coupon
    | V5Coupon
    | V4Coupon
    | V3Coupon
    | DDCoupon
    | LDCoupon
    | TrioCoupon
    | KombCoupon
    | TvillingCoupon;

type HarrySpecifics = {
    betMethod: BetMethod.Harry;
    harryBetLimit: number;
    stake: number;
    addOns: Array<string>;
    harryFlavour?: HarryFlavor;
    harrySubscriptionOrder?: {amount: number};
    amount?: number;
};

export type DeliveryOption = "SMS" | "EMAIL" | "NONE";

export type HarryCoupon = HarrySupportingCoupon & HarrySpecifics;
export type VXYHarryCoupon = VXYCoupon & HarrySpecifics;
export type TvillingHarryCoupon = TvillingCoupon & HarrySpecifics;
export type KombHarryCoupon = TrioCoupon & HarrySpecifics;
export type TrioHarryCoupon = KombCoupon & HarrySpecifics;
export type Top7HarryCoupon = Top7Coupon & HarrySpecifics;

export type Coupon =
    | V75Coupon
    | V86Coupon
    | GS75Coupon
    | V65Coupon
    | V64Coupon
    | V5Coupon
    | V4Coupon
    | V3LegacyCoupon
    | V3Coupon
    | DDCoupon
    | LDCoupon
    | Top7Coupon
    | TrioCoupon
    | TvillingCoupon
    | KombCoupon
    | VinnareCoupon
    | PlatsCoupon
    | VPCoupon
    | RaketCoupon
    | HarryCoupon;

export type ReducedCoupon =
    | V75Coupon
    | V86Coupon
    | GS75Coupon
    | V65Coupon
    | V64Coupon
    | V5Coupon
    | V4Coupon
    | V3LegacyCoupon
    | V3Coupon
    | DDCoupon
    | LDCoupon;

export type TransformedCoupon = Coupon & {
    betMethod: string;
    couponId: string;
    game: {
        id: string;
        type: GameType;
    };
    cost: number;
    costWithAddons: number;
};

export type BetWithGame = TransformedCoupon & {
    game: {
        id: string;
        type: GameType;
        tracks: Array<GameAPITypes.GameInfoTrack>;
        startTime: string;
    };
};

export type CouponLegs = Record<string, {marks: Array<number>; reserves: Array<number>}>;
export type HarryLegs = Record<string, {marks: Array<number>; open: boolean}>;

export type HarryVXYOptions = {
    flavor: HarryFlavor;
    legs: HarryLegs;
};

export type HarryTvillingOptions = {
    marks?: number[];
    baseMarks?: number[];
};

export type HarryTop7Options = {
    marks?: number[];
    boxes: number[];
};

export type HarryTrioOrKombOptions = CouponOrderedCombinationSelections;

export type CouponVXYSelections = {
    poolParticipation: PoolParticipation;
    legs: CouponLegs;
};

export type CouponVinnarePlatsSelections = {
    marks: number[];
};

export type CouponTvillingSelections = {
    marks: number[];
    baseMarks?: number[];
};

export type CouponTop7Selections = {
    marks: number[];
    reserves?: number[];
    boxes: number[];
};

export type CouponKombSelections = {
    positions: {
        [index: string]: {
            marks: number[];
        };
    };
};

export type CouponTrioSelections = {
    positions: {
        [index: string]: {
            marks: number[];
        };
    };
};

export type CouponOrderedCombinationSelections = {
    positions: Record<string, {marks: number[]; open?: true}>;
};
