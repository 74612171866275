import {fetchAuthorized} from "@atg-shared/auth";
import {now} from "lodash";
import {
    SHOP_SHARE_SERVICE_URL,
    SHOP_SHARE_SERVICE_URL_TOKENIZED,
} from "@atg-shared/service-url";
import {FETCH, call} from "@atg-shared/fetch-redux";
import type {ShopShare} from "@atg-shop-shared/types/shopShare";
// Types need to be extracted from atg-horse-bet
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    RESET_SHOP_SHARE_STATUS,
    UPDATE_SHOP_SHARE_REQUEST,
    UPDATE_SHOP_SHARE_RESPONSE,
    UPDATE_SHOP_SHARE_RESET,
    DELETE_SHOP_SHARE_REQUEST,
    DELETE_SHOP_SHARE_RESPONSE,
    DELETE_SHOP_SHARE_RESET,
    REQUEST_SHOP_SHARE,
    CREATE_SHARE_REQUEST,
    CREATE_SHARE_RESET,
    CREATE_SHARE_RESPONSE,
    EDIT_SHARE_NAME_REQUEST,
    EDIT_SHARE_NAME_RESET,
    EDIT_SHARE_NAME_RESPONSE,
    PURCHASE_SHOP_SHARE,
    RECEIVE_SHOP_SHARE,
    SET_COUPON_ID,
    SET_GAME_INITIATOR_REQUEST,
    SET_GAME_INITIATOR_RESET,
    SET_GAME_INITIATOR_RESPONSE,
    SET_RECEIPT,
    SET_RECEIPT_RESULT,
    SHOP_SHARE_COUPON_DETAILS_REQUEST,
    SHOP_SHARE_COUPON_DETAILS_RESET,
    SHOP_SHARE_COUPON_DETAILS_RESPONSE,
    SHOP_SHARE_COUPON_VISIBILITY_REQUEST,
    SHOP_SHARE_COUPON_VISIBILITY_RESET,
    SHOP_SHARE_COUPON_VISIBILITY_RESPONSE,
    USE_MAX_STAKE_PER_SYSTEM_DATA_REQUEST,
    USE_MAX_STAKE_PER_SYSTEM_DATA_RESPONSE,
    USE_MAX_STAKE_PER_SYSTEM_DATA_RESET,
} from "@atg-shop-shared/shops-domain-types/shares.action.types";
// Types need to be extracted from atg-horse-bet
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {
    ShopShareResetAction,
    ResetUpdateShareAction,
    ShopShareDeleteAction,
    DeleteUpdateShareAction,
    ShopShareFetchAction,
    CreateShareAction,
    CreateSharePayload,
    EditShareNameAction,
    EditShareNamePayload,
    ResetEditGameAction,
    SetCouponIdAction,
    SetGameInitiatorAction,
    SetGameInitiatorPayload,
    SetReceiptAction,
    SetReceiptResultAction,
    ShopShareCouponDetailsFetchAction,
    ShopShareCouponDetailsResetAction,
    ShopShareCouponVisibilityAction,
    ShopShareCouponVisibilityResetAction,
    ShopSharePurchaseAction,
    ResetCreateShareAction,
    ResetSetGameInitiatorAction,
    SetUseMaxStakePerSystemDataAction,
    MaxStakeDataPayload,
    ResetUseMaxStakePerSystemDataAction,
} from "@atg-shop-shared/shops-domain-types/shares.action.types";

export const shopShareReset = (): ShopShareResetAction => ({
    type: RESET_SHOP_SHARE_STATUS,
});

export const updateShopShareById = (shareId: string, operation: string) => ({
    type: FETCH,
    payload: {
        requestAction: UPDATE_SHOP_SHARE_REQUEST,
        receiveAction: UPDATE_SHOP_SHARE_RESPONSE,
        callApi: call(
            fetchAuthorized,
            `${SHOP_SHARE_SERVICE_URL_TOKENIZED}/protected/shares/${shareId}?operation=${operation}`,
            {
                method: "PUT",
            },
        ),
    },
});

export const resetUpdateShare = (): ResetUpdateShareAction => ({
    type: UPDATE_SHOP_SHARE_RESET,
});

export const deleteShopShareById = (shareId: string): ShopShareDeleteAction => ({
    type: FETCH,
    payload: {
        requestAction: DELETE_SHOP_SHARE_REQUEST,
        receiveAction: DELETE_SHOP_SHARE_RESPONSE,
        callApi: call(
            fetchAuthorized,
            `${SHOP_SHARE_SERVICE_URL_TOKENIZED}/protected/shares/${shareId}`,
            {
                method: "DELETE",
            },
        ),
    },
});

export const resetDeleteShare = (): DeleteUpdateShareAction => ({
    type: DELETE_SHOP_SHARE_RESET,
});

export const fetchShopShareById = (shopShareId: string): ShopShareFetchAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_SHOP_SHARE,
        receiveAction: RECEIVE_SHOP_SHARE,
        callApi: call(
            fetchAuthorized,
            `${SHOP_SHARE_SERVICE_URL_TOKENIZED}/shares/${shopShareId}`,
            undefined,
            {
                memberFlowEnabled: false,
                fallbackUrl: `${SHOP_SHARE_SERVICE_URL}/shares/${shopShareId}`,
            },
        ),
    },
});

export const purchaseShopShare = (shopShare: ShopShare): ShopSharePurchaseAction => ({
    type: PURCHASE_SHOP_SHARE,
    payload: shopShare,
});

export const fetchShopShareCouponDetails = (
    couponId: string,
): ShopShareCouponDetailsFetchAction => ({
    type: FETCH,
    payload: {
        requestAction: SHOP_SHARE_COUPON_DETAILS_REQUEST,
        receiveAction: SHOP_SHARE_COUPON_DETAILS_RESPONSE,
        context: {couponId},
        callApi: call(
            fetchAuthorized,
            `${SHOP_SHARE_SERVICE_URL_TOKENIZED}/protected/shares?couponId=${couponId}`,
        ),
    },
});

export const resetShopShareCouponDetails = (): ShopShareCouponDetailsResetAction => ({
    type: SHOP_SHARE_COUPON_DETAILS_RESET,
});

export const createShare = (
    shopId: number,
    payload: CreateSharePayload,
): CreateShareAction => ({
    type: FETCH,
    payload: {
        requestAction: CREATE_SHARE_REQUEST,
        receiveAction: CREATE_SHARE_RESPONSE,
        callApi: call(
            fetchAuthorized,
            `${SHOP_SHARE_SERVICE_URL_TOKENIZED}/protected/shops/${shopId}/shares`,
            {
                method: "POST",
                body: JSON.stringify(payload),
            },
        ),
    },
});

export const resetCreateShare = (): ResetCreateShareAction => ({
    type: CREATE_SHARE_RESET,
});

export const setGameInitiator = (
    shareId: string,
    payload: SetGameInitiatorPayload,
): SetGameInitiatorAction => ({
    type: FETCH,
    payload: {
        requestAction: SET_GAME_INITIATOR_REQUEST,
        receiveAction: SET_GAME_INITIATOR_RESPONSE,
        callApi: call(
            fetchAuthorized,
            `${SHOP_SHARE_SERVICE_URL_TOKENIZED}/protected/shares/${shareId}/gameinitiator`,
            {
                method: "PUT",
                body: JSON.stringify(payload),
            },
        ),
    },
});

export const setCouponId = (couponId: string): SetCouponIdAction => ({
    type: SET_COUPON_ID,
    payload: {couponId},
});

export const resetSetGameInitiator = (): ResetSetGameInitiatorAction => ({
    type: SET_GAME_INITIATOR_RESET,
});

export const setShopShareCouponVisibility = (
    shareId: string,
    couponId: string,
    showCoupon: boolean,
): ShopShareCouponVisibilityAction => ({
    type: FETCH,
    payload: {
        requestAction: SHOP_SHARE_COUPON_VISIBILITY_REQUEST,
        receiveAction: SHOP_SHARE_COUPON_VISIBILITY_RESPONSE,
        context: {isCouponPublic: showCoupon, couponId},
        callApi: call(
            fetchAuthorized,
            `${SHOP_SHARE_SERVICE_URL_TOKENIZED}/protected/shares/${shareId}/coupon`,
            {
                method: "PATCH",
                body: JSON.stringify({showCoupon}),
            },
        ),
    },
});

export const editShareName = (payload: EditShareNamePayload): EditShareNameAction => ({
    type: FETCH,
    payload: {
        requestAction: EDIT_SHARE_NAME_REQUEST,
        receiveAction: EDIT_SHARE_NAME_RESPONSE,
        callApi: call(
            fetchAuthorized,
            `${SHOP_SHARE_SERVICE_URL_TOKENIZED}/protected/shares/${payload.shareId}/rename`,
            {
                method: "PATCH",
                body: JSON.stringify(payload),
            },
        ),
    },
});

export const resetSetEditName = (): ResetEditGameAction => ({
    type: EDIT_SHARE_NAME_RESET,
});

export const resetShopShareCouponVisibility =
    (): ShopShareCouponVisibilityResetAction => ({
        type: SHOP_SHARE_COUPON_VISIBILITY_RESET,
    });

export const setReceiptResult = (message: string): SetReceiptResultAction => ({
    type: SET_RECEIPT_RESULT,
    payload: {
        message,
    },
});

export const setReceipt = (message: string): SetReceiptAction => ({
    type: SET_RECEIPT,
    payload: {
        message,
    },
});

export const resetUseMaxStakePerSystemDataState =
    (): ResetUseMaxStakePerSystemDataAction => ({
        type: USE_MAX_STAKE_PER_SYSTEM_DATA_RESET,
    });

export const fetchMaxStakePerSystemData = (
    payload: MaxStakeDataPayload,
): SetUseMaxStakePerSystemDataAction => ({
    type: FETCH,
    payload: {
        requestAction: USE_MAX_STAKE_PER_SYSTEM_DATA_REQUEST,
        receiveAction: USE_MAX_STAKE_PER_SYSTEM_DATA_RESPONSE,
        callApi: call(
            fetchAuthorized,
            `${SHOP_SHARE_SERVICE_URL_TOKENIZED}/protected/shares/${payload.shareId}/bet-calc-max`,
            {
                method: "POST",
                headers: {
                    token: `${now()}`,
                },
                body: JSON.stringify({cost: payload.cost}),
            },
        ),
    },
});
