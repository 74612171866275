import type {
    Game,
    GameRace,
    BasePool,
} from "@atg-horse-shared/racing-info-api/game/types";
import type {ReducedCoupon} from "@atg-horse-shared/coupon-types";
import type {AtgRequestError} from "@atg-shared/fetch-types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {AuthHelperActions} from "@atg-horse/auth-helpers";
import type {
    MinMax,
    LetterRank,
    ReductionMetadata,
    ReducedCouponSettings,
    ReducedBatchBetResponse,
    PlaceReducedBetResponse,
    ReducedBetGradingApiResponse,
    SortingType,
} from "./reducedBet";

export const REDUCED_BETS_SET_SELECTED_HORSES = "reducedBets/setSelectedHorses" as const;
export const REDUCED_BETS_INCREASE_LETTER = "reducedBets/increaseLetter" as const;
export const REDUCED_BETS_SET_POINTS = "reducedBets/setPoints" as const;
export const REDUCED_BETS_SET_LETTER = "reducedBets/setLetter" as const;
export const REDUCED_BETS_SET_BASE_SELECTION = "reducedBets/setBaseSelection" as const;
export const REDUCED_BETS_MOVE = "reducedBets/move" as const;
export const REDUCED_BETS_CHANGE_SORTING = "reducedBets/changeSorting" as const;
export const CREATE_REDUCED_SET = "reducedBets/createReducedSet" as const;
export const REMOVE_REDUCED_SET = "reducedBets/removeReducedSet" as const;
export const TOGGLE_SET_ENABLED = "reducedBets/toggleSetEnabled" as const;
export const REDUCED_BETS_SET_RESTRICTION = "reducedBets/setRestriction" as const;
export const SET_ACTIVE_REDUCED_SET = "reducedBets/setActiveReducedSet" as const;

/**
 * Action that is called when polling reduced bets jobs
 * To give visual feedback to user
 */
export const POLLING_REDUCED_BETS_JOB = "reducedBets/pollingReducedBetsJob" as const;

export const REDUCED_BET_FINISHED = "reducedBets/reducedBetFinished" as const;

/**
 * Action for initiating the betting process
 */
export const CREATE_REDUCED_BET = "reducedBets/createReducedBet" as const;

/**
 * Action for initiating the process of fetching
 */
export const REDUCTION_STARTED = "reducedBets/reductionStarted" as const;
/**
 * Action for initiating the fetching of reduction info.
 */
export const GET_REDUCTION = "reducedBets/getReduction" as const;

/**
 * Action for clearing the error of the previous reduction info request.
 */
export const RESET_REDUCTION_INFO_ERROR = "reducedBets/resetReductionInfoError" as const;

/**
 * Action for fetching reduction info.
 * Eg. rows before and after reduction and number of coupons after reduction.
 */
export const REQUEST_REDUCTION_INFO = "reducedBets/requestReductionInfo" as const;

/**
 * Action for setting reduction info.
 * Eg. rows before and after reduction and number of coupons after reduction.
 */
export const RECEIVE_REDUCTION_INFO = "reducedBets/receiveReductionInfo" as const;

/**
 * Action for setting settings of a coupon.
 */
export const REDUCED_COUPON_SETTINGS_SAVED = "reducedBets/couponSettingsSaved" as const;

/**
 * Action for getting reduced bet grading from api.
 */
export const REQUEST_GRADING = "reducedBets/getGrading" as const;

/**
 * Action for setting reduced bet grading in store.
 */
export const RECEIVE_GRADING = "reducedBets/setGrading" as const;

/**
 * Actions for change visible result for grading
 */
export const GRADING_CHANGE_VISIBLE_RESULT =
    "reducedBets/gradingChangeVisibleResult" as const;

/**
 * Action to trigger reduction calculation before placing a reduced Tillsammans bet if necessary
 */
export const PLACE_TILLSAMMANS_BET = "reducedBets/placeTillsammansBet" as const;
/**
 * Action before redirecting reduced tillsammans bet to restore loading state
 */
export const REDIRECTING_TILLSAMMANS_BET =
    "reducedBets/redirectingTillsammansBet" as const;

/**
 * Action for starting a metadata calculation for reduced bets
 */
export const START_REDUCTION_CALCULATION =
    "reducedBets/startReductionCalculation" as const;

export type SetSelectedHorsesPayload = {
    raceNumber: number;
    numberOfSelectedHorses: number;
    cid: string;
};

type IncreaseLetterPayload = {
    raceNumber: number;
    startNumber: number;
    cid?: string | null;
};

type SetPointsPayload = {
    raceNumber: number;
    startNumber: number;
    points: number;
    cid?: string | null;
};

type SetLetterPayload = {
    raceNumber: number;
    startNumber: number;
    letter: LetterRank;
    cid?: string | null;
};

type SetBaseSelectionPayload = {
    raceNumber: number;
    startNumber: number;
    cid?: string | null;
};

type SetPointsRestrictionPayload = {
    restrictions: MinMax;
    method: "points";
    cid?: string | null;
};

type SetLettersRestrictionPayload = {
    restrictions: Array<{
        enabled: boolean;
        letterRestrictions: {
            [letter in LetterRank]: MinMax;
        };
    }>;
    method: "letters";
    cid?: string | null;
};

type SetExpectedOutcomePayload = {
    restrictions: MinMax;
    method: "expectedOutcome";
    cid?: string | null;
};

type SetBaseRestrictionPayload = {
    restrictions: MinMax;
    enabled: boolean;
    method: "baseSelections";
    cid?: string | null;
    index: number;
};

type SortablePayload = {
    newIndex: number;
    oldIndex: number;
    cid: string;
    raceNumber: number;
};

export type SortingPayload = {
    sortValue: SortingType;
    cid: string;
    game: Game;
    numberOfSelectedHorses: Array<number>;
};

export type ToggleSetEnabledPayload = {
    reductionMethod: "expectedOutcome" | "baseSelections" | "letters" | "points";
    set?: number | null;
    cid?: string;
};

export type CreateReducedSetPayload = {
    races: Array<GameRace>;
    method: string;
    cid?: string;
};

export type RemoveReducedSetPayload = {
    cid?: string;
    index: number;
    reductionMethod: "letters" | "baseSelections";
};

export type SetActiveReducedSetPayload = {
    cid?: string;
    index: number;
};

export type CouponSettingsPayload = ReducedCouponSettings & {
    cid: string;
};

export type SetRestrictionPayload =
    | SetPointsRestrictionPayload
    | SetLettersRestrictionPayload
    | SetExpectedOutcomePayload
    | SetBaseRestrictionPayload;

export type SetSelectedHorsesAction = {
    type: typeof REDUCED_BETS_SET_SELECTED_HORSES;
    payload: SetSelectedHorsesPayload;
};

type IncreaseLetterAction = {
    type: typeof REDUCED_BETS_INCREASE_LETTER;
    payload: IncreaseLetterPayload;
};

type SetPointsAction = {
    type: typeof REDUCED_BETS_SET_POINTS;
    payload: SetPointsPayload;
};

type SetLetterAction = {
    type: typeof REDUCED_BETS_SET_LETTER;
    payload: SetLetterPayload;
};

type SetBaseSelectionAction = {
    type: typeof REDUCED_BETS_SET_BASE_SELECTION;
    payload: SetBaseSelectionPayload;
};
type CreateReducedBetPayload = {
    cid: string;
};

type PollingReducedBetJobPayload = {
    status?: string;
    response?: ReducedBatchBetResponse;
    errorMessage?: string;
};

type ReducedBetFinishedPayload = {
    response?: PlaceReducedBetResponse;
    errorMessage?: string;
};

export type ChangeSortingAction = {
    type: typeof REDUCED_BETS_CHANGE_SORTING;
    payload: SortingPayload;
};

export type SetRestrictionAction = {
    type: typeof REDUCED_BETS_SET_RESTRICTION;
    payload: SetRestrictionPayload;
};

export type ToggleSetEnabledAction = {
    type: typeof TOGGLE_SET_ENABLED;
    payload: ToggleSetEnabledPayload;
};
export type CreateReducedSetAction = {
    type: typeof CREATE_REDUCED_SET;
    payload: CreateReducedSetPayload;
};
export type RemoveReducedSetAction = {
    type: typeof REMOVE_REDUCED_SET;
    payload: RemoveReducedSetPayload;
};

export type SetActiveReducedSetAction = {
    type: typeof SET_ACTIVE_REDUCED_SET;
    payload: SetActiveReducedSetPayload;
};
export type MoveAction = {
    type: typeof REDUCED_BETS_MOVE;
    payload: SortablePayload;
};

export type GetReductionAction = {
    type: typeof GET_REDUCTION;
    payload: {cid?: string};
};

export type ReductionStartedAction = {
    type: typeof REDUCTION_STARTED;
    payload: {loading: boolean};
};

export type PollingReducedBetJobAction = {
    type: typeof POLLING_REDUCED_BETS_JOB;
    payload: PollingReducedBetJobPayload;
};

export type ReducedBetFinishedAction = {
    type: typeof REDUCED_BET_FINISHED;
    payload: ReducedBetFinishedPayload;
};

export type CreateReducedBetAction = {
    type: typeof CREATE_REDUCED_BET;
    payload: CreateReducedBetPayload;
};

export type ResetReductionInfoErrorAction = {
    type: typeof RESET_REDUCTION_INFO_ERROR;
};

export type RequestReductionInfo = {
    type: typeof REQUEST_REDUCTION_INFO;
    payload: {
        cid: string;
        coupon: ReducedCoupon;
        pool: BasePool;
    };
};

export type ReceiveReductionInfo = {
    type: typeof RECEIVE_REDUCTION_INFO;
    payload: {
        cid: string;
        coupon: ReducedCoupon;
        reductionMetadata?: ReductionMetadata;
        error?: AtgRequestError;
    };
};

export type CouponSettingsSavedAction = {
    type: typeof REDUCED_COUPON_SETTINGS_SAVED;
    payload: CouponSettingsPayload;
};

export type GradingChangeVisibleResultAction = {
    type: typeof GRADING_CHANGE_VISIBLE_RESULT;
    payload: {
        cid: string;
        raceIndex?: number;
    };
};

export type RequestGradingAction = {
    type: typeof REQUEST_GRADING;
    payload: {
        cid: string;
        isReload: boolean;
    };
};

export type ReceiveGradingAction = {
    type: typeof RECEIVE_GRADING;
    payload: {
        cid: string;
        data?: ReducedBetGradingApiResponse;
    };
    error?: true;
};

export type PlaceTillsammansBetAction = {
    type: typeof PLACE_TILLSAMMANS_BET;
};

export type RedirectingTillsammansBetAction = {
    type: typeof REDIRECTING_TILLSAMMANS_BET;
};

export type StartReductionCalculationAction = {
    type: typeof START_REDUCTION_CALCULATION;
    payload: {
        cid: string;
        withoutDelay?: boolean;
    };
};

export type ActionTypes =
    | ChangeSortingAction
    | ToggleSetEnabledAction
    | SetBaseSelectionAction
    | SetPointsAction
    | SetLetterAction
    | IncreaseLetterAction
    | SetRestrictionAction
    | SetSelectedHorsesAction
    | MoveAction
    | RemoveReducedSetAction
    | CreateReducedSetAction
    | SetActiveReducedSetAction
    | ResetReductionInfoErrorAction
    | RequestReductionInfo
    | ReceiveReductionInfo
    | CouponSettingsSavedAction
    | CreateReducedBetAction
    | ReductionStartedAction
    | PlaceTillsammansBetAction
    | RedirectingTillsammansBetAction
    | PollingReducedBetJobAction
    | ReducedBetFinishedAction
    | RequestGradingAction
    | ReceiveGradingAction
    | GradingChangeVisibleResultAction
    | StartReductionCalculationAction;

export const setSelectedHorses = ({
    raceNumber,
    numberOfSelectedHorses,
    cid,
}: SetSelectedHorsesPayload): SetSelectedHorsesAction => ({
    type: REDUCED_BETS_SET_SELECTED_HORSES,
    payload: {
        raceNumber,
        numberOfSelectedHorses,
        cid,
    },
});

export const increaseLetter = (payload: IncreaseLetterPayload): IncreaseLetterAction => ({
    type: REDUCED_BETS_INCREASE_LETTER,
    payload,
});

export const setPoints = (payload: SetPointsPayload): SetPointsAction => ({
    type: REDUCED_BETS_SET_POINTS,
    payload,
});

export const setLetter = (payload: SetLetterPayload): SetLetterAction => ({
    type: REDUCED_BETS_SET_LETTER,
    payload,
});

export const setBaseSelection = (
    payload: SetBaseSelectionPayload,
): SetBaseSelectionAction => ({
    type: REDUCED_BETS_SET_BASE_SELECTION,
    payload,
});

export const move = (payload: SortablePayload): MoveAction => ({
    type: REDUCED_BETS_MOVE,
    payload,
});

export const changeSorting = (sorting: SortingPayload): ChangeSortingAction => ({
    type: REDUCED_BETS_CHANGE_SORTING,
    payload: sorting,
});

export const removeReducedSet = (
    payload: RemoveReducedSetPayload,
): RemoveReducedSetAction => ({
    type: REMOVE_REDUCED_SET,
    payload,
});

export const setActiveReducedSet = (
    payload: SetActiveReducedSetPayload,
): SetActiveReducedSetAction => ({
    type: SET_ACTIVE_REDUCED_SET,
    payload,
});

export const getReduction = (cid?: string): GetReductionAction => ({
    type: GET_REDUCTION,
    payload: {cid},
});

export const reductionStarted = (loading: boolean): ReductionStartedAction => ({
    type: REDUCTION_STARTED,
    payload: {loading},
});

export const resetReductionInfoError = (): ResetReductionInfoErrorAction => ({
    type: RESET_REDUCTION_INFO_ERROR,
});

export const requestReductionInfo = (
    cid: string,
    coupon: ReducedCoupon,
    pool: BasePool,
): RequestReductionInfo => ({
    type: REQUEST_REDUCTION_INFO,
    payload: {cid, coupon, pool},
});

export const recieveReductionInfo = (
    cid: string,
    coupon: ReducedCoupon,
    reductionMetadata?: ReductionMetadata,
    error?: AtgRequestError,
): ReceiveReductionInfo => ({
    type: RECEIVE_REDUCTION_INFO,
    payload: {reductionMetadata, cid, coupon, error},
});

export const couponSettingsSaved = (
    payload: CouponSettingsPayload,
): CouponSettingsSavedAction => ({
    type: REDUCED_COUPON_SETTINGS_SAVED,
    payload,
});

export const setRestriction = AuthHelperActions.authCheckWrapper<SetRestrictionPayload>(
    (payload): SetRestrictionAction => ({
        type: REDUCED_BETS_SET_RESTRICTION,
        payload,
    }),
);

export const createReducedSet =
    AuthHelperActions.authCheckWrapper<CreateReducedSetPayload>(
        (payload): CreateReducedSetAction => ({
            type: CREATE_REDUCED_SET,
            payload,
        }),
    );

export const toggleSetEnabled =
    AuthHelperActions.authCheckWrapper<ToggleSetEnabledPayload>(
        (payload): ToggleSetEnabledAction => ({
            type: TOGGLE_SET_ENABLED,
            payload,
        }),
    );

export const createReducedBet =
    AuthHelperActions.authCheckWrapper<CreateReducedBetPayload>(
        (payload): CreateReducedBetAction => ({
            type: CREATE_REDUCED_BET,
            payload,
        }),
    );

export const pollingReducedBetJob = (
    payload: PollingReducedBetJobPayload,
): PollingReducedBetJobAction => ({
    type: POLLING_REDUCED_BETS_JOB,
    payload,
});

export const reducedBetFinished = (
    payload: ReducedBetFinishedPayload,
): ReducedBetFinishedAction => ({
    type: REDUCED_BET_FINISHED,
    payload,
});

export const getGrading = (cid: string, isReload = false): RequestGradingAction => ({
    type: REQUEST_GRADING,
    payload: {cid, isReload},
});

export const receiveGrading = (
    cid: string,
    data?: ReducedBetGradingApiResponse,
    error?: true,
): ReceiveGradingAction => ({
    type: RECEIVE_GRADING,
    payload: {cid, data},
    error,
});

export const gradingChangeVisibleResult = (
    cid: string,
    raceIndex?: number,
): GradingChangeVisibleResultAction => ({
    type: GRADING_CHANGE_VISIBLE_RESULT,
    payload: {
        cid,
        raceIndex,
    },
});

export const placeTillsammansBet = (): PlaceTillsammansBetAction => ({
    type: PLACE_TILLSAMMANS_BET,
});

export const redirectingTillsammansBet = (): RedirectingTillsammansBetAction => ({
    type: REDIRECTING_TILLSAMMANS_BET,
});

export const startReductionCalculation = (
    cid: string,
    withoutDelay?: boolean,
): StartReductionCalculationAction => ({
    type: START_REDUCTION_CALCULATION,
    payload: {
        cid,
        withoutDelay,
    },
});
