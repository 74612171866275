import {flow, zip, includes, omit, take, filter} from "lodash/fp";
import type {Game, GameRace} from "@atg-horse-shared/racing-info-api/game/types";
import * as CouponUtils from "../../domain/coupon";

function getRaceById(game: Game, raceId: string): GameRace | null | undefined {
    return game.races.find((race) => race.id === raceId);
}

// This file should be simplified, and the transformations should be run right before sending data
// to the backend, instead of in the middle of the bet flow.
// ref: [HRS1-7724]

const transformRaces = (raceTransform: any) => (coupon: any) => (bet: any) => ({
    ...bet,
    // @ts-expect-error not typed
    races: zip(coupon.races, bet.races).map(([couponRace, betRace]) =>
        raceTransform(couponRace, betRace),
    ),
});

const pickStandardAttributes = (coupon: any) =>
    flow(
        (bet) => ({
            ...bet,

            betMethod: coupon.betMethod,

            // coupon (server) ID
            couponId: coupon.id,

            game: {
                id: coupon.game.id,
                type: coupon.game.type,
            },

            // total cost of whole system (excluding addons)
            cost: coupon.cost,

            // cost of the boost addon
            ...(includes("boost", coupon.addOns)
                ? {boostCost: CouponUtils.getBoostCost(coupon)}
                : {}),

            // total cost of bet – what is actually subtracted from user's balance

            costWithAddons: CouponUtils.getCostWithAddons(coupon),
        }),
        (bet) => {
            if (!coupon.deactivateVerificationCode) return bet;
            return {
                ...bet,
                deactivateVerificationCode: coupon.deactivateVerificationCode,
            };
        },
        (bet) => {
            if (!coupon.teamId) return bet;
            return {
                ...bet,
                teamId: coupon.teamId,
            };
        },
        transformRaces((couponRace: any, betRace: any) => ({
            ...betRace,
            id: couponRace.id,
        }))(coupon),
    );

export const createSingleRaceGameBet = (coupon: any) => {
    switch (coupon.betMethod) {
        case "harry":
            return flow(
                pickStandardAttributes(coupon),
                (bet) => ({
                    ...bet,
                    harryBetLimit: coupon.harryBetLimit,
                }),
                transformRaces((couponRace: any, betRace: any) => ({
                    ...betRace,
                    bets: couponRace.bets,
                    harryOpen: couponRace.harryOpen,
                }))(coupon),
            );
        default:
            return flow(
                pickStandardAttributes(coupon),
                (bet) => ({
                    ...bet,
                    stake: coupon.stake,
                }),
                transformRaces((couponRace: any, betRace: any) => ({
                    ...betRace,
                    bets: couponRace.bets,
                }))(coupon),
            );
    }
};

export const createTvillingBet = (coupon: any) => {
    switch (coupon.betMethod) {
        case "harry":
            return flow(
                pickStandardAttributes(coupon),
                (bet) => ({
                    ...bet,
                    harryBetLimit: coupon.harryBetLimit,
                }),
                transformRaces((couponRace: any, betRace: any) => ({
                    ...betRace,
                    bets: couponRace.bets,
                    baseBets: couponRace.baseBets,
                }))(coupon),
            );
        default:
            return flow(
                pickStandardAttributes(coupon),
                (bet) => ({
                    ...bet,
                    stake: coupon.stake,
                    combinations: coupon.combinations,
                }),
                transformRaces((couponRace: any, betRace: any) => ({
                    ...betRace,
                    bets: couponRace.bets,
                    baseBets: couponRace.baseBets,
                }))(coupon),
            );
    }
};

export const createTrioBet = (coupon: any) => {
    switch (coupon.betMethod) {
        case "harry":
            return flow(
                pickStandardAttributes(coupon),
                (bet) => ({
                    ...bet,
                    harryBetLimit: coupon.harryBetLimit,
                }),
                transformRaces((couponRace: any, betRace: any) => ({
                    ...betRace,
                    firstPlaceBets: couponRace.firstPlaceBets,
                    secondPlaceBets: couponRace.secondPlaceBets,
                    thirdPlaceBets: couponRace.thirdPlaceBets,
                    firstPlaceHarryOpen: couponRace.firstPlaceHarryOpen,
                    secondPlaceHarryOpen: couponRace.secondPlaceHarryOpen,
                    thirdPlaceHarryOpen: couponRace.thirdPlaceHarryOpen,
                }))(coupon),
            );

        case "flex":
            return flow(
                pickStandardAttributes(coupon),
                (bet) => ({
                    ...bet,
                    flexValue: coupon.flexValue,
                    flexBetCost: coupon.flexBetCost,
                    combinations: coupon.combinations,
                }),
                transformRaces((couponRace: any, betRace: any) => ({
                    ...betRace,
                    firstPlaceBets: couponRace.firstPlaceBets,
                    secondPlaceBets: couponRace.secondPlaceBets,
                    thirdPlaceBets: couponRace.thirdPlaceBets,
                }))(coupon),
            );
        default:
            return flow(
                pickStandardAttributes(coupon),
                (bet) => ({
                    ...bet,
                    stake: coupon.stake,
                    combinations: coupon.combinations,
                }),
                transformRaces((couponRace: any, betRace: any) => ({
                    ...betRace,
                    firstPlaceBets: couponRace.firstPlaceBets,
                    secondPlaceBets: couponRace.secondPlaceBets,
                    thirdPlaceBets: couponRace.thirdPlaceBets,
                }))(coupon),
            );
    }
};

export const createKombBet = (coupon: any) => {
    switch (coupon.betMethod) {
        case "harry":
            return flow(
                pickStandardAttributes(coupon),
                (bet) => ({
                    ...bet,
                    harryBetLimit: coupon.harryBetLimit,
                }),
                transformRaces((couponRace: any, betRace: any) => ({
                    ...betRace,
                    firstPlaceBets: couponRace.firstPlaceBets,
                    secondPlaceBets: couponRace.secondPlaceBets,
                    firstPlaceHarryOpen: couponRace.firstPlaceHarryOpen,
                    secondPlaceHarryOpen: couponRace.secondPlaceHarryOpen,
                }))(coupon),
            );
        default:
            return flow(
                pickStandardAttributes(coupon),
                (bet) => ({
                    ...bet,
                    stake: coupon.stake,
                    combinations: coupon.combinations,
                }),
                transformRaces((couponRace: any, betRace: any) => ({
                    ...betRace,
                    firstPlaceBets: couponRace.firstPlaceBets,
                    secondPlaceBets: couponRace.secondPlaceBets,
                }))(coupon),
            );
    }
};

const handleHarrySubscription = (coupon: any) => (bet: any) =>
    coupon.harrySubscriptionOrder
        ? {...bet, harrySubscriptionOrder: coupon.harrySubscriptionOrder}
        : bet;

export const createSimpleDivisionGameBet = (coupon: any) => {
    switch (coupon.betMethod) {
        case "harry":
            return flow(
                pickStandardAttributes(coupon),
                (bet) => ({
                    ...bet,
                    addOns: coupon.addOns,
                    harryBetLimit: coupon.harryBetLimit,
                    harryFlavour: coupon.harryFlavour,
                }),
                handleHarrySubscription(coupon),
                transformRaces((couponRace: any, betRace: any) => ({
                    ...betRace,
                    bets: couponRace.bets,
                    harryOpen: couponRace.harryOpen,
                }))(coupon),
            );
        default:
            return flow(
                pickStandardAttributes(coupon),
                (bet) => ({
                    ...bet,
                    stake: coupon.stake,
                    addOns: coupon.addOns,
                    rows: coupon.rows,
                    systems: coupon.systems,
                }),
                transformRaces((couponRace: any, betRace: any) => ({
                    ...betRace,
                    bets: couponRace.bets,
                }))(coupon),
            );
    }
};

export const createDivisionGameBet = (coupon: any) => {
    switch (coupon.betMethod) {
        case "harry":
            return createSimpleDivisionGameBet(coupon);
        default:
            return flow(
                createSimpleDivisionGameBet(coupon),
                transformRaces((couponRace: any, betRace: any) => ({
                    ...betRace,
                    reserves: couponRace.reserves,
                }))(coupon),
            );
    }
};

export const createDubbelBet = (coupon: any) => {
    switch (coupon.betMethod) {
        case "harry":
            return createSimpleDivisionGameBet(coupon);
        default:
            return flow(createSimpleDivisionGameBet(coupon), (bet) => {
                const betWithoutSystems = omit("systems", bet);
                return {
                    ...betWithoutSystems,
                    stake: coupon.stake,
                };
            });
    }
};

const isBettableRace = (game: Game, raceId: string) => {
    const race = getRaceById(game, raceId);
    return race?.status === "upcoming";
};

const filterOutNonBettableRaces = (game: Game) => (bet: any) => ({
    ...bet,
    races: filter(
        (race) => race.bets.length > 0 && isBettableRace(game, race.id),
        bet.races,
    ),
});

export const createRaketBet = (coupon: any, {game}: {game: Game}) =>
    flow(
        pickStandardAttributes(coupon),
        (bet) => ({
            ...bet,
            system: coupon.system,
            stake: coupon.stake,
        }),
        transformRaces((couponRace: any, betRace: any) => ({
            ...betRace,
            bets: couponRace.bets,
            betType: couponRace.betType,
        }))(coupon),
        filterOutNonBettableRaces(game),
    );

export const createTop7Bet = (coupon: any) => {
    const {systemId} = coupon;

    if (coupon.betMethod === "harry") {
        return flow(
            pickStandardAttributes(coupon),
            (bet) => ({
                ...bet,
                systemId,
                harryBetLimit: coupon.harryBetLimit,
                combinations: coupon.combinations,
                banker: coupon.banker,
            }),
            handleHarrySubscription(coupon),
            transformRaces((couponRace: any, betRace: any) => ({
                ...betRace,
                bets: coupon.banker ? take(1, couponRace.bets) : [],
            }))(coupon),
        );
    }

    return flow(
        pickStandardAttributes(coupon),
        (bet) => ({
            ...bet,
            boxedBets: CouponUtils.getBoxedBets({...coupon, cost: coupon.betCost}),
            systemId,
            banker: coupon.banker,
        }),
        transformRaces((couponRace: any, betRace: any) => ({
            ...betRace,
            bets: couponRace.bets,
            reserves: couponRace.reserves,
        }))(coupon),
    );
};
