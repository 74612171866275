import {map, set, forEach, includes} from "lodash/fp";
import {GameTypes} from "@atg-horse-shared/game-types";
import type {Start} from "@atg-horse-shared/racing-info-api/game/types";
import {
    LETTER_RESTRICTION,
    EXPECTED_OUTCOME_RESTRICTION,
    BASE_SELECTIONS_RESTRICTION,
    POINTS_RESTRICTION,
    sortStarts,
} from "./reducedBet";
import type {ReductionType, ReductionTerms, ReductionTermsRaces} from "./reducedBet";

// @ts-expect-error
const forEachWithIndex = forEach.convert({cap: false});
// @ts-expect-error
const mapWithIndex = map.convert({cap: false});

/**
 * Copy a new coupon with new values inside the reductionType
 *
 * @param reductionType ["expectedOutcome","letters", "baseSelection", "points"]
 * @param coupon ReduceCoupon with reductionTerms
 */
const newRestriction = <T>(
    reductionType: ReductionType,
    reducedBets: ReductionTerms,
    data: T,
) => set(`${reductionType}.restrictions`, data, reducedBets);

export const getDefaultStartRanking = (starts: Array<Start>, type: string) =>
    sortStarts(
        starts,
        includes(type, [GameTypes.ld, GameTypes.dd]) ? "odds" : "betDistribution",
        type,
    );

export const toggleSetEnabled = (
    reducedBets: ReductionTerms,
    restrictionSet: number,
    reductionType: ReductionType,
) => {
    switch (reductionType) {
        case LETTER_RESTRICTION: {
            const newReduction = newRestriction(reductionType, reducedBets, [
                ...reducedBets.letters.restrictions,
            ]);
            return set(
                `letters.restrictions[${restrictionSet}].enabled`,
                !newReduction.letters.restrictions[restrictionSet].enabled,
                newReduction,
            );
        }
        case EXPECTED_OUTCOME_RESTRICTION: {
            return set(
                `expectedOutcome.enabled`,
                !reducedBets.expectedOutcome?.enabled,
                reducedBets,
            );
        }
        case BASE_SELECTIONS_RESTRICTION: {
            return set(
                `baseSelections[${restrictionSet}].enabled`,
                !reducedBets.baseSelections[restrictionSet].enabled,
                reducedBets,
            );
        }
        case POINTS_RESTRICTION: {
            return set(`points.enabled`, !reducedBets.points.enabled, reducedBets);
        }
        default:
            return reducedBets;
    }
};

export const resortPoints = (
    pointsRaces: ReductionTermsRaces<number>,
    newRanking: Array<Array<number>>,
) => {
    const setStartsPointsByRanking = (rankingRow: any) => {
        // here we map the ranking array with to the number of points required,
        // thus when the horses are sorted the new first horse gets 1 point, the second 2 ponts, etc.
        const newStarts = {};
        forEachWithIndex((start: any, index: any) => {
            // the points should range from 1 to 20, the first start in the ranking array
            // should have 1 point, the next one 2 points, and so on. The start var corresponds
            // to the horse number and his index in the rankingRow array is the sort position
            // @ts-expect-error
            newStarts[start] = index + 1;
        }, rankingRow);
        return newStarts;
    };

    return mapWithIndex(
        (row: any, rowIndex: number) => ({
            ...row,
            starts: setStartsPointsByRanking(newRanking[rowIndex]),
        }),
        pointsRaces,
    );
};
