import {combineReducers} from "redux";
import {createFetchReducer} from "@atg-shared/fetch-redux";
import type {AtgRequestError, FetchReducer, FetchState} from "@atg-shared/fetch-types";
import {
    REQUEST_GAME_SCHEDULE,
    RECEIVE_GAME_SCHEDULE,
    REQUEST_GAME_RESULTS,
    RECEIVE_GAME_RESULTS,
    RESET_STATUS,
} from "./productActions";
import type {GameScheduleFetchAction, GameResultsFetchAction} from "./productActions";
import type {GameResult, GameSchedule} from "./product";
import type {Action} from "./index";

export type GameResults = {
    data: Array<GameResult> | null;
    error: AtgRequestError | null;
};

export type GameResultsState = {
    [key: string]: FetchState<GameResults>;
};

export type GameScheduleState = {
    [key: string]: FetchState<GameSchedule>;
};

const gameSchedules: FetchReducer<GameSchedule, GameScheduleFetchAction> =
    createFetchReducer(
        REQUEST_GAME_SCHEDULE,
        RECEIVE_GAME_SCHEDULE,
        RESET_STATUS,
        (state: GameSchedule, action) => {
            if (action.type === RECEIVE_GAME_SCHEDULE) {
                if (action.error) return state;

                return action.payload;
            }

            return state;
        },
        {},
    );

const schedulesByGameType = (state: GameScheduleState = {}, action: Action) => {
    switch (action.type) {
        case REQUEST_GAME_SCHEDULE:
        case RECEIVE_GAME_SCHEDULE: {
            if (!action.context) return state;

            const {gameType} = action.context;
            return {
                ...state,
                [gameType]: gameSchedules(state[gameType], action),
            };
        }
        default:
            return state;
    }
};

const gameResults: FetchReducer<GameResults, GameResultsFetchAction> = createFetchReducer(
    REQUEST_GAME_RESULTS,
    RECEIVE_GAME_RESULTS,
    RESET_STATUS,
    (state: GameResults, action) => {
        if (action.type === RECEIVE_GAME_RESULTS) {
            if (action.error) {
                return {
                    error: action.payload,
                    data: null,
                };
            }
            return {
                data: action.payload.gameResults,
                error: null,
            };
        }

        return state;
    },
    {data: null, error: null},
);

const resultsByGameType = (state: GameResultsState = {}, action: Action) => {
    switch (action.type) {
        case REQUEST_GAME_RESULTS:
        case RECEIVE_GAME_RESULTS: {
            if (!action.context) return state;

            const {gameType} = action.context;
            return {
                ...state,
                [gameType]: gameResults(state[gameType], action),
            };
        }
        default:
            return state;
    }
};

export type State = {
    gameResults: GameResultsState;
    gameSchedules: GameScheduleState;
};

const product = combineReducers({
    gameResults: resultsByGameType,
    gameSchedules: schedulesByGameType,
});

export default product;
