import {call} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import atgRequest, {pureFetch} from "@atg-shared/fetch";
import root from "window-or-global";

import {
    PUBLIC_COUPON_SERVICE_URL,
    COUPON_SERVICE_URL,
    USER_COUPON_SERVICE_URL,
} from "@atg-shared/service-url";
import type {Coupon, CouponType} from "@atg-horse-shared/coupon-types";
import {DIRECT_COUPON_INTEGRATION} from "@atg-global-shared/personalization/domain/nativePersonalizationConstants";
import {runNativeABTest} from "@atg-global-shared/personalization/domain/personalizationUtil";
import type {AtgResponse} from "@atg-shared/fetch-types";
import * as CouponUtils from "./coupon";

export function* getCouponById(id: string, isLoggedIn: boolean): SagaIterator<Coupon> {
    const {variation} = yield call(
        runNativeABTest,
        DIRECT_COUPON_INTEGRATION,
        // eslint-disable-next-line no-underscore-dangle
        window._horseStore,
    );
    let response: AtgResponse<Coupon>;
    if (variation === 1) {
        if (isLoggedIn) {
            response = yield call(atgRequest, `${COUPON_SERVICE_URL}/${id}`);
        } else {
            response = yield call(
                pureFetch,
                `${root.clientConfig.horse.publicApiUrl}/horse-coupons/api-public/v1/coupons/${id}`,
            );
        }
    } else {
        response = yield call(atgRequest, `${PUBLIC_COUPON_SERVICE_URL}/${id}`);
    }
    return CouponUtils.createCouponFromJSON(response.data);
}

export const fetchCouponByGameId = async (
    gameId: string,
    types: Array<CouponType>,
): Promise<Coupon> => {
    const {variation} = await runNativeABTest(
        DIRECT_COUPON_INTEGRATION,
        // eslint-disable-next-line no-underscore-dangle
        window._horseStore,
    );

    const url = `${
        variation === 1 ? COUPON_SERVICE_URL : USER_COUPON_SERVICE_URL
    }/game/${gameId}/latest`;

    const response =
        types.length > 0
            ? await atgRequest<Coupon>(`${url}?types=${types.join(",")}`)
            : await atgRequest<Coupon>(url);

    return CouponUtils.createCouponFromJSON(response.data);
};

export const getCoupon = (id: string, useDirectCouponIntegration = false) =>
    atgRequest<Coupon>(
        `${
            useDirectCouponIntegration ? COUPON_SERVICE_URL : PUBLIC_COUPON_SERVICE_URL
        }/${id}`,
    );
