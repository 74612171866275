import type {Game} from "@atg-horse-shared/racing-info-api/game/types";
import {type CalendarAPITypes} from "@atg-horse-shared/racing-info-api";
import type {GameError} from "./gameReducer";

export const REQUEST_ALL_GAMES_FOR_GAME_TYPE_AND_TRACK: "game/REQUEST_ALL_GAMES_FOR_GAME_TYPE_AND_TRACK" =
    "game/REQUEST_ALL_GAMES_FOR_GAME_TYPE_AND_TRACK" as const;
export const REQUEST_GAME: "game/REQUEST_GAME" = "game/REQUEST_GAME" as const;
export const FETCH_GAME: "game/FETCH_GAME" = "game/FETCH_GAME" as const;
export const RECEIVE_GAME: "game/RECEIVE_GAME" = "game/RECEIVE_GAME" as const;
export const RESERVE_GAME: "game/RESERVE_GAME" = "game/RESERVE_GAME" as const;
export const RELEASE_GAME: "game/RELEASE_GAME" = "game/RELEASE_GAME" as const;
export const REMOVE_GAMES: "game/REMOVE_GAMES" = "game/REMOVE_GAMES" as const;
export const RECEIVE_GAME_PUSH: "game/RECEIVE_GAME_PUSH" =
    "game/RECEIVE_GAME_PUSH" as const;
export const CHANGE_SHOW_RESULTS: "game/CHANGE_SHOW_RESULTS" =
    "game/CHANGE_SHOW_RESULTS" as const;
export const SET_HAS_CLOSED_NEW_OTHER_GAMES_BOX: "game/SET_HAS_CLOSED_NEW_OTHER_GAMES_BOX" =
    "game/SET_HAS_CLOSED_NEW_OTHER_GAMES_BOX" as const;
export const SHOW_RESULTS_FOR_START: "game/SHOW_RESULTS_FOR_START" =
    "game/SHOW_RESULTS_FOR_START" as const;
export const CLEAR_STARTS_SHOWING_RESULTS: "game/CLEAR_STARTS_SHOWING_RESULTS" =
    "game/CLEAR_STARTS_SHOWING_RESULTS" as const;
export const SET_WINNER_COUNT: "game/SET_WINNER_COUNT" = "game/SET_WINNER_COUNT" as const;
export const SELECT_RACE: "game/SELECT_RACE" = "game/SELECT_RACE" as const;
export const CHANGE_GAME_PATH: "game/CHANGE_GAME_PATH" = "game/CHANGE_GAME_PATH" as const;
export const SET_CURRENT_TRACK_ID: "game/SET_CURRENT_TRACK_ID" =
    "game/SET_CURRENT_TRACK_ID" as const;
export const SET_CLICKED_TRACK_ID: "game/SET_CLICKED_TRACK_ID" =
    "game/SET_CLICKED_TRACK_ID" as const;
export const SET_CURRENT_GAME_ID: "game/SET_CURRENT_GAME_ID" =
    "game/SET_CURRENT_GAME_ID" as const;
export const CHANGE_GAME: "game/CHANGE_GAME" = "game/CHANGE_GAME" as const;

type JSONPatch = Array<{
    op: string;
    path: string;
    value: unknown;
}>;

export type JSONPatchData = {
    id: string;
    previousVersion: number;
    version: number;
    patch: JSONPatch;
};

export type ChangeShowResultsAction = {
    type: typeof CHANGE_SHOW_RESULTS;
    payload: {
        showResults: boolean;
    };
};

export type SetHasClosedNewOtherGamesBoxAction = {
    type: typeof SET_HAS_CLOSED_NEW_OTHER_GAMES_BOX;
};

export type RequestGameAction = {
    type: typeof REQUEST_GAME;
    payload: {
        gameId: string;
    };
};

export type RequestAllGamesForGameTypeAndTrackAction = {
    type: typeof REQUEST_ALL_GAMES_FOR_GAME_TYPE_AND_TRACK;
    gameIds: Array<string>;
};
export type FetchGameAction = {
    type: typeof FETCH_GAME;
    payload: {
        gameId: string;
        forceFetch?: boolean;
        context: string;
    };
};

export type ReceiveGameAction = {
    type: typeof RECEIVE_GAME;
    error: false;
    payload: {
        gameId: string;
        game: Game;
    };
    context: string;
};

export type ReceiveGameErrorAction = {
    type: typeof RECEIVE_GAME;
    error: true;
    payload: {
        gameId: string;
        error: GameError;
    };
    context: string;
};

export type ReserveGameAction = {
    type: typeof RESERVE_GAME;
    payload: {
        gameId: string;
    };
};

export type ReleaseGameAction = {
    type: typeof RELEASE_GAME;
    payload: {
        gameId: string;
    };
};

export type ReceiveGamePushAction = {
    type: typeof RECEIVE_GAME_PUSH;
    payload: {
        data: Game | JSONPatchData;
    };
};

export type RemoveGamesAction = {
    type: typeof REMOVE_GAMES;
    payload: {
        gameIds: Array<string>;
    };
};

export type SelectRaceActionPayload = {
    game: Game;
    raceId: string;
    initiator: string;
};

export type SelectRaceAction = {
    type: typeof SELECT_RACE;
    payload: SelectRaceActionPayload;
};

export type ChangeGamePathAction = {
    type: typeof CHANGE_GAME_PATH;
    payload: {
        url: string;
    };
};

export type ShowResultsForStartAction = {
    type: typeof SHOW_RESULTS_FOR_START;
    payload: {
        startId: string;
    };
};

export type SetWinnerCountAction = {
    type: typeof SET_WINNER_COUNT;
    payload: {
        winnerCount: number;
    };
};

export type ClearStartsShowingResultsAction = {
    type: typeof CLEAR_STARTS_SHOWING_RESULTS;
};

export type SetCurrentTrackIdAction = {
    type: typeof SET_CURRENT_TRACK_ID;
    payload: {
        trackId: number | null | undefined;
    };
};

export type SetClickedTrackIdAction = {
    type: typeof SET_CLICKED_TRACK_ID;
    payload: {
        trackId: number | null | undefined;
    };
};

export type SetCurrentGameIdAction = {
    type: typeof SET_CURRENT_GAME_ID;
    payload: {
        gameId: string | null | undefined;
    };
};

export type ChangeGameAction = {
    type: typeof CHANGE_GAME;
    payload: {
        raceStatus?: CalendarAPITypes.RaceStatus;
    };
};

export type GameAction =
    | ChangeShowResultsAction
    | RequestGameAction
    | RequestAllGamesForGameTypeAndTrackAction
    | FetchGameAction
    | ReceiveGameAction
    | ReceiveGameErrorAction
    | ReserveGameAction
    | ReleaseGameAction
    | ReceiveGamePushAction
    | RemoveGamesAction
    | SelectRaceAction
    | ChangeGamePathAction
    | ShowResultsForStartAction
    | SetWinnerCountAction
    | ClearStartsShowingResultsAction
    | SetCurrentTrackIdAction
    | SetClickedTrackIdAction
    | SetCurrentGameIdAction
    | ChangeGameAction
    | SetHasClosedNewOtherGamesBoxAction;

export const changeShowResults = (showResults: boolean): ChangeShowResultsAction => ({
    type: CHANGE_SHOW_RESULTS,
    payload: {
        showResults,
    },
});

export const setHasClosedNewOtherGamesBox = (): SetHasClosedNewOtherGamesBoxAction => ({
    type: SET_HAS_CLOSED_NEW_OTHER_GAMES_BOX,
});

export const showResultsForStart = (startId: string): ShowResultsForStartAction => ({
    type: SHOW_RESULTS_FOR_START,
    payload: {
        startId,
    },
});

export const setWinnerCount = (winnerCount: number): SetWinnerCountAction => ({
    type: SET_WINNER_COUNT,
    payload: {
        winnerCount,
    },
});

export const setCurrentTrackId = (
    trackId: number | null | undefined,
): SetCurrentTrackIdAction => ({
    type: SET_CURRENT_TRACK_ID,
    payload: {
        trackId,
    },
});

export const setClickedTrackId = (
    trackId: number | null | undefined,
): SetClickedTrackIdAction => ({
    type: SET_CLICKED_TRACK_ID,
    payload: {
        trackId,
    },
});

export const clearStartsShowingResults = (): ClearStartsShowingResultsAction => ({
    type: CLEAR_STARTS_SHOWING_RESULTS,
});

export const requestGame = (gameId: string): RequestGameAction => ({
    type: REQUEST_GAME,
    payload: {
        gameId,
    },
});

export const requestAllGamesForGameTypeAndTrack = (
    gameIds: Array<string>,
): RequestAllGamesForGameTypeAndTrackAction => ({
    type: REQUEST_ALL_GAMES_FOR_GAME_TYPE_AND_TRACK,
    gameIds,
});

export const fetchGame = (gameId: string, forceFetch?: boolean): FetchGameAction => ({
    type: FETCH_GAME,
    payload: {
        gameId,
        forceFetch,
        context: gameId,
    },
});

export const receiveGame = (gameId: string, game: Game): ReceiveGameAction => ({
    type: RECEIVE_GAME,
    error: false,
    payload: {
        gameId,
        game,
    },
    context: gameId,
});

export const receiveGameError = (
    gameId: string,
    error: GameError,
): ReceiveGameErrorAction => ({
    type: RECEIVE_GAME,
    error: true,
    payload: {
        gameId,
        error,
    },
    context: gameId,
});

export const reserveGame = (gameId: string): ReserveGameAction => ({
    type: RESERVE_GAME,
    payload: {
        gameId,
    },
});

export const releaseGameReservation = (gameId: string): ReleaseGameAction => ({
    type: RELEASE_GAME,
    payload: {
        gameId,
    },
});

export const receiveGamePush = (data: Game | JSONPatchData): ReceiveGamePushAction => ({
    type: RECEIVE_GAME_PUSH,
    payload: {
        data,
    },
});

export const removeGames = (gameIds: Array<string>): RemoveGamesAction => ({
    type: REMOVE_GAMES,
    payload: {
        gameIds,
    },
});

export const selectRace = (payload: SelectRaceActionPayload): SelectRaceAction => ({
    type: SELECT_RACE,
    payload,
});

export const changeGamePath = (url: string): ChangeGamePathAction => ({
    type: CHANGE_GAME_PATH,
    payload: {
        url,
    },
});

export const setCurrentGameId = (
    gameId: string | null | undefined,
): SetCurrentGameIdAction => ({
    type: SET_CURRENT_GAME_ID,
    payload: {
        gameId,
    },
});

export const setGameIdMissing = (): SetCurrentGameIdAction => ({
    type: SET_CURRENT_GAME_ID,
    payload: {
        gameId: "missing",
    },
});

export const changeGame = (
    raceStatus?: CalendarAPITypes.RaceStatus,
): ChangeGameAction => ({
    type: CHANGE_GAME,
    payload: {
        raceStatus,
    },
});
