import type {SagaIterator} from "redux-saga";
import {call, select} from "redux-saga/effects";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {checkHarrySubscriptionAvailability} from "@atg-horse-shared/horse-harry/domain/harrySubscriptionSaga";
import * as UserSelectors from "@atg-global-shared/user/userSelectors";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {CouponUtils} from "@atg-horse-shared/coupon";
import * as PurchaseSelectors from "./purchaseSelectors";

export default function* harrySubscriptionUpSellSaga({
    payload: {context: shouldUpSellHarrySubscription = true},
}: {
    [key: string]: any;
}): SagaIterator<void> {
    const product = yield select(PurchaseSelectors.getProduct);
    const isLoggedIn = yield select(UserSelectors.isLoggedIn);

    if (!product) return;

    if (product.coupon) {
        const shouldUpSell =
            isLoggedIn &&
            CouponUtils.isSubscriptionPossible(product.coupon) &&
            shouldUpSellHarrySubscription;
        if (shouldUpSell) yield call(checkHarrySubscriptionAvailability, product.coupon);
    }
}
