import {parseDateTimestamp} from "@atg-shared/datetime";
import {serverTime} from "@atg-shared/server-time";

type CanWatchArchiveRaceProps = {
    isLoggedIn: boolean;
    date?: string;
    countryCode?: string | null;
};

export function canWatchArchiveRace({
    date,
    countryCode,
    isLoggedIn,
}: CanWatchArchiveRaceProps) {
    const nowDate = serverTime();
    if (!date) return false;
    const raceDate = parseDateTimestamp(date);
    const freeDaysToWatchWithoutAuth = 10;
    const limitDate = raceDate.add(freeDaysToWatchWithoutAuth, "days");
    const withinFreePeriod = !nowDate.isAfter(limitDate);
    // `options.countryCode` will be undefined when triggered from the receipt page.
    // In that case `isFrenchRace` will evaluate to `false` and the user will be allowed to watch
    // the video which is fine since they're always logged in on receipt page.
    const isFrenchRace = countryCode === "FR";

    return isLoggedIn || (!isFrenchRace && withinFreePeriod);
}
