import * as ModalActions from "atg-modals/modalActionTypes";
import type {StartDetailsResponse, HistoricStart} from "./startDetailsInterfaces";

export const REQUESTED_START_DETAILS = "game/requestedStartDetails";
export const RECEIVED_START_DETAILS = "game/receivedStartDetails";
export const SHOW_START_DETAILS_VIDEO = "game/showStartDetailVideo";
export const STORE_START_DETAILS_VIDEO = "game/storeStartDetailVideo";
export const CLOSE_START_DETAILS_VIDEO = "game/closeStartDetailVideo";
export const OPEN_HEAD_2_HEAD = "game/openHead2Head";
export const REQUEST_RACE_DETAILS = "game/requestedRaceDetails";
export const RECEIVED_RACE_DETAILS = "game/receivedRaceDetails";
export const RECEIVED_RACE_DETAILS_ERROR = "game/receivedRaceDetailsError";

export type RequestStartDetails = {
    type: typeof REQUESTED_START_DETAILS;
    payload: {startId: string};
};

export type RequestRaceDetails = {
    type: typeof REQUEST_RACE_DETAILS;
    payload?: {raceId: string};
};

export type ReceiveRaceDetails = {
    type: typeof RECEIVED_RACE_DETAILS;
    payload: {data: [StartDetailsResponse]};
};

export type ReceiveStartDetailsError = {
    type: typeof RECEIVED_START_DETAILS;
    error: boolean;
    payload: {startId: string; error: unknown};
};

export type ReceiveRaceDetailsError = {
    type: typeof RECEIVED_RACE_DETAILS_ERROR;
    error: boolean;
    payload: {raceId: string; error: unknown};
};

export type ReceiveStartDetails = {
    type: typeof RECEIVED_START_DETAILS;
    payload: {startId: string; data: StartDetailsResponse};
};

export type DisplayStartDetails = {
    type: typeof ModalActions.OPEN_START_DETAILS;
    payload: {startId: string; gameId: string};
};

export type CloseStartDetails = {
    type: typeof ModalActions.CLOSE_START_DETAILS;
};

export type CloseStartDetailsVideo = {
    type: typeof CLOSE_START_DETAILS_VIDEO;
};

export type StartDetailsVideo = {
    type: typeof SHOW_START_DETAILS_VIDEO;
    payload: {race: HistoricStart};
};

export type StoreStartDetailsVideo = {
    type: typeof STORE_START_DETAILS_VIDEO;
    payload: {race: HistoricStart; videoSource: string};
};

export type OpenHead2Head = {
    type: typeof OPEN_HEAD_2_HEAD;
    payload: {raceId: string};
};

export const requestStartDetails = (startId: string): RequestStartDetails => ({
    type: REQUESTED_START_DETAILS,
    payload: {startId},
});

export const requestedRaceDetails = (): RequestRaceDetails => ({
    type: REQUEST_RACE_DETAILS,
});

export const openHead2Head = (raceId: string): OpenHead2Head => ({
    type: OPEN_HEAD_2_HEAD,
    payload: {raceId},
});

export const receiveStartDetailsError = (
    startId: string,
    error: unknown,
): ReceiveStartDetailsError => ({
    type: RECEIVED_START_DETAILS,
    error: true,
    payload: {startId, error},
});

export const receiveRaceDetailsError = (
    raceId: string,
    error: unknown,
): ReceiveRaceDetailsError => ({
    type: RECEIVED_RACE_DETAILS_ERROR,
    error: true,
    payload: {raceId, error},
});

export const receiveStartDetails = (data: StartDetailsResponse): ReceiveStartDetails => {
    const {raceId, startNumber} = data;
    const startId = `${raceId}_${startNumber}`;
    return {
        type: RECEIVED_START_DETAILS,
        payload: {startId, data},
    };
};

export const receiveRaceDetails = (data: [StartDetailsResponse]): ReceiveRaceDetails => ({
    type: RECEIVED_RACE_DETAILS,
    payload: {data},
});

export const displayStartDetails = (
    startId: string,
    gameId: string,
): DisplayStartDetails => ({
    type: ModalActions.OPEN_START_DETAILS,
    payload: {startId, gameId},
});

export const closeStartDetails = (): CloseStartDetails => ({
    type: ModalActions.CLOSE_START_DETAILS,
});

export const showStartDetailsVideo = (race: HistoricStart): StartDetailsVideo => ({
    type: SHOW_START_DETAILS_VIDEO,
    payload: {race},
});

export const closeStartDetailsVideo = (): CloseStartDetailsVideo => ({
    type: CLOSE_START_DETAILS_VIDEO,
});

export const storeStartDetailsVideo = (
    race: HistoricStart,
    videoSource: string,
): StoreStartDetailsVideo => ({
    type: STORE_START_DETAILS_VIDEO,
    payload: {race, videoSource},
});
