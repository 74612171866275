import {type TrackEvent, trackEvent} from "@atg-shared/analytics";
import {deprecated_logEvent} from "@atg-shared/analytics";
import * as HorseAnalyticsTypes from "./horseAnalytics.types";
import type * as LivebarAnalyticsTypes from "./livebarAnalytics.types";
import type * as RadarAnalyticsTypes from "./radarAnalytics.types";
import type * as HarryBagsAnalyticsTypes from "./types/harryBagsAnalytics.types";
import type * as PurchaseAnalyticsTypes from "./types/purchaseAnalytics.types";
import type * as ReducedBetAnalyticsTypes from "./types/reducedBetAnalytics.types";

export * as RadarAnalytics from "./RadarAnalytics";

export {HorseAnalyticsTypes, LivebarAnalyticsTypes, RadarAnalyticsTypes};

export function checkCoupon(type = "online") {
    deprecated_logEvent({coupon_event: "check_coupon", coupon_type: type});
}

export function checkReceipt(type = "online") {
    deprecated_logEvent({coupon_event: "result", coupon_type: type});
}

export function codeCorrect() {
    deprecated_logEvent({code_event: "correct_clicked"});
}

export function vrCouponWarning(
    eventAction: string,
    highestPossibleWin: string,
    stake: string,
    betType: string,
) {
    deprecated_logEvent({
        vr_warning_event: eventAction,
        vr_highest_possible_win: highestPossibleWin,
        vr_stake: stake,
        vr_betType: betType,
    });
}

export function videoArchive(eventAction: string, key: string, value: string) {
    deprecated_logEvent({
        video_archive_event: eventAction,
        video_archive_key: key,
        video_archive_value: value,
    });
}

export function banner(
    eventAction: string,
    position: string | undefined,
    label: string,
    id: string | undefined,
) {
    deprecated_logEvent({
        banner_event: eventAction,
        banner_position: position,
        banner_label: label,
        banner_id: id,
    });
}

export function trackStartPageClick(startPageObject: string, resultOfClick: string) {
    deprecated_logEvent({
        start_page_event: "Klick på startsidan",
        start_page_object: startPageObject,
        result_of_click: resultOfClick,
    });
}

export function VMiV75(event: string) {
    deprecated_logEvent({
        vmiv75_event: event,
    });
}

export const trackHorseEvent: TrackEvent<
    | HorseAnalyticsTypes.HorseTrackingEvent
    | LivebarAnalyticsTypes.LivebarTrackingEvent
    | RadarAnalyticsTypes.RadarTrackingEvent
    | HarryBagsAnalyticsTypes.HarryBagsTrackingEvent
    | PurchaseAnalyticsTypes.PurchaseTrackingEvent
    | ReducedBetAnalyticsTypes.ReducedBetTrackingEvent
> = trackEvent;
