import * as React from "react";
import type {Props as SvgIconPropType} from "../components/SvgIcon";
import SvgIcon from "../components/SvgIcon";

export function Help(props: SvgIconPropType) {
    return (
        <SvgIcon {...props}>
            <path d="M16 0C7.2 0 0 7.2 0 16s7.2 16.1 16 16.1S32.1 24.9 32.1 16c0-8.9-7.2-16-16.1-16zm-1.3 24.4c-.4 3-.7 3-3.6 3-2.3 0-2.9-.1-2.9-1.4 0-.4 0-.9.1-1.6.4-2.6.6-2.7 3.5-2.7 2.3 0 3 .1 3 1.3 0 .4-.1.9-.1 1.4zm10-12.4c-.3 3.4-1.4 5.4-8.6 6.9l-6.4 1.4h-.6l.3-2.1c.5-3.1 1.7-3.5 5.3-4.1l1.6-.3c2.4-.4 2.8-1.1 2.8-2.2 0-1-.6-1.6-2.4-1.6h-6.5c.6-3.5 1.3-5.4 6.9-5.4 6.7 0 8.1 3.3 7.6 7.4z" />
        </SvgIcon>
    );
}

export default Help;
