import dayjs from "dayjs";
import type {Saga} from "redux-saga";
import {
    put,
    select,
    takeLatest,
    delay,
    race,
    take,
    fork,
    cancel,
    all,
} from "redux-saga/effects";
import {find, isEmpty, once} from "lodash/fp";
import {serverTime} from "@atg-shared/server-time";
import log from "@atg-shared/log";
import type {CalendarAPITypes} from "@atg-horse-shared/racing-info-api";
import type {CalendarTrack} from "@atg-horse-shared/racing-info-api/game/types";
import * as Track from "@atg-horse-shared/utils/track";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as Calendar from "@atg-horse-shared/calendar/domain/calendar";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as CalendarSelectors from "@atg-horse-shared/calendar/domain/calendarSelectors";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {RECEIVE_CALENDAR_PUSH} from "@atg-horse-shared/calendar/domain/calendarActions";
import type {Channel} from "../video/videoReducer";
import {
    RECEIVE_CHANNELS_PUSH,
    SELECT_CHANNEL,
    LOADED_CHANNEL_CONFIG,
} from "../video/videoActionConstants";
import * as Live from "../live/liveUtils";
import * as LiveSelectors from "../live/redux/liveSelectors";
import * as QuickplayActions from "./quickplayActions";
import * as QuickplaySelectors from "./quickplaySelectors";

/**
 * Compare if `currentRace` in channel by `selectRaceId` for the quickplay store
 */
function isNoDiff(channel: Channel, selectedRaceId: string) {
    const {currentRace} = channel;
    return currentRace?.id === selectedRaceId;
}

const RACE_TIME = 4.5 * 60 * 1000; //  5 minutes before start on PRE should not change the race even if another race is closer

/**
 * When PRE in betting system is set a new startTime is added to the `selectedRace`,
 * This will place any race that is close to the currentRace as the primary.
 * This function check if it should be placed before or not base on
 * `status` of the race, if the race has been given a PRE time that is more than
 * 5 minuts before start or the currentRace does not exist.
 * This is because the given time of the race is on preliminary until all horses are ready for the start
 */
export const shouldNotWaitOnPreliminaryTime = (
    track: CalendarTrack | null | undefined,
    selectedRaceId?: string,
) => {
    // @ts-expect-error
    const oldRace = selectedRaceId && Track.findRaceById(track, selectedRaceId);

    if (!oldRace) return true;
    if (
        oldRace.status === Calendar.GetRaceStatus.results ||
        oldRace.status === Calendar.GetRaceStatus.cancelled
    )
        return true;

    return dayjs(serverTime()).diff(oldRace.startTime) > RACE_TIME;
};

/**
 * Saga that select the latest liveTrack,
 */
function* changeLiveTracks() {
    // @ts-expect-error
    const isActive = yield select(QuickplaySelectors.isAutomatic);
    if (!isActive) return;
    // @ts-expect-error
    const selectedRaceId = yield select(QuickplaySelectors.getSelectedRaceId);
    // @ts-expect-error
    const selectedGameType = yield select(QuickplaySelectors.getSelectedGameType);
    // @ts-expect-error
    const selectedChannel = yield select(LiveSelectors.getSelectedChannel);
    // @ts-expect-error
    const day = yield select(CalendarSelectors.getCalendarForToday);
    const channelTracks = Live.getTracksForToday(day, selectedChannel?.tracks);

    const tracks = !isEmpty(channelTracks) ? channelTracks : day?.tracks;

    if (!tracks || isEmpty(tracks)) {
        return;
    }

    const [nextTrack, resultNextRace, nextRace] = Live.getClosestTrackWithRace(tracks);
    if (nextRace && nextRace.id !== selectedRaceId) {
        const availableGameTypes =
            QuickplaySelectors.selectAvailableSingleRaceGameTypesForGivenRaceId(
                day,
                nextRace?.id,
            );
        const isCurrentGameTypeAvailable = availableGameTypes?.includes(selectedGameType);
        const nextTrackWithNextRace = Live.findTrackByRaceId(nextRace.id, tracks);
        yield put(
            QuickplayActions.selectLiveTrack(
                nextRace.id,
                // @ts-expect-error
                nextTrackWithNextRace?.id,
                isCurrentGameTypeAvailable ? selectedGameType : "vinnare",
            ),
        );
        return;
    }
    if (!resultNextRace) return;

    if (resultNextRace.id !== selectedRaceId) {
        const availableGameTypes =
            QuickplaySelectors.selectAvailableSingleRaceGameTypesForGivenRaceId(
                day,
                resultNextRace?.id,
            );
        const isCurrentGameTypeAvailable = availableGameTypes?.includes(selectedGameType);
        yield put(
            QuickplayActions.selectLiveTrack(
                resultNextRace.id,
                nextTrack?.id,
                isCurrentGameTypeAvailable ? selectedGameType : "vinnare",
            ),
        );
    }
}

const WAIT_FOR_RESULT = 1000 * 120; // Delay for track changes on `nextRace`
// @ts-expect-error
function* changeRaceOnNextRace(
    nextRace: CalendarAPITypes.CalendarRace | null | undefined,
    tracks: Array<CalendarTrack>,
) {
    if (nextRace) {
        // @ts-expect-error
        const selectedGameType = yield select(QuickplaySelectors.getSelectedGameType);
        const nextTrackWithNextRace = Live.findTrackByRaceId(nextRace.id, tracks);
        // @ts-expect-error
        const day = yield select(CalendarSelectors.getCalendarForToday);
        const availableGameTypes =
            QuickplaySelectors.selectAvailableSingleRaceGameTypesForGivenRaceId(
                day,
                nextRace?.id,
            );
        const isCurrentGameTypeAvailable = availableGameTypes?.includes(selectedGameType);
        const [waited, cancelOnTrack] = yield race([
            delay(WAIT_FOR_RESULT),
            take(QuickplayActions.SELECT_LIVE_TRACK), // Cancel this race if `SELECT_LIVE_TRACK` is called which could be that `Result` has already been given
        ]);
        if (cancelOnTrack) {
            yield cancel();
            return;
        }
        if (waited) {
            yield put(
                QuickplayActions.selectLiveTrack(
                    nextRace.id,
                    // @ts-expect-error
                    nextTrackWithNextRace?.id,
                    isCurrentGameTypeAvailable ? selectedGameType : "vinnare",
                ),
            );
            yield cancel();
            return;
        }
    }
    yield cancel();
}

/**
 * Automatic track and race switcher,
 * wait for `star` when `nextRace` changes in the CalendareSubscription
 * then creates a effect that races between `Result` and `nextRace` + 120 s.
 */
const automaticLiveTrackSwitcher = (actionTypes: any) =>
    fork(function* () {
        let task;
        let taskRaceId;
        while (true) {
            yield take(actionTypes);
            if (task && task.isCancelled()) {
                task = null;
            }
            const [
                isAutomatic,
                selectedChannel,
                selectedRaceId,
                day,
                selectedLiveTrack,
                selectedGameType,
            ] = yield all([
                select(QuickplaySelectors.isAutomatic),
                select(LiveSelectors.getSelectedChannel),
                select(QuickplaySelectors.getSelectedRaceId),
                select(CalendarSelectors.getCalendarForToday),
                select(LiveSelectors.getSelectedLiveTrack),
                select(QuickplaySelectors.getSelectedGameType),
            ]);
            if (selectedChannel?.tracks && day) {
                const tracks = Live.getTracksForToday(day, selectedChannel.tracks);
                const [nextTrack, resultNextRace, nextRace] =
                    Live.getClosestTrackWithRace(tracks);
                if (
                    isAutomatic &&
                    resultNextRace &&
                    !isNoDiff(selectedChannel, selectedRaceId)
                ) {
                    const onResult = resultNextRace?.id !== selectedRaceId;
                    const changingNextRace = taskRaceId !== nextRace?.id;

                    // On `Result` selecte new race
                    if (
                        onResult &&
                        shouldNotWaitOnPreliminaryTime(selectedLiveTrack, selectedRaceId)
                    ) {
                        const availableGameTypes =
                            QuickplaySelectors.selectAvailableSingleRaceGameTypesForGivenRaceId(
                                day,
                                nextRace?.id,
                            );
                        const isCurrentGameTypeAvailable =
                            availableGameTypes?.includes(selectedGameType);
                        yield put(
                            QuickplayActions.selectLiveTrack(
                                resultNextRace.id,
                                nextTrack?.id,
                                isCurrentGameTypeAvailable ? selectedGameType : "vinnare",
                            ),
                        );
                    }

                    if (task && (changingNextRace || onResult)) {
                        yield cancel(task);
                    }
                    if (changingNextRace && !onResult) {
                        taskRaceId = nextRace?.id;
                        // @ts-expect-error
                        task = yield fork(changeRaceOnNextRace, nextRace, tracks);
                    }
                }
            }
        }
    });

const logChangeRaceOnQuickplayStartlist = once(log.error);

/**
 * Change race whenever a the closest race has changed,
 * Use a cache for the raceId to avoid forcing a user that
 * to the closest race when checking result on a previous one
 */
let cacheQuickplayStartListRaceId = "";
function* changeRaceOnQuickplayStartlist() {
    // @ts-expect-error
    const followBroadcast = yield select(QuickplaySelectors.isAutomatic);
    if (followBroadcast) return;
    const selectedLiveTrack: CalendarTrack | null = yield select(
        LiveSelectors.getSelectedLiveTrack,
    );
    if (!selectedLiveTrack?.races) return;
    // @ts-expect-error
    const selectedRaceId = yield select(QuickplaySelectors.getSelectedRaceId);

    const selectedRace = find(
        ({id}) => id === selectedRaceId,
        selectedLiveTrack?.races || [],
    );
    if (!selectedRace) {
        logChangeRaceOnQuickplayStartlist(
            "changeRaceOnQuickplayStartlist: selectedRace from liveTrack does not exist:",
            {
                selectedRaceId,
                url: selectedLiveTrack?.url,
            },
        );
        return;
    }
    if (
        selectedRace.status === Calendar.GetRaceStatus.results ||
        selectedRace.status === Calendar.GetRaceStatus.cancelled
    ) {
        // For every push check status on each race and take the first upcoming or ongoing race
        const closestRace = Live.getClosestRace(selectedLiveTrack);
        if (closestRace && cacheQuickplayStartListRaceId !== closestRace?.id) {
            cacheQuickplayStartListRaceId = closestRace.id;
            // @ts-expect-error
            const selectedGameType = yield select(QuickplaySelectors.getSelectedGameType);
            // @ts-expect-error
            const day = yield select(CalendarSelectors.getCalendarForToday);
            const availableGameTypes =
                QuickplaySelectors.selectAvailableSingleRaceGameTypesForGivenRaceId(
                    day,
                    closestRace?.id,
                );
            const isCurrentGameTypeAvailable =
                availableGameTypes?.includes(selectedGameType);
            yield put(
                QuickplayActions.selectLiveTrack(
                    closestRace.id,
                    selectedLiveTrack.id,
                    isCurrentGameTypeAvailable ? selectedGameType : "vinnare",
                ),
            );
        }
    }
}
// @ts-expect-error
export default function* quickplaySaga(): Saga<void> {
    yield takeLatest(
        [
            LOADED_CHANNEL_CONFIG,
            SELECT_CHANNEL,
            QuickplayActions.ACTIVATE_AUTOMATIC_TRACK_SWITCHER,
            QuickplayActions.SELECT_CURRENT_LIVE_TRACK,
        ],
        changeLiveTracks,
    );
    yield automaticLiveTrackSwitcher([RECEIVE_CALENDAR_PUSH, RECEIVE_CHANNELS_PUSH]);
    yield takeLatest(RECEIVE_CALENDAR_PUSH, changeRaceOnQuickplayStartlist);
}
