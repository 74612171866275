import {
    constants,
    type Action,
    type RealityCheckState,
} from "@atg-responsible-gambling-shared/reality-check-types";
import {LOGOUT_FINALIZE} from "@atg-global-shared/user/userActionTypes";
import type {FinalizeLogoutAction} from "@atg-global-shared/user/userActions";

const {SET_NOINTERRUPT_MODE, SHOW_MODAL, HIDE_MODAL} = constants;

const INITIAL_STATE: RealityCheckState = {
    noInterruptMode: false,
    showModal: false,
    startTime: undefined,
};

const reducer = (
    state: RealityCheckState = INITIAL_STATE,
    action: Action | FinalizeLogoutAction,
): RealityCheckState => {
    switch (action.type) {
        case SET_NOINTERRUPT_MODE:
            return {
                ...state,
                noInterruptMode: action.payload && action.payload.noInterrupt,
            };
        case SHOW_MODAL:
            return {...state, showModal: true, startTime: action.payload.startTime};
        case HIDE_MODAL:
            return {
                ...state,
                showModal: false,
            };
        case LOGOUT_FINALIZE:
            return INITIAL_STATE;
        default:
            return state;
    }
};
export default reducer;
