import {AtgAlertTypes} from "@atg-global-shared/alerts-types";
import type {AlertPropsType} from "@atg-global-shared/alerts-types";
import {frameAction} from "atg-store-addons";

export const CLOSE_ALERT = "CLOSE_ALERT";
export const FOLLOW_LINK = "FOLLOW_LINK";
export const GAME_ALERT = "GAME_ALERT";

export type CloseAlertAction = {
    type: typeof CLOSE_ALERT;
    payload: {
        id: string;
    };
};

export type FollowLinkAction = {
    type: typeof FOLLOW_LINK;
    payload: {
        id: string;
    };
};

export type GameAlertAction = {
    type: typeof GAME_ALERT;
    payload: {
        alert: AlertPropsType;
    };
};

export type AlertActions = CloseAlertAction | FollowLinkAction | GameAlertAction;

export const closeAlert = (id: string): CloseAlertAction =>
    frameAction({
        type: CLOSE_ALERT,
        payload: {
            id,
        },
    });

export const followLink = (id: string): FollowLinkAction =>
    frameAction({
        type: FOLLOW_LINK,
        payload: {
            id,
        },
    });

export const gameInfoAlert = ({
    alertId,
    gameId,
    message,
    title,
}: {
    alertId: string;
    gameId: string;
    message: string;
    title: string;
}): GameAlertAction =>
    frameAction({
        type: GAME_ALERT,
        payload: {
            alert: {
                alertLevel: AtgAlertTypes.WARNING,
                games: [gameId],
                id: alertId,
                message,
                title,
                slug: "",
                tags: [],
                link: "",
            },
        },
    });
