import dayjs from "dayjs";
import {replace} from "lodash";
import {combineReducers} from "redux";
import type {Reducer} from "redux";
import {SET_LAST_VISITED_START_LIST} from "./navigationActions";
import type {NavigationAction} from "./navigationActions";

export type ProductState = {
    [key: string]: {
        lastVisitedStartlist: string;
        lastVisitedGameId: string;
        timeAdded: number;
    };
};

export type NavigationState = {
    product: ProductState;
};

export const InitialProduct: ProductState = {};

function product(
    state: ProductState = InitialProduct,
    action: NavigationAction,
): ProductState {
    if (action.type === SET_LAST_VISITED_START_LIST) {
        return {
            ...state,
            [action.payload.gameType]: {
                // we want to endup on "main" startlist, not reduced. Therefore removing "/reducerat" part from the url
                lastVisitedStartlist: replace(action.payload.url, "/reducerat", ""),
                lastVisitedGameId: action.payload.gameId,
                timeAdded: Number(dayjs()),
            },
        };
    }

    return state;
}

const navigationReducer: Reducer<NavigationState, NavigationAction> = combineReducers({
    product,
});

export default navigationReducer;
