import type {AtgRequestError, FetchPromiseAction} from "@atg-shared/fetch-types";
import {FETCH_PROMISE, call} from "@atg-shared/fetch-redux";
import type {AuthOptions} from "./fetchAuthorized";
import _fetchAuthorized from "./fetchAuthorized";

export const fetchAuthorized = <Response>(
    resolve: (response: Response) => void,
    reject: (error: AtgRequestError) => void,
    url: string,
    requestOptions?: Record<string, unknown>,
    authOptions?: AuthOptions,
): FetchPromiseAction<Response> => ({
    type: FETCH_PROMISE,
    payload: {
        callApi: call(_fetchAuthorized, url, requestOptions, authOptions),
        resolve,
        reject,
    },
});
