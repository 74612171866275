import {combineReducers} from "redux";
import {
    SHARED_BET_CANCEL,
    SHARED_BET_CANCEL_RESPONSE,
    SHARED_BET_CANCEL_CLEAR,
} from "./sharedBetActions";

export type SharedBetCancelReducer = {
    loading: boolean;
    error: string | null;
};

const error = (state = null, action: any): string | null => {
    const {type, payload, error: actionError} = action;
    switch (type) {
        case SHARED_BET_CANCEL_RESPONSE:
            return actionError ? payload : state;
        case SHARED_BET_CANCEL_CLEAR:
            return null;
        default:
            return state;
    }
};

const loading = (state = false, action: any): boolean => {
    switch (action.type) {
        case SHARED_BET_CANCEL:
            return true;
        case SHARED_BET_CANCEL_RESPONSE:
        case SHARED_BET_CANCEL_CLEAR:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    loading,
    error,
});
