import {css} from "@emotion/react";
import styled from "@emotion/styled";
import type {
    gradingCorrectClassic,
    atgWhite,
    atgGreyNo3,
} from "atg-ui/css/overridableVariables.styles";
import {gradingCorrectGreen} from "atg-ui/css/overridableVariables.styles";

export type BoostCodeSize = 16 | 25;
export type BoostCodeColor = typeof atgWhite | typeof atgGreyNo3 | typeof undefined;
export type CorrectCodeColor =
    | typeof gradingCorrectGreen
    | typeof gradingCorrectClassic
    | typeof undefined;

export const boostCode = (size?: BoostCodeSize, color?: BoostCodeColor) => css`
    height: ${size === 25 ? "25px" : "16px"};
    width: ${size === 25 ? "88px" : "56px"};
    fill: ${color || "#324c69"};
    position: relative;
`;

export const boostCodeDigit = (size?: BoostCodeSize) => {
    switch (size) {
        case 16:
        // eslint-disable-next-line default-case-last, no-fallthrough
        default:
            return css`
                width: 22px;
                height: 16px;
                position: absolute;
            `;
        case 25:
            return css`
                width: 35px;
                height: 24px;
                position: absolute;
            `;
    }
};

export const boostCodeDigitFirst = css`
    left: 0;
`;

export const boostCodeDigitSecond = (size?: BoostCodeSize) => {
    switch (size) {
        case 16:
        // eslint-disable-next-line default-case-last, no-fallthrough
        default:
            return css`
                left: 17px;
            `;
        case 25:
            return css`
                left: 26px;
            `;
    }
};

export const boostCodeDigitThird = (size?: BoostCodeSize) => {
    switch (size) {
        case 16:
        // eslint-disable-next-line default-case-last, no-fallthrough
        default:
            return css`
                left: 34px;
            `;
        case 25:
            return css`
                left: 52px;
            `;
    }
};

export const boostCodeDigitCorrect = (color?: CorrectCodeColor) => css`
    fill: ${color || gradingCorrectGreen};
`;

/*
 * placeholder rectangle
 * it seems it is a bit more narrow than the ones with digits
 * therefore will use 1px smaller margins.
 */
export const PlaceholderRect = styled.div`
    left: 0;
    :nth-of-type(1) {
        left: 25px;
    }
    :nth-of-type(2) {
        left: 50px;
    }
    cursor: pointer;
`;

export const svg = css`
    height: 100%;
`;
