const systems = {
    simple: {
        label: "Enkelt",
        priceMultiplier: 1,
    },
    "2of3": {
        label: "2 av 3",
        requiredBetCount: 3,
        priceMultiplier: 3,
    },
    "2of4": {
        label: "2 av 4",
        requiredBetCount: 4,
        priceMultiplier: 6,
    },
    "3of4": {
        label: "3 av 4",
        requiredBetCount: 4,
        priceMultiplier: 4,
    },
    "3of5": {
        label: "3 av 5",
        requiredBetCount: 5,
        priceMultiplier: 10,
    },
    "4of5": {
        label: "4 av 5",
        requiredBetCount: 5,
        priceMultiplier: 5,
    },
};

export default systems;
