import {privateGetTop7SneakState} from "@atg-horse-shared/horse-bet";
import {flow, filter, union, find, map} from "lodash/fp";

function isSneaked(receipt: any, row: any) {
    return find({id: row.id}, receipt.sneakedRows) !== undefined;
}

export function calculateCorrect(receipt: any) {
    const {bet} = receipt;
    const correctRaces = bet.races.filter((race: any) => {
        const correctHorses = flow(union(race.reserves), filter("win"))(race.bets);
        const atLeastOneCorrect = correctHorses.length > 0;
        return (
            (receipt.selectedTab === "result" || isSneaked(receipt, race)) &&
            (atLeastOneCorrect || race.cancelled)
        );
    });
    return correctRaces.length;
}

// default function for all games except Top7
export function getSneakState() {
    return [];
}

export function getTop7SneakState(receipt: any) {
    const isReceiptTab = receipt.selectedTab === "receipt";
    const sneakedPositions = isReceiptTab ? map("id", receipt.sneakedRows) : undefined;
    return privateGetTop7SneakState(receipt.bet, sneakedPositions);
}
