import type {Store} from "redux";
import type {SagaIterator} from "redux-saga";
import {put, call, takeLatest} from "redux-saga/effects";
import {DepositActions} from "@atg-payment-shared/deposit-domain";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {SharedBetProducts, SharedBetActions} from "@atg-horse/shared-bet";
import * as Products from "./products";
import * as PurchaseActions from "./purchaseActions";
import * as PurchaseSelectors from "./purchaseSelectors";

/**
 * This function loads the `couponChunk` before the restoreCoupon is called.
 * That is because the coupon need to be connected to the product cid.
 * The restoreCoupon action fixes that in the couponReducer
 *
 * See jira:https://jira-atg.riada.cloud/browse/LIVE-154
 */
export const lazyLoadCouponChunks = async () =>
    // eslint-disable-next-line @nx/enforce-module-boundaries
    import(/* webpackChunkName: "couponChunk" */ "@atg-horse-shared/coupon").then(
        (module) => module.default,
    );

export function* restoreCouponProduct(
    product: Products.CouponProduct,
    purchaseState: {
        [key: string]: any;
    },
    store: Store<any, any>,
): SagaIterator<void> {
    const {coupon, cid} = product;

    // Load the coupon reducers and sagas
    // before the restoreCoupon runs
    // See jira:https://jira-atg.riada.cloud/browse/LIVE-154
    const couponChunk = yield call(lazyLoadCouponChunks);
    couponChunk(store);

    yield put(PurchaseActions.restoreCoupon({coupon, cid}));
    yield put(PurchaseActions.restorePurchaseFlow(purchaseState));
    yield put(DepositActions.depositFinalize());
}

export function* restorePurchaseParams(
    product: Products.Product,
    purchaseState: {
        [key: string]: any;
    },
): SagaIterator<void> {
    switch (product.type) {
        case SharedBetProducts.SHARED_BET_CREATE_PRODUCT:
            return yield put(SharedBetActions.setCreateParams(purchaseState.params));
        case SharedBetProducts.SHARED_BET_SHARE_PRODUCT:
            return yield put(SharedBetActions.setPurchaseParams(purchaseState.params));
        default:
            return undefined;
    }
}

export function* restoreProduct(
    store: Store<any, any>,
    {
        payload,
    }: {
        [key: string]: any;
    },
): SagaIterator<void> {
    const product = yield call(PurchaseSelectors.getProduct, payload);
    if (!product) return undefined;

    switch (product.type) {
        case Products.HARRY_PRODUCT:
        case Products.TOP7_PRODUCT:
        case Products.HARRY_BAG_PRODUCT:
        case Products.HARRY_SUBSCRIPTION_PRODUCT:
        case Products.COUPON_PRODUCT:
            return yield call(restoreCouponProduct, product, payload, store);
        case SharedBetProducts.SHARED_BET_CREATE_PRODUCT:
        case SharedBetProducts.SHARED_BET_SHARE_PRODUCT:
            yield call(restorePurchaseParams, product, payload);
            yield call(restoreCouponProduct, product, payload, store);
            return undefined;
        default:
            return undefined;
    }
}

export default function* restorePurchaseSaga(store: Store<any, any>): SagaIterator<void> {
    // @ts-expect-error
    yield takeLatest(PurchaseActions.RESTORE_PRODUCT, restoreProduct, store);
}
