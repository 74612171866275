import type {SagaIterator} from "redux-saga";
import {findIndex} from "lodash";
import {call, put, select, debounce} from "redux-saga/effects";
import {RouterSelectors} from "@atg-shared/router";
import * as TableDisplayOptionsSelectors from "@atg-horse/results-table-displayoptions/components/displayOptions/tableDisplayOptionsSelectors";
import browserHistory from "atg-history";
import {
    buildDivisionGameUrl,
    getGameIdWithTrackNameFromPath,
} from "atg-horse-game/domain/gameUrl";
import * as GameActions from "atg-horse-game/domain/gameActions";

function* updateUrl({payload}: any): SagaIterator<void> {
    const {game, raceId, initiator} = payload;
    if (initiator === "scroll") {
        const raceIndex = findIndex(game.races, {id: raceId});
        const isViewingResults = yield select(
            TableDisplayOptionsSelectors.isViewingResults,
        );
        const currentPath = yield select(RouterSelectors.getPath);
        const url = yield call(buildDivisionGameUrl, {
            game,
            legNumber: raceIndex + 1,
            showResults: isViewingResults,
            useRaceNumberInsteadOfleg: game.type === "raket",
            addQuery: true,
        });
        if (
            getGameIdWithTrackNameFromPath(url) ===
            getGameIdWithTrackNameFromPath(currentPath)
        ) {
            yield call(browserHistory.replace, url);
            yield put(GameActions.changeGamePath(url));
        }
    }
}

export default function* selectRaceFlow() {
    yield debounce(100, GameActions.SELECT_RACE, updateUrl);
}
