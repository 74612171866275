import root from "window-or-global";
import * as Redux from "react-redux";
import * as React from "react";
import {memoize, flow} from "lodash";
import {isWeb} from "@atg-shared/system";
import log, {serializeError} from "@atg-shared/log";
import {pureFetch} from "@atg-shared/fetch";
import type {AtgResponse} from "@atg-shared/fetch-types";
import * as Storage from "@atg-shared/storage";
import {serverTime} from "@atg-shared/server-time";
import type {
    ExperienceConfig,
    ExperienceOptions,
    ExperienceReturnValue,
    ExperiencePayload,
    ExperienceResponse,
    GetVariationOptions,
    UseAbTestWithRenderPropsType,
    UseAbTestWithRenderPropsWithTimeoutType,
} from "@atg-global-shared/personalization-types";
import {nativeExperienceTriggered} from "./nativePersonalizationActions";
import type {QubitEnv} from "./config";
import {getQubitExperienceApiUrl} from "./config";

const getEnv = () => root.clientConfig?.qubit?.env ?? "dev";
export const env: QubitEnv = getEnv();
const getApiRoot = () => getQubitExperienceApiUrl(getEnv(), isWeb);
const CONTEXT_ID_COOKIE = "_qubitContextId";
const CONTEXT_ID_KEY = "contextId";
const STORAGE_TYPE = isWeb ? "session" : "local";

const prefixKey = (key: string) => `__qubit-${key}`;
const getItem = (key: string) => Storage.getItem(key, STORAGE_TYPE);
const getItemFromStorage = flow(prefixKey, getItem);

const getQubitContextId = () => {
    if (isWeb) {
        const qubitCookie = root.document.cookie
            .split("; ")
            .find((c) => c.includes(CONTEXT_ID_COOKIE))
            ?.split("=");

        return qubitCookie ? qubitCookie[1] : undefined;
    }
    const qubitContext = getItemFromStorage(CONTEXT_ID_KEY);
    return qubitContext ?? undefined;
};

const trackVariation = ({
    callback,
    id: experienceId,
    variation: variationId,
    payload,
}: ExperiencePayload) => {
    const event = {
        event: "qubit.experience",
        qubitExperimentId: experienceId,
        qubitVariationMasterId: variationId,
    };

    const disableTracking = payload?.disableTracking ?? false;
    if (!disableTracking) {
        // trackEvent in atg-analytics enforces a camel-case name, but to have old and native
        // experiences tracked side by side, we want to keep the event name as "qubit.experience"
        root.dataLayer.push(event);
    }

    pureFetch(callback, {method: "post"}).catch((error) => {
        log.error(`personalizationHooks::useNativeABTest: callback to Qubit failed`, {
            error: serializeError(error),
            experienceId,
        });
    });
};

/**
 * Fetch experience payload from Qubit
 */
export const getExperience = memoize(async (experienceId: number) => {
    const contextId = getQubitContextId();
    let url = `${getApiRoot()}?experienceIds=${experienceId}`;

    if (contextId !== undefined) {
        url += `&contextId=${contextId}`;
    }

    const variationOverride = JSON.parse(
        getItemFromStorage(`experience-${experienceId}`) ?? "null",
    );
    const ignoreSegments = getItemFromStorage("ignore-segments") === "true";

    if (variationOverride) {
        url += `&variation=${variationOverride}&preview`;
    }

    if (ignoreSegments) {
        url += `&ignoreSegments`;
    }

    const response = await pureFetch<ExperienceResponse>(url);

    if (contextId === undefined) {
        if (isWeb) {
            // In case a new user and the __qubitTracker cookie has not yet been set,
            // qubit returns a context id we can use
            const newContextId = response.data.contextId;
            const expiresAt = new Date();
            expiresAt.setFullYear(expiresAt.getFullYear() + 1);

            document.cookie = `${CONTEXT_ID_COOKIE}=${newContextId}; expires=${expiresAt.toUTCString()}; path=/`;
        } else {
            const newContextId = response.data.contextId;
            Storage.setItem(prefixKey(CONTEXT_ID_KEY), newContextId);
        }
    }

    if (response.data.experiencePayloads.length > 0) {
        trackVariation(response.data.experiencePayloads[0]);
    }

    return response;
});

/**
 * Given an experience payload (from the Qubit API) and a list of known variations for the
 * experience, return the index (0-based) for the active variation
 */
export const getVariationIndex = ({experience, variations}: GetVariationOptions) => {
    const variationIndex = variations.indexOf(experience.variation);
    if (variationIndex === -1) {
        log.warn(
            `personalizationHooks::getVariationIndex: mismatch in experience with config, defaulting to variationIndex 0. Make sure that variation ids in Qubit match the ones in the config`,
            {experienceId: experience.id, variationId: experience.variation},
        );

        // give up and fallback to variation 0 (the control)
        return 0;
    }
    return variationIndex;
};

function timeoutPromise<T>(promise: Promise<T>, timeout: number): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        promise.then(resolve).catch(reject);
        setTimeout(() => {
            reject(new Error("Timeout reached for experience"));
        }, timeout);
    });
}

/**
 * React hook for using native experiences. Returns the current variation and eventual payload
 * described in fields.json
 *
 * Note: this hook will always return `{variation: 0}` on the first render. If you want to
 * wait until Qubit has returned the correct variation before rendering anything, @see {useNativeABTestWithTimeout}
 * Note: the variation is index based, so instead of getting the variation id, you will get a number,
 * where `0` is the control. Therefore, the order of variation IDs in the config array is important.
 *
 * In general the A/B test tracking will start as soon as this hook is called. If you want to track
 * conditionally (e.g. only for small screens), use `options.enabled` to control when the test gets
 * triggered.
 * (Reminder: As per [Rules of Hooks](https://reactjs.org/docs/hooks-rules.html) hooks themselves
 * should never be called conditionally)
 *
 *
 * Example usage:
 *
 * ```js
 * const {variation, fields} = useNativeAbTest({
 *   dev: {experienceId: 200722, variations: [1237861, 1246234]},
 *   prod: {experienceId: 207232, variations: [1237123, 1234123]}
 * });
 * ```
 *
 * @see {useNativeABTestWithTimeout}
 *
 * @param {ExperienceConfig} config
 * @param {ExperienceOptions} overrideOptions
 * @param {boolean} overrideOptions.enabled - the experience (and tracking) will not run when this is false
 * @returns {ExperienceReturnValue}
 */
export function useNativeABTest(
    config: ExperienceConfig,
    overrideOptions?: ExperienceOptions,
): ExperienceReturnValue {
    const options = {enabled: true, ...overrideOptions};

    const dispatch = Redux.useDispatch();

    const [experienceData, setExperienceData] = React.useState<ExperienceReturnValue>({
        variation: 0,
    });

    React.useEffect(() => {
        let isMounted = true;

        async function fetchExperience() {
            const {experienceId, variations} = config[getEnv()];
            try {
                const {data} = await getExperience(experienceId);

                const [experience] = data.experiencePayloads;

                if (!experience) {
                    dispatch(
                        nativeExperienceTriggered({
                            // default to the first variation (typically the control)
                            variation: 0,
                            experienceId,
                        }),
                    );
                    return;
                }

                const mappedExperience = {
                    fields: experience.payload,
                    variation: getVariationIndex({experience, variations}),
                };

                // The component might have unmounted during the async `getExperience` call above.
                // If so there's no point updating the local state (and it would trigger a warning
                // message to the console).
                if (isMounted) setExperienceData(mappedExperience);

                dispatch(nativeExperienceTriggered({...mappedExperience, experienceId}));
            } catch (error: unknown) {
                // turn this error logging off during elitloppet weekend
                // https://jira-atg.riada.cloud/browse/LIVE-1393
                if (
                    serverTime().format("YYYY-MM-DD") !== "2023-05-27" &&
                    serverTime().format("YYYY-MM-DD") !== "2023-05-28"
                ) {
                    log.error(
                        `personalizationHooks:useNativeABTest: failed to fetch experience from Qubit`,
                        {
                            error: serializeError(error),
                            experienceId,
                        },
                    );
                }
            }
        }

        if (!options.enabled) return undefined;

        fetchExperience();

        return () => {
            isMounted = false;
        };
        // make sure we do not accidentaly put the hook in an endless loop if the config object
        // is passed in as `useNativeABTest({...})` for some reason
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options.enabled, dispatch]);

    return experienceData;
}

/**
 * Timeout based version of above hook. Will return undefined until Qubit has
 * decided which variation to show, OR when the specified timeout has passed.
 *
 * Note: second argument is optional, if no timeout is specified, will default to 5000ms
 *
 * In general the A/B test tracking will start as soon as this hook is called. If you want to track
 * conditionally (e.g. only for small screens), use `options.enabled` to control when the test gets
 * triggered.
 * (Reminder: As per [Rules of Hooks](https://reactjs.org/docs/hooks-rules.html) hooks themselves
 * should never be called conditionally)
 *
 * Example usage:
 *
 * ```js
 * const experience = useNativeABTestWithTimeout({
 *   dev: { experienceId: 200722, variations: [1237861, 1246234]},
 *   prod: { experienceId: 207232, variations: [1237123, 1234123]}
 * }, {timeout: 5000});
 *
 * if (!experience) {
 *   // still waiting, show spinner
 * } else {
 *   // got result OR timeout
 *   const { variation, fields } = experience
 * }
 * ```
 *
 * @see {useNativeABTest}
 *
 * @param {ExperienceConfig} config
 * @param {ExperienceOptions} overrideOptions
 * @param {boolean} overrideOptions.enabled - the experience (and tracking) will not run when this is false
 * @param {number} overrideOptions.timeout - optional, timeout defaults to 5000ms
 */
export function useNativeABTestWithTimeout(
    config: ExperienceConfig,
    overrideOptions?: ExperienceOptions & {
        timeout?: number;
    },
):
    | (ExperienceReturnValue & {
          experienceId?: number;
          getExperience?: typeof getExperience;
      })
    | null
    | undefined {
    const dispatch = Redux.useDispatch();
    const [experienceData, setExperienceData] = React.useState<
        ExperienceReturnValue | null | undefined
    >();
    const options = {enabled: true, timeout: 5000, ...overrideOptions};
    const {experienceId, variations} = config[getEnv()];
    React.useEffect(() => {
        let isMounted = true;
        async function fetchExperience() {
            try {
                const {data} = await timeoutPromise<AtgResponse<ExperienceResponse>>(
                    getExperience(experienceId),
                    options.timeout,
                );
                const [experience] = data.experiencePayloads;

                if (!experience) {
                    // The component might have unmounted during the async `getExperience` call above.
                    // If so there's no point updating the local state (and it would trigger a warning
                    // message to the console).
                    if (isMounted) setExperienceData({variation: 0});
                    dispatch(
                        nativeExperienceTriggered({
                            // default to the first variation (typically the control)
                            variation: 0,
                            experienceId,
                        }),
                    );
                    return;
                }

                const mappedExperience = {
                    fields: experience.payload,
                    variation: getVariationIndex({experience, variations}),
                };

                if (isMounted) setExperienceData(mappedExperience);
                dispatch(nativeExperienceTriggered({...mappedExperience, experienceId}));
            } catch (error: unknown) {
                if (serverTime().format("YYYY-MM-DD") !== "2022-12-31") {
                    log.error(
                        `personalizationHooks:useNativeABTestWithTimeout: failed to fetch experience from Qubit, falling back to variation 0`,
                        {
                            error: serializeError(error),
                            experienceId,
                        },
                    );
                }
                // default to the first variation (typically the control)
                if (isMounted) setExperienceData({variation: 0});
                dispatch(nativeExperienceTriggered({variation: 0, experienceId}));
            }
        }
        if (!options.enabled) {
            // default to the first variation (typically the control)
            if (isMounted) setExperienceData({variation: 0});
            return undefined;
        }

        fetchExperience();

        return () => {
            isMounted = false;
        };

        // make sure we do not accidentally put the hook in an endless loop if the config object
        // is passed in as `useNativeABTest({...})` for some reason
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options.timeout, options.enabled, dispatch]);

    return experienceData
        ? {
              ...experienceData,
              experienceId,
              getExperience,
          }
        : experienceData;
}

// This wrapper is required because new hooks useNativeABTest || useNativeABTestWithTimeout are not compatible with class component
export const UseAbTestWithRenderProps = (props: UseAbTestWithRenderPropsType) => {
    const experience = useNativeABTest(props.config, props.options);
    return props.children(experience);
};
export const UseAbTestWithRenderPropsWithTimeout = (
    props: UseAbTestWithRenderPropsWithTimeoutType,
) => {
    const experience = useNativeABTestWithTimeout(props.config, props.options);
    return props.children(experience);
};
