import {
    type QualityLevel,
    VIDEO_VOLUME_LOCAL_STORAGE_KEY,
} from "@atg/utils/player-util/types";
import {PlayerActionTypes} from "./actionconstants";
import type {PlayerPlayTargets} from "./actionconstants";

export interface SourceOptions {
    player?: string;
    playlist?: Array<string>;
    volume?: number;
    autoPlay: boolean;
    source: string;
}

export interface PlayAction {
    type: PlayerActionTypes.PLAYER_PLAY;
    payload: {
        player: string;
        target?: PlayerPlayTargets; // specific which user event that triggers this action
    };
}

export interface StartPlayerSourceAction {
    type: PlayerActionTypes.START_PLAYER_SOURCE;
    payload: {
        player: string;
    } & SourceOptions;
}

export interface ResetAutoplayAction {
    type: PlayerActionTypes.RESET_AUTOPLAY;
    payload: {
        player: string;
    };
}

interface ISetAirplayAvailable {
    player: string;
    isAirplayAvailable: boolean;
}

interface ISetAirplayCalled {
    player: string;
}

export function init(player: string, manifest: Record<string, unknown>) {
    return {
        type: PlayerActionTypes.PLAYER_INIT,
        payload: {
            player,
            ...manifest,
        },
    };
}

export function play(player: string, target?: PlayerPlayTargets): PlayAction {
    return {
        type: PlayerActionTypes.PLAYER_PLAY,
        payload: {
            player,
            target,
        },
    };
}

export function pause(player: string) {
    return {
        type: PlayerActionTypes.PLAYER_PAUSE,
        payload: {
            player,
        },
    };
}

export function checkAvailablePlace(player: string, availablePlace: boolean) {
    return {
        type: PlayerActionTypes.CHECK_AVAILABLE_PLACE,
        payload: {
            player,
            availablePlace,
        },
    };
}

export function trackCompleted(player: string) {
    return {
        type: PlayerActionTypes.PLAYER_TRACK_COMPLETED,
        payload: {
            player,
        },
    };
}

export function setQualityLevel(player: string, qualityLevel?: QualityLevel) {
    return {
        type: PlayerActionTypes.SET_QUALITY_LEVEL,
        payload: {
            player,
            qualityLevel,
        },
    };
}

export function setIsBuffering(player: string, isBuffering: boolean) {
    return {
        type: PlayerActionTypes.SET_IS_BUFFERING,
        payload: {
            player,
            isBuffering,
        },
    };
}

export function setLowLatencyChromeCastAvailable(
    player: string,
    chromeCastAvailable: boolean,
) {
    return {
        type: PlayerActionTypes.LOW_LATENCY_CHROME_CAST_AVAILABLE,
        payload: {
            player,
            chromeCastAvailable,
        },
    };
}

export function changeVolume(player: string, volume: number) {
    if (volume !== 0) {
        localStorage.setItem(VIDEO_VOLUME_LOCAL_STORAGE_KEY, volume.toString());
    }
    return {
        type: PlayerActionTypes.PLAYER_CHANGE_VOLUME,
        payload: {
            player,
            volume,
        },
    };
}

export function unmute(player: string) {
    const volume = Number(localStorage.getItem(VIDEO_VOLUME_LOCAL_STORAGE_KEY)) || 1;
    return {
        type: PlayerActionTypes.PLAYER_UNMUTE,
        payload: {
            player,
            volume,
        },
    };
}

export function mute(player: string) {
    return {
        type: PlayerActionTypes.PLAYER_MUTE,
        payload: {
            player,
        },
    };
}

export function source(player: string, options: SourceOptions) {
    return {
        type: PlayerActionTypes.PLAYER_SOURCE,
        payload: {
            player,
            ...options,
        },
    };
}

export const setPlayerSourceUrlOnly = (player: string, url: string) => ({
    type: PlayerActionTypes.SET_PLAYER_SOURCE_URL,
    payload: {
        player,
        url,
    },
});

/**
 * (newHorseLiveView-remove)
 */
export function startSource(player: string, options: SourceOptions) {
    return {
        type: PlayerActionTypes.START_PLAYER_SOURCE,
        payload: {
            player,
            ...options,
        },
    };
}

export function resetAutoplay(player: string) {
    return {
        type: PlayerActionTypes.RESET_AUTOPLAY,
        payload: {
            player,
        },
    };
}

export function startMediaSrouce(autoPlay: boolean, mediaType: string) {
    return {
        type: PlayerActionTypes.START_PLAYER_MEDIA_SOURCE,
        payload: {
            autoPlay,
            mediaType,
        },
    };
}

export function previous(player: string, position?: number) {
    return {
        type: PlayerActionTypes.PLAYER_PREVIOUS_TRACK,
        payload: {
            player,
            position,
        },
    };
}

export function next(player: string, position?: number) {
    return {
        type: PlayerActionTypes.PLAYER_NEXT_TRACK,
        payload: {
            player,
            position,
        },
    };
}

export function enterFullscreen(player: string) {
    return {
        type: PlayerActionTypes.VIDEO_ENTER_FULLSCREEN,
        payload: {
            player,
        },
    };
}

export function requestFullscreen(player: string, isRequestFullscreen: boolean) {
    return {
        type: PlayerActionTypes.REQUEST_FULLSCREEN,
        payload: {
            player,
            requestFullscreen: isRequestFullscreen,
        },
    };
}

export function exitFullscreen(player: string) {
    return {
        type: PlayerActionTypes.VIDEO_EXIT_FULLSCREEN,
        payload: {
            player,
        },
    };
}

/**
 * (newHorseLiveView-remove)
 * TODO check if possible
 */
export function toggleFullscreen(player: string) {
    return {
        type: PlayerActionTypes.PLAYER_TOGGLE_FULLSCREEN,
        payload: {
            player,
        },
    };
}

export function destroy(player: string) {
    return {
        type: PlayerActionTypes.PLAYER_DESTROY,
        payload: {
            player,
        },
    };
}

export function resetPlaylistPos(player: string) {
    return {
        type: PlayerActionTypes.RESET_PLAYLIST_POS,
        payload: {
            player,
        },
    };
}

export const setIsAirPlayAvailable = ({
    player,
    isAirplayAvailable,
}: ISetAirplayAvailable) => ({
    type: PlayerActionTypes.SET_IS_AIRPLAY_AVAILABLE,
    payload: {
        player,
        isAirplayAvailable,
    },
});

export const setIsAirplayCalled = ({player}: ISetAirplayCalled) => ({
    type: PlayerActionTypes.SET_IS_AIRPLAY_CALLED,
    payload: {
        player,
    },
});

export function deactivateAudioOnly(player: string) {
    return {
        type: PlayerActionTypes.DEACTIVATE_AUDIO_ONLY,
        payload: {
            player,
        },
    };
}

/**
 * Actions for setting videosettings current state in the controlbar
 */
export function showVideoSettings(player: string, active?: boolean) {
    return {
        type: PlayerActionTypes.SHOW_VIDEO_SETTINGS,
        payload: {
            player,
            active,
        },
    };
}

/**
 * Actions for setting radar menu current state in the controlbar
 */
export function openRadarMenu(player: string) {
    return {
        type: PlayerActionTypes.OPEN_RADAR_MENU,
        payload: {
            player,
        },
    };
}

export function closeRadarMenu(player: string) {
    return {
        type: PlayerActionTypes.CLOSE_RADAR_MENU,
        payload: {
            player,
        },
    };
}
