import {call, fork, put, select, take, takeLatest} from "redux-saga/effects";
import {has, isEmpty} from "lodash";
import type {SagaIterator} from "redux-saga";
import {getObjectDifferences} from "@atg/utils";
import {AtgAlertTypes} from "@atg-global-shared/alerts-types";
import * as UserActions from "@atg-global-shared/user/userActions";
import {SmsRecoveryActions} from "@atg-global-shared/sms-recovery-data-access";
import type {SaveContactOptionsPayload} from "@atg-payment-shared/account-settings-types";
import {t} from "@lingui/macro";
import * as ModalActions from "atg-modals/modalActions";
import * as ModalSelectors from "atg-modals/modalSelectors";
import * as ToastActions from "atg-ui-toast/domain/toastActions";
import * as AccountSettingsActions from "../accountSettingsActions";
import * as AccountSettingsApi from "../accountSettingsApi";
import * as AccountSettingsSelectors from "../accountSettingsSelectors";

export const getErrorMessage = (error: Record<string, any>): string => {
    switch (error.response.meta.code) {
        case 400:
            return t`Ogiltig information i något av fälten.`;
        case 401:
            return t`Fel lösenord.`;
        default:
            return t`Ett okänt tekniskt fel har uppstått.`;
    }
};

export function* closeModal(): SagaIterator {
    const isEditMobileModalOpen = yield select(
        ModalSelectors.isAccountSettingsMobileModalOpen,
    );
    if (isEditMobileModalOpen) {
        yield put(ModalActions.closeEditMobileNumberModal());
        return;
    }

    const isEditEmailModalOpen = yield select(
        ModalSelectors.isAccountSettingsEmailModalOpen,
    );
    if (isEditEmailModalOpen) {
        yield put(ModalActions.closeEditEmailModal());
    }
}

export function* successToast() {
    yield put(
        ToastActions.showToast({
            type: AtgAlertTypes.SUCCESS,
            message: t`Dina inställningar är uppdaterade.`,
        }),
    );
}

export function* errorToast() {
    yield put(
        ToastActions.showToast({
            type: AtgAlertTypes.DANGER,
            message: t`Ett fel uppstod.`,
        }),
    );
}

export function* accountSettingsEditContactInfoFlow({
    payload,
}: AccountSettingsActions.EditContactInfoAction) {
    const {type} = payload;

    if (!type) return;

    switch (type) {
        case AccountSettingsActions.EDIT_EMAIL:
            yield put(ModalActions.showEditEmailModal());
            break;
        case AccountSettingsActions.EDIT_PHONE_NUMBER:
            yield put(ModalActions.showEditMobileNumberModal());
            break;
        default:
            break;
    }
}

export function* editContactInfoFlow(
    action: AccountSettingsActions.SetContactInfoAction,
) {
    if ("error" in action && action.error) return;

    yield put(UserActions.fetchUser());

    yield call(closeModal);
    yield call(successToast);
}

export function* editCommunicationOptionsFlow({
    payload,
}: AccountSettingsActions.EditCommunicationOptionsAction): SagaIterator {
    const oldContactOptions = yield select(AccountSettingsSelectors.getContactOptions);
    const diff = getObjectDifferences(oldContactOptions, payload, "id");

    if (!isEmpty(diff)) {
        let diffFormatted: SaveContactOptionsPayload;
        if (has(diff, "SHOP_SHARES")) {
            const {SHOP_SHARES: shopContactOption, ...otherContactOptions} = diff;
            diffFormatted = {
                ...otherContactOptions,
                shopShare: shopContactOption.EMAIL.optIn,
            };
        } else {
            diffFormatted = diff;
        }

        const {ok} = yield call(AccountSettingsApi.saveUserContactOptions, diffFormatted);
        if (ok) {
            yield put(AccountSettingsActions.editCommunicationOptionsSuccess(payload));
        } else {
            yield put(AccountSettingsActions.editCommunicationOptionsFailure());
            yield call(errorToast);
        }
    }
}

export function* editCommunicationSettings(
    action: AccountSettingsActions.EditCommunicationOptionsAction,
): SagaIterator {
    yield fork(editCommunicationOptionsFlow, action);
    yield take(AccountSettingsActions.COMMUNICATION_OPTIONS_EDIT_SETTINGS_SUCCESS);
    yield call(successToast);
}

function* handleSmsRecoveryEditResponse() {
    const action:
        | SmsRecoveryActions.SmsRecoverySavedAnswerResponseAction
        | SmsRecoveryActions.SmsRecoveryDeletedAnswerResponseAction = yield take([
        SmsRecoveryActions.SMS_RECOVERY_SAVE_RESPONSE,
        SmsRecoveryActions.SMS_RECOVERY_DELETE_RESPONSE,
    ]);
    if (!action.error) {
        yield call(successToast);
    }
}

export default function* accountSettingsSaga() {
    yield takeLatest(
        AccountSettingsActions.ACCOUNT_SETTINGS_EDIT_CONTACT_INFO,
        accountSettingsEditContactInfoFlow,
    );

    yield takeLatest(
        [AccountSettingsActions.COMMUNICATION_OPTIONS_EDIT_SETTINGS],
        editCommunicationSettings,
    );

    yield takeLatest(
        AccountSettingsActions.SET_CONTACT_INFO_RECEIVE,
        editContactInfoFlow,
    );

    yield takeLatest(
        [
            SmsRecoveryActions.SMS_RECOVERY_SAVE_REQUEST,
            SmsRecoveryActions.SMS_RECOVERY_DELETE_REQUEST,
        ],
        handleSmsRecoveryEditResponse,
    );
}
