import log from "@atg-shared/log";
import {slugifyNoDash} from "@atg/utils/strings";
import {getGameTypeByGameId, parseGameId} from "@atg-horse-shared/utils/gameid";
import type {GameType} from "@atg-horse-shared/game-types";
import {gameDefs, SINGLE_RACE_GAME_URL} from "@atg-horse-shared/game-defs";
import * as Track from "@atg-horse-shared/utils/track";
import type {CalendarGame, CalendarDay} from "./calendar";
import * as Calendar from "./calendar";

export function getGameSpecificSlug(day: CalendarDay, game: CalendarGame): string | null {
    const gameType = getGameTypeByGameId(game?.id || null);
    if (!gameType) return null;

    const {urlType} = gameDefs[gameType];
    if (urlType !== SINGLE_RACE_GAME_URL) return null;

    const track = Calendar.findTrackById(day, game.tracks[0]);
    if (!track) {
        const {id} = game;
        log.error("Failed to build game url slug. Couldn't find track for game", {
            gameId: id,
        });
        return "";
    }

    const race = Track.findRaceById(track, game.races[0]);
    if (!race) {
        log.error("Failed to build game url slug. Couldn't find race", {
            firstGameRace: game.races[0],
        });
        return "";
    }

    return `lopp/${race.number}`;
}

export const getTrackSlug = (day: CalendarDay, trackId: number): string => {
    const track = Calendar.findTrackById(day, trackId);
    if (!track) {
        log.error("Failed to build game url, Couldn't find track by id", {trackId});
        return "";
    }
    return slugifyNoDash(track.name);
};

export function getRaceUrlFromDay(
    day: CalendarDay,
    raceId: string,
    gameType: GameType,
): string {
    const calendarGame = Calendar.findGameById(day, `${gameType}_${raceId}`);
    if (!calendarGame) {
        log.error("Failed to build game url. Couldn't find game by id", {
            gameType,
            raceId,
        });
        return "";
    }
    const parsedGame = parseGameId(calendarGame.id);
    if (!parsedGame) {
        log.error("Failed to build game url. Couldn't parse gameId", {
            gameId: calendarGame.id,
            gameType,
            raceId,
        });
        return "";
    }
    const {date} = parsedGame;

    const trackSlug = calendarGame.tracks
        .map((track) => getTrackSlug(day, track))
        .join("-");
    const gameSpecifics = getGameSpecificSlug(day, calendarGame);

    return `/spel/${date}/${gameType}/${trackSlug}${
        gameSpecifics ? `/${gameSpecifics}` : ""
    }`;
}

export function buildFriendlyDivisionGameUrl(
    day: CalendarDay,
    game: CalendarGame,
): string {
    if (!game.id) return "";
    const splitId = game.id.split("_");
    const trackSlug = game.tracks.map((track) => getTrackSlug(day, track)).join("-");
    return `/spel/${splitId[1]}/${splitId[0]}/${trackSlug}`;
}
