import fetch from "@atg-shared/fetch";
import {FETCH, call} from "@atg-shared/fetch-redux";
import type {FetchAction} from "@atg-shared/fetch-types";
import {PRODUCT_SERVICE_URL, SIMPLE_RESULTS_URL} from "@atg-shared/service-url";
import type {GameSchedule, GameResult} from "./product";
import type {State} from "./index";

export const REQUEST_GAME_SCHEDULE = "product/REQUEST_GAME_SCHEDULE";
export const RECEIVE_GAME_SCHEDULE = "product/RECEIVE_GAME_SCHEDULE";
export const REQUEST_GAME_RESULTS = "product/REQUEST_GAME_RESULTS";
export const RECEIVE_GAME_RESULTS = "product/RECEIVE_GAME_RESULTS";
export const RESET_STATUS = "product/RESET_STATUS";

type Context = {
    gameType: string;
};

type GameResultResponse = {
    gameResults: Array<GameResult>;
};

export type GameScheduleFetchAction = FetchAction<
    typeof REQUEST_GAME_SCHEDULE,
    typeof RECEIVE_GAME_SCHEDULE,
    GameSchedule,
    State,
    Context
>;

export type GameResultsFetchAction = FetchAction<
    typeof REQUEST_GAME_RESULTS,
    typeof RECEIVE_GAME_RESULTS,
    GameResultResponse,
    State,
    Context
>;

export type Action = GameScheduleFetchAction | GameResultsFetchAction;

export const fetchGameSchedule = (gameType: string): GameScheduleFetchAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_GAME_SCHEDULE,
        receiveAction: RECEIVE_GAME_SCHEDULE,
        callApi: call(fetch, `${PRODUCT_SERVICE_URL}/${gameType}`),
        context: {
            gameType,
        },
    },
});

export const fetchGameResults = (gameType: string): GameResultsFetchAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_GAME_RESULTS,
        receiveAction: RECEIVE_GAME_RESULTS,
        callApi: call(fetch, `${SIMPLE_RESULTS_URL}/${gameType}`),
        context: {
            gameType,
        },
    },
});
