import * as React from "react";

type Props = {
    tableProps: {
        [key: string]: any;
    };
    index: number;
};

function HarryStatusColumnView({tableProps, index}: Props) {
    const {races} = tableProps.bet;

    const getHarryState = () => {
        const gameType = tableProps.bet.game.type;

        if (gameType === "komb" || gameType === "trio") {
            const harryOpenProperties = [
                "firstPlaceHarryOpen",
                "secondPlaceHarryOpen",
                "thirdPlaceHarryOpen",
            ];
            const race = races[0];
            const harryProperty = harryOpenProperties[index];
            return race[harryProperty];
        }

        const division = races[index];
        return division.harryOpen;
    };

    const harryState = getHarryState();
    const classes = ["harry-open-col", harryState ? "open" : "closed"].join(" ");
    const stateText = harryState ? "Öppen" : "Stängd";

    return <td className={classes}>{stateText}</td>;
}

export default HarryStatusColumnView;
