import {LOGOUT_FINISHED} from "@atg-global-shared/user/userActionTypes";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {RECEIVE_LEGACY_BET_SUMMARIES, SET_LIVE_BETS_FILTER} from "@atg-horse/horse-bet";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {BetFilters, PLAYED_BET} from "@atg-horse/horse-bet/src/redux/betactions";
import {RECEIVE_HBH_LIVE_BETS, REQUEST_HBH_LIVE_BETS} from "../liveactions";
import type {BetReceipts} from "./horseBetHistoryInterfaces";

export type LiveBetsState = {
    filter: BetFilters;
    hideButton?: boolean;
    bets: BetReceipts;
    shouldFetchHBHLiveBets?: boolean;
};
export function liveBets(
    state: LiveBetsState = {filter: BetFilters.CURRENT, bets: []},
    action: {
        type: string;
        payload: string;
        error: boolean;
        shouldFetchHBHReceipts?: boolean;
    },
) {
    const {payload, error} = action;
    switch (action.type) {
        case RECEIVE_LEGACY_BET_SUMMARIES:
            return {
                ...state,
                hideButton: !error,
            };
        case LOGOUT_FINISHED:
            return {
                ...state,
                hideButton: false,
            };
        case SET_LIVE_BETS_FILTER:
            return {
                ...state,
                filter: payload,
            };
        case RECEIVE_HBH_LIVE_BETS:
            if (!action.payload) return state;
            return {
                ...state,
                bets: action.payload,
            };
        case REQUEST_HBH_LIVE_BETS:
            return {
                ...state,
                shouldFetchHBHLiveBets: false,
            };
        case PLAYED_BET:
            return {
                ...state,
                shouldFetchHBHLiveBets: true,
            };

        default:
            return state;
    }
}
