import {flow} from "lodash/fp";
import atgRequest from "@atg-shared/fetch";
import {fetchAuthorized} from "@atg-shared/auth";
import {
    USER_SERVICE_URL,
    BET_HISTORY_GRADING,
    BET_SERVICE_URL,
    VARENNE_COUPON_TEAMS_URL,
} from "@atg-shared/service-url";
import {isDevice} from "@atg/utils/device";
import {get409ErrorMessage} from "@atg-shared/response-mapping/get409ErrorMessage";
import {SharedBetUtils} from "@atg-tillsammans-shared/shared-bet-utils";
import {
    BetMethod,
    type BaseBet,
    type BetGradingResponse,
    type ReceiptRace,
} from "@atg-horse-shared/bet-types";
import {getErrorMessageFromResponse} from "@atg-horse/error-messages";
import {getStatusFromResponse} from "@atg-shared/response-mapping/deprecated_loadingStatus";
import type {Game} from "@atg-horse-shared/racing-info-api/game/types";
import type {ReducedCoupon} from "@atg-horse-shared/coupon-types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {CouponUtils} from "@atg-horse-shared/coupon";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    createBatchBetApiBody,
    type ReductionTerms,
    type ReducedBatchBetResponse,
    type ReducedBatchBetJobResponse,
} from "@atg-horse/reduced-bets";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {get400ShopErrorMessage} from "@atg-shop-shared/purchase-redux/src/errorResponse";

export interface IReceipt {
    id: string;
    races?: Array<ReceiptRace>;
    game: Game;
    cost: number;
    timestamp: string;
}
export interface IReceiptResponse {
    data: IReceipt;
}

/**
 * Forward device data (mobile/desktop) through the bet API to spelsystemet. Sent as integer as
 * storage space in spelsystemet is limited.
 * NOTE: From the perspective of the `/user/bets` endpoint, we can send any (small) integer. The
 * parsing is handled later in spelsystemet.
 * ref: [HRS1-53]
 */
const addDeviceType = (bet: any) => ({...bet, deviceType: isDevice() ? 1 : 2});

const BETS_URL = `${USER_SERVICE_URL}/bets`;
const BAGS_URL = `${USER_SERVICE_URL}/bets/harry/bags`;
const SUBSCRIPTIONS_URL = `${USER_SERVICE_URL}/subscriptions/harry`;

// New batch bet endpoint `/api/v1/batchbetting/reduce/coupon`
// Swagger: https://qa-batch-betting.dev.horsedigital.aws.atg.se/webjars/swagger-ui/index.html?configUrl=/v3/api-docs/swagger-config#/
const REDUCED_BETS_URL =
    "/services/tokenized-proxy/horse-batch-betting/api/v1/reduce/bet";

const BATCH_BETS_JOB_URL = "/services/tokenized-proxy/horse-batch-betting/api/v1/jobs";

export type Headers = {
    token: string;
    Authorization?: string;
};

export const purchaseBet = (
    bet: {
        [key: string]: any;
    },
    token: string,
    isNewBettingSystem = false,
) => {
    const body = flow(
        CouponUtils.prepareBetForSave,
        CouponUtils.prepareForSave,
        addDeviceType,
    )(bet);

    /**
     * Note:
     * This feature is for temporary testing the new endpoint for
     * placing coupon team (aniston) bets via varenne. The way forward
     * for coupon team bets is to move it to a separate purchase flow managed
     * within the shop domain.
     * Reference: https://jira-atg.riada.cloud/browse/SHOPS-2734
     */
    if (isNewBettingSystem) {
        if (
            SharedBetUtils.isNotSingleRaceSharedBetGameType(bet.game.type) &&
            Array.isArray(bet.races)
        ) {
            bet.races.forEach((race: any, raceIndex: number) => {
                if (race?.bets?.length === 1) {
                    body.races[raceIndex].bankerPickHorse = {
                        number: race.bets[0].number,
                        name: race.bets[0].horse.name,
                    };
                }
            });
        }
        return fetchAuthorized<BaseBet>(`${VARENNE_COUPON_TEAMS_URL}/bet`, {
            method: "POST",
            headers: {token},
            body: JSON.stringify(body),
        });
    }

    return atgRequest<BaseBet>(BETS_URL, {
        method: "POST",
        headers: {token},
        body: JSON.stringify(body),
    });
};

export const purchaseReducedBetLegacy = (
    coupon: ReducedCoupon,
    reductionTerms: ReductionTerms,
    game: Game,
    returnToPlayer: number | null | undefined,
    token: string,
) =>
    atgRequest<ReducedBatchBetResponse>(REDUCED_BETS_URL, {
        method: "POST",
        headers: {token},
        body: flow(
            createBatchBetApiBody(reductionTerms, game, returnToPlayer),
            CouponUtils.prepareForSave,
            addDeviceType,
            JSON.stringify,
        )(coupon),
    });

export const getReducedBetBatchBettingJob = (batchBettingJobId: string) =>
    atgRequest<ReducedBatchBetJobResponse>(`${BATCH_BETS_JOB_URL}/${batchBettingJobId}`, {
        method: "GET",
    });

export const fetchReceiptResult = (betId: string) =>
    fetchAuthorized<IReceiptResponse>(
        `${USER_SERVICE_URL}/bets/${betId}?forceBetHistory=true&check=true`, // forceBetHistory flag tells backend it is ok to fetch from the horse bet history
    );

export const fetchBetByCode = (betCode: string) =>
    atgRequest<BaseBet>(`${BET_SERVICE_URL}/${betCode}?forceBetHistory=true`, {
        method: "GET",
    });

/**
 * @param betId without date. Ex: 0D04111110354400
 */
export const fetchReceiptResultNewApi = (betId: string) =>
    fetchAuthorized<BetGradingResponse>(`${BET_HISTORY_GRADING}/bets?betId=${betId}`);

export const purchaseHarryBag = (
    bag: {
        [key: string]: any;
    },
    token: string,
) =>
    fetchAuthorized(BAGS_URL, {
        method: "POST",
        headers: {token},
        body: JSON.stringify(addDeviceType(bag)),
    });

export const purchaseSubscription = (
    subscription: {
        [key: string]: any;
    },
    token: string,
) =>
    fetchAuthorized(SUBSCRIPTIONS_URL, {
        method: "POST",
        headers: {token},
        body: JSON.stringify({
            ...subscription,
        }),
    });

/**
 * This function translates the response from the backend into a message to be displayed for the customer
 * [Spec] https://confluence-atg.riada.cloud/display/HDDev/Error+responses+in+frontend-facing+applications
 * Note:
 * Depending on isReducedBet this funciton can return two different types of errors:
 * if isReducedBet === false it returns an error object that is then handled on Confirm step/modal
 * if isReducedBet === true it returns a react component (or string) that is then handled on Receipt step/modal
 */
export const getBetResponseStatus = (
    response: any,
    isReducedBet = false,
    isVarenneSharedBetCoupon: boolean,
    betMethod: BetMethod,
) => {
    if (!isReducedBet) {
        if (isVarenneSharedBetCoupon && betMethod === BetMethod.Harry) {
            return getStatusFromResponse(response, {
                "400": get400ShopErrorMessage(response),
            });
        }
        return getStatusFromResponse(response, {
            "0": "Anslutningen till servern bröts. För att kontrollera status på den senaste transaktionen se Mina pengar > Kontohistorik.",
            "401": "Fel lösenord.",
            "406": "Din insats överskrider ditt valda maxbelopp.",
            "409": get409ErrorMessage(response),
            "423": "Du har överskridit max speltid utan paus.",
            "503": "ATG har stängt för spel. Försök igen senare.",
        });
    }
    return getErrorMessageFromResponse(response);
};

export const BatchBetErrorMessage = {
    FAILED: "Ett tekniskt fel uppstod. Vad god kontrollera dina hästkvitton och försök igen",
    MISSING:
        "Ett tekniskt fel uppstod. Vad god kontrollera dina hästkvitton och försök igen",
    REJECTED:
        "Ditt spel gick inte igenom. Vad god kontrollera dina hästkvitton och försök igen",
    DONE: "Kunde inte skapa ett kvitto för ditt spel. Var god kontrollera dina hästkvitton för att se om spelet gått igenom",
    TIME_OUT:
        "Tiden för spelläggning nådde en maxgräns. Var god kontrollera dina hästkvitton för att se om spelet gått igenom",
};

const bet = "";
