import {isEmpty, map} from "lodash";
import * as React from "react";
import {type GameType, GameTypes} from "@atg-horse-shared/game-types";

type HorsesProps = {
    tableProps: {
        hideWinMark?: boolean;
        emptyHorsePicksMessage: string;
        receipt?: any;
    };
    separateWith: string;
    showEmptyMessage: boolean;
    horses: {
        [key: string]: any;
    };
    column: {
        [key: string]: any;
    };
    isTableCell: boolean;
    prefix: any;
    showScratched: boolean;
    showResults: boolean;
    data?: {
        columns?: any;
    };
    isCancelled?: boolean;
};

export type Pick = {
    number: number;
    name: string;
    nationality: string;
    win: boolean;
    tempWin: boolean;
    placementCorrect: boolean;
    moved: boolean;
    given: boolean;
    scratched: boolean;
    isReserve: boolean;
    isUndecided: boolean;
    banker: boolean;
};

type PickProps = {
    pick: Pick;
    showScratched: boolean;
    showResults: boolean;
    hideWinMark?: boolean;
    separator?: string;
    greenCircleWhenCancelled?: boolean;
};

export const UNDECIDED_LABEL = "(R)";

function HorseNumber({
    pick,
    showScratched,
    showResults,
    hideWinMark,
    greenCircleWhenCancelled,
}: PickProps) {
    const classNames = [
        pick.scratched && showScratched && "receipt__scratched",
        pick.tempWin && "receipt__temp-win",
        pick.given && showScratched && "receipt__given",
        pick.moved && showScratched && "receipt__moved",
        showResults &&
            (pick.win || greenCircleWhenCancelled) &&
            !hideWinMark &&
            "receipt__win",
        (showResults && pick.isReserve) || (pick.isUndecided && "receipt__reserve"),
        showResults && pick.placementCorrect && !pick.moved && "receipt__correct-place",
    ]
        .filter(Boolean)
        .join(" ");

    const label = pick.isUndecided ? UNDECIDED_LABEL : pick.number;

    return (
        <span
            className={classNames}
            data-test-id={`horse-number${
                showResults && pick.win && !hideWinMark ? "-win" : ""
            }`}
        >
            {label}
        </span>
    );
}

function HorsePick({
    pick,
    showScratched,
    showResults,
    hideWinMark,
    separator,
    greenCircleWhenCancelled,
}: PickProps) {
    return (
        <span className="receipt__pick">
            <HorseNumber
                pick={pick}
                showScratched={showScratched}
                showResults={showResults}
                hideWinMark={hideWinMark}
                greenCircleWhenCancelled={greenCircleWhenCancelled}
            />
            {separator}
        </span>
    );
}

// TODO: remove this component completely when ReNo is out, since reserves won't have any
// additional information like tooltip
function ReservePick({
    pick,
    showScratched,
    showResults,
    hideWinMark,
    separator,
    greenCircleWhenCancelled,
}: PickProps) {
    const tooltipClasses = [
        "reserve-tooltip",
        !pick.win && "reserve-tooltip--normal",
        !pick.win && pick.number > 9 && "reserve-tooltip--normal--high",
        pick.win && "reserve-tooltip--win",
    ]
        .filter(Boolean)
        .join(" ");

    // TODO: remove this when ReNo is out
    const reserveText = pick.given ? "Reserv från turordningen" : "Förvald reserv";

    return (
        <span className="receipt__pick">
            <div className={tooltipClasses}>
                <div>{reserveText}</div>
            </div>

            <HorseNumber
                pick={pick}
                showScratched={showScratched}
                showResults={showResults}
                hideWinMark={hideWinMark}
                greenCircleWhenCancelled={greenCircleWhenCancelled}
            />
            {separator}
        </span>
    );
}

function BankerPick({
    pick,
    showScratched,
    showResults,
    hideWinMark,
    greenCircleWhenCancelled,
}: PickProps) {
    const classNames = [
        "receipt__pick",
        pick.scratched && showScratched && "receipt__scratched-banker",
        pick.moved && showScratched && "receipt__moved-banker",
    ]
        .filter(Boolean)
        .join(" ");

    return (
        <span className={classNames}>
            <HorseNumber
                pick={pick}
                showScratched={showScratched}
                showResults={showResults}
                hideWinMark={hideWinMark}
                greenCircleWhenCancelled={greenCircleWhenCancelled}
            />
            <span className="receipt__banker-name" data-test-id="horse-name-banker">
                <span className="space-right" data-test-id="horse-name-text">
                    {pick.name}
                </span>
                {pick.nationality}
            </span>
        </span>
    );
}

const shouldHaveGreenCircleWhenCancelled = (isCancelled: boolean, betType: GameType) => {
    switch (betType) {
        case GameTypes.GS75:
        case GameTypes.V75:
        case GameTypes.V65:
        case GameTypes.V64:
        case GameTypes.V86:
        case GameTypes.V5:
        case GameTypes.V4:
        case GameTypes.V3:
        case GameTypes.dd:
        case GameTypes.ld:
            if (isCancelled) {
                return true;
            }

            return false;
        default:
            return false;
    }
};

class Horses extends React.Component<HorsesProps> {
    /* eslint-disable-next-line react/static-property-placement */
    static defaultProps = {
        isTableCell: true,
        prefix: null,
        separateWith: ",",
    };

    /* eslint-disable-next-line react/sort-comp */
    renderPick = (pick: Pick, index: number, picksData: Array<any>) => {
        if (!pick) return null;

        const {
            isCancelled,
            showScratched,
            showResults,
            separateWith,
            tableProps = {},
        }: any = this.props;
        const {hideWinMark} = tableProps;
        const betType = tableProps.receipt?.bet.betType;
        const isLast = index === picksData.length - 1;
        const separator = isLast ? "" : separateWith;
        const greenCircleWhenCancelled = shouldHaveGreenCircleWhenCancelled(
            isCancelled,
            betType,
        );

        if (pick.isReserve) {
            return (
                <ReservePick
                    key={index}
                    pick={pick}
                    showResults={showResults}
                    showScratched={showScratched}
                    hideWinMark={hideWinMark}
                    separator={separator}
                    greenCircleWhenCancelled={greenCircleWhenCancelled}
                />
            );
        }

        if (pick.banker) {
            return (
                <BankerPick
                    key="banker"
                    pick={pick}
                    showResults={showResults}
                    showScratched={showScratched}
                    hideWinMark={hideWinMark}
                    greenCircleWhenCancelled={greenCircleWhenCancelled}
                />
            );
        }

        return (
            <HorsePick
                key={index}
                pick={pick}
                showResults={showResults}
                showScratched={showScratched}
                hideWinMark={hideWinMark}
                separator={separator}
                greenCircleWhenCancelled={greenCircleWhenCancelled}
            />
        );
    };

    getPicks() {
        const {horses, showEmptyMessage, tableProps} = this.props;

        if (isEmpty(horses.picks) && showEmptyMessage) {
            return (
                <div className="receipt__no-picks">
                    {tableProps.emptyHorsePicksMessage}
                </div>
            );
        }

        return map<any, React.ReactNode>(horses.picks, this.renderPick);
    }

    render() {
        const {prefix, column, isTableCell, horses, data} = this.props;

        const columnsLength =
            (data && data.columns && Object.keys(data.columns).length) || 0;
        const shouldShortenHorseName =
            columnsLength > 3 && horses.picks && horses.picks.length === 1;

        const horseNameClasses = shouldShortenHorseName
            ? ["receipt__horses", "receipt__horses-short-name"].join(" ")
            : "receipt__horses";

        const horsesColumn = (
            <div className={horseNameClasses} data-test-id="receipt-horses">
                {prefix}
                {this.getPicks()}
            </div>
        );

        if (!isTableCell) return horsesColumn;

        const cellClasses = column.id && `${column.id}-col`;

        return (
            <td className={cellClasses} data-test-id={`${column.id}-col`}>
                {horsesColumn}
            </td>
        );
    }
}

export default Horses;
