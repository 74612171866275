import {
    SHOP_SHARE_SERVICE_URL,
    USER_SERVICE_URL,
    SHOP_SHARE_SERVICE_URL_TOKENIZED,
} from "@atg-shared/service-url";
import {fetchAuthorized} from "@atg-shared/auth";
import type {
    DetailedBet,
    BetAction as Bet,
    BetResponse,
} from "@atg-horse-shared/bet-types";
import {FETCH, call} from "@atg-shared/fetch-redux";
import {type FetchAction} from "@atg-shared/fetch-types";
import type {LogoutFinishedAction} from "@atg-global-shared/user/userActions";
import {
    getStatusFromResponse,
    type Response as HttpResponse,
} from "@atg-shared/response-mapping/deprecated_loadingStatus";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {PlaceReducedBetResponse} from "@atg-horse-shared/purchase-varenne";
import type {BetSummary} from "./betSummary";
import type {State} from ".";

export const BET_REFUND_SUCCESS_STATUS = "bet/betRefundSuccessStatus" as const;
export const CLEAR_LEGACY_BET_SUMMARIES = "bet/clearLegacyBetSummaries" as const;
export const GET_BET_BY_CODE = "bet/getBetByCode" as const;
export const PLACE_BET = "bet/placeBet" as const;
export const PLACING_BET = "bet/placingBet" as const;
export const PLAYED_BET = "bet/playedBet" as const;
export const PLAYED_BETS = "bet/playedBets" as const;
export const RECEIVE_BET = "bet/receiveBet" as const;
export const RECEIVE_BET_BY_CODE = "bet/receiveBetByCode" as const;
export const RECEIVE_BET_REFUND = "bet/receiveBetRefund" as const;
export const RECEIVE_BET_SPEED_PAYOUT = "bet/receiveBetSpeedPayout" as const;
export const RECEIVE_BET_SUMMARIES = "bet/receiveBetSummaries" as const;
export const RECEIVE_CORRECTED_BET = "bet/receiveCorrectedBet" as const;
export const RECEIVE_LEGACY_BET_SUMMARIES = "bet/receiveLegacyBetSummaries" as const;
export const RECEIVE_UPDATED_BET = "bet/receiveUpdatedBet" as const;
export const REQUEST_BET = "bet/requestBet" as const;
export const REQUEST_BET_BY_CODE = "bet/requestBetByCode" as const;
export const REQUEST_BET_REFUND = "bet/requestBetRefund" as const;
export const REQUEST_BET_SPEED_PAYOUT = "bet/requestBetSpeedPayout" as const;
export const REQUEST_BET_SUMMARIES = "bet/requestBetSummaries" as const;
export const REQUEST_CORRECTED_BET = "bet/requestCorrectedBet" as const;
export const REQUEST_LEGACY_BET_SUMMARIES = "bet/requestLegacyBetSummaries" as const;
export const REQUEST_UPDATED_BET = "bet/requestUpdatedBet" as const;
export const RESET_BET_ACTION_STATUS = "bet/requestBetCorrectionStatus" as const;
export const RESET_STATUS = "bet/resetStatus" as const;
export const SET_LIVE_BETS_FILTER = "bet/setLiveBetsFilter" as const;
export const START_REQUEST_LIVE_BETS = "bet/startRequestLiveBets" as const;
export const STARTED_VARENNE_FLOW = "bet/startedVarenneFlow" as const;
export const STOP_REQUEST_LIVE_BETS = "bet/stopRequestLiveBets" as const;
export const VARENNE_BET_ERROR = "bet/varenneBetError" as const;
export const VARENNE_BET_SUCCESS = "bet/varenneBetSuccess" as const;

export type BetContext = {
    betContextId: string;
};

export type BetSummariesContext = {
    page: number;
    limit: number;
    appendData?: boolean;
};

type SpeedActionPayload = {
    amount: number;
    fee: number;
    timestamp: string;
};

export type BetFetchAction = FetchAction<
    typeof REQUEST_BET,
    typeof RECEIVE_BET,
    DetailedBet,
    State,
    BetContext
>;

export type BetFetchSummariesAction = FetchAction<
    typeof REQUEST_BET_SUMMARIES,
    typeof RECEIVE_BET_SUMMARIES,
    {data: Array<BetSummary>; totalCount: number},
    State,
    BetSummariesContext
>;

type ResetStatusAction = {
    type: typeof RESET_STATUS;
};

export type SpeedPayoutAction = FetchAction<
    typeof REQUEST_BET_SPEED_PAYOUT,
    typeof RECEIVE_BET_SPEED_PAYOUT,
    SpeedActionPayload,
    BetContext
>;

export type FetchBetSummariesOptions = {
    from?: string;
    to?: string;
    sortBy: string;
    sortDirection: string;
    gameDate?: string;
    page?: number;
    limit?: number;
    cache?: boolean;
    appendData?: boolean;
};

export type BetWithMappedLegacyFlavor = Omit<Bet, "harryFlavour"> & {
    harryFlavour?: "chans" | "favorit";
};

export type PlayedBetSuccessAction = {
    type: typeof PLAYED_BET;
    error?: boolean;
    payload: {
        bet: BetResponse;
        couponId: string | null;
        id: string;
        isReducedBet: boolean;
        isNewBettingSystem: boolean;
        useDirectCouponIntegration: boolean;
    };
};

/**
 * Old error message format. Check function getBetResponseStatus in betAPI.js
 * TODO: Remove when new error format is fully implemented
 */
export type ErrorResponseStatus = {
    code?: string;
    error: {
        httpCode?: string | number;
        message: string;
        reason?: string;
    };
    isLoading?: boolean;
};

export type PlayedBetErrorAction = {
    type: typeof PLAYED_BET;
    error: boolean;
    payload: {
        id: string | null | undefined;
        bet: BetWithMappedLegacyFlavor;
        status: ErrorResponseStatus | string;
        isReducedBet: boolean;
    };
};

export type PlayedBetAction = PlayedBetSuccessAction | PlayedBetErrorAction;

type GetBetByCodeAction = {
    type: typeof GET_BET_BY_CODE;
    payload: {
        betCode: string;
    };
};

type ReceiveBetByCodeAction = {
    type: typeof RECEIVE_BET_BY_CODE;
    error?: boolean;
    payload: {
        betCode: string;
        bet?: {
            data: Bet;
        };
        status?: any;
    };
};

type RequestBetByCodeAction = {
    type: typeof REQUEST_BET_BY_CODE;
    payload: {
        betCode: string;
    };
};

export type ReceiveBetSpeedPayoutAction = {
    type: typeof RECEIVE_BET_SPEED_PAYOUT;
    context: {
        betContextId: string;
    };
    payload: {
        amount: number;
        fee: number;
        timestamp: string;
    };
};

export type StartedVarenneBetAction = {
    type: typeof STARTED_VARENNE_FLOW;
};

export type VarenneBetSuccessAction = {
    type: typeof VARENNE_BET_SUCCESS;
    payload: {
        cid?: string;
        bet?: {
            tsn: string;
            placedAt: string;
            offeringId: string;
        };
        reducedBet?: PlaceReducedBetResponse;
    };
};

type VarenneBetErrorAction = {
    type: typeof VARENNE_BET_ERROR;
};

export type Action =
    | BetFetchAction
    | BetFetchSummariesAction
    | SpeedPayoutAction
    | ResetStatusAction
    | LogoutFinishedAction
    | PlayedBetAction
    | GetBetByCodeAction
    | ReceiveBetByCodeAction
    | RequestBetByCodeAction;

export const resetStatus = (): ResetStatusAction => ({
    type: RESET_STATUS,
});

/**
 *  @deprecated
 *
 *  Usage of this has been purged new functions now use the actions in '/tillsammans-packages/atg-shops-shopShareBet/domain/shopShareBetActions.js'
 */
export const fetchBetByShareId = (
    shareId: string,
    betContextId: string,
): BetFetchAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_BET,
        receiveAction: RECEIVE_BET,
        callApi: call(
            fetchAuthorized,
            `${SHOP_SHARE_SERVICE_URL_TOKENIZED}/shares/${shareId}/receipt`,
            undefined,
            {
                memberFlowEnabled: false,
                fallbackUrl: `${SHOP_SHARE_SERVICE_URL}/shares/${shareId}/receipt`,
            },
        ),
        context: {
            betContextId,
        },
    },
});

export const speedPayout = (betId: string): SpeedPayoutAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_BET_SPEED_PAYOUT,
        receiveAction: RECEIVE_BET_SPEED_PAYOUT,
        callApi: call(fetchAuthorized, `${USER_SERVICE_URL}/bets/${betId}/speedpayout`, {
            method: "POST",
        }),
        context: {
            betContextId: betId,
        },
    },
});

export const fetchBetSummaries = ({
    from,
    to,
    sortBy,
    sortDirection,
    gameDate,
    page = 0,
    limit = 25,
    cache = false,
    appendData = false,
}: FetchBetSummariesOptions): BetFetchSummariesAction => {
    const offset = page * limit;

    const filterQuery = gameDate
        ? `gameDate=${gameDate}`
        : `timestamp_from=${from}&timestamp_to=${to}`;

    return {
        type: FETCH,
        payload: {
            requestAction: REQUEST_BET_SUMMARIES,
            receiveAction: RECEIVE_BET_SUMMARIES,
            callApi: call(
                fetchAuthorized,
                `/services/v1/user/bets?${filterQuery}&sortColumn=${sortBy}&sortDirection=${sortDirection}&offset=${offset}&limit=${limit}&cache=${String(
                    cache,
                )}&forceBetHistory=true`, // forceBetHistory flag tells backend it is ok to fetch from the horse bet history
            ),
            context: {
                page,
                limit,
                appendData,
            },
        },
    };
};

export const playedBet = (
    bet: BetWithMappedLegacyFlavor,
    response: BetResponse,
    isReducedBet: boolean,
    isNewBettingSystem = false,
    useDirectCouponIntegration = false,
): PlayedBetSuccessAction => ({
    type: PLAYED_BET,
    payload: {
        bet: response,
        // @ts-expect-error
        couponId: bet.couponId,
        id: response.id,
        isReducedBet,
        isNewBettingSystem,
        useDirectCouponIntegration,
    },
});

export const playedBetError = (
    bet: BetWithMappedLegacyFlavor,
    status: ErrorResponseStatus | string,
    isReducedBet = false,
): PlayedBetErrorAction => ({
    type: PLAYED_BET,
    error: true,
    payload: {
        id: bet.couponId,
        bet,
        status,
        isReducedBet,
    },
});

export const playedBets = (betIds: Array<string>) => ({
    type: PLAYED_BETS,
    payload: {betIds},
});

export const placingBet = () => ({
    type: PLACING_BET,
});

export const requestBetByCode = (betCode: string): RequestBetByCodeAction => ({
    type: REQUEST_BET_BY_CODE,
    payload: {betCode},
});

export const receiveBetByCode = (
    betCode: string,
    bet: {data: Bet},
): ReceiveBetByCodeAction => ({
    type: RECEIVE_BET_BY_CODE,
    payload: {
        betCode,
        bet,
    },
});

export const receiveBetByCodeError = (
    betCode: string,
    res: {
        response: HttpResponse;
    },
): ReceiveBetByCodeAction => {
    const formattedResponse = res.response ? res.response : res;

    return {
        type: RECEIVE_BET_BY_CODE,
        error: true,
        payload: {
            betCode,
            // @ts-expect-error
            status: getStatusFromResponse(formattedResponse, {
                "404": "Ogiltig rättningskod. Rättningskoden är aktiv i 10 dagar.",
            }),
        },
    };
};

export const getBetByCode = (betCode: string): GetBetByCodeAction => ({
    type: GET_BET_BY_CODE,
    payload: {betCode},
});

export const startedVarenneBet = (): StartedVarenneBetAction => ({
    type: STARTED_VARENNE_FLOW,
});

export const varenneBetSuccess = ({
    cid,
    bet,
    reducedBet,
}: {
    cid?: string;
    bet?: {
        tsn: string;
        placedAt: string;
        offeringId: string;
    };
    reducedBet?: PlaceReducedBetResponse;
}): VarenneBetSuccessAction => ({
    type: VARENNE_BET_SUCCESS,
    payload: {
        cid,
        bet,
        reducedBet,
    },
});

export const varenneBetError = (): VarenneBetErrorAction => ({
    type: VARENNE_BET_ERROR,
});
