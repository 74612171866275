import log, {serializeError} from "@atg-shared/log";
import {delay, spawn, put, select, takeLatest, takeEvery, call} from "redux-saga/effects";
import {flow, keys, keyBy, isEqual, get, flatMap} from "lodash/fp";
import type {Response} from "@atg-shared/response-mapping/deprecated_loadingStatus";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {CalendarDay} from "@atg-horse-shared/calendar/domain/calendar";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as CalendarSelectors from "@atg-horse-shared/calendar/domain/calendarSelectors";
import {serverDate} from "@atg-shared/server-time";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    REQUEST_CALENDAR,
    RECEIVE_CALENDAR,
    RECEIVE_CALENDAR_PUSH,
    STOP_LISTENING_TO_CALENDAR_PUSH,
    RESET_STATUS,
} from "@atg-horse-shared/calendar/domain/calendarActions";
// @ts-ignore
// eslint-disable-next-line @nx/enforce-module-boundaries
import {correctBet} from "@atg-horse/horse-bet/src/domain/betSaga";
import type {TrackHorseInfo} from "./reducerPartials/types";
import * as LiveSelectors from "./liveSelectors";
import {getTrackHorseInfo} from "./liveApi";
import {
    ongoingRace,
    finishedRace,
    resultTimerStarted,
    resultTimerElapsed,
    REQUEST_CORRECT_LIVE_BET,
    liveBetCorrected,
    liveBetCorrectedError,
    FETCH_TRACK_HORSE_INFO,
    type GetTrackHorseInfoActionType,
    trackHorseInfoLoaded,
    trackHorseInfoLoadError,
    fetchingTrackHorseInfo,
} from "./liveactions";

let oldRaces: Record<string, any> = {};
const RACE_RESULT_DELAY = 2 * 60 * 1000;

function* elapseAfterDelay(raceId: string, delayTime: number) {
    yield delay(delayTime);
    yield put(resultTimerElapsed(raceId));
}

function* updateLiveBetReceipts(delayTime: number) {
    // @ts-ignore
    const day: CalendarDay | null = yield select(
        CalendarSelectors.getCalendarDay,
        serverDate(),
    );
    const newRaces = flow(get("tracks"), flatMap("races"), keyBy("id"))(day);

    if (isEqual(oldRaces, newRaces)) return;

    const racesKeys = keys(newRaces);
    let i = 0;
    while (i < racesKeys.length) {
        const key = racesKeys[i];
        const oldRace = oldRaces[key]; // previous race state
        const newRace = newRaces[key]; // new race state
        if (!isEqual(newRace, oldRace) && oldRace) {
            if (oldRace.status !== "results" && newRace.status === "results") {
                // race has finished, but keep the receipt visible for two more minutes
                yield put(resultTimerStarted(newRace.id));
                // start a timer that will remove the race from the list
                yield spawn(elapseAfterDelay, newRace.id, delayTime);
            }
            if (oldRace.status !== "ongoing" && newRace.status === "ongoing") {
                // mark race as ongoing
                yield put(ongoingRace(newRace.id));
            }
            if (oldRace.status === "ongoing" && newRace.status !== "ongoing") {
                // mark race as finished
                yield put(finishedRace(newRace.id));
            }
        }

        i += 1;
    }

    oldRaces = newRaces;
}

function* correctLiveBet(action: any) {
    const {payload} = action;
    try {
        // @ts-expect-error
        yield* correctBet({payload: {betId: action.payload.id, checkable: true}});
        yield put(liveBetCorrected(payload));
    } catch (err: unknown) {
        yield put(liveBetCorrectedError(payload, err as Response));
    }
}

function* fetchTrackHorseInfo(action: GetTrackHorseInfoActionType) {
    const {track, date} = action;
    const {trackHorseInfoLoadingStatus} = yield select(
        LiveSelectors.getLiveBetLoadingStatus,
    );

    const raceDayIndex = `${date}_${track}`;

    try {
        // if we have ever made a request to racingInfo for trackHorseInfo -> we should not do it again
        // (regardless if it was a success)
        if (trackHorseInfoLoadingStatus[raceDayIndex]) return;

        yield put(fetchingTrackHorseInfo(raceDayIndex));

        const res: {data: {trackWithHorseInformation: Record<number, TrackHorseInfo>}} =
            yield call(getTrackHorseInfo, track, date);
        const {trackWithHorseInformation} = res.data;
        yield put(
            trackHorseInfoLoaded({
                [raceDayIndex]: trackWithHorseInformation[track],
            }),
        );
    } catch (e: unknown) {
        yield put(trackHorseInfoLoadError(raceDayIndex));
        log.error("liveSaga: fetchingTrackHorseInfo failed", {
            track,
            date,
            error: serializeError(e),
        });
    }
}

export default function* liveSaga(delayTime: number = RACE_RESULT_DELAY) {
    yield takeLatest(
        [
            REQUEST_CALENDAR,
            RECEIVE_CALENDAR,
            RECEIVE_CALENDAR_PUSH,
            STOP_LISTENING_TO_CALENDAR_PUSH,
            RESET_STATUS,
        ],
        updateLiveBetReceipts,
        delayTime,
    );

    yield takeEvery([FETCH_TRACK_HORSE_INFO], fetchTrackHorseInfo);
    yield takeLatest(REQUEST_CORRECT_LIVE_BET, correctLiveBet);
}
