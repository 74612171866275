import {
    find,
    map,
    flow,
    includes,
    endsWith,
    values,
    every,
    filter,
    compact,
    difference,
    differenceWith,
    findIndex,
} from "lodash/fp";
import {last, head, sortBy} from "lodash";
import {slugifyNoDash} from "@atg/utils/strings";
import {parseGameId} from "@atg-horse-shared/utils/gameid";
import * as GameTypes from "@atg-horse-shared/game-types";
import type {GameType} from "@atg-horse-shared/game-types";
import {serverDate} from "@atg-shared/server-time";
import type {CalendarTrack} from "@atg-horse-shared/racing-info-api/game/types";
import type {
    GameSchedule,
    GameScheduleRace,
} from "@atg-horse/product-pages/domain/product";
import type {CalendarDay} from "@atg-horse-shared/calendar/domain/calendar";
import * as Calendar from "@atg-horse-shared/calendar/domain/calendar";
import {NavigationSelectors} from "@atg-horse-shared/startlist-navigation";
import {type ParsedPath, parsePath} from "atg-horse-game/domain/gameUrl";

const getClosestAvailableRaceNumber = (
    calendarDay: CalendarDay,
    track: CalendarTrack,
    gameType: GameType,
): number => {
    const games = calendarDay.games[gameType];
    let game = find({status: "ongoing", tracks: [track.id]}, games); // closest ongoing
    if (!game) game = find({status: "bettable", tracks: [track.id]}, games); // closest bettable

    if (game) {
        const race = find({id: game.races[0]}, track.races || []);
        return race ? race.number : 1;
    }

    // closest available on track
    const closestAvailableRace = find(
        (race) => Calendar.isSingleRaceGameAvailable(calendarDay, gameType, race.id),
        track.races || [],
    );

    return closestAvailableRace ? closestAvailableRace.number : 1;
};

const getRaceNumberForGame = (
    calendarDay: CalendarDay,
    trackId: number,
    gameType: GameType,
    raceNumber: number | null | undefined,
): number => {
    const track = find({id: trackId}, calendarDay.tracks);
    if (!track || !track.races) return 1;

    if (raceNumber) {
        const calendarRace = find({number: raceNumber}, track.races);
        if (!calendarRace) return 1; // default to race 1

        const isGameAvailable = Calendar.isSingleRaceGameAvailable(
            calendarDay,
            gameType,
            calendarRace.id,
        );

        if (isGameAvailable) return raceNumber;
    }

    return getClosestAvailableRaceNumber(calendarDay, track, gameType);
};

export const getTracksByTrackNameSlug = (
    calendarDay: CalendarDay,
    trackName: string | null | undefined,
): Array<CalendarTrack> | null | undefined => {
    if (!trackName) return null;
    if (includes("-", trackName)) {
        const trackNames = trackName.split("-");
        return flow([
            map((_track: CalendarTrack) => ({
                ..._track,
                name: slugifyNoDash(_track.name),
            })),
            filter((track: CalendarTrack) =>
                trackNames.some((name) => name === track.name),
            ),
        ])(calendarDay.tracks);
    }

    return flow([
        map((_track: CalendarTrack) => ({
            ..._track,
            name: slugifyNoDash(_track.name),
        })),
        filter({name: trackName}),
    ])(calendarDay.tracks);
};

export const getGameIdFromBiggestGameType = (
    calendarDay: CalendarDay | null | undefined,
): string | null | undefined => {
    // @ts-expect-error
    const calendarGame = Calendar.findBiggestCalendarGameForCalendarDay(calendarDay);
    return calendarGame ? calendarGame.id : null;
};

export const getGameIdFromGameTypeAndTrackIds = (
    calendarDay: CalendarDay,
    gameType: GameType,
    trackIds: Array<number>,
    raceNumber: number | null | undefined,
): string | null | undefined => {
    const calendarGame = find((_calendarGame) => {
        // If we are in here, we basically have a full game url.
        // For single race games we need to take in to account the race number
        // to get the correct gameId
        if (GameTypes.isSingleRaceGameType(gameType) && raceNumber) {
            return (
                includes(trackIds[0], _calendarGame.tracks) &&
                endsWith(`${raceNumber}`, _calendarGame.id || "")
            );
        }

        return difference(trackIds, _calendarGame.tracks).length === 0;
    }, calendarDay.games[gameType]);

    return calendarGame && calendarGame.id ? calendarGame.id : null;
};

export const getGameIdFromBiggestGameTypeOnTracks = (
    calendarDay: CalendarDay,
    tracks: Array<CalendarTrack> | null | undefined,
): string | null | undefined => {
    if (!tracks) return null;

    const biggestGameTypes = compact(map("biggestGameType", tracks));
    if (!biggestGameTypes.length) return null;

    const calendarGames = calendarDay.games[biggestGameTypes[0]];
    const calendarGame = find(
        (game) =>
            differenceWith(
                (calendarTrack, gameTrackId) => calendarTrack.id === gameTrackId,
                tracks,
                game.tracks,
            ).length === 0,
        calendarGames,
    );

    return calendarGame ? calendarGame.id : null;
};

export const getGameIdFromCalendarDay = (
    calendarDay: CalendarDay | null | undefined,
    parsedGamePath: ParsedPath,
    currentTrackId?: number | null,
): string | null | undefined => {
    if (!calendarDay) return null;

    const isAllGamesScheduled = Calendar.isAllGamesScheduled(calendarDay);
    if (isAllGamesScheduled) return null;

    const valuesOfParsedGamePath = values(parsedGamePath);
    const isAllValuesFalsy = every((value) => !value, valuesOfParsedGamePath);

    const onlyDateInPath =
        parsedGamePath.date && !parsedGamePath.track && !parsedGamePath.gameType;
    // /spel or /spel/{date}
    if (isAllValuesFalsy || onlyDateInPath)
        return getGameIdFromBiggestGameType(calendarDay);

    // Links from travsport.se have the track number instead of name.
    // If the below line fails it will be NaN, which is falsy.
    const trackIdFromSlug = parseInt(parsedGamePath.track as string, 10);

    let tracks;

    if (trackIdFromSlug) {
        const track = Calendar.findTrackById(calendarDay, trackIdFromSlug) || null;
        tracks = track ? [track] : null;
    } else {
        tracks = getTracksByTrackNameSlug(calendarDay, parsedGamePath.track);
    }

    const trackIds = map("id", tracks);

    // /spel/{date}/{gameType}/{trackName}
    if (parsedGamePath.gameType && trackIds.length) {
        const raceNumber = GameTypes.isSingleRaceGameType(parsedGamePath.gameType)
            ? getRaceNumberForGame(
                  calendarDay,
                  trackIds[0],
                  parsedGamePath.gameType,
                  parsedGamePath.raceNumber,
              )
            : parsedGamePath.raceNumber;

        return getGameIdFromGameTypeAndTrackIds(
            calendarDay,
            parsedGamePath.gameType,
            trackIds,
            raceNumber,
        );
    }

    // /spel/{date}/{gameType}
    if (parsedGamePath.gameType && !trackIds.length) {
        const gameType = parsedGamePath.gameType as GameType;
        const games = calendarDay.games[gameType];

        const tracksToSearch = currentTrackId ? {tracks: [currentTrackId]} : {};

        if (GameTypes.isSingleRaceGameType(parsedGamePath.gameType)) {
            let game = find({status: "ongoing", ...tracksToSearch}, games);
            if (!game) game = find({status: "bettable", ...tracksToSearch}, games);
            if (game) return game.id;
        }

        const currentTrackIndex = currentTrackId
            ? findIndex({tracks: [currentTrackId]}, games)
            : 0;
        const trackIndex = currentTrackIndex > -1 ? currentTrackIndex : 0;

        const game = calendarDay.games[gameType]
            ? calendarDay.games[gameType][trackIndex]
            : null;

        return game ? game.id : null;
    }

    // /spel/{date}/{trackName}
    return getGameIdFromBiggestGameTypeOnTracks(calendarDay, tracks);
};

export const getCalendarDateFromPath = (
    path: Array<string>,
): string | null | undefined => {
    const parsedPath = parsePath(path);

    if (parsedPath.gameType && !parsedPath.date) return null;
    return parsedPath.date || serverDate();
};

type GameSelectorFn = (games: Array<GameScheduleRace>) => GameScheduleRace;
type ClosestGame = {
    date: string | null | undefined;
    id: string;
};

const getClosestGame = (
    games: Array<{
        [key: string]: any;
    }>,
    selectorFn: GameSelectorFn,
): ClosestGame | null | undefined => {
    const sortedGames = sortBy(games, (currentGame) => currentGame.startTime);
    // @ts-expect-error
    const game = selectorFn(sortedGames);
    const gameIdParts = parseGameId(game.id);
    if (!gameIdParts) return null;

    return {
        date: Calendar.parseCalendarDate(gameIdParts.date),
        id: game.id,
    };
};

export const getCalendarDateFromSchedule = (
    gameSchedule: GameSchedule,
): ClosestGame | null | undefined => {
    if (gameSchedule.upcoming && gameSchedule.upcoming.length) {
        // @ts-expect-error
        return getClosestGame(gameSchedule.upcoming, head);
    }

    if (gameSchedule.results && gameSchedule.results.length) {
        // @ts-expect-error
        return getClosestGame(gameSchedule.results, last);
    }

    return null;
};

export const getLastVisitedGameType = (state: {
    [key: string]: any;
}): string | null | undefined => {
    // @ts-expect-error
    const visitedGameTypes = NavigationSelectors.getProductStateGameTypes(state);
    if (!visitedGameTypes.length) return null;

    const sortedGameTypesByTimeAdded = sortBy(visitedGameTypes, (gameType) =>
        // @ts-expect-error
        NavigationSelectors.getTimeAddedForGameType(state, gameType),
    ).reverse();

    return sortedGameTypesByTimeAdded[0];
};
