import * as React from "react";
import type {Props as SvgIconPropType} from "../components/SvgIcon";
import SvgIcon from "../components/SvgIcon";

export function Cross(props: SvgIconPropType) {
    return (
        <SvgIcon {...props}>
            <path d="M30.5 23.3L23.2 16l7.3-7.3c2-2 2-5.2 0-7.2s-5.2-2-7.2 0L16 8.8 8.7 1.5c-2-2-5.2-2-7.2 0s-2 5.2 0 7.2L8.8 16l-7.2 7.3c-2 2-2 5.2 0 7.2s5.2 2 7.2 0l7.2-7.3 7.3 7.3c2 2 5.2 2 7.2 0 2-2.1 2-5.3 0-7.2z" />
        </SvgIcon>
    );
}

export default Cross;
