import {PoolParticipation} from "@atg-horse-shared/bet-types";
import {GameTypes, type GameType} from "@atg-horse-shared/game-types";
import type {HarryCouponTypes} from "@atg-horse-shared/coupon-types";
import {CouponTypes} from "@atg-horse-shared/coupon-types";
import {t} from "@lingui/macro";

const getV6Label = () => t`V6 - endast vinst vid 6 rätt`;
const getV6MixLabel = () => t`Spelet innehåller V6 - endast vinst vid 6 rätt`;
const getGs7Label = () => t`GS7 - endast vinst vid 7 rätt`;

// const GS7_MIX_LABEL = "Spelet innehåller GS7 - endast vinst vid 7 rätt";

/**
 * @param poolParticipation
 * @returns string | null
 */
export const betMethodLabel = (poolParticipation?: PoolParticipation | null) => {
    switch (true) {
        case poolParticipation === PoolParticipation.Pool6:
            return getV6Label();
        case poolParticipation === PoolParticipation.Pool7:
            return getGs7Label();
        default:
            return null;
    }
};

export const getBetMethodLabelForFilebet = (coupons: Array<PoolParticipation>) => {
    if (coupons.every((coupon) => coupon === PoolParticipation.Pool6)) {
        return getV6Label();
    }

    if (coupons.some((coupon) => coupon === PoolParticipation.Pool6)) {
        return getV6MixLabel();
    }

    return null;
};

export const isReducedCouponTypes = (couponType?: CouponTypes | HarryCouponTypes) =>
    couponType === CouponTypes.REDUCED || couponType === CouponTypes.PRIVATE_TEAM_REDUCED;

export const getReducedSharedBetMaxSystemSizeLimits = (gameType?: GameType) => {
    switch (gameType) {
        case GameTypes.V75:
        case GameTypes.GS75:
        case GameTypes.V86:
            return 5000;
        case GameTypes.V64:
        case GameTypes.V65:
            return 2000;
        case GameTypes.V5:
        case GameTypes.V4:
            return 500;
        case GameTypes.top7:
            return 100;
        default:
            return 5000;
    }
};
