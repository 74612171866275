import type {ShareGame} from "@atg-shop-shared/types/share";
import type {Game as RacingInfoGame} from "@atg-horse-shared/racing-info-api/game/types";
import {type Coupon} from "@atg-horse-shared/coupon-types";
import type {ShopShare} from "@atg-shop-shared/types/shopShare";

export const SHOP_SHARE_PRODUCT = "shop/SHARE_PRODUCT";
export const SHOP_SHARE_COUPON_PRODUCT = "shop/SHARE_COUPON_PRODUCT";

export type ShopShareProduct = {
    type: typeof SHOP_SHARE_PRODUCT;
    shareId: string;
    game: ShareGame;
    cost: number;
};

export type ShopShareCouponProduct = {
    type: typeof SHOP_SHARE_COUPON_PRODUCT;
    game: RacingInfoGame;
    cid: string;
    coupon: Coupon;
    shopShare: ShopShare;
};

export type ShopProduct = ShopShareProduct;

export const shopShareProduct = (
    shareId: string,
    game: ShareGame,
    cost: number,
): ShopShareProduct => ({
    type: SHOP_SHARE_PRODUCT,
    shareId,
    game,
    cost,
});

export const shopShareCouponProduct = (
    game: RacingInfoGame,
    coupon: Coupon,
    shopShare: ShopShare,
): ShopShareCouponProduct => ({
    type: SHOP_SHARE_COUPON_PRODUCT,
    game,
    cid: coupon.cid,
    coupon,
    shopShare,
});
