import {find, forEach, isNumber} from "lodash";
import {every, some} from "lodash/fp";
import type {GameType} from "@atg-horse-shared/game-types";
import * as GameTypes from "@atg-horse-shared/game-types";

import type {Location} from "atg-history";
import {
    type ReductionTerms,
    type Tab,
    LETTER_RESTRICTION,
    POINTS_RESTRICTION,
    BASE_SELECTIONS_RESTRICTION,
    EXPECTED_OUTCOME_RESTRICTION,
    GRADING,
    RESERVES,
    RESERVE_TYPES,
    type LettersReductions,
    type BaseSelectionsReductions,
    type ExpectedOutComesReductions,
    type PointsReductions,
    type HorseCount,
    GRADING_STATUS_LIVE,
    GRADING_STATUS_FINAL,
} from "./reducedBet";

export const isLettersUsed = ({restrictions = []}: LettersReductions) =>
    some(
        ({enabled, letterRestrictions}) =>
            some(
                ({min, max}) => enabled && (isNumber(min) || isNumber(max)),
                letterRestrictions,
            ),
        restrictions,
    );

export const isBaseSelectionUsed = (baseSelections: BaseSelectionsReductions) =>
    some(
        ({restrictions: {min, max}, enabled}) =>
            enabled && (isNumber(max) || isNumber(min)),
        baseSelections,
    );

export const isExpectedOutcomeUsed = ({
    restrictions,
    enabled,
}: ExpectedOutComesReductions) => {
    const {min, max} = restrictions;
    return enabled && (typeof min === "number" || typeof max === "number");
};

export const isPointsUsed = (points: PointsReductions) => {
    if (!points || !points?.restrictions) {
        return false; // Splunk logs show that points do not always exist.
    }

    const {
        restrictions: {min, max},
        enabled,
    } = points;

    return enabled && (isNumber(max) || isNumber(min));
};

type Props = {
    reductionMethod: Tab;
    reductionTerms: ReductionTerms | null | undefined;
};

export const isConditionSet = ({reductionMethod, reductionTerms}: Props): boolean => {
    if (!reductionTerms) return false;

    const {expectedOutcome, letters, baseSelections, points} = reductionTerms;
    switch (reductionMethod) {
        case RESERVES:
            return false;
        case GRADING:
            return false;
        case LETTER_RESTRICTION:
            return letters ? isLettersUsed(letters) : false;
        case POINTS_RESTRICTION:
            return isPointsUsed(points);
        case BASE_SELECTIONS_RESTRICTION:
            return isBaseSelectionUsed(baseSelections);
        case EXPECTED_OUTCOME_RESTRICTION: {
            return expectedOutcome?.restrictions
                ? isExpectedOutcomeUsed(expectedOutcome)
                : false;
        }
        default:
            return false;
    }
};

export const hasSetRestrictions = (reductionTerms: ReductionTerms): boolean =>
    some(
        (reductionMethod: any) => isConditionSet({reductionMethod, reductionTerms}),
        [
            LETTER_RESTRICTION,
            POINTS_RESTRICTION,
            BASE_SELECTIONS_RESTRICTION,
            EXPECTED_OUTCOME_RESTRICTION,
        ],
    );

export const isReductionValuesSet = (reductionTerms: ReductionTerms): boolean => {
    const {letters, baseSelections, points} = reductionTerms;

    const hasSetLettersReductions = some(
        ({starts}) => find(starts, (start) => /[^A]/.test(start)),
        letters.races,
    );

    const hasSetPointsReductions = some(
        ({starts}) => find(starts, (start) => start > 0),
        points.races,
    );

    const hasSetBaseSelectionReductions = some(
        ({races}) => some(({starts}) => find(starts, (start) => start === true), races),
        baseSelections,
    );

    return (
        hasSetLettersReductions || hasSetPointsReductions || hasSetBaseSelectionReductions
    );
};

export const isOrderChanged = (reductionTerms: ReductionTerms): boolean => {
    const {ranking} = reductionTerms;
    let orderIsChanged = false;
    let prevHorseStartNumber = -1;

    forEach(ranking, (rank) => {
        forEach(rank, (horse) => {
            if (horse < prevHorseStartNumber) {
                orderIsChanged = true;
                return;
            }
            prevHorseStartNumber = horse;
        });
        prevHorseStartNumber = -1;
    });

    return orderIsChanged;
};

/**
 * Return true if no horses remain after reduction calculation
 */
export const areAllHorsesUnplayed = (horseRowCounts: Array<Array<HorseCount>>) =>
    every(
        every(({numberOfRowsIncludedIn}) => numberOfRowsIncludedIn === 0),
        horseRowCounts,
    );

export const getReserveTypeLabel = (
    reserveType: string,
    gameType: GameType,
): string | null | undefined => {
    const label = find(RESERVE_TYPES, {value: reserveType})?.label;
    return typeof label === "function" ? label(gameType) : label;
};

export const getWorth = (
    value: number,
    jackpot: boolean,
    gradingType: string | null | undefined,
    gameType: string,
): string | number => {
    switch (gameType) {
        case GameTypes.V3:
        case GameTypes.V4:
        case GameTypes.V5: {
            // If race is finished value should be displayed. If no value there is no winner and the payout is on less legs.
            if (gradingType === GRADING_STATUS_FINAL) {
                if (value) {
                    return value;
                }
                switch (gameType) {
                    case GameTypes.V3:
                        return "Utd. på 2 rätt";
                    case GameTypes.V4:
                        return "Utd. på 3 rätt";
                    case GameTypes.V5:
                        return "Utd. på 4 rätt";
                    default:
                        return "-";
                }
            }
            if (gradingType === GRADING_STATUS_LIVE) {
                /*
              If a race is ongoing and no value is delivered it means that it is payout on less legs
              or a win over 200.000 and it should be displayed as *.
          */
                if (value) {
                    return value;
                }
                return "*";
            }
            return "-";
        }
        default: {
            // If race is finished Jackpot and the value, no matter how high, should be displayed.
            if (gradingType === GRADING_STATUS_FINAL) {
                if (jackpot) {
                    return "Jackpot";
                }
                if (value) {
                    return value;
                }
                return "-";
            }
            if (gradingType === GRADING_STATUS_LIVE) {
                /*
              If a race is ongoing and no value is delivered it means that it is Jackpot
              or a win over 200.000 and it should be displayed as *.
          */
                if (value) {
                    return value;
                }
                return "*";
            }
            return "-";
        }
    }
};

export const getWinAmountWorth = (value: number, gameType: string): string | number => {
    switch (gameType) {
        case GameTypes.V64:
        case GameTypes.V65:
        case GameTypes.V86:
        case GameTypes.V75:
        case GameTypes.GS75: {
            if (value === 0) {
                return "Jackpot";
            }
            return value;
        }
        default:
            return value;
    }
};

export const getAsteriskText = (gameType: string): string => {
    switch (gameType) {
        case GameTypes.V5:
            return "* = Ev. värde över 200 000 kr eller utdelning på 4 rätt";
        case GameTypes.V4:
            return "* = Ev. värde över 200 000 kr eller utdelning på 3 rätt";
        case GameTypes.V3:
            return "* = Ev. värde över 200 000 kr eller utdelning på 2 rätt";
        case GameTypes.ld:
        case GameTypes.dd:
            return "";
        default:
            return "* = Ev. värde överstiger 200 000 kr eller Jackpot";
    }
};

/**
 * Utility function that checks if the current url is a reduced bets page
 * @param {Location} location
 * @returns boolean - is it a reduced page
 */
export const isReducedPage = (location: Location) =>
    location.pathname.includes("reducerat/");
