import {isEmpty, reduce} from "lodash";
import dayjs from "dayjs";

import type {HorseSelection} from "@atg-horse-shared/bet-types";
import {assertExists} from "@atg-horse/utils";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {CouponUtils} from "@atg-horse-shared/coupon";
import type {Coupon, ReducedCoupon} from "@atg-horse-shared/coupon-types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {getReducedCost, type ReductionTerms} from "@atg-horse/reduced-bets";

export const getCost = (coupon: Coupon, reductionTerms?: ReductionTerms) => {
    if (!assertExists(coupon)) {
        return 0;
    }

    // for reduced coupons we calculate the cost "on demand", instead of saving it in the bet object
    if (reductionTerms) return getReducedCost(coupon as ReducedCoupon, reductionTerms);

    if (!isEmpty(coupon.addOns)) return CouponUtils.getCostWithAddons(coupon);

    if (coupon.betMethod === "harry") return coupon.harryBetLimit || 0;

    return coupon.cost || 0;
};

export const removeTimePart = (id: string): string | null | any => {
    if (!id) return null;

    const [time, ...rest] = id.split("_");

    return [dayjs(time).format("YYYY-MM-DD"), ...rest].join("_") || null;
};

/**
 * Calculates amount of V3 shares, which is a sum of all baseInvestment in selections
 */
export const calculateV3Shares = (selections: Array<HorseSelection>) =>
    reduce(selections, (a, v) => a + (v.baseInvestment || 0), 0);
