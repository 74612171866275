import {createSelector} from "reselect";
import {RouterSelectors, type GlobalRouterState} from "@atg-shared/router";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {isViewingResults} from "@atg-horse/results-table-displayoptions/components/displayOptions/tableDisplayOptionsSelectors";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {TableDisplayOptionsState} from "@atg-horse/results-table-displayoptions/components/displayOptions/tableDisplayOptions";
import type {GameType} from "@atg-horse-shared/game-types";
import {GameTypes, isDivisionGameType, isVXYGameType} from "@atg-horse-shared/game-types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {type SettingId} from "@atg-horse-shared/startlist-table/src/domain/utils/startlistCellData";
import type {Target} from "./types/startlists";
import type {StartlistSettings} from "./startlistReducer";
import type * as StartlistActions from "./startlistActions";

type GlobalState = {
    startlistSettings: StartlistSettings;
    startlistDisplayOptions: TableDisplayOptionsState;
};
export const getSelectedRaceId = (state: GlobalRouterState) => {
    const pathname = RouterSelectors.getPath(state);
    const urlParts = pathname.split("/");

    return urlParts.length === 6 && urlParts[5].indexOf("avd") !== -1
        ? urlParts[5].substring(3)
        : null;
};

const getSortOptionsForStartlist = (
    state: GlobalState,
): StartlistActions.SortOptionsStartlist => state.startlistSettings.sortOptionsStartlist;

const getSortOptionsResult = (
    state: GlobalState,
): StartlistActions.SortOptionsStartlist => state.startlistSettings.sortOptionsResult;

export const getSortOptions = createSelector(
    isViewingResults,
    getSortOptionsForStartlist,
    getSortOptionsResult,
    (
        inResultsMode: boolean,
        startlistSortOptions: StartlistActions.SortOptionsStartlist,
        resultSortOptions: StartlistActions.SortOptionsStartlist,
    ) => (inResultsMode ? resultSortOptions : startlistSortOptions),
);

export const getSortOptionsExtendedStart = (
    state: GlobalState,
): StartlistActions.SortOptionsExtendedStart =>
    state.startlistSettings.sortOptionsExtendedStart;

export const getShowRaceComments = (state: GlobalState) =>
    state.startlistSettings.globalStartlistSettings.showRaceComments;

export const getShowCompactStartlist = (state: GlobalState) =>
    state.startlistSettings.globalStartlistSettings.showCompactStartlist;

export const getIsVerticalStartlistsActive = (state: GlobalState) =>
    state.startlistSettings.globalStartlistSettings.showVerticalStartlists;

export const getShowVerticalStartlists = (gameType: GameType) => (state: GlobalState) =>
    getIsVerticalStartlistsActive(state) &&
    (isDivisionGameType(gameType) || gameType === GameTypes.raket);

export const getShowBetDistributionDecimals = (state: GlobalState) =>
    state.startlistSettings.globalStartlistSettings.showBetDistributionDecimals;

export const areAllExtendedStartsExpanded = (state: GlobalState) =>
    state.startlistSettings.expandAllExtendedStarts;

export const getCustomStartlists = (state: GlobalState) =>
    state.startlistSettings.customStartlists;

export const getStaticStartlists = (state: GlobalState) =>
    state.startlistSettings.staticStartlists;

export const getAllStartlists = createSelector(
    getStaticStartlists,
    getCustomStartlists,
    (staticStartlists, customStartlists) => [...staticStartlists, ...customStartlists],
);

export const getStartlistName = (id: string) => (state: GlobalState) =>
    getAllStartlists(state).find((startlist) => startlist.id === id)?.name || "";

export const getCurrentStartlist = (id: string) => (state: GlobalState) =>
    getAllStartlists(state).find((startlist) => startlist.id === id);

export const getIsCustomStartlist = (state: GlobalState) =>
    !getStaticStartlists(state)
        .map((startlist) => startlist.id)
        .includes(state.startlistSettings.currentStartlistId);

export const getCurrentStartlistId = (state: GlobalState) =>
    state.startlistSettings.currentStartlistId;

export const getSelectedSettings =
    (id: string, target: Target) =>
    (state: GlobalState): Array<keyof SettingId> => {
        const startlist = getCurrentStartlist(id)(state);
        return startlist?.selectedSettings[target] || [];
    };

export const getSelectedSettingsForResultsTable = (
    state: GlobalState,
): Array<keyof SettingId> => state.startlistSettings.selectedSettingsForResultsTable;

export const getHighlightedPicksGameId = (state: GlobalState) =>
    state.startlistSettings.highlightedPicks.gameId;

export const getHighlightedSelection = (state: GlobalState) =>
    state.startlistSettings.highlightedPicks.selection;

export const getHighlightedPicksSelectedReceiptId = (state: GlobalState) =>
    state.startlistSettings.highlightedPicks.selectedReceiptId;

export const getHasFinishedOnboarding = (state: GlobalState) =>
    state.startlistSettings.hasFinishedOnboarding;

export const getHasClosedOnboardingItem =
    (item: StartlistActions.OnboardingValues) => (state: GlobalState) =>
        state.startlistSettings.onboardings[item];

export const getShowPedigree = (id: string, target: Target) => (state: GlobalState) =>
    getSelectedSettings(id, target)(state).some((setting) => setting === "pedigree");

export const getShowTrainer = (id: string, target: Target) => (state: GlobalState) =>
    getSelectedSettings(id, target)(state).some((setting) => setting === "trainer");

export const getShowTrMediaTipsComment =
    (id: string, target: Target) => (state: GlobalState) =>
        getSelectedSettings(
            id,
            target,
        )(state).some((setting) => setting === "showDagensSpelComment");

export const getShowTRMediaComment =
    (id: string, target: Target) => (state: GlobalState) =>
        getSelectedSettings(
            id,
            target,
        )(state).some((setting) => setting === "showTRMediaComment");

const getIsBetDistributionSelected =
    (id: string, target: Target) => (state: GlobalState) =>
        getSelectedSettings(
            id,
            target,
        )(state).some((setting) => setting === "betDistribution");

const getIsIndexSelected = (id: string, target: Target) => (state: GlobalState) =>
    getSelectedSettings(id, target)(state).some((setting) => setting === "index");

const getIsVOddsSelected = (id: string, target: Target) => (state: GlobalState) =>
    getSelectedSettings(
        id,
        target,
    )(state).some((setting) => setting === "vOdds" || setting === "enforcedVOdds");

const getIsPOddsSelected = (id: string, target: Target) => (state: GlobalState) =>
    getSelectedSettings(
        id,
        target,
    )(state).some((setting) => setting === "pOdds" || setting === "enforcedPOdds");

export const getIsGameSpecificGameOddsSelected =
    (gameType: GameType, id: string, target: Target) => (state: GlobalState) => {
        if (isVXYGameType(gameType)) {
            return getIsBetDistributionSelected(id, target)(state);
        }
        if (gameType === GameTypes.trio) {
            return getIsIndexSelected(id, target)(state);
        }
        if (gameType === GameTypes.plats) {
            return getIsPOddsSelected(id, target)(state);
        }
        return getIsVOddsSelected(id, target)(state);
    };

export const getIsStartlistCustomizationDrawerOpen = (state: GlobalState) =>
    state.startlistSettings.isStartlistCustomizationDrawerOpen;
