import type * as horseFavoritesActions from "./horseFavoritesActions";

export const getFavoriteHorses = (state: any) => state.horse?.horseFavorites?.horses;

export const isHorseMarkedAsFavorite = (horseId: number) => (state: any) =>
    state.horse?.horseFavorites?.horses?.some(
        (horse: horseFavoritesActions.FavoriteHorse) => horse.id === horseId,
    );

export const getPreferences = (state: any): horseFavoritesActions.Preferences =>
    state.horse?.horseFavorites?.preferences;

export const showHorseFavoritesSettingsModal = (state: any): boolean =>
    state.horse?.horseFavorites?.showHorseFavoritesSettingsModal;

export const getIsLoading = (state: any): boolean =>
    state.horse?.horseFavorites?.isLoading;

export const getHasError = (state: any): boolean => state.horse?.horseFavorites?.hasError;
