import {css} from "@emotion/react";

export const close = css`
    font-size: 12px;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
`;

export const icon = css`
    font-size: 12px;
    margin-left: 3px;
    vertical-align: bottom;
`;

export const label = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`;

export const link = css`
    margin-top: 10px;
    color: #fff;
    display: block;
    text-decoration: underline;

    &:hover {
        color: #fff;
    }
`;

export const root = css`
    position: relative;
`;

export const tooltip = css`
    position: absolute;
    left: -195px;
    right: 25px;
    top: -24px;
    z-index: 10;
    background: rgba(0, 0, 0, 0.85);
    color: #fff;
    border-radius: 3px;
    font-weight: normal;
    padding: 15px;
    text-align: initial; /* legacy global less styles set text-align: right, undo here */
    text-transform: none;
    outline: none;
    font-size: 14px;
    white-space: normal;
    line-height: normal;

    &::after {
        position: absolute;
        top: 25px;
        margin-top: -4px;
        right: -16px;
        border: 8px solid transparent;
        border-left-color: rgba(0, 0, 0, 0.85);
        content: "";
        width: 0;
        height: 0;
    }
`;
