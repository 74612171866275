import type {RegisterStep} from "./memberRegisterSteps";

export const MEMBER_REGISTER_VERIFY = "MEMBER_REGISTER_VERIFY";
export const MEMBER_REGISTER_VERIFY_RESPONSE = "MEMBER_REGISTER_VERIFY_RESPONSE";

export const MEMBER_REGISTER_CREATE = "MEMBER_REGISTER_CREATE";
export const MEMBER_REGISTER_CREATE_RESPONSE = "MEMBER_REGISTER_CREATE_RESPONSE";

export const MEMBER_REGISTER_COMPLETE_DISMISS = "MEMBER_REGISTER_COMPLETE_DISMISS";

export const MEMBER_REGISTER_SET_STEP = "MEMBER_REGISTER_SET_STEP";
export const MEMBER_REGISTER_CLEAR = "MEMBER_REGISTER_CLEAR";

export const TOGGLE_FIRST_GAME_MODAL = "TOGGLE_FIRST_GAME_MODAL";

export const DISABLE_WELCOME_MODAL = "DISABLE_WELCOME_MODAL";
export type MemberRegisterVerifyData = {
    userId?: string;
    firstName?: string;
    lastName?: string;
    personalIdentityNumber?: string;
};

export type MemberRegisterContactData = {
    email?: string;
    password?: string;
    cellphone?: string;
};

export type MemberRegisterCreateData = MemberRegisterVerifyData &
    MemberRegisterContactData;

export type MemberRegisterVerifyAction = {
    type: typeof MEMBER_REGISTER_VERIFY;
    payload: MemberRegisterVerifyData;
};

type MemberRegisterVerifySuccessAction = {
    type: typeof MEMBER_REGISTER_VERIFY_RESPONSE;
    error: false;
    payload: MemberRegisterVerifyData;
};

export type MemberRegisterVerifyErrorPayload = {
    data: MemberRegisterVerifyData;
    errorMessage: string;
};

type MemberRegisterVerifyErrorAction = {
    type: typeof MEMBER_REGISTER_VERIFY_RESPONSE;
    error: true;
    payload: MemberRegisterVerifyErrorPayload;
};
export type MemberRegisterCreateAction = {
    type: typeof MEMBER_REGISTER_CREATE;
    payload: MemberRegisterCreateData;
};

type MemberRegisterCreateSuccessAction = {
    type: typeof MEMBER_REGISTER_CREATE_RESPONSE;
    error: false;
    payload: MemberRegisterCreateData;
};

export type MemberRegisterCreateErrorPayload = {
    data: MemberRegisterCreateData;
    errorMessage: string;
};

type MemberRegisterCreateErrorAction = {
    type: typeof MEMBER_REGISTER_CREATE_RESPONSE;
    error: true;
    payload: MemberRegisterCreateErrorPayload;
};

type MemberRegisterCompleteDismissAction = {
    type: typeof MEMBER_REGISTER_COMPLETE_DISMISS;
};

export type MemberRegisterSetStepAction = {
    type: typeof MEMBER_REGISTER_SET_STEP;
    payload: RegisterStep;
};

type MemberRegisterClearAction = {
    type: typeof MEMBER_REGISTER_CLEAR;
};

export type MemberRegisterFirstGameToggleModalAction = {
    type: typeof TOGGLE_FIRST_GAME_MODAL;
    payload: {showModal: boolean};
};

export type DisableWelcomeModal = {
    type: typeof DISABLE_WELCOME_MODAL;
};

export type MemberRegisterAction =
    | MemberRegisterVerifyAction
    | MemberRegisterVerifySuccessAction
    | MemberRegisterVerifyErrorAction
    | MemberRegisterCreateAction
    | MemberRegisterCreateSuccessAction
    | MemberRegisterCreateErrorAction
    | MemberRegisterCompleteDismissAction
    | MemberRegisterSetStepAction
    | MemberRegisterClearAction
    | MemberRegisterFirstGameToggleModalAction
    | DisableWelcomeModal;

export const memberRegisterVerify = (
    payload: MemberRegisterVerifyData,
): MemberRegisterVerifyAction => ({
    type: MEMBER_REGISTER_VERIFY,
    payload,
});
export const memberRegisterVerifySuccess = (
    payload: MemberRegisterVerifyData,
): MemberRegisterVerifySuccessAction => ({
    type: MEMBER_REGISTER_VERIFY_RESPONSE,
    error: false,
    payload,
});
export const memberRegisterVerifyError = (
    payload: MemberRegisterVerifyErrorPayload,
): MemberRegisterVerifyErrorAction => ({
    type: MEMBER_REGISTER_VERIFY_RESPONSE,
    error: true,
    payload,
});

export const memberRegisterCreate = (
    payload: MemberRegisterCreateData,
): MemberRegisterCreateAction => ({
    type: MEMBER_REGISTER_CREATE,
    payload,
});
export const memberRegisterCreateSuccess = (
    payload: MemberRegisterCreateData,
): MemberRegisterCreateSuccessAction => ({
    type: MEMBER_REGISTER_CREATE_RESPONSE,
    error: false,
    payload,
});

export const memberRegisterCreateError = (
    payload: MemberRegisterCreateErrorPayload,
): MemberRegisterCreateErrorAction => ({
    type: MEMBER_REGISTER_CREATE_RESPONSE,
    error: true,
    payload,
});

export const memberRegisterCompleteDismiss = (): MemberRegisterCompleteDismissAction => ({
    type: MEMBER_REGISTER_COMPLETE_DISMISS,
});

export const memberRegisterSetStep = (
    payload: RegisterStep,
): MemberRegisterSetStepAction => ({type: MEMBER_REGISTER_SET_STEP, payload});
export const memberRegisterClear = (): MemberRegisterClearAction => ({
    type: MEMBER_REGISTER_CLEAR,
});

export const toggleMemberFirstGameModal = (
    value: boolean,
): MemberRegisterFirstGameToggleModalAction => ({
    type: TOGGLE_FIRST_GAME_MODAL,
    payload: {showModal: value},
});

export const disableWelcomeModal = (): DisableWelcomeModal => ({
    type: DISABLE_WELCOME_MODAL,
});
