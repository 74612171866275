import * as Sfx from "redux-saga/effects";
import {AuthActions, AuthSelectors} from "@atg-shared/auth";
import {MemberActions} from "@atg-global-shared/member-data-access";
import * as UserSelectors from "@atg-global-shared/user/userSelectors";
import {LimitsActions} from "@atg-responsible-gambling-shared/limits-domain";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as PurchaseActions from "@atg-horse-shared/purchase/src/domain/purchaseActions";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as MemberRegisterActions from "atg-member-register/domain/memberRegisterActions";
import {frameAction} from "atg-store-addons";
import {
    TRIGGER_AUTH_CHECK,
    KEEP_USER_IN_BETTING_STATE,
    type AuthCheckWrapperAction,
} from "./authHelperActions";

/**
 * This saga will check if the user is logged in – if not it will prompt a login and then "wait"
 * until a successful login has happened before proceeding and `put` (dispatch) the original
 * payload.
 */
export function* checkAuthAndProceed({action}: AuthCheckWrapperAction) {
    const isLoggedIn: boolean = yield Sfx.select(UserSelectors.isLoggedIn);
    // if the user is already logged in, just proceed right away
    if (isLoggedIn) {
        yield Sfx.put(action); // Proceed with action
        return;
    }

    yield Sfx.put(AuthActions.checkAuth());

    function* handleAuthResponse() {
        const authResponse:
            | AuthActions.CheckAuthSuccessAction
            | AuthActions.CheckAuthErrorAction
            | MemberActions.MemberRegisterFinishedAction = yield Sfx.take([
            AuthActions.AUTH_CHECK_RESPONSE,
            MemberActions.MEMBER_REGISTER_FINISHED,
        ]);

        if (authResponse.type === MemberActions.MEMBER_REGISTER_FINISHED) {
            /**
             * Prevent the welcome modal after a user has registered.
             * It is not wanted when registering during another flow
             */
            yield Sfx.put(frameAction(MemberRegisterActions.disableWelcomeModal()));

            // wait for limits to be set during registration or flow to be cancelled
            const depositLimitResponse:
                | LimitsActions.SetDepositLimitsSuccessAction
                | PurchaseActions.FinishPurchaseAction = yield Sfx.take([
                LimitsActions.SET_DEPOSIT_LIMITS_SUCCESS,
                PurchaseActions.FINISH_PURCHASE_FLOW,
            ]);

            if (depositLimitResponse.type === LimitsActions.SET_DEPOSIT_LIMITS_SUCCESS) {
                yield Sfx.put(action); // Proceed with authResponse
            }

            return;
        }

        if (
            authResponse.type === AuthActions.AUTH_CHECK_RESPONSE &&
            !("error" in authResponse)
        ) {
            yield Sfx.put(action); // Proceed with action
        }
    }

    yield Sfx.race([
        Sfx.call(handleAuthResponse),
        Sfx.take(MemberActions.CANCELLED_LOGIN_FLOW),
    ]);
}

/**
 * This saga will check if the user is fully logged in (Betting state) and dispatch the action provided.
 * If the user is not fully logged in (General state or logged out) then we bail early
 */
export function* checkAuthOrAbort({
    payload: {shouldTriggerLogin},
}: AuthActions.CheckAuthAction) {
    const isFullyLoggedIn: boolean = yield Sfx.select(AuthSelectors.isNormalLogin);
    if (!isFullyLoggedIn) return;
    yield Sfx.put(AuthActions.checkAuth(shouldTriggerLogin));
}

export default function* authHelperSaga() {
    yield Sfx.takeEvery(TRIGGER_AUTH_CHECK, checkAuthAndProceed);
    yield Sfx.throttle(20 * 1000, KEEP_USER_IN_BETTING_STATE, checkAuthOrAbort);
}
