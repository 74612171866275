import type {GlobalState, TableSettings} from "./tableDisplayOptions";
/**
 * Get the user's tableSettings ("anpassa" settings)
 */
export const getTableSettings = (state: GlobalState): TableSettings =>
    state.startlistDisplayOptions.tableSettings;

export const getTableSettingsFor = (state: GlobalState, target: keyof TableSettings) =>
    getTableSettings(state)[target];

export const isViewingResults = (state: GlobalState): boolean =>
    state.startlistDisplayOptions.isViewingResults;

export const hasClosedNewOtherGamesBox = (state: GlobalState): boolean =>
    state.startlistDisplayOptions.hasClosedNewOtherGamesBox;

export const isShowingRaceComments = (state: GlobalState): boolean =>
    getTableSettings(state).global.selectedFlags.showRaceComments;

export const getExpandAllStartlists = (state: GlobalState): boolean =>
    getTableSettings(state).global.selectedFlags.expandAllStartlists;

export const isShowingTrMediaTipsComment = (state: GlobalState): boolean =>
    getTableSettings(state).global.selectedFlags.showDagensSpelComment;

export const isShowingTRMediaComment = (state: GlobalState): boolean =>
    getTableSettings(state).global.selectedFlags.showTRMediaComment;

export const isViewingResultsForStart = (state: GlobalState, startId: string): boolean =>
    state.startlistDisplayOptions.startsShowingResults.startIds.indexOf(startId) !== -1;

export const getStartsShowingResults = (state: GlobalState): Array<string> =>
    state.startlistDisplayOptions.startsShowingResults.startIds;

export const isAllStartsSneaked = (state: GlobalState): boolean => {
    const displayOptionsState = state.startlistDisplayOptions.startsShowingResults;

    if (displayOptionsState.winnerCount === 0) return false;
    return displayOptionsState.winnerCount === displayOptionsState.startIds.length;
};

export const getWinnerCount = (state: GlobalState): number =>
    state.startlistDisplayOptions.startsShowingResults.winnerCount;
