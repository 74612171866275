import {findIndex} from "lodash";
import {QUALITY_LEVEL_LOCAL_STORAGE_KEY} from "@atg/utils/player-util/types";
import type {QualityLevel} from "@atg/utils/player-util/types";
import {PlayerActionTypes} from "./actionconstants";

export enum PlayerIds {
    WARMUP_ARCHIVE_PLAYER_ID = "bannerRight",
    LIVE_PLAYER_ID = "live",
}

/**
 * Defines which type of menu that is active in the controlbar
 * For now: it should only be one visible menu at one time
 */
export enum ActiveMenu {
    VIDEO_SETTINGS = "video_settings",
    RADAR_MENU = "radar_menu",
}

export type VideoPlayerState = {
    playing: boolean;
    trackCompleted: boolean;
    streams?: any;
    isLowLatencyChromeCastAvailable?: boolean;
    audioOnly: boolean;
    imageURL: string | null;
    // volume is a float integer
    volume: number;
    channel: any | null;
    source: string | null;
    playlist?: any;
    autoPlay: boolean;
    playlistPos: number;
    qualityLevelsCount?: any;
    fullscreen: boolean;
    streamName?: string | null;
    isPlaceAvailableInLowLatency: boolean;
    // `live` in players state has a position inside the live state,
    position?: number;
    loadedMetadata?: boolean;
    isAirplayAvailable: boolean;
    isAirplayCalled: boolean;
    chromecastPlayer: string | null;
    requestFullscreen: boolean;
    activeMenu: ActiveMenu | null;
    qualityLevel?: QualityLevel;
    isBuffering: boolean;
};

export const initialState = () => ({
    playing: false,
    trackCompleted: false,
    streams: null,
    isLowLatencyChromeCastAvailable: false,
    audioOnly: false,
    imageURL: null,
    volume: 1,
    channel: null,
    source: null,
    playlist: null,
    autoPlay: false,
    playlistPos: 0,
    qualityLevelsCount: null,
    fullscreen: false,
    streamName: null,
    isPlaceAvailableInLowLatency: true,
    loadedMetadata: false,
    isAirplayAvailable: false,
    isAirplayCalled: false,
    chromecastPlayer: null,
    requestFullscreen: false,
    activeMenu: null,
    // This will also be used in packages/global-packages/atg-global-player/domain/contexts/ContentfulPlayerContext.tsx and can therefore not use regular persist-redux
    qualityLevel: localStorage.getItem(QUALITY_LEVEL_LOCAL_STORAGE_KEY)
        ? JSON.parse(localStorage.getItem(QUALITY_LEVEL_LOCAL_STORAGE_KEY) || "{}")
        : undefined,
    isBuffering: false,
});

export const createPlayerReducer =
    (id: PlayerIds) =>
    (
        state: VideoPlayerState = initialState(),
        action: {type: PlayerActionTypes | PlayerActionTypes; payload: any},
    ) => {
        const {type, payload} = action;
        if (payload && payload.player === id) {
            switch (type) {
                case PlayerActionTypes.PLAYER_PLAY:
                    return {
                        ...state,
                        playing: true,
                        trackCompleted: false,
                    };
                case PlayerActionTypes.PLAYER_PAUSE:
                    return {
                        ...state,
                        playing: false,
                    };
                case PlayerActionTypes.PLAYER_INIT: {
                    const {streams, qualityLevelsCount} = payload;
                    return {
                        ...state,
                        initialised: true,
                        streams,

                        qualityLevelsCount,
                    };
                }
                case PlayerActionTypes.PLAYER_SOURCE: {
                    const {
                        volume,
                        playlist,
                        source,
                        autoPlay,
                        imageURL,
                        streamName,
                        audioOnly,
                    } = payload;
                    const playlistPos = playlist
                        ? findIndex(playlist, (x) => x === streamName)
                        : 0;
                    return {
                        ...state,
                        position: 0,
                        volume,
                        source,
                        playlist,
                        autoPlay,
                        playlistPos,
                        imageURL,
                        streamName,
                        audioOnly,
                    };
                }
                case PlayerActionTypes.SET_PLAYER_SOURCE_URL: {
                    return {
                        ...state,
                        source: action.payload.url,
                    };
                }

                case PlayerActionTypes.LOW_LATENCY_CHROME_CAST_AVAILABLE: {
                    return {
                        ...state,
                        isLowLatencyChromeCastAvailable: payload.chromeCastAvailable,
                    };
                }
                case PlayerActionTypes.PLAYER_MUTE: {
                    return {
                        ...state,
                        volume: 0,
                    };
                }
                case PlayerActionTypes.PLAYER_UNMUTE: {
                    return {
                        ...state,
                        volume: payload.volume,
                    };
                }
                case PlayerActionTypes.PLAYER_NEXT_TRACK:
                case PlayerActionTypes.PLAYER_PREVIOUS_TRACK:
                    return {
                        ...state,
                        mediaplayListPosition: payload.position,
                    };
                case PlayerActionTypes.PLAYER_CHANGE_VOLUME:
                    return {
                        ...state,
                        volume: payload.volume,
                    };
                case PlayerActionTypes.PLAYER_TRACK_COMPLETED:
                    return {
                        ...state,
                        trackCompleted: true,
                    };
                case PlayerActionTypes.PLAYER_TOGGLE_FULLSCREEN: {
                    return {
                        ...state,
                        fullscreen: !state.fullscreen,
                    };
                }
                case PlayerActionTypes.CHECK_AVAILABLE_PLACE: {
                    return {
                        ...state,
                        isPlaceAvailableInLowLatency: action.payload.availablePlace,
                    };
                }
                case PlayerActionTypes.VIDEO_ENTER_FULLSCREEN: {
                    return {
                        ...state,
                        fullscreen: true,
                    };
                }
                case PlayerActionTypes.VIDEO_EXIT_FULLSCREEN: {
                    return {
                        ...state,
                        fullscreen: false,
                    };
                }
                case PlayerActionTypes.RESET_PLAYLIST_POS: {
                    return {
                        ...state,
                        playlistPos: 0,
                        mediaplayListPosition: 0,
                    };
                }
                case PlayerActionTypes.SHOW_VIDEO_SETTINGS: {
                    const {active} = action.payload;
                    const {activeMenu} = state;

                    if (!active && ActiveMenu.RADAR_MENU === activeMenu) return state;
                    return {
                        ...state,
                        activeMenu: active ? ActiveMenu.VIDEO_SETTINGS : null,
                    };
                }
                case PlayerActionTypes.OPEN_RADAR_MENU: {
                    return {
                        ...state,
                        activeMenu: ActiveMenu.RADAR_MENU,
                    };
                }
                case PlayerActionTypes.CLOSE_RADAR_MENU: {
                    const {activeMenu} = state;

                    if (activeMenu && ActiveMenu.RADAR_MENU !== activeMenu) return state;
                    return {
                        ...state,
                        activeMenu: null,
                    };
                }
                case PlayerActionTypes.REQUEST_FULLSCREEN: {
                    const {requestFullscreen} = action.payload;
                    return {
                        ...state,
                        requestFullscreen,
                    };
                }
                case PlayerActionTypes.PLAYER_DESTROY: {
                    return {
                        ...initialState(),
                        volume: state.volume,
                    };
                }
                case PlayerActionTypes.SET_IS_AIRPLAY_AVAILABLE: {
                    return {
                        ...state,
                        isAirplayAvailable: action.payload.isAirplayAvailable,
                    };
                }
                case PlayerActionTypes.SET_IS_AIRPLAY_CALLED: {
                    return {
                        ...state,
                        isAirplayCalled: !state.isAirplayCalled,
                    };
                }

                case PlayerActionTypes.RESET_AUTOPLAY: {
                    return {
                        ...state,
                        autoPlay: false,
                    };
                }
                case PlayerActionTypes.DEACTIVATE_AUDIO_ONLY: {
                    return {
                        ...state,
                        audioOnly: false,
                        source: null,
                    };
                }
                case PlayerActionTypes.SET_QUALITY_LEVEL: {
                    const {qualityLevel} = action.payload;
                    return {
                        ...state,
                        qualityLevel,
                    };
                }
                case PlayerActionTypes.SET_IS_BUFFERING: {
                    return {
                        ...state,
                        isBuffering: action.payload.isBuffering,
                    };
                }
                default:
                    return state;
            }
        }
        return state;
    };

const playerReducerFactory = (id: PlayerIds) => createPlayerReducer(id);

export default playerReducerFactory;
