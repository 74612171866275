import * as React from "react";
import type {Props as SvgIconPropType} from "../components/SvgIcon";
import SvgIcon from "../components/SvgIcon";

export function Eye(props: SvgIconPropType) {
    return (
        <SvgIcon {...props}>
            <path d="M32 15.6v-.2c0-.2-.2-.5-.3-.6-1-1.2-2.3-2.3-3.5-3.2-2.6-2-5.7-3.8-9-4.5-2-.4-4-.4-5.9-.1-1.8.4-3.5 1-5.1 1.8-2.5 1.2-4.8 3-6.8 4.9-.4.3-.7.7-1 1.1-.5.5-.5 1.1 0 1.6 1 1.2 2.2 2.3 3.4 3.2 2.7 2.1 5.7 3.8 9.1 4.5 1.9.4 3.9.4 5.8.1 1.7-.4 3.5-1 5.1-1.8 2.5-1.2 4.8-3 6.8-4.9.3-.3.7-.7 1-1.1.2-.2.3-.4.3-.7v-.1h.1zm-16 6c-3.4 0-6.1-2.7-6.1-6s2.7-6 6.1-6c3.4 0 6.1 2.7 6.1 6s-2.7 6-6.1 6z" />
            <path d="M16.1 13.6c-1.2 0-2.1.9-2.1 2s.9 2 2.1 2c1.1 0 2-.9 2-2s-.9-2-2-2z" />
        </SvgIcon>
    );
}

export default Eye;
