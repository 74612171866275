import type {AtgErrorResponse} from "@atg-shared/fetch-types";

export enum Reason {
    INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
    RGS_DEPOSIT_LIMIT_NOT_SET = "RGS_DEPOSIT_LIMIT_NOT_SET",
    DEPOSIT_LIMIT_NOT_SET = "DEPOSIT_LIMIT_NOT_SET",
    RGS_SELF_EXCLUSION = "RGS_SELF_EXCLUSION",
    SELF_EXCLUSION = "SELF_EXCLUSION",
    TEMPORARY_SELF_EXCLUSION = "TEMPORARY_SELF_EXCLUSION",
    RGS_NAR_EXCLUSION = "RGS_NAR_EXCLUSION",
    NAR_EXCLUSION = "NAR_EXCLUSION",
    RGS_NAR_UNDETERMINED = "RGS_NAR_UNDETERMINED",
    NAR_UNDETERMINED = "NAR_UNDETERMINED",
    NETLOSS_LIMIT_EXCEEDED = "NETLOSS_LIMIT_EXCEEDED",
    KYC_QUESTIONNAIRE_NOT_SUBMITTED = "KYC_QUESTIONNAIRE_NOT_SUBMITTED",
    SELL_NOT_OPEN = "SELL_NOT_OPEN",
    RGS_KYC_AML_BLOCK = "RGS_KYC_AML_BLOCK",
    KYC_AML_BLOCK = "KYC_AML_BLOCK",
}

const genericError =
    "Osäker status på ditt spel. Var god uppdatera och kontrollera lagda spel under Mitt konto > Mina pengar > Kontohistorik.";

export const get409ErrorMessageFromReason = (reason?: Reason) => {
    switch (reason) {
        case Reason.SELL_NOT_OPEN:
            return "Stängt för spel.";
        case Reason.INSUFFICIENT_FUNDS:
            return "Ditt saldo är för lågt för att lägga spelet.";
        case Reason.RGS_DEPOSIT_LIMIT_NOT_SET:
        case Reason.DEPOSIT_LIMIT_NOT_SET:
            return "Nya regler för spel. Du behöver sätta en insättningsgräns innan du kan lägga ditt spel.";
        case Reason.RGS_SELF_EXCLUSION:
        case Reason.SELF_EXCLUSION:
            return "Ditt konto är spärrat för spel.";
        case Reason.TEMPORARY_SELF_EXCLUSION:
        case Reason.RGS_KYC_AML_BLOCK:
        case Reason.KYC_AML_BLOCK:
            return "Ditt konto är tillfälligt spärrat för spel. Vänligen kontakta ATG Kundservice.";
        case Reason.RGS_NAR_EXCLUSION:
        case Reason.NAR_EXCLUSION:
            return "Ditt konto är spärrat via Spelpaus.se.";
        case Reason.RGS_NAR_UNDETERMINED:
        case Reason.NAR_UNDETERMINED:
            return "Tjänsten spelpaus.se svarar inte just nu. Om du inte har stängt av dig för spel hos spelpaus.se vänligen logga ut och in på nytt.";
        case Reason.NETLOSS_LIMIT_EXCEEDED:
            return "Du har nått din förlustgräns. Kostnaden för spelet överskrider din satta förlustgräns.";
        case Reason.KYC_QUESTIONNAIRE_NOT_SUBMITTED:
            return "Du behöver besvara en kundkännedomsenkät under Mina pengar på atg.se";
        default:
            return genericError;
    }
};

export const get409ErrorMessage = (response: AtgErrorResponse): string => {
    if (!response.data) return genericError;

    const reason = response.data.reason as Reason;

    return get409ErrorMessageFromReason(reason);
};
