import "@atg-shared/micro-frontend/setPublicPath";
import log, {serverLogPlugin} from "@atg-shared/log";
import {configureStoreForAuthentication} from "@atg-shared/auth";
import {store as horseStore} from "@atg-horse/horse-app";
import {initialiseLanguage} from "@atg-shared/language";
import {configureEmotionCache} from "atg-emotion-cache";

serverLogPlugin(log, "horse");
log.setLevel("warn");
configureStoreForAuthentication(horseStore);
configureEmotionCache("horse");

export const SideMenu = () =>
    import(/* webpackChunkName: "SideMenu" */ "./components/SideMenu");

export const VideoFrame = () =>
    import(/* webpackChunkName: "VideoFrame" */ "./components/VideoFrame");

export const App = () => import(/* webpackChunkName: "App" */ "./components/App");

initialiseLanguage(async (locale) => {
    const {messages} = await import(
        /* webpackChunkName: "i18n" */ `../locales/${locale}/messages.po`
    );
    return messages;
});
