import {REQUEST_PROGRAM_CHANGES, RECEIVE_PROGRAM_CHANGES} from "./trackActions";
import type {FetchProgramChangesAction} from "./trackActions";
import type {ProgramChanges} from "./trackApi";

export type ProgramChangesState =
    // loading scenario
    | {
          isLoading: true;
          isError: boolean;
          data: null;
      }
    // error scenario
    | {
          isLoading: boolean;
          isError: true;
          data: null;
      }
    // successfully loaded scenario
    | {
          isLoading: false;
          isError: false;
          data: ProgramChanges;
      };

export type GlobalState = {
    programChanges: ProgramChangesState;
};

const initialState: ProgramChangesState = {
    isLoading: true,
    isError: false,
    data: null,
};

export function programChanges(
    state: ProgramChangesState = initialState,
    action: FetchProgramChangesAction,
): ProgramChangesState {
    switch (action.type) {
        case REQUEST_PROGRAM_CHANGES:
            return {
                isLoading: true,
                isError: false,
                // throw away any potential old data, so we don't show stale data or data for
                // another track (especially express games), especially on slow internet
                data: null,
            };

        case RECEIVE_PROGRAM_CHANGES:
            if (action.error)
                return {
                    isLoading: false,
                    isError: true,
                    data: null,
                };

            return {
                isError: false,
                isLoading: false,
                data: action.payload,
            };

        default:
            return state;
    }
}
