const reportCloseGuide = (
    currentStepIndex: number,
    numberOfSteps: number,
    isAutoStart: boolean,
    isClosedBeforeStart = false,
) => ({
    event: "guide_completion",
    guide_completion: isClosedBeforeStart
        ? "0.0"
        : (currentStepIndex + 1) / numberOfSteps,
    guide_start: isAutoStart ? "auto" : "manual",
});
export default reportCloseGuide;
